import { defineComponent as _defineComponent } from 'vue'
import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, createCommentVNode as _createCommentVNode, withModifiers as _withModifiers, normalizeClass as _normalizeClass, toDisplayString as _toDisplayString, vModelCheckbox as _vModelCheckbox, withDirectives as _withDirectives, vModelText as _vModelText, vShow as _vShow, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "uploader-container" }
const _hoisted_2 = { class: "uploader" }
const _hoisted_3 = ["onClick"]
const _hoisted_4 = ["src"]
const _hoisted_5 = {
  key: 0,
  class: "cover-label"
}
const _hoisted_6 = ["onClick"]
const _hoisted_7 = { class: "editor" }
const _hoisted_8 = { class: "editor-header" }
const _hoisted_9 = { class: "form-check" }
const _hoisted_10 = ["disabled"]
const _hoisted_11 = { class: "form-group" }
const _hoisted_12 = ["value"]
const _hoisted_13 = { class: "form-group" }
const _hoisted_14 = { class: "preview" }
const _hoisted_15 = ["src"]

import { ref, reactive, watch, computed, onMounted, onBeforeUnmount } from 'vue';
import { NewConnector } from './NewConnector';
import ImagePreviewModal from './ImagePreviewModal.vue';
import { ApiAdminProductNew } from '@/admin/global/Api/ProductNew';

const MAX_FILES = 10;

export default /*@__PURE__*/_defineComponent({
  __name: 'NewUploader',
  setup(__props) {

const files = ref<{ id: number, name: string, url: string, isCover?: boolean, header?: string }[]>([]);
const fileInput = ref<HTMLInputElement | null>(null);
const selectedFile = ref<{ id: number, name: string, url: string, isCover?: boolean, header?: string } | null>(null);
const previewFile = ref<{ name: string, url: string } | null>(null);

const selectedFileHeader = computed({
  get: () => selectedFile.value?.header || '',
  set: (value: string) => {
    if (selectedFile.value) {
      selectedFile.value.header = value;
    }
  }
});

const selectedFileIsCover = computed({
  get: () => selectedFile.value?.isCover || false,
  set: (value: boolean) => {
    if (selectedFile.value) {
      updateCoverStatus(selectedFile.value, value);
    }
  }
});

function selectFiles() {
  fileInput.value?.click();
}

async function handleFiles(event: Event) {
  const input = event.target as HTMLInputElement;
  const validExtensions = ['image/svg+xml', 'image/png', 'image/gif', 'image/jpeg', 'image/bmp'];
  if (input.files && input.files.length > 0) {
    for (const file of Array.from(input.files)) {
      if (files.value.length >= MAX_FILES) break;
      if (!validExtensions.includes(file.type)) {
        alert(`Invalid file type: ${file.name}`);
        continue;
      }
      await uploadFile(file);
    }
  }
}

async function uploadFile(file: File) {
  const formData = new FormData();
  formData.append('file', file);

  try {
    const res = await ApiAdminProductNew.uploadFile(formData);
    files.value.push({ id: res.id, name: file.name, url: `/${res.id}--/slug`, isCover: files.value.length === 0, header: '' });
  } catch (err) {
    console.error('Upload failed:', err.response.data);
  }
}

function selectFile(file: { id: number, name: string, url: string, isCover?: boolean, header?: string }) {
  selectedFile.value = file;
}

function deleteFile(file: { id: number, name: string, url: string, isCover?: boolean, header?: string } | null) {
  if (!file) return;

  files.value = files.value.filter(f => f !== file);
  if (selectedFile.value === file) {
    selectedFile.value = null;
  }
}

function updateCoverStatus(file: { id: number, name: string, url: string, isCover?: boolean, header?: string } | null, isCover: boolean) {
  if (!file) return;
  
  files.value.forEach(f => f.isCover = false);
  file.isCover = isCover;
}

function openPreview(file: { name: string, url: string }) {
  previewFile.value = file;
}

watch(files, (newFiles) => {
  NewConnector.uploaded_images = newFiles;
}, { deep: true });

function handleClickOutside(event: MouseEvent) {
  const editor = document.querySelector('.editor');
  if (editor && !editor.contains(event.target as Node) && !event.composedPath().some(el => (el as HTMLElement).classList?.contains('upload-box'))) {
    selectedFile.value = null;
  }
}

onMounted(() => {
  document.addEventListener('click', handleClickOutside);
  if (NewConnector.uploaded_images.length > 0) {
    files.value = NewConnector.uploaded_images;
  }
});

onBeforeUnmount(() => {
  document.removeEventListener('click', handleClickOutside);
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(files.value, (file, index) => {
          return (_openBlock(), _createElementBlock("div", {
            class: _normalizeClass(["upload-box", { selected: selectedFile.value === file }]),
            key: file.id,
            onClick: ($event: any) => (selectFile(file))
          }, [
            _createElementVNode("img", {
              src: file.url,
              class: "uploaded-image"
            }, null, 8, _hoisted_4),
            (file.isCover)
              ? (_openBlock(), _createElementBlock("div", _hoisted_5, "Okładka"))
              : _createCommentVNode("", true),
            _createElementVNode("i", {
              class: "fas fa-search preview-button",
              onClick: _withModifiers(($event: any) => (openPreview(file)), ["stop"]),
              "data-bs-toggle": "modal",
              "data-bs-target": "#previewModal"
            }, null, 8, _hoisted_6)
          ], 10, _hoisted_3))
        }), 128)),
        (files.value.length < MAX_FILES)
          ? (_openBlock(), _createElementBlock("div", {
              key: 0,
              class: "upload-box",
              onClick: selectFiles
            }, _cache[3] || (_cache[3] = [
              _createElementVNode("i", { class: "fas fa-plus" }, null, -1)
            ])))
          : _createCommentVNode("", true),
        _createElementVNode("input", {
          type: "file",
          ref_key: "fileInput",
          ref: fileInput,
          onChange: handleFiles,
          multiple: "",
          accept: ".svg,.png,.gif,.jpg,.jpeg,.bmp",
          style: {"display":"none"}
        }, null, 544)
      ]),
      _withDirectives(_createElementVNode("div", _hoisted_7, [
        _createElementVNode("div", _hoisted_8, [
          _createElementVNode("div", null, "#" + _toDisplayString(selectedFile.value !== null ? files.value.indexOf(selectedFile.value) + 1 : 0) + "/" + _toDisplayString(files.value.length), 1),
          _createElementVNode("div", _hoisted_9, [
            _withDirectives(_createElementVNode("input", {
              type: "checkbox",
              class: "form-check-input",
              id: "coverCheckbox",
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((selectedFileIsCover).value = $event)),
              disabled: files.value.filter(f => f.isCover).length === 1 && selectedFileIsCover.value
            }, null, 8, _hoisted_10), [
              [_vModelCheckbox, selectedFileIsCover.value]
            ]),
            _cache[4] || (_cache[4] = _createElementVNode("label", {
              class: "form-check-label",
              for: "coverCheckbox"
            }, "Obraz Okładki", -1))
          ])
        ]),
        _createElementVNode("div", _hoisted_11, [
          _cache[5] || (_cache[5] = _createElementVNode("label", { for: "fileName" }, "Nazwa pliku", -1)),
          _createElementVNode("input", {
            type: "text",
            id: "fileName",
            class: "form-control",
            value: selectedFile.value?.name,
            disabled: ""
          }, null, 8, _hoisted_12)
        ]),
        _createElementVNode("div", _hoisted_13, [
          _cache[6] || (_cache[6] = _createElementVNode("label", { for: "headerTextarea" }, "Nagłówek", -1)),
          _withDirectives(_createElementVNode("textarea", {
            id: "headerTextarea",
            class: "form-control",
            "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((selectedFileHeader).value = $event))
          }, null, 512), [
            [_vModelText, selectedFileHeader.value]
          ])
        ]),
        _createElementVNode("button", {
          class: "btn btn-danger",
          onClick: _cache[2] || (_cache[2] = ($event: any) => (deleteFile(selectedFile.value)))
        }, "Usuń")
      ], 512), [
        [_vShow, selectedFile.value]
      ])
    ]),
    _withDirectives(_createElementVNode("div", _hoisted_14, [
      _cache[7] || (_cache[7] = _createElementVNode("h3", null, "Preview", -1)),
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(files.value, (file) => {
        return (_openBlock(), _createElementBlock("div", {
          class: "preview-box",
          key: file.id
        }, [
          _createElementVNode("img", {
            src: file.url,
            class: "preview-image"
          }, null, 8, _hoisted_15)
        ]))
      }), 128))
    ], 512), [
      [_vShow, false]
    ]),
    _createVNode(ImagePreviewModal, { file: previewFile.value }, null, 8, ["file"])
  ], 64))
}
}

})