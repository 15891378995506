import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, createVNode as _createVNode, createTextVNode as _createTextVNode, toDisplayString as _toDisplayString, createStaticVNode as _createStaticVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "content" }
const _hoisted_2 = { class: "container-fluid" }
const _hoisted_3 = { class: "row" }
const _hoisted_4 = { class: "col-12" }
const _hoisted_5 = { class: "card" }
const _hoisted_6 = { class: "card-body d-flex justify-content-between" }
const _hoisted_7 = { class: "row" }
const _hoisted_8 = { class: "col-lg-4" }
const _hoisted_9 = { class: "card" }
const _hoisted_10 = { class: "card-body" }
const _hoisted_11 = { class: "row" }
const _hoisted_12 = { class: "col-12 text-center" }
const _hoisted_13 = { class: "activity-box" }
const _hoisted_14 = { class: "card card-widget widget-user-2 shadow-sm mt-3" }
const _hoisted_15 = { class: "widget-user-header bg-primary py-2 pl-3" }
const _hoisted_16 = { class: "small text-warning font-weight-bold" }
const _hoisted_17 = { class: "card-body p-0" }
const _hoisted_18 = { class: "nav flex-column" }
const _hoisted_19 = { class: "nav-item" }
const _hoisted_20 = { class: "nav-link" }
const _hoisted_21 = { class: "float-right h5" }
const _hoisted_22 = { class: "col-lg-8" }
const _hoisted_23 = { class: "card" }
const _hoisted_24 = { class: "card-body" }

import $ from 'jquery';
import 'daterangepicker';
import { onMounted, onUnmounted, ref } from 'vue';
import productsSellsCard from './Home/productsSellsCard.vue';
import HomeDateButtons from './Home/HomeDateButtons.vue';
import { DatetimeButtonDateType } from './Home/HomeDateButtonsEnum';
import HomeMetrics from './Home/HomeMetrics.vue';
import moment from 'moment';
import { DashboardMetricBlock } from './Home/HomeMetricsEnum';
import { nextTick } from 'process';
import { ApiAdminHome, ApiResultBestSeller, ApiResultMetricsData } from '@/admin/global/Api/Home';
import { ApiResultDataSingle } from '@/admin/global/Api/OrdersSearch';


export default /*@__PURE__*/_defineComponent({
  __name: 'Home',
  setup(__props) {

let myChart:{destroy:() => void}|null = {destroy: () => {}};
let before_button_idx = -1;

const date_start = ref('');
const date_end = ref('');

const last_orders = ref([] as Array<ApiResultDataSingle>);
const best_sellers = ref([] as Array<ApiResultBestSeller>);
const metrics_data = ref<ApiResultMetricsData>({ sales: "0", orders: "0", cart_value: "0", visits: "0", conversion_rate: "0", net_profit: "0", new_clients: "0" });

let sales_per_day:Array<string> = [];
let orders_per_day:Array<string> = [];

function dateButtonClicked(button_idx: number) {
  reinitDateRangePicker(button_idx);
}

function loadCustomDateData():void{
  if([DatetimeButtonDateType.today, DatetimeButtonDateType.yesterday].includes(before_button_idx)){
    destroyChart();
  }
  else{
    reinitChart();
  }
}

function reinitDateRangePicker(button_idx: number) {
  let start: moment.Moment;
  let end: moment.Moment;

  if (button_idx === DatetimeButtonDateType.today) {
    start = moment();
    end = moment();
  } else if (button_idx === DatetimeButtonDateType.this_month) {
    start = moment().startOf('month');
    end = moment().endOf('month');
  } else if (button_idx === DatetimeButtonDateType.this_year) {
    start = moment().startOf('year');
    end = moment().endOf('year');
  } else if (button_idx === DatetimeButtonDateType.yesterday) {
    start = moment().subtract(1, 'days');
    end = moment().subtract(1, 'days');
  } else if (button_idx === DatetimeButtonDateType.last_month) {
    start = moment().subtract(1, 'month').startOf('month');
    end = moment().subtract(1, 'month').endOf('month');
  } else if (button_idx === DatetimeButtonDateType.last_year) {
    start = moment().subtract(1, 'year').startOf('year');
    end = moment().subtract(1, 'year').endOf('year');
  } else {
    // Default to today if no valid button_idx is provided
    start = moment();
    end = moment();
  }

  date_start.value = start.format('YYYY/MM/DD');
  date_end.value = end.format('YYYY/MM/DD');

  $("#reservation").daterangepicker({
    locale: {
      format: 'YYYY/MM/DD',
      applyLabel: 'Zastosuj',
      cancelLabel: 'Anuluj',
      fromLabel: 'Od',
      toLabel: 'Do',
      customRangeLabel: 'Niestandardowy',
      daysOfWeek: ['Nd', 'Pn', 'Wt', 'Śr', 'Cz', 'Pt', 'So'],
      monthNames: ['Styczeń', 'Luty', 'Marzec', 'Kwiecień', 'Maj', 'Czerwiec', 'Lipiec', 'Sierpień', 'Wrzesień', 'Październik', 'Listopad', 'Grudzień'],
      firstDay: 1
    },
    startDate: start,
    endDate: end
  });

  $('#reservation').on('apply.daterangepicker', function(ev, picker) {
    loadCustomDateData();
  });

  ApiAdminHome.request({
    dt_from: moment(date_start.value).format('YYYY-MM-DD'),
    dt_to: moment(date_end.value).format('YYYY-MM-DD')
  }, data => {
    last_orders.value = data.last_orders;
    best_sellers.value = data.best_sellers;
    metrics_data.value = data.metrics_data;
    sales_per_day = data.sales_per_day;
    orders_per_day = data.orders_per_day;

    if([DatetimeButtonDateType.today, DatetimeButtonDateType.yesterday].includes(button_idx)){
      destroyChart();
    }
    else{
      reinitChart();
    }
    before_button_idx = button_idx;
  });
}

function generateChartLabels():Array<string> {
  const dt_from = moment(date_start.value).format('YYYY-MM-DD');
  const dt_to = moment(date_end.value).format('YYYY-MM-DD');

  const labels:Array<string> = [];
  let currentDate = moment(dt_from);

  while (currentDate.isSameOrBefore(dt_to)) {
    labels.push(currentDate.format('YYYY-MM-DD'));
    currentDate.add(1, 'days');
  }

  return labels;
}

function reinitChart(metric_block_idx = DashboardMetricBlock.Sales) {
  const colors = {
    [DashboardMetricBlock.Sales]: 'rgb(23, 119, 182, 1)',
    [DashboardMetricBlock.Orders]: 'rgb(44, 161, 33, 1)',
    [DashboardMetricBlock.CartValue]: 'rgb(230, 20, 9, 1)',
    [DashboardMetricBlock.Visits]: 'rgb(255, 127, 0, 1)',
    [DashboardMetricBlock.ConversionRate]: 'rgb(107, 57, 156, 1)',
    [DashboardMetricBlock.NetProfit]: 'rgb(179, 89, 31, 1)'
  };

  const borderColor = colors[metric_block_idx];
  document.getElementById('canvas-out')?.setAttribute('style', 'height: 400px');

  myChart?.destroy();
  const ctx = (document.getElementById('chart') as HTMLCanvasElement).getContext('2d');

  const labels = generateChartLabels();
  const data = metric_block_idx === DashboardMetricBlock.Orders ? orders_per_day : sales_per_day;
  
  // @ts-ignore
  myChart = new Chart(ctx, {
      type: 'line',
      data: {
          labels: labels,
          datasets: [{
              label: 'Sprzedaż',
              data: data,
              backgroundColor: 'rgb(23, 119, 182, 0.2)',
              borderColor: borderColor,
              borderWidth: 1
          }]
      },
      options: {
          scales: {
              y: {
                  beginAtZero: true
              }
          },
          responsive: true,
          maintainAspectRatio: false
      },
      locale: 'pl'
  });
}

function destroyChart(){
  myChart?.destroy();
  document.getElementById('canvas-out')?.setAttribute('style', 'height:0');
}

function metricsSelectionChanged(index: DashboardMetricBlock) {
  reinitChart(index);
}

onMounted(() => {
  reinitDateRangePicker(DatetimeButtonDateType.this_month);
});

onUnmounted(() => {
  if (myChart) {
    myChart.destroy();
  }
});

// function reinitDateRangePicker(startDate: string, endDate: string) {
//   console.log(dateRangePicker[0]);
//   // if (dateRangePicker) {
//   //   dateRangePicker.setStartDate(startDate);
//   //   dateRangePicker.setEndDate(endDate);
//   // }
// }

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _cache[9] || (_cache[9] = _createStaticVNode("<div class=\"content-header\"><div class=\"container-fluid\"><div class=\"row mb-2\"><div class=\"col-sm-6\"><h1 class=\"m-0\">Pulpit</h1></div><div class=\"col-sm-6\"><ol class=\"breadcrumb float-sm-right\"><li class=\"breadcrumb-item active\">Pulpit</li></ol></div></div></div></div>", 1)),
    _createElementVNode("section", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("div", _hoisted_4, [
            _createElementVNode("div", _hoisted_5, [
              _createElementVNode("div", _hoisted_6, [
                _createVNode(HomeDateButtons, { on_change: dateButtonClicked }),
                _cache[0] || (_cache[0] = _createStaticVNode("<div class=\"input-group mr-0 ml-auto\" style=\"width:auto;\"><div class=\"input-group-prepend\"><span class=\"input-group-text\"><i class=\"far fa-calendar-alt\"></i></span><input type=\"text\" class=\"form-control\" id=\"reservation\" style=\"width:210px;margin:0 0 auto auto;\"></div></div>", 1))
              ])
            ])
          ])
        ]),
        _createElementVNode("div", _hoisted_7, [
          _createElementVNode("section", _hoisted_8, [
            _createElementVNode("div", _hoisted_9, [
              _cache[6] || (_cache[6] = _createElementVNode("div", { class: "card-header" }, [
                _createElementVNode("h3", { class: "card-title" }, [
                  _createElementVNode("i", { class: "far fa-clock mr-1" }),
                  _createTextVNode(" Przegląd aktywności ")
                ])
              ], -1)),
              _createElementVNode("div", _hoisted_10, [
                _createElementVNode("div", _hoisted_11, [
                  _createElementVNode("div", _hoisted_12, [
                    _createElementVNode("div", _hoisted_13, [
                      _cache[5] || (_cache[5] = _createStaticVNode("<div><a href=\"#\" class=\"h4 text-primary\">Aktywne koszyki sklepowe: </a><span class=\"h4\"><span class=\"text-todo\">0</span></span></div><span class=\"text-secondary\">w ciągu ostatnich 30 minut</span><div class=\"card card-widget widget-user-2 shadow-sm mt-3\"><div class=\"widget-user-header bg-primary py-2 pl-3\"> Obecnie Oczekują </div><div class=\"card-body p-0\"><ul class=\"nav flex-column\"><li class=\"nav-item\"><span class=\"nav-link\"><a href=\"#\">Zamówienia</a><span class=\"float-right h5\"><span class=\"text-todo ml-2\">0</span></span></span><span class=\"nav-link\"><a href=\"#\">Zwroty/Wymiany</a><span class=\"float-right h5\"><span class=\"text-todo ml-2\">0</span></span></span><span class=\"nav-link\"><a href=\"#\">Porzucone koszyki</a><span class=\"float-right h5\"><span class=\"text-todo ml-2\">0</span></span></span><span class=\"nav-link\"><a href=\"#\">Produkty których brak na stanie</a><span class=\"float-right h5\"><span class=\"text-todo ml-2\">0</span></span></span></li></ul></div></div><div class=\"card card-widget widget-user-2 shadow-sm mt-3\"><div class=\"widget-user-header bg-primary py-2 pl-3\"> Powiadomienia </div><div class=\"card-body p-0\"><ul class=\"nav flex-column\"><li class=\"nav-item\"><span class=\"nav-link\"><a href=\"#\">Nowe wiadomości</a><span class=\"float-right h5\"><span class=\"text-todo ml-2\">0</span></span></span></li></ul></div></div>", 4)),
                      _createElementVNode("div", _hoisted_14, [
                        _createElementVNode("div", _hoisted_15, [
                          _cache[1] || (_cache[1] = _createTextVNode(" Klienci i Biuletyny ")),
                          _cache[2] || (_cache[2] = _createElementVNode("br", null, null, -1)),
                          _createElementVNode("span", _hoisted_16, _toDisplayString(date_start.value) + " - " + _toDisplayString(date_end.value), 1)
                        ]),
                        _createElementVNode("div", _hoisted_17, [
                          _createElementVNode("ul", _hoisted_18, [
                            _createElementVNode("li", _hoisted_19, [
                              _createElementVNode("span", _hoisted_20, [
                                _cache[3] || (_cache[3] = _createElementVNode("a", { href: "#" }, "Nowi Klienci", -1)),
                                _createElementVNode("span", _hoisted_21, _toDisplayString(metrics_data.value.new_clients), 1)
                              ]),
                              _cache[4] || (_cache[4] = _createStaticVNode("<span class=\"nav-link\"><a href=\"#\">Nowe subskrypcje</a><span class=\"float-right h5\"><span class=\"text-todo ml-2\">0</span></span></span><span class=\"nav-link\"><a href=\"#\">Razem Subskrybentów</a><span class=\"float-right h5\"><span class=\"text-todo ml-2\">0</span></span></span>", 2))
                            ])
                          ])
                        ])
                      ])
                    ])
                  ])
                ])
              ])
            ])
          ]),
          _createElementVNode("section", _hoisted_22, [
            _createElementVNode("div", _hoisted_23, [
              _cache[8] || (_cache[8] = _createElementVNode("div", { class: "card-header" }, [
                _createElementVNode("h3", { class: "card-title" }, [
                  _createElementVNode("i", { class: "fas fa-chart-bar" }),
                  _createTextVNode(" Pulpit ")
                ])
              ], -1)),
              _createElementVNode("div", _hoisted_24, [
                _createVNode(HomeMetrics, {
                  on_change: metricsSelectionChanged,
                  metrics_data: metrics_data.value
                }, null, 8, ["metrics_data"]),
                _cache[7] || (_cache[7] = _createElementVNode("div", {
                  id: "canvas-out",
                  style: {"height":"400px"}
                }, [
                  _createElementVNode("canvas", { id: "chart" })
                ], -1))
              ])
            ]),
            _createVNode(productsSellsCard, {
              date_start: date_start.value,
              date_end: date_end.value,
              last_orders: last_orders.value,
              best_sellers: best_sellers.value
            }, null, 8, ["date_start", "date_end", "last_orders", "best_sellers"])
          ])
        ])
      ])
    ])
  ], 64))
}
}

})