import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "col-lg-3 col-6" }
const _hoisted_2 = { class: "inner" }
const _hoisted_3 = { class: "icon" }


export default /*@__PURE__*/_defineComponent({
  __name: 'SmallBox',
  props: {
  count: { type: String },
  title: { type: String },
  icon: { type: String },
  bg_class: { type: String }
},
  setup(__props) {


const props = __props;

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", {
      class: _normalizeClass(["small-box", props.bg_class])
    }, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("h3", null, _toDisplayString(props.count), 1),
        _createElementVNode("p", null, _toDisplayString(props.title), 1)
      ]),
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("i", {
          class: _normalizeClass(["ion", props.icon])
        }, null, 2)
      ])
    ], 2)
  ]))
}
}

})