import { defineComponent as _defineComponent } from 'vue'
import { openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

import { onMounted, ref } from 'vue';
import NewMainScreen from './New/NewMainScreen.vue';
import { CategoriesConnector } from './New/CategoriesConnector';


export default /*@__PURE__*/_defineComponent({
  __name: 'New',
  setup(__props) {

const isLoaded = ref<boolean>(false);

onMounted(() => {
  CategoriesConnector.categoryId = -1;
  CategoriesConnector.name_pl = '';
  CategoriesConnector.name_gb = '';
  CategoriesConnector.description_pl = '';
  CategoriesConnector.description_gb = '';
  CategoriesConnector.meta_title_pl = '';
  CategoriesConnector.meta_title_gb = '';
  CategoriesConnector.meta_description_pl = '';
  CategoriesConnector.meta_description_gb = '';
  CategoriesConnector.meta_keywords_pl = '';
  CategoriesConnector.meta_keywords_gb = '';
  CategoriesConnector.friendly_url_pl = '';
  CategoriesConnector.friendly_url_gb = '';
  CategoriesConnector.is_displayed = true;
  CategoriesConnector.parent_category_id = 2;
  isLoaded.value = true;
});

return (_ctx: any,_cache: any) => {
  return (isLoaded.value)
    ? (_openBlock(), _createBlock(NewMainScreen, { key: 0 }))
    : _createCommentVNode("", true)
}
}

})