import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, vModelText as _vModelText, createElementVNode as _createElementVNode, withDirectives as _withDirectives, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, toDisplayString as _toDisplayString, withModifiers as _withModifiers, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "content" }
const _hoisted_2 = { class: "container-fluid" }
const _hoisted_3 = { class: "row" }
const _hoisted_4 = { class: "col-12" }
const _hoisted_5 = { class: "card" }
const _hoisted_6 = { class: "d-flex justify-content-between p-0" }
const _hoisted_7 = {
  class: "card-title input-group-lg p-3",
  style: {"width":"calc(100% - 600px)"}
}
const _hoisted_8 = { class: "p-3" }
const _hoisted_9 = {
  key: 0,
  class: "row"
}
const _hoisted_10 = { class: "col-9" }
const _hoisted_11 = { class: "card" }
const _hoisted_12 = { class: "card-body" }
const _hoisted_13 = { class: "row" }
const _hoisted_14 = { class: "col-12" }
const _hoisted_15 = { class: "form-group row" }
const _hoisted_16 = { class: "col-12 card-title mb-2" }
const _hoisted_17 = { class: "w-100" }
const _hoisted_18 = { class: "row" }
const _hoisted_19 = { class: "col-12" }
const _hoisted_20 = { class: "form-group row" }
const _hoisted_21 = { class: "w-100" }
const _hoisted_22 = { class: "row" }
const _hoisted_23 = { class: "col-12" }
const _hoisted_24 = { class: "form-group row" }
const _hoisted_25 = { class: "w-100" }
const _hoisted_26 = { class: "col-12" }
const _hoisted_27 = { class: "form-group row" }
const _hoisted_28 = { class: "w-100 pt-1" }
const _hoisted_29 = { class: "col-12" }
const _hoisted_30 = { class: "form-group row mb-0" }
const _hoisted_31 = { class: "col-12" }
const _hoisted_32 = { class: "col-3" }
const _hoisted_33 = { class: "card" }
const _hoisted_34 = { class: "card-header" }
const _hoisted_35 = { class: "card-title font-weight-bold" }
const _hoisted_36 = { class: "card-body" }
const _hoisted_37 = { class: "row" }
const _hoisted_38 = ["value"]
const _hoisted_39 = { class: "card" }
const _hoisted_40 = {
  class: "d-flex justify-content-between",
  style: {"border-bottom":"1px solid rgba(0, 0, 0, .125)","padding":".75rem 1.25rem"}
}
const _hoisted_41 = { class: "card-title font-weight-bold" }
const _hoisted_42 = { class: "card-title" }
const _hoisted_43 = { class: "font-weight-bold" }
const _hoisted_44 = { class: "card" }
const _hoisted_45 = { class: "card-header" }
const _hoisted_46 = { class: "card-title font-weight-bold" }
const _hoisted_47 = { class: "card-body" }
const _hoisted_48 = { class: "row" }
const _hoisted_49 = { class: "col-6" }
const _hoisted_50 = { for: "price_net" }
const _hoisted_51 = { class: "input-group" }
const _hoisted_52 = { class: "col-6" }
const _hoisted_53 = { for: "price_gross" }
const _hoisted_54 = { class: "input-group" }
const _hoisted_55 = { class: "row mt-2" }
const _hoisted_56 = { class: "col-12" }
const _hoisted_57 = { class: "input-group" }
const _hoisted_58 = { class: "font-weight-bold text-primary" }
const _hoisted_59 = {
  key: 1,
  class: "row"
}
const _hoisted_60 = {
  key: 2,
  class: "row"
}
const _hoisted_61 = {
  key: 3,
  class: "row"
}
const _hoisted_62 = {
  key: 4,
  class: "row"
}
const _hoisted_63 = { class: "sticky-footer-section" }
const _hoisted_64 = { class: "d-flex justify-content-center" }
const _hoisted_65 = ["href"]

import ContentHeader from '@/admin/components/Layouts/ContentHeader.vue';
import { Form } from '@/web/global/Form';
import { GeneralCategories } from '@/admin/global/General/Categories';
import { onMounted, ref, watch, onUnmounted } from 'vue';
import { ApiResultCorrect } from '@/admin/global/Api/GeneralCategories';
import NewCategories from '@/admin/views/Panel/Sell/Products/New/NewCategories.vue';
import NewFeatures from './NewFeatures.vue';
import HelpTooltip from '@/admin/components/HelpTooltip.vue';
import VueToggle from "vue-toggle-component";
import NewReleatedProducts from './NewReleatedProducts.vue';
import NewNewCategory from './NewNewCategory.vue';
import NewCombinations from './NewCombinations.vue';
import NewUploader from './NewUploader.vue';
import { NewConnector, ProductStatusType } from './NewConnector';
import NewSummary from './NewSummary.vue';
import NewDescription from './NewDescription.vue';
import NewShipping from './NewShipping.vue';
import NewPricing from './NewPricing.vue';
import NewSEO from './NewSEO.vue';
import Required from '@/admin/components/Required.vue';
import ErrorModal from './ErrorModal.vue';
import { ApiAdminProductNew, ApiParams as ApiParamsNew } from '@/admin/global/Api/ProductNew';
import { Notify } from '@/admin/global/Notify';
import { Notify as NotifyWeb } from '@/web/global/Notify';
import router from '@/admin/router';
import { ApiAdminProductUpdate, ApiParams as ApiParamsUpdate } from '@/admin/global/Api/ProductUpdate';
import { ApiAdminProductDelete } from '@/admin/global/Api/ProductDelete';

enum NavigationType {
  basic_settings = 1,
  combinations = 2,
  shipping = 3,
  pricing = 4,
  seo = 5
}


export default /*@__PURE__*/_defineComponent({
  __name: 'NewMainScreen',
  setup(__props) {

const WEB_URL = process.env.VUE_APP_WEB_URL;
const navigation_idx = ref(1);
function click_navigation(idx: number): void {
  navigation_idx.value = idx;
}

const product_status = ref<ProductStatusType>(NewConnector.product_status);
const price_gross = ref<number>(0);
const price_net = ref<number>(0);
const is_online = ref(false);

const update_netto_price = () => {
  price_net.value = Number((price_gross.value / (1 + NewConnector.vat_rate / 100)).toFixed(6));
  NewConnector.price_net = price_net.value;
  NewConnector.price_gross = price_gross.value;
};

const update_brutto_price = () => {
  price_gross.value = Number((price_net.value * (1 + NewConnector.vat_rate / 100)).toFixed(6));
  NewConnector.price_net = price_net.value;
  NewConnector.price_gross = price_gross.value;
};

const categories = ref({} as ApiResultCorrect);

function clean_tags():void{
  const to_clean_tags = ['name_pl', 'name_gb', 'description_pl', 'description_gb', 'meta_title_pl', 'meta_title_gb', 'meta_description_pl', 'meta_description_gb', 'meta_keywords_pl', 'meta_keywords_gb', 'friendly_url_pl', 'friendly_url_gb'];
  Form.clean_tags(to_clean_tags);
}

GeneralCategories.run_with_data(data => {
  categories.value = data;
});

function prepare_copy_product(): void {
  NotifyWeb.confirm('Czy na pewno chcesz skopiować ten produkt?', () => {
    NewConnector.is_copy = true;
    NewConnector.productNamePL += ' - kopia';
    NewConnector.productNameGB += ' - kopia';
    router.push('/panel/sell/catalog/products/new');
  }, 'Kopiowanie produktu', 'Anuluj');
}

function prepare_delete_product(): void {
  NotifyWeb.confirm('Czy na pewno chcesz usunąć produkt: #' + NewConnector.productId + '?', () => {
    delete_product(NewConnector.productId);
  }, 'Usuń produkt', 'Anuluj');
}

function delete_product(id: number): void {
  ApiAdminProductDelete.request({ id }, () => {
    router.push('/panel/sell/catalog/products');
    Notify.success('Produkt: #' + id + ' został usunięty');
  }, () => {
    console.log('product delete error: ' + id);
  });
}

function prepare_new_product(): void {
  NotifyWeb.confirm('Czy na pewno chcesz rozpocząć tworzenie nowego produktu, bez zapisu aktulanego?', () => {
    router.push('/panel/sell/catalog/products/new');
  }, 'Nowy produkt', 'Anuluj');
}

const productNamePL = ref(NewConnector.productNamePL);
const productNameGB = ref(NewConnector.productNameGB);
const totalQuantity = ref(NewConnector.totalQuantity);

watch(productNamePL, (newValue) => {
  NewConnector.productNamePL = newValue;
});

watch(productNameGB, (newValue) => {
  NewConnector.productNameGB = newValue;
});

watch(navigation_idx, (newValue) => {
  if(newValue === NavigationType.basic_settings){
    totalQuantity.value = NewConnector.totalQuantity;
  }
});

watch(product_status, (newValue) => {
  NewConnector.product_status = newValue;
});

onMounted(() => {
  clean_tags();

  // Synchronize values from NewConnector
  price_net.value = NewConnector.price_net;
  price_gross.value = NewConnector.price_gross;
  is_online.value = NewConnector.is_online;
  if(is_online.value) document.getElementById('online-status')?.click();
  productNamePL.value = NewConnector.productNamePL;
  productNameGB.value = NewConnector.productNameGB;
  totalQuantity.value = NewConnector.totalQuantity;
  product_status.value = NewConnector.product_status;

  NewConnector.reload_action_buttons = () => {
    product_status.value = NewConnector.product_status;
  }
});

onUnmounted(() => {
  NewConnector.reload_action_buttons = () => null;
});

function is_online_changed(){
  is_online.value = !is_online.value;
  NewConnector.is_online = is_online.value;
}

const updateIndex = (event: Event) => {
  NewConnector.index = (event.target as HTMLInputElement).value;
};

const showErrorModal = ref(false);
const errorMessages = ref<string[]>([]);

function createOrUpdateProduct() {
  const params:ApiParamsNew = {
    index: NewConnector.index,
    price_net: NewConnector.price_net,
    price_gross: NewConnector.price_gross,
    categories: NewConnector.categories,
    combinations: NewConnector.combinations,
    summary_pl: NewConnector.summary_pl,
    summary_gb: NewConnector.summary_gb,
    descriptionPL: NewConnector.descriptionPL,
    descriptionGB: NewConnector.descriptionGB,
    features: NewConnector.features,
    related_products: NewConnector.related_products,
    is_online: NewConnector.is_online,
    availabilityPreference: NewConnector.availabilityPreference,
    width: NewConnector.width,
    height: NewConnector.height,
    depth: NewConnector.depth,
    weight: NewConnector.weight,
    deliveryTime: NewConnector.deliveryTime,
    customDeliveryTime: NewConnector.customDeliveryTime,
    productNamePL: NewConnector.productNamePL,
    productNameGB: NewConnector.productNameGB,
    uploaded_images: NewConnector.uploaded_images,
    seoTitlePL: NewConnector.seoTitlePL || NewConnector.productNamePL,
    seoDescriptionPL: NewConnector.seoDescriptionPL || `Apearl poleca ➤ ${NewConnector.productNamePL} ✔️ Szybka wysyłka ⭐️ Duży wybór ✔️ Idealne na prezent ✔️ Wysoka jakość i atrakcyjne ceny ✔️ Sprawdź!`,
    seoUrlPL: NewConnector.seoUrlPL || NewConnector.productNamePL.toLowerCase().replace(/\s+/g, '-'),
    seoTitleGB: NewConnector.seoTitleGB || NewConnector.productNameGB,
    seoDescriptionGB: NewConnector.seoDescriptionGB || `Apearl recommends ➤ ${NewConnector.productNameGB} ✔️ Fast shipping ⭐️ Wide selection ✔️ Perfect for gifts ✔️ High quality and attractive prices ✔️ Check it out!`,
    seoUrlGB: NewConnector.seoUrlGB || NewConnector.productNameGB.toLowerCase().replace(/\s+/g, '-'),
    specific_price: NewConnector.specific_price,
  };

  if(product_status.value === ProductStatusType.new)
    ApiAdminProductNew.request(params, (result) => {
      Notify.success('Produkt został zapisany');
      router.push('/panel/sell/catalog/products/' + result.product_id + '/edit');
    }, (result) => {
      errorMessages.value = Object.keys(result.errors || {}).map(key => `${key}: ${result.errors![key].join(', ')}`);
      showErrorModal.value = true;
      const errorModalElement = document.getElementById('errorModalTrigger');
      if (errorModalElement) {
        errorModalElement.click();
      }
    });
  else if(product_status.value === ProductStatusType.edit){
    const params_update:ApiParamsUpdate = {
      id: NewConnector.productId,
      ... params,
    }
    ApiAdminProductUpdate.request(params_update, (result) => {
      Notify.success('Produkt został zaktualizowany');
    }, (result) => {
      errorMessages.value = Object.keys(result.errors || {}).map(key => `${key}: ${result.errors![key].join(', ')}`);
      showErrorModal.value = true;
      const errorModalElement = document.getElementById('errorModalTrigger');
      if (errorModalElement) {
        errorModalElement.click();
      }
    });
  }
}

return (_ctx: any,_cache: any) => {
  const _component_RouterLink = _resolveComponent("RouterLink")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(ContentHeader, {
      main_title: "Produkty",
      sub_title: product_status.value === _unref(ProductStatusType).new ? 'Nowy produkt' : 'Edytowanie danych produktu'
    }, null, 8, ["sub_title"]),
    _createElementVNode("section", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("div", _hoisted_4, [
            _createElementVNode("div", _hoisted_5, [
              _createElementVNode("div", _hoisted_6, [
                _createElementVNode("h3", _hoisted_7, [
                  _withDirectives(_createElementVNode("input", {
                    name: "title",
                    type: "text",
                    class: "form-control",
                    placeholder: "Nazwa Produktu (PL)",
                    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((productNamePL).value = $event)),
                    onInput: _cache[1] || (_cache[1] = ($event: any) => (_unref(NewConnector).changed_productName()))
                  }, null, 544), [
                    [_vModelText, productNamePL.value]
                  ])
                ]),
                _createElementVNode("div", _hoisted_8, [
                  _createElementVNode("button", {
                    class: _normalizeClass([{ 'btn-primary': navigation_idx.value == NavigationType.basic_settings, 'btn-outline-primary': navigation_idx.value != NavigationType.basic_settings }, "btn mr-2"]),
                    onClick: _cache[2] || (_cache[2] = ($event: any) => (click_navigation(NavigationType.basic_settings)))
                  }, "Ustawienia podstawowe", 2),
                  _createElementVNode("button", {
                    class: _normalizeClass([{ 'btn-primary': navigation_idx.value == NavigationType.combinations, 'btn-outline-primary': navigation_idx.value != NavigationType.combinations }, "btn mr-2"]),
                    onClick: _cache[3] || (_cache[3] = ($event: any) => (click_navigation(NavigationType.combinations)))
                  }, "Kombinacje", 2),
                  false
                    ? (_openBlock(), _createElementBlock("button", {
                        key: 0,
                        class: _normalizeClass([{ 'btn-primary': navigation_idx.value == NavigationType.shipping, 'btn-outline-primary': navigation_idx.value != NavigationType.shipping }, "btn mr-2"]),
                        onClick: _cache[4] || (_cache[4] = ($event: any) => (click_navigation(NavigationType.shipping)))
                      }, "Wysyłka", 2))
                    : _createCommentVNode("", true),
                  _createElementVNode("button", {
                    class: _normalizeClass([{ 'btn-primary': navigation_idx.value == NavigationType.pricing, 'btn-outline-primary': navigation_idx.value != NavigationType.pricing }, "btn mr-2"]),
                    onClick: _cache[5] || (_cache[5] = ($event: any) => (click_navigation(NavigationType.pricing)))
                  }, "Wycena", 2),
                  _createElementVNode("button", {
                    class: _normalizeClass([{ 'btn-primary': navigation_idx.value == NavigationType.seo, 'btn-outline-primary': navigation_idx.value != NavigationType.seo }, "btn"]),
                    onClick: _cache[6] || (_cache[6] = ($event: any) => (click_navigation(NavigationType.seo)))
                  }, "SEO", 2)
                ])
              ])
            ])
          ])
        ]),
        (navigation_idx.value === NavigationType.basic_settings)
          ? (_openBlock(), _createElementBlock("div", _hoisted_9, [
              _createElementVNode("div", _hoisted_10, [
                _createElementVNode("div", _hoisted_11, [
                  _cache[15] || (_cache[15] = _createElementVNode("div", { class: "card-header" }, [
                    _createElementVNode("h3", { class: "card-title font-weight-bold" }, "Ustawienia Podstawowe")
                  ], -1)),
                  _createElementVNode("div", _hoisted_12, [
                    _createElementVNode("div", _hoisted_13, [
                      _createElementVNode("div", _hoisted_14, [
                        _createElementVNode("div", _hoisted_15, [
                          _createElementVNode("label", _hoisted_16, [
                            _cache[10] || (_cache[10] = _createTextVNode("Nazwa produktu (GB) ")),
                            _createVNode(Required)
                          ]),
                          _createElementVNode("div", _hoisted_17, [
                            _withDirectives(_createElementVNode("input", {
                              name: "productNameGB",
                              type: "text",
                              class: "form-control",
                              placeholder: "Nazwa Produktu (GB)",
                              "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((productNameGB).value = $event))
                            }, null, 512), [
                              [_vModelText, productNameGB.value]
                            ])
                          ])
                        ])
                      ])
                    ]),
                    _createElementVNode("div", _hoisted_18, [
                      _createElementVNode("div", _hoisted_19, [
                        _createElementVNode("div", _hoisted_20, [
                          _cache[11] || (_cache[11] = _createElementVNode("label", { class: "col-12 card-title mb-2" }, "Obrazy produktu", -1)),
                          _createElementVNode("div", _hoisted_21, [
                            _createVNode(NewUploader)
                          ])
                        ])
                      ])
                    ]),
                    _createElementVNode("div", _hoisted_22, [
                      _createElementVNode("div", _hoisted_23, [
                        _createElementVNode("div", _hoisted_24, [
                          _cache[12] || (_cache[12] = _createElementVNode("label", { class: "col-12 card-title mb-2" }, "Podsumowanie", -1)),
                          _createElementVNode("div", _hoisted_25, [
                            _createVNode(NewSummary)
                          ])
                        ])
                      ]),
                      _createElementVNode("div", _hoisted_26, [
                        _createElementVNode("div", _hoisted_27, [
                          _cache[13] || (_cache[13] = _createElementVNode("label", { class: "col-12 card-title mb-2" }, "Opis", -1)),
                          _createElementVNode("div", _hoisted_28, [
                            _createVNode(NewDescription)
                          ])
                        ])
                      ]),
                      _createElementVNode("div", _hoisted_29, [
                        _createElementVNode("div", _hoisted_30, [
                          _cache[14] || (_cache[14] = _createElementVNode("label", {
                            class: "col-12 card-title mb-2",
                            "db-name": "features"
                          }, "Cechy", -1)),
                          _createVNode(NewFeatures)
                        ])
                      ]),
                      _createElementVNode("div", _hoisted_31, [
                        _createVNode(NewReleatedProducts)
                      ])
                    ])
                  ])
                ])
              ]),
              _createElementVNode("div", _hoisted_32, [
                _createElementVNode("div", _hoisted_33, [
                  _createElementVNode("div", _hoisted_34, [
                    _createElementVNode("h3", _hoisted_35, [
                      _cache[16] || (_cache[16] = _createTextVNode("Indeks ")),
                      _createVNode(Required),
                      _cache[17] || (_cache[17] = _createTextVNode()),
                      _createVNode(HelpTooltip, { message: "Twój kod referencyjny dla tego produktu. Dozwolone znaki specjalne: .-_#." })
                    ])
                  ]),
                  _createElementVNode("div", _hoisted_36, [
                    _createElementVNode("div", _hoisted_37, [
                      _createElementVNode("input", {
                        name: "indeks",
                        type: "text",
                        class: "form-control",
                        placeholder: "Indeks",
                        onInput: updateIndex,
                        value: _unref(NewConnector).index
                      }, null, 40, _hoisted_38)
                    ])
                  ])
                ]),
                _createElementVNode("div", _hoisted_39, [
                  _createElementVNode("div", _hoisted_40, [
                    _createElementVNode("h3", _hoisted_41, [
                      _cache[18] || (_cache[18] = _createTextVNode("Ilość łączna ")),
                      _createVNode(HelpTooltip, { message: "Ilość łączna zmienia się zależnie od ilości zadeklarowanej w poszczególnych kombinacjach." })
                    ]),
                    _createElementVNode("h3", _hoisted_42, [
                      _createElementVNode("span", _hoisted_43, _toDisplayString(totalQuantity.value), 1),
                      _cache[19] || (_cache[19] = _createTextVNode(" Sztuk"))
                    ])
                  ])
                ]),
                _createElementVNode("div", _hoisted_44, [
                  _createElementVNode("div", _hoisted_45, [
                    _createElementVNode("h3", _hoisted_46, [
                      _cache[20] || (_cache[20] = _createTextVNode("Cena Detaliczna ")),
                      _createVNode(HelpTooltip, { message: "Jest to cena detaliczna, za którą zamierzasz sprzedać ten produkt swoim klientom. Cena netto będzie się zmieniać w zależności od wybranej zasady opodatkowania (Ustawiamy tylko cene brutto)." })
                    ])
                  ]),
                  _createElementVNode("div", _hoisted_47, [
                    _createElementVNode("div", _hoisted_48, [
                      _createElementVNode("div", _hoisted_49, [
                        _createElementVNode("label", _hoisted_50, [
                          _cache[21] || (_cache[21] = _createTextVNode("Netto ")),
                          _createVNode(Required)
                        ]),
                        _createElementVNode("div", _hoisted_51, [
                          _withDirectives(_createElementVNode("input", {
                            name: "price_net",
                            class: "form-control",
                            "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => ((price_net).value = $event)),
                            onInput: update_brutto_price,
                            type: "number",
                            step: "0.01"
                          }, null, 544), [
                            [
                              _vModelText,
                              price_net.value,
                              void 0,
                              { number: true }
                            ]
                          ]),
                          _cache[22] || (_cache[22] = _createElementVNode("div", { class: "input-group-append" }, [
                            _createElementVNode("span", { class: "input-group-text" }, "zł")
                          ], -1))
                        ])
                      ]),
                      _createElementVNode("div", _hoisted_52, [
                        _createElementVNode("label", _hoisted_53, [
                          _cache[23] || (_cache[23] = _createTextVNode("Brutto ")),
                          _createVNode(Required)
                        ]),
                        _createElementVNode("div", _hoisted_54, [
                          _withDirectives(_createElementVNode("input", {
                            name: "price_gross",
                            class: "form-control",
                            "onUpdate:modelValue": _cache[9] || (_cache[9] = ($event: any) => ((price_gross).value = $event)),
                            onInput: update_netto_price,
                            type: "number",
                            step: "0.01"
                          }, null, 544), [
                            [
                              _vModelText,
                              price_gross.value,
                              void 0,
                              { number: true }
                            ]
                          ]),
                          _cache[24] || (_cache[24] = _createElementVNode("div", { class: "input-group-append" }, [
                            _createElementVNode("span", { class: "input-group-text" }, "zł")
                          ], -1))
                        ])
                      ])
                    ]),
                    _createElementVNode("div", _hoisted_55, [
                      _createElementVNode("div", _hoisted_56, [
                        _createElementVNode("div", _hoisted_57, [
                          _createElementVNode("span", _hoisted_58, "Podatek " + _toDisplayString(_unref(NewConnector).vat_rate) + "%", 1)
                        ])
                      ])
                    ])
                  ])
                ]),
                _createVNode(NewCategories),
                _createVNode(NewNewCategory)
              ])
            ]))
          : _createCommentVNode("", true),
        (navigation_idx.value === NavigationType.combinations)
          ? (_openBlock(), _createElementBlock("div", _hoisted_59, [
              _createVNode(NewCombinations)
            ]))
          : _createCommentVNode("", true),
        (navigation_idx.value === NavigationType.shipping)
          ? (_openBlock(), _createElementBlock("div", _hoisted_60, [
              _createVNode(NewShipping)
            ]))
          : _createCommentVNode("", true),
        (navigation_idx.value === NavigationType.pricing)
          ? (_openBlock(), _createElementBlock("div", _hoisted_61, [
              _createVNode(NewPricing)
            ]))
          : _createCommentVNode("", true),
        (navigation_idx.value === NavigationType.seo)
          ? (_openBlock(), _createElementBlock("div", _hoisted_62, [
              _createVNode(NewSEO)
            ]))
          : _createCommentVNode("", true)
      ])
    ]),
    _createElementVNode("section", _hoisted_63, [
      _createElementVNode("div", _hoisted_64, [
        (product_status.value === _unref(ProductStatusType).edit)
          ? (_openBlock(), _createElementBlock("button", {
              key: 0,
              onClick: _withModifiers(prepare_delete_product, ["prevent"]),
              class: "btn btn-danger mr-2"
            }, "Usuń"))
          : _createCommentVNode("", true),
        (product_status.value === _unref(ProductStatusType).edit)
          ? (_openBlock(), _createElementBlock("a", {
              key: 1,
              class: "btn btn-secondary mr-2",
              href: _unref(WEB_URL) + '/[category]/' + _unref(NewConnector).seoUrlPL + '.html',
              target: "_blank"
            }, "Podgląd", 8, _hoisted_65))
          : _createCommentVNode("", true),
        _createElementVNode("label", {
          class: _normalizeClass(["mx-2 mt-auto text-success", { 'text-success': is_online.value, 'text-danger': !is_online.value }]),
          for: "online-status"
        }, _toDisplayString(is_online.value ? 'Online' : 'Offline'), 3),
        _createVNode(_unref(VueToggle), {
          name: "online-status",
          title: "",
          toggled: is_online.value,
          onToggle: is_online_changed
        }, null, 8, ["toggled"])
      ]),
      _createElementVNode("div", null, [
        _createElementVNode("button", {
          class: "btn btn-primary mr-2",
          onClick: createOrUpdateProduct
        }, _toDisplayString(product_status.value === _unref(ProductStatusType).new ? 'Stwórz' : 'Aktualizuj'), 1),
        (product_status.value === _unref(ProductStatusType).edit)
          ? (_openBlock(), _createElementBlock("button", {
              key: 0,
              onClick: _withModifiers(prepare_copy_product, ["prevent"]),
              class: "btn btn-outline-secondary mr-2"
            }, "Skopiuj"))
          : _createCommentVNode("", true),
        (product_status.value === _unref(ProductStatusType).edit)
          ? (_openBlock(), _createBlock(_component_RouterLink, {
              key: 1,
              to: '/panel/sell/catalog/products/',
              class: "btn btn-outline-secondary mr-2"
            }, {
              default: _withCtx(() => _cache[25] || (_cache[25] = [
                _createTextVNode("Idź do katalogu")
              ])),
              _: 1
            }))
          : _createCommentVNode("", true),
        (product_status.value === _unref(ProductStatusType).edit)
          ? (_openBlock(), _createElementBlock("button", {
              key: 2,
              onClick: _withModifiers(prepare_new_product, ["prevent"]),
              class: "btn btn-outline-secondary"
            }, "Dodaj nowy produkt"))
          : _createCommentVNode("", true)
      ])
    ]),
    _createVNode(ErrorModal, {
      show: showErrorModal.value,
      errors: errorMessages.value
    }, null, 8, ["show", "errors"]),
    _cache[26] || (_cache[26] = _createElementVNode("button", {
      id: "errorModalTrigger",
      type: "button",
      class: "btn btn-primary",
      "data-bs-toggle": "modal",
      "data-bs-target": "#errorModal",
      style: {"display":"none"}
    }, null, -1))
  ], 64))
}
}

})