import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, unref as _unref, toDisplayString as _toDisplayString, withModifiers as _withModifiers, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "content content-full-height-with-header" }
const _hoisted_2 = { class: "container-fluid" }
const _hoisted_3 = { class: "row" }
const _hoisted_4 = { class: "row" }
const _hoisted_5 = { class: "col-12" }
const _hoisted_6 = { class: "card" }
const _hoisted_7 = { class: "card-header" }
const _hoisted_8 = { class: "card-title" }
const _hoisted_9 = { class: "card-tools" }
const _hoisted_10 = { class: "nav nav-pills ml-auto" }
const _hoisted_11 = { class: "nav-item" }
const _hoisted_12 = { class: "btn-group" }
const _hoisted_13 = {
  class: "dropdown-menu",
  role: "menu"
}
const _hoisted_14 = {
  class: "card-body table-responsive p-0",
  style: {"height":"500px"}
}
const _hoisted_15 = { class: "table table-head-fixed text-nowrap" }
const _hoisted_16 = {
  key: 0,
  class: "fas fa-sort-down ml-2"
}
const _hoisted_17 = {
  key: 1,
  class: "fas fa-sort-up ml-2"
}
const _hoisted_18 = {
  key: 0,
  class: "fas fa-sort-down ml-2"
}
const _hoisted_19 = {
  key: 1,
  class: "fas fa-sort-up ml-2"
}
const _hoisted_20 = {
  key: 0,
  class: "fas fa-sort-down ml-2"
}
const _hoisted_21 = {
  key: 1,
  class: "fas fa-sort-up ml-2"
}
const _hoisted_22 = {
  key: 0,
  class: "fas fa-sort-down ml-2"
}
const _hoisted_23 = {
  key: 1,
  class: "fas fa-sort-up ml-2"
}
const _hoisted_24 = {
  key: 0,
  class: "fas fa-sort-down ml-2"
}
const _hoisted_25 = {
  key: 1,
  class: "fas fa-sort-up ml-2"
}
const _hoisted_26 = {
  key: 0,
  class: "fas fa-sort-down ml-2"
}
const _hoisted_27 = {
  key: 1,
  class: "fas fa-sort-up ml-2"
}
const _hoisted_28 = {
  key: 0,
  class: "fas fa-sort-down ml-2"
}
const _hoisted_29 = {
  key: 1,
  class: "fas fa-sort-up ml-2"
}
const _hoisted_30 = {
  key: 0,
  class: "fas fa-sort-down ml-2"
}
const _hoisted_31 = {
  key: 1,
  class: "fas fa-sort-up ml-2"
}
const _hoisted_32 = {
  key: 0,
  class: "fas fa-sort-down ml-2"
}
const _hoisted_33 = {
  key: 1,
  class: "fas fa-sort-up ml-2"
}
const _hoisted_34 = { class: "filter-row" }
const _hoisted_35 = { class: "text-center" }
const _hoisted_36 = ["value"]
const _hoisted_37 = { class: "text-center" }
const _hoisted_38 = { class: "text-center" }
const _hoisted_39 = { class: "text-center" }
const _hoisted_40 = ["value"]
const _hoisted_41 = { class: "date-th" }
const _hoisted_42 = { class: "text-center" }
const _hoisted_43 = {
  key: 0,
  class: "badge bg-success"
}
const _hoisted_44 = {
  key: 1,
  class: "badge bg-danger"
}
const _hoisted_45 = {
  key: 1,
  class: "text-danger"
}
const _hoisted_46 = { class: "btn-group" }
const _hoisted_47 = {
  class: "dropdown-menu",
  role: "menu"
}
const _hoisted_48 = {
  key: 0,
  class: "dropdown-item",
  href: "#"
}
const _hoisted_49 = { key: 0 }
const _hoisted_50 = { class: "card-footer clearfix" }

import ContentHeader from '@/admin/components/Layouts/ContentHeader.vue';
import SmallBox from '@/admin/components/Layouts/SmallBox.vue';
import Pager from '@/admin/components/Pager.vue';
import { ref } from 'vue';
import { ApiAdminOrdersExport } from '@/admin/global/Api/OrdersExport';
import { ApiParams } from "@/admin/global/Api/OrdersSearch";
import { Form } from '@/web/global/Form';
import { DateTime } from '@/admin/global/DateTime';
import { ApiAdminOrdersGeneral } from '@/admin/global/Api/OrdersGeneral';
import { ApiAdminOrdersSearch, ApiResultDataSingle } from '@/admin/global/Api/OrdersSearch';
import { GeneralCountries } from '@/admin/global/General/Countries';
import { GeneralCurrencies } from '@/admin/global/General/Currencies';
import { GeneralOrderStates } from '@/admin/global/General/OrderStates';

interface ApiResultDataSingleCustom extends ApiResultDataSingle{
  currency_iso_code: string;
  state_name: string;
}

const item_per_page = 50;

export default /*@__PURE__*/_defineComponent({
  __name: 'Orders',
  setup(__props) {

let item_max = ref(0);
let orders = ref([] as Array<ApiResultDataSingleCustom>);
let actual_page = 1;

let sort_by = ref('id');
let sort_order = ref('asc');

let conversion_rate = ref(0);
let abandoned_carts = ref(0);
let average_order_value = ref(0);
let net_profit_per_visit = ref(0);

const countries = ref({});
const countries_sorted = ref({});
const order_states_sorted = ref({});

GeneralCountries.run_with_data(data => {
  countries.value = data;
});

GeneralCountries.run_with_data_sorted_alphabetically(data => {
  countries_sorted.value = data;
});

GeneralOrderStates.run_with_data_sorted_alphabetically(data => {
  order_states_sorted.value = data;
});

function get_search_params(page = -1, per_page = -1):ApiParams{
  let params:ApiParams = {};

  const params_name = ['id','reference','newcustomer','delivery_country_id','customer','total','payment','state_id','dt_from', 'dt_to'];
  params_name.forEach(name => {
    const v = (Form.value(name) + '').trim();

    if(v.length > 0){
      params[name] = v;
    }
  });

  if(page !== -1){
    params['page'] = page;
    params['per_page'] = per_page;
  }

  params["sort_by"] = sort_by.value;
  params["sort_order"] = sort_order.value === 'asc' ? 'asc' : 'desc';

  return params;
}

function search(page: number) {
  ApiAdminOrdersSearch.request(get_search_params(page, item_per_page), result => {
    orders.value = (result.data as Array<ApiResultDataSingleCustom>).map(v => {
      v.date = DateTime.format_datetime(v.date);
      
      v.currency_iso_code = 'PLN';
      GeneralCurrencies.run_with_data(data => {
        if(data[v.currency_id]){
          v.currency_iso_code = data[v.currency_id].iso_code;
        }
      });

      v.state_name = 'Nieznany';
      GeneralOrderStates.run_with_data(data => {
        if(data[v.state_id]){
          v.state_name = data[v.state_id] ;
        }
      });

      return v;
    });
    
    item_max.value = result.data_count;
    actual_page = page;
  }, () => {
    console.log('error');

    orders.value = [];
    item_max.value = 0;
  });
}

function data_export(){
  ApiAdminOrdersExport.request(get_search_params());
}

function order_by(name: string){
  if(sort_by.value === name){
    sort_order.value = sort_order.value === 'asc' ? 'desc' : 'asc';
  }
  else{
    sort_by.value = name;
    sort_order.value = 'asc';
  }

  search(actual_page);
}

ApiAdminOrdersGeneral.request(result => {
  conversion_rate.value = result.conversion_rate;
  abandoned_carts.value = result.abandoned_carts;
  average_order_value.value = result.average_order_value;
  net_profit_per_visit.value = result.net_profit_per_visit;
});

search(actual_page);

return (_ctx: any,_cache: any) => {
  const _component_RouterLink = _resolveComponent("RouterLink")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(ContentHeader, {
      main_title: "Zamówienia",
      sub_title: "Zarządzanie Zamówieniami"
    }, {
      default: _withCtx(() => [
        _createVNode(_component_RouterLink, {
          to: "/panel/sell/orders/new",
          class: "btn btn-primary"
        }, {
          default: _withCtx(() => _cache[22] || (_cache[22] = [
            _createElementVNode("i", { class: "fas fa-plus-circle" }, null, -1),
            _createTextVNode(" Dodaj nowe zamówienie ")
          ])),
          _: 1
        })
      ]),
      _: 1
    }),
    _createElementVNode("section", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, [
          _createVNode(SmallBox, {
            count: String(_unref(conversion_rate)),
            title: "Stopa konwersji",
            icon: "ion-stats-bars",
            bg_class: "text-todo bg-info"
          }, null, 8, ["count"]),
          _createVNode(SmallBox, {
            count: String(_unref(abandoned_carts)),
            title: "Porzucone koszyki",
            icon: "ion-ios-cart-outline",
            bg_class: "text-todo bg-danger"
          }, null, 8, ["count"]),
          _createVNode(SmallBox, {
            count: String(_unref(average_order_value)),
            title: "Średnia wartość zamówienia",
            icon: "ion-bag",
            bg_class: "text-todo bg-primary"
          }, null, 8, ["count"]),
          _createVNode(SmallBox, {
            count: String(_unref(net_profit_per_visit)),
            title: "Zysk netto na Wizytę",
            icon: "ion-person",
            bg_class: "text-todo bg-success"
          }, null, 8, ["count"])
        ]),
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("div", _hoisted_5, [
            _createElementVNode("div", _hoisted_6, [
              _createElementVNode("div", _hoisted_7, [
                _createElementVNode("h3", _hoisted_8, "Zamówienia (" + _toDisplayString(_unref(item_max)) + ")", 1),
                _createElementVNode("div", _hoisted_9, [
                  _createElementVNode("ul", _hoisted_10, [
                    _createElementVNode("li", _hoisted_11, [
                      _createElementVNode("div", _hoisted_12, [
                        _createElementVNode("button", {
                          class: "btn btn-default btn-sm",
                          onClick: _cache[0] || (_cache[0] = _withModifiers(($event: any) => (data_export()), ["prevent"]))
                        }, _cache[23] || (_cache[23] = [
                          _createElementVNode("i", { class: "fas fa-download mr-1" }, null, -1),
                          _createTextVNode(" Eksportuj ")
                        ])),
                        _cache[26] || (_cache[26] = _createElementVNode("button", {
                          type: "button",
                          class: "btn btn-default btn-sm dropdown-toggle dropdown-icon",
                          "data-toggle": "dropdown",
                          "aria-expanded": "false"
                        }, [
                          _createElementVNode("span", { class: "sr-only" }, "Zobacz więcej")
                        ], -1)),
                        _createElementVNode("div", _hoisted_13, [
                          _createElementVNode("button", {
                            class: "dropdown-item",
                            onClick: _cache[1] || (_cache[1] = _withModifiers(($event: any) => (data_export()), ["prevent"]))
                          }, _cache[24] || (_cache[24] = [
                            _createElementVNode("i", { class: "fas fa-download mr-1" }, null, -1),
                            _createTextVNode(" Eksportuj ")
                          ])),
                          _createElementVNode("button", {
                            class: "dropdown-item",
                            onClick: _cache[2] || (_cache[2] = _withModifiers(($event: any) => (search(_unref(actual_page))), ["prevent"]))
                          }, _cache[25] || (_cache[25] = [
                            _createElementVNode("i", { class: "fas fa-sync-alt mr-1" }, null, -1),
                            _createTextVNode(" Odśwież liste ")
                          ]))
                        ])
                      ])
                    ])
                  ])
                ])
              ]),
              _createElementVNode("div", _hoisted_14, [
                _createElementVNode("table", _hoisted_15, [
                  _createElementVNode("thead", null, [
                    _createElementVNode("tr", null, [
                      _createElementVNode("th", {
                        class: "th-sortable",
                        onClick: _cache[3] || (_cache[3] = ($event: any) => (order_by('id')))
                      }, [
                        _cache[27] || (_cache[27] = _createTextVNode(" ID ")),
                        (_unref(sort_by) == 'id' && _unref(sort_order) == 'asc')
                          ? (_openBlock(), _createElementBlock("i", _hoisted_16))
                          : _createCommentVNode("", true),
                        (_unref(sort_by) == 'id' && _unref(sort_order) == 'desc')
                          ? (_openBlock(), _createElementBlock("i", _hoisted_17))
                          : _createCommentVNode("", true)
                      ]),
                      _createElementVNode("th", {
                        class: "th-sortable",
                        onClick: _cache[4] || (_cache[4] = ($event: any) => (order_by('reference')))
                      }, [
                        _cache[28] || (_cache[28] = _createTextVNode(" Indeks ")),
                        (_unref(sort_by) == 'reference' && _unref(sort_order) == 'asc')
                          ? (_openBlock(), _createElementBlock("i", _hoisted_18))
                          : _createCommentVNode("", true),
                        (_unref(sort_by) == 'reference' && _unref(sort_order) == 'desc')
                          ? (_openBlock(), _createElementBlock("i", _hoisted_19))
                          : _createCommentVNode("", true)
                      ]),
                      _createElementVNode("th", {
                        class: "th-sortable",
                        onClick: _cache[5] || (_cache[5] = ($event: any) => (order_by('newcustomer')))
                      }, [
                        _cache[29] || (_cache[29] = _createTextVNode(" Nowy klient ")),
                        (_unref(sort_by) == 'newcustomer' && _unref(sort_order) == 'asc')
                          ? (_openBlock(), _createElementBlock("i", _hoisted_20))
                          : _createCommentVNode("", true),
                        (_unref(sort_by) == 'newcustomer' && _unref(sort_order) == 'desc')
                          ? (_openBlock(), _createElementBlock("i", _hoisted_21))
                          : _createCommentVNode("", true)
                      ]),
                      _createElementVNode("th", {
                        class: "th-sortable",
                        onClick: _cache[6] || (_cache[6] = ($event: any) => (order_by('delivery_country')))
                      }, [
                        _cache[30] || (_cache[30] = _createTextVNode(" Dostawa ")),
                        (_unref(sort_by) == 'delivery_country' && _unref(sort_order) == 'asc')
                          ? (_openBlock(), _createElementBlock("i", _hoisted_22))
                          : _createCommentVNode("", true),
                        (_unref(sort_by) == 'delivery_country' && _unref(sort_order) == 'desc')
                          ? (_openBlock(), _createElementBlock("i", _hoisted_23))
                          : _createCommentVNode("", true)
                      ]),
                      _createElementVNode("th", {
                        class: "th-sortable",
                        onClick: _cache[7] || (_cache[7] = ($event: any) => (order_by('customer')))
                      }, [
                        _cache[31] || (_cache[31] = _createTextVNode(" Klient ")),
                        (_unref(sort_by) == 'customer' && _unref(sort_order) == 'asc')
                          ? (_openBlock(), _createElementBlock("i", _hoisted_24))
                          : _createCommentVNode("", true),
                        (_unref(sort_by) == 'customer' && _unref(sort_order) == 'desc')
                          ? (_openBlock(), _createElementBlock("i", _hoisted_25))
                          : _createCommentVNode("", true)
                      ]),
                      _createElementVNode("th", {
                        class: "th-sortable",
                        onClick: _cache[8] || (_cache[8] = ($event: any) => (order_by('total')))
                      }, [
                        _cache[32] || (_cache[32] = _createTextVNode(" Razem ")),
                        (_unref(sort_by) == 'total' && _unref(sort_order) == 'asc')
                          ? (_openBlock(), _createElementBlock("i", _hoisted_26))
                          : _createCommentVNode("", true),
                        (_unref(sort_by) == 'total' && _unref(sort_order) == 'desc')
                          ? (_openBlock(), _createElementBlock("i", _hoisted_27))
                          : _createCommentVNode("", true)
                      ]),
                      _createElementVNode("th", {
                        class: "th-sortable",
                        onClick: _cache[9] || (_cache[9] = ($event: any) => (order_by('payment')))
                      }, [
                        _cache[33] || (_cache[33] = _createTextVNode(" Płatność ")),
                        (_unref(sort_by) == 'payment' && _unref(sort_order) == 'asc')
                          ? (_openBlock(), _createElementBlock("i", _hoisted_28))
                          : _createCommentVNode("", true),
                        (_unref(sort_by) == 'payment' && _unref(sort_order) == 'desc')
                          ? (_openBlock(), _createElementBlock("i", _hoisted_29))
                          : _createCommentVNode("", true)
                      ]),
                      _createElementVNode("th", {
                        class: "th-sortable",
                        onClick: _cache[10] || (_cache[10] = ($event: any) => (order_by('state_id')))
                      }, [
                        _cache[34] || (_cache[34] = _createTextVNode(" Status ")),
                        (_unref(sort_by) == 'state_id' && _unref(sort_order) == 'asc')
                          ? (_openBlock(), _createElementBlock("i", _hoisted_30))
                          : _createCommentVNode("", true),
                        (_unref(sort_by) == 'state_id' && _unref(sort_order) == 'desc')
                          ? (_openBlock(), _createElementBlock("i", _hoisted_31))
                          : _createCommentVNode("", true)
                      ]),
                      _createElementVNode("th", {
                        class: "th-sortable",
                        onClick: _cache[11] || (_cache[11] = ($event: any) => (order_by('date')))
                      }, [
                        _cache[35] || (_cache[35] = _createTextVNode(" Data ")),
                        (_unref(sort_by) == 'date' && _unref(sort_order) == 'asc')
                          ? (_openBlock(), _createElementBlock("i", _hoisted_32))
                          : _createCommentVNode("", true),
                        (_unref(sort_by) == 'date' && _unref(sort_order) == 'desc')
                          ? (_openBlock(), _createElementBlock("i", _hoisted_33))
                          : _createCommentVNode("", true)
                      ]),
                      _cache[36] || (_cache[36] = _createElementVNode("th", null, "Akcje", -1))
                    ]),
                    _createElementVNode("tr", _hoisted_34, [
                      _createElementVNode("th", null, [
                        _createElementVNode("input", {
                          class: "form-control form-control-sm",
                          type: "text",
                          placeholder: "ID",
                          onInput: _cache[12] || (_cache[12] = ($event: any) => (search(1))),
                          name: "id"
                        }, null, 32)
                      ]),
                      _createElementVNode("th", null, [
                        _createElementVNode("input", {
                          class: "form-control form-control-sm",
                          type: "text",
                          placeholder: "Indeks",
                          onInput: _cache[13] || (_cache[13] = ($event: any) => (search(1))),
                          name: "reference"
                        }, null, 32)
                      ]),
                      _createElementVNode("th", _hoisted_35, [
                        _createElementVNode("select", {
                          class: "form-control form-control-sm",
                          onChange: _cache[14] || (_cache[14] = ($event: any) => (search(1))),
                          name: "newcustomer"
                        }, _cache[37] || (_cache[37] = [
                          _createElementVNode("option", {
                            value: "",
                            selected: ""
                          }, "Niewybrane", -1),
                          _createElementVNode("option", { value: "1" }, "Tak", -1),
                          _createElementVNode("option", { value: "0" }, "Nie", -1)
                        ]), 32)
                      ]),
                      _createElementVNode("th", null, [
                        _createElementVNode("select", {
                          class: "form-control form-control-sm",
                          onChange: _cache[15] || (_cache[15] = ($event: any) => (search(1))),
                          name: "delivery_country_id"
                        }, [
                          _cache[38] || (_cache[38] = _createElementVNode("option", {
                            value: "",
                            selected: ""
                          }, "Niewybrane", -1)),
                          _cache[39] || (_cache[39] = _createElementVNode("option", { value: "14" }, "Polska", -1)),
                          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(countries_sorted.value, (v, k) => {
                            return (_openBlock(), _createElementBlock(_Fragment, null, [
                              (k !== 'Polska')
                                ? (_openBlock(), _createElementBlock("option", {
                                    key: 0,
                                    value: v
                                  }, _toDisplayString(k), 9, _hoisted_36))
                                : _createCommentVNode("", true)
                            ], 64))
                          }), 256))
                        ], 32)
                      ]),
                      _createElementVNode("th", null, [
                        _createElementVNode("input", {
                          class: "form-control form-control-sm",
                          type: "text",
                          placeholder: "Klient",
                          onInput: _cache[16] || (_cache[16] = ($event: any) => (search(1))),
                          name: "customer"
                        }, null, 32)
                      ]),
                      _createElementVNode("th", _hoisted_37, [
                        _createElementVNode("input", {
                          class: "form-control form-control-sm",
                          type: "text",
                          placeholder: "Razem",
                          onInput: _cache[17] || (_cache[17] = ($event: any) => (search(1))),
                          name: "total"
                        }, null, 32)
                      ]),
                      _createElementVNode("th", _hoisted_38, [
                        _createElementVNode("input", {
                          class: "form-control form-control-sm",
                          type: "text",
                          placeholder: "Platnosc",
                          onInput: _cache[18] || (_cache[18] = ($event: any) => (search(1))),
                          name: "payment"
                        }, null, 32)
                      ]),
                      _createElementVNode("th", _hoisted_39, [
                        _createElementVNode("select", {
                          class: "form-control form-control-sm",
                          onChange: _cache[19] || (_cache[19] = ($event: any) => (search(1))),
                          name: "state_id"
                        }, [
                          _cache[40] || (_cache[40] = _createElementVNode("option", {
                            value: "",
                            selected: ""
                          }, "Niewybrane", -1)),
                          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(order_states_sorted.value, (v, k) => {
                            return (_openBlock(), _createElementBlock("option", { value: v }, _toDisplayString(k), 9, _hoisted_40))
                          }), 256))
                        ], 32)
                      ]),
                      _createElementVNode("th", _hoisted_41, [
                        _createElementVNode("input", {
                          class: "form-control form-control-sm",
                          type: "date",
                          placeholder: "Data od",
                          onInput: _cache[20] || (_cache[20] = ($event: any) => (search(1))),
                          name: "dt_from"
                        }, null, 32),
                        _createElementVNode("input", {
                          class: "form-control form-control-sm",
                          type: "date",
                          placeholder: "Data do",
                          onInput: _cache[21] || (_cache[21] = ($event: any) => (search(1))),
                          name: "dt_to"
                        }, null, 32)
                      ]),
                      _cache[41] || (_cache[41] = _createElementVNode("th", null, null, -1))
                    ])
                  ]),
                  _createElementVNode("tbody", null, [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(orders), (order) => {
                      return (_openBlock(), _createElementBlock("tr", null, [
                        _createElementVNode("td", null, _toDisplayString(order.id), 1),
                        _createElementVNode("td", null, _toDisplayString(order.reference), 1),
                        _createElementVNode("td", _hoisted_42, [
                          (order.newcustomer)
                            ? (_openBlock(), _createElementBlock("span", _hoisted_43, "Tak"))
                            : _createCommentVNode("", true),
                          (!order.newcustomer)
                            ? (_openBlock(), _createElementBlock("span", _hoisted_44, "Nie"))
                            : _createCommentVNode("", true)
                        ]),
                        _createElementVNode("td", null, _toDisplayString(countries.value[order.delivery_country_id]), 1),
                        _createElementVNode("td", null, [
                          (order.customer)
                            ? (_openBlock(), _createBlock(_component_RouterLink, {
                                key: 0,
                                to: '/panel/sell/customers/' + order.customer_id
                              }, {
                                default: _withCtx(() => [
                                  _createTextVNode(_toDisplayString(order.customer), 1)
                                ]),
                                _: 2
                              }, 1032, ["to"]))
                            : (_openBlock(), _createElementBlock("span", _hoisted_45, "Usunięty klient"))
                        ]),
                        _createElementVNode("td", null, _toDisplayString(Number(order.total).toLocaleString('pl-PL', { style: 'currency', currency: order.currency_iso_code })), 1),
                        _createElementVNode("td", null, _toDisplayString(order.payment), 1),
                        _createElementVNode("td", null, _toDisplayString(order.state_name), 1),
                        _createElementVNode("td", null, _toDisplayString(order.date), 1),
                        _createElementVNode("td", null, [
                          _createElementVNode("div", _hoisted_46, [
                            _createVNode(_component_RouterLink, {
                              to: '/panel/sell/orders/' + order.id,
                              class: "btn btn-default btn-sm"
                            }, {
                              default: _withCtx(() => _cache[42] || (_cache[42] = [
                                _createElementVNode("i", { class: "fas fa-eye mr-1" }, null, -1),
                                _createTextVNode(" Zobacz ")
                              ])),
                              _: 2
                            }, 1032, ["to"]),
                            _cache[46] || (_cache[46] = _createElementVNode("button", {
                              type: "button",
                              class: "btn btn-default btn-sm dropdown-toggle dropdown-icon",
                              "data-toggle": "dropdown",
                              "aria-expanded": "false"
                            }, [
                              _createElementVNode("span", { class: "sr-only" }, "Zobacz więcej")
                            ], -1)),
                            _createElementVNode("div", _hoisted_47, [
                              _createVNode(_component_RouterLink, {
                                to: '/panel/sell/orders/' + order.id,
                                class: "dropdown-item"
                              }, {
                                default: _withCtx(() => _cache[43] || (_cache[43] = [
                                  _createElementVNode("i", { class: "fas fa-eye mr-1" }, null, -1),
                                  _createTextVNode(" Zobacz ")
                                ])),
                                _: 2
                              }, 1032, ["to"]),
                              _createVNode(_component_RouterLink, {
                                to: '/panel/sell/orders/' + order.id + '/edit',
                                class: "dropdown-item"
                              }, {
                                default: _withCtx(() => _cache[44] || (_cache[44] = [
                                  _createElementVNode("i", { class: "fas fa-pen mr-1" }, null, -1),
                                  _createTextVNode(" Edytuj ")
                                ])),
                                _: 2
                              }, 1032, ["to"]),
                              (order.delivery_number !== 0)
                                ? (_openBlock(), _createElementBlock("a", _hoisted_48, _cache[45] || (_cache[45] = [
                                    _createElementVNode("i", { class: "fas fa-truck mr-1" }, null, -1),
                                    _createTextVNode(" Zobacz list przewozowy ")
                                  ])))
                                : _createCommentVNode("", true)
                            ])
                          ])
                        ])
                      ]))
                    }), 256)),
                    (_unref(orders).length === 0)
                      ? (_openBlock(), _createElementBlock("tr", _hoisted_49, _cache[47] || (_cache[47] = [
                          _createElementVNode("td", {
                            colspan: "9",
                            class: "text-center"
                          }, "Brak wyników", -1)
                        ])))
                      : _createCommentVNode("", true)
                  ])
                ])
              ]),
              _createElementVNode("div", _hoisted_50, [
                _createVNode(Pager, {
                  now_page: 1,
                  item_max: _unref(item_max),
                  item_per_page: item_per_page,
                  event_update: search
                }, null, 8, ["item_max"])
              ])
            ])
          ])
        ])
      ])
    ])
  ], 64))
}
}

})