import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString } from "vue"

const _hoisted_1 = {
  class: "modal fade",
  id: "errorModal",
  tabindex: "-1",
  "aria-labelledby": "errorModalLabel",
  "aria-hidden": "true"
}
const _hoisted_2 = { class: "modal-dialog modal-lg" }
const _hoisted_3 = { class: "modal-content" }
const _hoisted_4 = { class: "modal-body" }

import { PropType, computed } from 'vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'ErrorModal',
  props: {
  show: {
    type: Boolean as PropType<boolean>,
    required: true
  },
  errors: {
    type: Array as PropType<string[]>,
    required: true
  }
},
  setup(__props) {

const props = __props;

const errorMappings: { [key: string]: string } = {
  productNamePL: 'Nazwa Produktu (PL)',
  productNameGB: 'Nazwa Produktu (GB)',
  summary_pl: 'Podsumowanie (PL)',
  summary_gb: 'Podsumowanie (GB)',
  descriptionPL: 'Opis (PL)',
  descriptionGB: 'Opis (GB)',
  index: 'Indeks',
  categories: 'Kategorie',
  combinations: 'Kombinacje',
  'combinations.0.index': 'Kombinacja #1 - Indeks',
  'combinations.1.index': 'Kombinacja #2 - Indeks',
  'combinations.2.index': 'Kombinacja #3 - Indeks',
  'combinations.3.index': 'Kombinacja #4 - Indeks',
  'combinations.4.index': 'Kombinacja #5 - Indeks',
  'combinations.5.index': 'Kombinacja #6 - Indeks',
  'specific_price.0.from_date': 'Cena Specjalna #1 - Data od',
  'specific_price.0.to_date': 'Cena Specjalna #1 - Data do',
  'specific_price.1.from_date': 'Cena Specjalna #2 - Data od',
  'specific_price.1.to_date': 'Cena Specjalna #2 - Data do',
  'specific_price.2.from_date': 'Cena Specjalna #3 - Data od',
  'specific_price.2.to_date': 'Cena Specjalna #3 - Data do',
  'specific_price.3.from_date': 'Cena Specjalna #4 - Data od',
  'specific_price.3.to_date': 'Cena Specjalna #4 - Data do',
  'specific_price.4.from_date': 'Cena Specjalna #5 - Data od',
  'specific_price.4.to_date': 'Cena Specjalna #5 - Data do',
  'specific_price.5.from_date': 'Cena Specjalna #6 - Data od',
  'specific_price.5.to_date': 'Cena Specjalna #6 - Data do',
  'specific_price.0.reduction': 'Cena Specjalna #1 - Wartość zniżki',
  'specific_price.1.reduction': 'Cena Specjalna #2 - Wartość zniżki',
  'specific_price.2.reduction': 'Cena Specjalna #3 - Wartość zniżki',
  'specific_price.3.reduction': 'Cena Specjalna #4 - Wartość zniżki',
  'specific_price.4.reduction': 'Cena Specjalna #5 - Wartość zniżki',
  'specific_price.5.reduction': 'Cena Specjalna #6 - Wartość zniżki',
  'specific_price.0.reduction_type': 'Cena Specjalna #1 - Typ zniżki',
  'specific_price.1.reduction_type': 'Cena Specjalna #2 - Typ zniżki',
  'specific_price.2.reduction_type': 'Cena Specjalna #3 - Typ zniżki',
  'specific_price.3.reduction_type': 'Cena Specjalna #4 - Typ zniżki',
  'specific_price.4.reduction_type': 'Cena Specjalna #5 - Typ zniżki',
  'specific_price.5.reduction_type': 'Cena Specjalna #6 - Typ zniżki',
};

const formattedErrors = computed(() => {
  return props.errors.map(error => {
    let [field, message] = error.split(': ');
    const fieldName = errorMappings[field] || field;
    Object.keys(errorMappings).forEach(key => {
      message = message.replace(key
        .replaceAll('_', ' '), '')
        .replaceAll('product name p l', '')
        .replaceAll('product name g b', '')
        .replaceAll('description p l ', '')
        .replaceAll('description g b ', '')
        .replaceAll('.0.index', '')
        .replaceAll('.1.index', '')
        .replaceAll('.2.index', '')
        .replaceAll('.3.index', '')
        .replaceAll('.4.index', '')
        .replaceAll('.5.index', '');
    });
    let new_value = `${fieldName}: ${message}`;

    if(new_value === 'Kategoria: Pole  musi być ciągiem znaków.'){
      new_value = 'Kategoria: Wybierz chociaż jedną kategorię.';
    }
    else if(new_value === 'Kombinacje: Pole  jest wymagane.'){
      new_value = 'Kombinacje: Stwórz przynajmniej jedną kombinacje.';
    }
    else{
      console.log(new_value);
    }

    return new_value;
  });
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _cache[1] || (_cache[1] = _createElementVNode("div", { class: "modal-header" }, [
          _createElementVNode("h5", {
            class: "modal-title",
            id: "errorModalLabel"
          }, "Błąd zapisu"),
          _createElementVNode("button", {
            type: "button",
            class: "btn-close",
            "data-bs-dismiss": "modal",
            "aria-label": "Close"
          })
        ], -1)),
        _createElementVNode("div", _hoisted_4, [
          _cache[0] || (_cache[0] = _createElementVNode("p", null, "Wypełnij poniższe wartości, następnie spróbuj ponownie.", -1)),
          _createElementVNode("ul", null, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(formattedErrors.value, (error) => {
              return (_openBlock(), _createElementBlock("li", { key: error }, _toDisplayString(error), 1))
            }), 128))
          ])
        ]),
        _cache[2] || (_cache[2] = _createElementVNode("div", { class: "modal-footer" }, [
          _createElementVNode("button", {
            type: "button",
            class: "btn btn-secondary",
            "data-bs-dismiss": "modal"
          }, "Zamknij")
        ], -1))
      ])
    ])
  ]))
}
}

})