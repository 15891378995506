import { Combination, SpecificPrice } from "@/admin/views/Panel/Sell/Products/New/NewConnector";
import { _rootApi } from "../../../web/global/Api/_root";

export interface ApiParams {
  id: number;
  index: string;
  price_net: number;
  price_gross: number;
  categories: Array<string>;
  combinations: Array<Combination>;
  summary_pl: string;
  summary_gb: string;
  descriptionPL: string;
  descriptionGB: string;
  features: Array<any>;
  related_products: Array<any>;
  is_online: boolean;
  availabilityPreference: string;
  width: number;
  height: number;
  depth: number;
  weight: number;
  deliveryTime: string;
  customDeliveryTime: string;
  productNamePL: string;
  productNameGB: string;
  uploaded_images: Array<{ name: string, url: string, isCover?: boolean, header?: string }>;
  seoTitlePL: string;
  seoDescriptionPL: string;
  seoUrlPL: string;
  seoTitleGB: string;
  seoDescriptionGB: string;
  seoUrlGB: string;
  specific_price: Array<SpecificPrice>;
}

export interface ApiResult {
  result?: string;
  errors?: { [index: string]: Array<string> };
  message?: string;
}

export class ApiAdminProductUpdate extends _rootApi {
  static request(params: ApiParams, func_success?: (r: ApiResult) => void, func_error?: (r: ApiResult) => void, func_finally?: () => void): void {
    this._request({
      url: '/product/update',
      params: params as unknown as { [index: string]: string },
      func_success: (r) => {
        if (func_success) func_success(r);
      },
      func_error: (r) => {
        if (func_error) func_error(r);
      },
      func_finally: () => {
        if (func_finally) func_finally();
      }
    });
  }
}