import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, unref as _unref, createVNode as _createVNode, createTextVNode as _createTextVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, vModelCheckbox as _vModelCheckbox, withDirectives as _withDirectives, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, vModelText as _vModelText, vModelRadio as _vModelRadio, vShow as _vShow } from "vue"

const _hoisted_1 = { class: "col-9" }
const _hoisted_2 = { class: "card" }
const _hoisted_3 = { class: "card-body" }
const _hoisted_4 = { class: "form-group" }
const _hoisted_5 = { class: "d-flex" }
const _hoisted_6 = ["disabled"]
const _hoisted_7 = { class: "form-group" }
const _hoisted_8 = {
  key: 0,
  class: "table table-bordered"
}
const _hoisted_9 = { class: "numb-value" }
const _hoisted_10 = { class: "short-values" }
const _hoisted_11 = { class: "short-values-checkbox" }
const _hoisted_12 = ["onUpdate:modelValue", "onChange"]
const _hoisted_13 = { class: "text-center position-relative" }
const _hoisted_14 = { key: 0 }
const _hoisted_15 = ["src", "onClick"]
const _hoisted_16 = ["onClick"]
const _hoisted_17 = {
  key: 2,
  class: "fa fa-pencil edit-icon"
}
const _hoisted_18 = {
  key: 3,
  class: "image-selection-popup"
}
const _hoisted_19 = { class: "image-selection-container" }
const _hoisted_20 = ["onClick"]
const _hoisted_21 = ["src"]
const _hoisted_22 = {
  key: 1,
  class: "text-center"
}
const _hoisted_23 = { class: "input-group" }
const _hoisted_24 = ["onUpdate:modelValue", "onInput"]
const _hoisted_25 = { class: "input-group" }
const _hoisted_26 = ["onUpdate:modelValue", "onInput"]
const _hoisted_27 = { class: "input-group" }
const _hoisted_28 = ["onUpdate:modelValue"]
const _hoisted_29 = ["onUpdate:modelValue"]
const _hoisted_30 = { class: "text-center position-relative" }
const _hoisted_31 = ["onClick"]
const _hoisted_32 = {
  key: 1,
  class: "text-muted text-center"
}
const _hoisted_33 = { class: "card" }
const _hoisted_34 = { class: "card-body" }
const _hoisted_35 = { class: "row" }
const _hoisted_36 = { class: "col-md-6" }
const _hoisted_37 = { class: "form-group" }
const _hoisted_38 = { class: "form-check" }
const _hoisted_39 = { class: "form-check" }
const _hoisted_40 = { class: "col-3" }
const _hoisted_41 = { class: "card" }
const _hoisted_42 = { class: "card-body" }

import { onMounted, onUnmounted, ref, watch, computed } from 'vue';
import vSelect from "vue-select";
import NewCombinationsAttributeList from './NewCombinationsAttributeList.vue';
import { ApiAdminGeneralAttributes, ApiResultCorrect } from '@/admin/global/Api/GeneralAttributes';
import { Combination, NewConnector } from './NewConnector';
import NewCombinationsDetails from './NewCombinationsDetails.vue';
import Required from '@/admin/components/Form/Required.vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'NewCombinations',
  setup(__props) {

const selectedAttributes = ref<string[]>([]);
const combinations = ref<Combination[]>(NewConnector.combinations);
const availabilityPreference = ref(NewConnector.availabilityPreference);

const combinations_data = ref({} as {[index:string]:string});
const to_generate_value = ref([] as Array<string>);
const predefinedValues = ref([] as Array<string>);
const is_details_showed = ref(false);

const editingCombinationIndex = ref<number | null>(null);
const editingImageIndex = ref<number | null>(null);

const generateCombination = () => {
  if (selectedAttributes.value.length > 0) {
    const isFirstCombination = combinations.value.length === 0;
    combinations.value.push({
      attributes: [...selectedAttributes.value],
      price: NewConnector.price_net,
      quantity: 1,
      initial_price: NewConnector.price_net,
      brutto_price: NewConnector.price_gross,
      imageIndex: [],
      defaultCombination: isFirstCombination,
      index: NewConnector.index,
    });
    selectedAttributes.value = [];
  }
};

const removeCombination = (index: number) => {
  combinations.value.splice(index, 1);
};

function generate_new_value_simple() {
  to_generate_value.value.forEach((v: string) => {
    selectedAttributes.value.push(v);
  });

  generateCombination();
  to_generate_value.value = [];
}

function convert_combinations_data(result:ApiResultCorrect){
  const data:{[index:string]:string} = {};

  Object.keys(result).forEach((id) => {
    const category = result[id];
    Object.values(category.subcategories as Array<{id:number, name:string}>).forEach(subcategory => {
      data[id + '-' + subcategory.id] = category.label + ': ' + subcategory.name;
    });
  });

  return data;
}

const updatePrice = (combination: Combination, newPrice: number) => {
  combination.price = newPrice;
  combination.brutto_price = Number((newPrice * (1 + (NewConnector.vat_rate / 100))).toFixed(6));
};

const updateBruttoPrice = (combination: Combination, newBruttoPrice: number) => {
  const newPrice = (newBruttoPrice / (1 + (NewConnector.vat_rate / 100))).toFixed(6);
  combination.price = Number(newPrice);
  combination.brutto_price = newBruttoPrice;
};

const startEditingImage = (index: number) => {
  if (editingImageIndex.value === index) {
    editingImageIndex.value = null;
  } else {
    editingImageIndex.value = index;
  }
};

const selectImageForCombination = (imageIndex: number) => {
  if (editingImageIndex.value !== null) {
    const combination = combinations.value[editingImageIndex.value];
    if (!combination.imageIndex.includes(imageIndex)) {
      combination.imageIndex.push(imageIndex);
    } else {
      combination.imageIndex = combination.imageIndex.filter(index => index !== imageIndex);
    }
  }
};

const closePopups = (event: Event) => {
  const target = event.target as HTMLElement;
  if (editingCombinationIndex.value !== null && !target.closest('.combination-edit-popup') && !target.closest('.btn-primary')) {
    editingCombinationIndex.value = null;
  }
  if (editingImageIndex.value !== null && !target.closest('.image-selection-popup') && !target.closest('.img-thumbnail') && !target.closest('.placeholder')) {
    editingImageIndex.value = null;
  }
};

document.addEventListener('click', closePopups);

watch(to_generate_value, (newValue) => {
  console.log('to_generate_value changed:', newValue);

  const data = Object.keys(combinations_data.value).filter(key => {
    const combination = combinations_data.value[key];
    return to_generate_value.value.includes(combination);
  });

  NewConnector.selected_combinations = data;
  NewConnector.selected_combinations_on_attributes_refresh();
});

watch(combinations, (newValue) => {
  NewConnector.combinations = newValue;
  NewConnector.totalQuantity = newValue.reduce((sum, combination) => sum + combination.quantity, 0);
}, { deep: true });

watch(availabilityPreference, (newValue) => {
  NewConnector.availabilityPreference = newValue;
});

onMounted(() => {
  ApiAdminGeneralAttributes.request({lang_id: 1}, (result) => {
    combinations_data.value = convert_combinations_data(result);
    predefinedValues.value = Object.values(combinations_data.value);
  }, () => {
    console.log('error');
  });
  
  NewConnector.back_to_combinations = () => {
    is_details_showed.value = false;
  };
  NewConnector.selected_combinations_refresh = () => {
    const data:Array<string> = [];
    NewConnector.selected_combinations.forEach((v:string) => {
      const [category, subcategory] = v.split('-');
      data.push(combinations_data.value[category + '-' + subcategory]);
    });

    to_generate_value.value = data;
    console.log(data);
  };

  combinations.value = NewConnector.combinations;
  availabilityPreference.value = NewConnector.availabilityPreference;
});

onUnmounted(() => {
  document.removeEventListener('click', closePopups);
  NewConnector.selected_combinations = [];
  NewConnector.back_to_combinations = () => null;
  NewConnector.selected_combinations_refresh = () => null;
  NewConnector.selected_combinations_on_attributes_refresh = () => null;
  NewConnector.availabilityPreference = availabilityPreference.value;
});

const setDefaultCombination = (index: number) => {
  combinations.value.forEach((combination, i) => {
    combination.defaultCombination = i === index;
  });
};

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _withDirectives(_createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _cache[21] || (_cache[21] = _createElementVNode("div", { class: "card-header" }, [
          _createElementVNode("h3", {
            class: "card-title font-weight-bold",
            "db-name": "product_attribute"
          }, "Zarządzaj kombinacjami produktów")
        ], -1)),
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("div", _hoisted_4, [
            _cache[3] || (_cache[3] = _createElementVNode("label", null, "Generowanie nowej kombinacji", -1)),
            _createElementVNode("div", _hoisted_5, [
              _createVNode(_unref(vSelect), {
                class: "w-100",
                modelValue: to_generate_value.value,
                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((to_generate_value).value = $event)),
                options: predefinedValues.value || [],
                reduce: option => option,
                placeholder: "Kombinacja kilku atrybutów",
                multiple: ""
              }, null, 8, ["modelValue", "options", "reduce"]),
              _createElementVNode("button", {
                onClick: generate_new_value_simple,
                disabled: to_generate_value.value.length === 0,
                class: "btn btn-primary btn-sm ml-2"
              }, "Generuj", 8, _hoisted_6)
            ])
          ]),
          _createElementVNode("div", _hoisted_7, [
            _cache[20] || (_cache[20] = _createElementVNode("label", null, "Kombinacje", -1)),
            (combinations.value.length > 0)
              ? (_openBlock(), _createElementBlock("table", _hoisted_8, [
                  _createElementVNode("thead", null, [
                    _createElementVNode("tr", null, [
                      _cache[5] || (_cache[5] = _createElementVNode("th", { class: "short-values" }, "#", -1)),
                      _cache[6] || (_cache[6] = _createElementVNode("th", { class: "short-values-checkbox" }, "Domyślna", -1)),
                      _cache[7] || (_cache[7] = _createElementVNode("th", { class: "img-value" }, "Zdjęcie", -1)),
                      _cache[8] || (_cache[8] = _createElementVNode("th", null, "Kombinacje", -1)),
                      _cache[9] || (_cache[9] = _createElementVNode("th", { class: "numb-value" }, "Cena netto", -1)),
                      _cache[10] || (_cache[10] = _createElementVNode("th", { class: "numb-value" }, "Cena brutto", -1)),
                      _cache[11] || (_cache[11] = _createElementVNode("th", { class: "numb-value" }, "Ilość", -1)),
                      _createElementVNode("th", _hoisted_9, [
                        _cache[4] || (_cache[4] = _createTextVNode("Indeks ")),
                        _createVNode(Required)
                      ]),
                      _cache[12] || (_cache[12] = _createElementVNode("th", { class: "action-value" }, "Akcje", -1))
                    ])
                  ]),
                  _createElementVNode("tbody", null, [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(combinations.value, (combination, index) => {
                      return (_openBlock(), _createElementBlock("tr", { key: index }, [
                        _createElementVNode("td", _hoisted_10, _toDisplayString(index + 1), 1),
                        _createElementVNode("td", _hoisted_11, [
                          _withDirectives(_createElementVNode("input", {
                            type: "checkbox",
                            "onUpdate:modelValue": ($event: any) => ((combination.defaultCombination) = $event),
                            onChange: ($event: any) => (setDefaultCombination(index)),
                            class: "large-checkbox"
                          }, null, 40, _hoisted_12), [
                            [_vModelCheckbox, combination.defaultCombination]
                          ])
                        ]),
                        _createElementVNode("td", _hoisted_13, [
                          (combination.imageIndex.length > 0)
                            ? (_openBlock(), _createElementBlock("div", _hoisted_14, [
                                _createElementVNode("img", {
                                  src: _unref(NewConnector).uploaded_images[Math.min(...combination.imageIndex)].url,
                                  alt: "Zdjęcie",
                                  class: "img-thumbnail",
                                  onClick: ($event: any) => (startEditingImage(index)),
                                  style: {"max-width":"90px","max-height":"90px"}
                                }, null, 8, _hoisted_15)
                              ]))
                            : (_openBlock(), _createElementBlock("div", {
                                key: 1,
                                class: "placeholder",
                                onClick: ($event: any) => (startEditingImage(index))
                              }, _cache[13] || (_cache[13] = [
                                _createElementVNode("i", { class: "fa fa-question" }, null, -1)
                              ]), 8, _hoisted_16)),
                          (editingImageIndex.value !== index)
                            ? (_openBlock(), _createElementBlock("i", _hoisted_17))
                            : _createCommentVNode("", true),
                          (editingImageIndex.value === index)
                            ? (_openBlock(), _createElementBlock("div", _hoisted_18, [
                                (_unref(NewConnector).uploaded_images.length > 0)
                                  ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                                      _cache[14] || (_cache[14] = _createElementVNode("div", { class: "py-2" }, [
                                        _createElementVNode("h5", null, "Wybór zdjęcia")
                                      ], -1)),
                                      _createElementVNode("div", _hoisted_19, [
                                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(NewConnector).uploaded_images, (image, imgIndex) => {
                                          return (_openBlock(), _createElementBlock("div", {
                                            key: imgIndex,
                                            class: _normalizeClass(["img-container", { 'selected': combination.imageIndex.includes(imgIndex) }]),
                                            onClick: ($event: any) => (selectImageForCombination(imgIndex))
                                          }, [
                                            _createElementVNode("img", {
                                              src: image.url,
                                              alt: "Zdjęcie",
                                              class: "img-preview"
                                            }, null, 8, _hoisted_21)
                                          ], 10, _hoisted_20))
                                        }), 128))
                                      ])
                                    ], 64))
                                  : _createCommentVNode("", true),
                                (_unref(NewConnector).uploaded_images.length === 0)
                                  ? (_openBlock(), _createElementBlock("div", _hoisted_22, _cache[15] || (_cache[15] = [
                                      _createElementVNode("div", { class: "image-selection-container-label" }, "Załaduj zdjęcie do produktu, aby móc wybrać zdjęcie do danej kombinacji.", -1)
                                    ])))
                                  : _createCommentVNode("", true)
                              ]))
                            : _createCommentVNode("", true)
                        ]),
                        _createElementVNode("td", null, _toDisplayString(combination.attributes.join(', ')), 1),
                        _createElementVNode("td", null, [
                          _createElementVNode("div", _hoisted_23, [
                            _withDirectives(_createElementVNode("input", {
                              name: "additional_cost",
                              class: "form-control",
                              "onUpdate:modelValue": ($event: any) => ((combination.price) = $event),
                              onInput: ($event: any) => (updatePrice(combination, combination.price)),
                              type: "number",
                              step: "0.01",
                              min: "0"
                            }, null, 40, _hoisted_24), [
                              [
                                _vModelText,
                                combination.price,
                                void 0,
                                { number: true }
                              ]
                            ]),
                            _cache[16] || (_cache[16] = _createElementVNode("div", { class: "input-group-append" }, [
                              _createElementVNode("span", { class: "input-group-text" }, "zł")
                            ], -1))
                          ])
                        ]),
                        _createElementVNode("td", null, [
                          _createElementVNode("div", _hoisted_25, [
                            _withDirectives(_createElementVNode("input", {
                              name: "additional_cost",
                              class: "form-control",
                              "onUpdate:modelValue": ($event: any) => ((combination.brutto_price) = $event),
                              onInput: ($event: any) => (updateBruttoPrice(combination, combination.brutto_price)),
                              type: "number",
                              step: "0.01",
                              min: "0"
                            }, null, 40, _hoisted_26), [
                              [
                                _vModelText,
                                combination.brutto_price,
                                void 0,
                                { number: true }
                              ]
                            ]),
                            _cache[17] || (_cache[17] = _createElementVNode("div", { class: "input-group-append" }, [
                              _createElementVNode("span", { class: "input-group-text" }, "zł")
                            ], -1))
                          ])
                        ]),
                        _createElementVNode("td", null, [
                          _createElementVNode("div", _hoisted_27, [
                            _withDirectives(_createElementVNode("input", {
                              type: "number",
                              class: "form-control",
                              "onUpdate:modelValue": ($event: any) => ((combination.quantity) = $event),
                              min: "0",
                              step: "1",
                              placeholder: "Ilość"
                            }, null, 8, _hoisted_28), [
                              [
                                _vModelText,
                                combination.quantity,
                                void 0,
                                { number: true }
                              ]
                            ]),
                            _cache[18] || (_cache[18] = _createElementVNode("div", { class: "input-group-append" }, [
                              _createElementVNode("span", { class: "input-group-text" }, "sztuk")
                            ], -1))
                          ])
                        ]),
                        _createElementVNode("td", null, [
                          _withDirectives(_createElementVNode("input", {
                            type: "text",
                            class: "form-control",
                            "onUpdate:modelValue": ($event: any) => ((combination.index) = $event),
                            placeholder: "Indeks"
                          }, null, 8, _hoisted_29), [
                            [_vModelText, combination.index]
                          ])
                        ]),
                        _createElementVNode("td", _hoisted_30, [
                          _createElementVNode("button", {
                            class: "btn btn-danger",
                            onClick: ($event: any) => (removeCombination(index))
                          }, _cache[19] || (_cache[19] = [
                            _createElementVNode("i", { class: "fa fa-trash" }, null, -1)
                          ]), 8, _hoisted_31)
                        ])
                      ]))
                    }), 128))
                  ])
                ]))
              : _createCommentVNode("", true),
            (combinations.value.length === 0)
              ? (_openBlock(), _createElementBlock("div", _hoisted_32, "Brak kombinacji"))
              : _createCommentVNode("", true)
          ])
        ])
      ]),
      _createElementVNode("div", _hoisted_33, [
        _cache[25] || (_cache[25] = _createElementVNode("div", { class: "card-header" }, [
          _createElementVNode("h3", { class: "card-title font-weight-bold" }, "Preferencje dostępności")
        ], -1)),
        _createElementVNode("div", _hoisted_34, [
          _createElementVNode("div", _hoisted_35, [
            _createElementVNode("div", _hoisted_36, [
              _createElementVNode("div", _hoisted_37, [
                _cache[24] || (_cache[24] = _createElementVNode("label", null, "Zachowanie przy braku na stanie", -1)),
                _createElementVNode("div", _hoisted_38, [
                  _withDirectives(_createElementVNode("input", {
                    type: "radio",
                    id: "no-order",
                    value: "no-order",
                    "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((availabilityPreference).value = $event)),
                    class: "form-check-input"
                  }, null, 512), [
                    [_vModelRadio, availabilityPreference.value]
                  ]),
                  _cache[22] || (_cache[22] = _createElementVNode("label", {
                    for: "no-order",
                    class: "form-check-label"
                  }, "Nie pozwalaj zamawiać", -1))
                ]),
                _createElementVNode("div", _hoisted_39, [
                  _withDirectives(_createElementVNode("input", {
                    type: "radio",
                    id: "allow-order",
                    value: "allow-order",
                    "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((availabilityPreference).value = $event)),
                    class: "form-check-input"
                  }, null, 512), [
                    [_vModelRadio, availabilityPreference.value]
                  ]),
                  _cache[23] || (_cache[23] = _createElementVNode("label", {
                    for: "allow-order",
                    class: "form-check-label"
                  }, "Pozwól zamawiać", -1))
                ])
              ])
            ])
          ])
        ])
      ])
    ], 512), [
      [_vShow, !is_details_showed.value]
    ]),
    _withDirectives(_createElementVNode("div", _hoisted_40, [
      _createElementVNode("div", _hoisted_41, [
        _cache[26] || (_cache[26] = _createElementVNode("div", { class: "card-header" }, [
          _createElementVNode("h3", { class: "card-title font-weight-bold" }, "Atrybuty")
        ], -1)),
        _createElementVNode("div", _hoisted_42, [
          _createVNode(NewCombinationsAttributeList)
        ])
      ])
    ], 512), [
      [_vShow, !is_details_showed.value]
    ]),
    _withDirectives(_createVNode(NewCombinationsDetails, null, null, 512), [
      [_vShow, is_details_showed.value]
    ])
  ], 64))
}
}

})