import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, vModelText as _vModelText, withDirectives as _withDirectives, normalizeClass as _normalizeClass, vModelSelect as _vModelSelect, renderList as _renderList, resolveComponent as _resolveComponent, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "content" }
const _hoisted_2 = { class: "container-fluid" }
const _hoisted_3 = { class: "row" }
const _hoisted_4 = { class: "col-12" }
const _hoisted_5 = { class: "card" }
const _hoisted_6 = { class: "card-header" }
const _hoisted_7 = { class: "card-title" }
const _hoisted_8 = { class: "card-body" }
const _hoisted_9 = { class: "row" }
const _hoisted_10 = { class: "col-6" }
const _hoisted_11 = { class: "form-group row" }
const _hoisted_12 = { class: "col-4 col-form-label text-right pr-3" }
const _hoisted_13 = { class: "col-8" }
const _hoisted_14 = { class: "form-group row" }
const _hoisted_15 = { class: "col-8" }
const _hoisted_16 = { class: "d-flex justify-content-between mt-1" }
const _hoisted_17 = { class: "text-muted small" }
const _hoisted_18 = { class: "form-group row" }
const _hoisted_19 = { class: "col-8" }
const _hoisted_20 = { class: "text-muted small" }
const _hoisted_21 = { class: "form-group row" }
const _hoisted_22 = { class: "col-8" }
const _hoisted_23 = { class: "text-muted small" }
const _hoisted_24 = { class: "form-group row" }
const _hoisted_25 = { class: "col-8" }
const _hoisted_26 = { class: "form-group row" }
const _hoisted_27 = { class: "col-4 col-form-label text-right pr-3" }
const _hoisted_28 = { class: "col-8" }
const _hoisted_29 = { class: "form-group row" }
const _hoisted_30 = { class: "col-6" }
const _hoisted_31 = { class: "col-6" }
const _hoisted_32 = { class: "form-group row" }
const _hoisted_33 = { class: "col-4 col-form-label text-right pr-3" }
const _hoisted_34 = { class: "col-8" }
const _hoisted_35 = { class: "form-group row" }
const _hoisted_36 = { class: "col-8" }
const _hoisted_37 = { class: "d-flex justify-content-between mt-1" }
const _hoisted_38 = { class: "text-muted small" }
const _hoisted_39 = { class: "form-group row" }
const _hoisted_40 = { class: "col-8" }
const _hoisted_41 = { class: "text-muted small" }
const _hoisted_42 = { class: "form-group row" }
const _hoisted_43 = { class: "col-8" }
const _hoisted_44 = { class: "text-muted small" }
const _hoisted_45 = { class: "form-group row" }
const _hoisted_46 = { class: "col-8" }
const _hoisted_47 = { class: "form-group row" }
const _hoisted_48 = { class: "col-4 col-form-label text-right pr-3" }
const _hoisted_49 = { class: "col-8" }
const _hoisted_50 = { class: "form-group row" }
const _hoisted_51 = { class: "col-6" }
const _hoisted_52 = { class: "form-group row" }
const _hoisted_53 = { class: "col-4 col-form-label text-right pr-3" }
const _hoisted_54 = { class: "col-6" }
const _hoisted_55 = { class: "form-group row" }
const _hoisted_56 = { class: "col-4 col-form-label text-right pr-3" }
const _hoisted_57 = { class: "col-6" }
const _hoisted_58 = ["value"]
const _hoisted_59 = { class: "card-footer clearfix" }

import ContentHeader from '@/admin/components/Layouts/ContentHeader.vue';
import Required from '@/admin/components/Form/Required.vue';
import { ApiAdminCategoryNew, ApiParams } from '@/admin/global/Api/CategoryNew';
import { Notify } from '@/admin/global/Notify';
import { Form } from '@/web/global/Form';
import { useRouter } from 'vue-router';
import { GeneralCategories } from '@/admin/global/General/Categories';
import { onMounted, ref } from 'vue';
import { ApiResultCorrect } from '@/admin/global/Api/GeneralCategories';
import SearchResultPreview from '@/admin/components/SearchResultPreview.vue';
import { CategoriesConnector } from './CategoriesConnector';
import { ApiAdminCategoryUpdate, ApiParams as ApiParamsUpdate } from '@/admin/global/Api/CategoryUpdate';


export default /*@__PURE__*/_defineComponent({
  __name: 'NewMainScreen',
  setup(__props) {

const router = useRouter();
const categories = ref({} as ApiResultCorrect);

const categoryId = ref<number>(CategoriesConnector.categoryId);
const idLabel = ref<string>(CategoriesConnector.categoryId !== -1 ? String(CategoriesConnector.categoryId) : '[id]');
const namePl = ref<string>(CategoriesConnector.name_pl);
const nameGb = ref<string>(CategoriesConnector.name_gb);
const descriptionPl = ref<string>(CategoriesConnector.description_pl);
const descriptionGb = ref<string>(CategoriesConnector.description_gb);
const metaTitlePl = ref<string>(CategoriesConnector.meta_title_pl);
const metaTitleGb = ref<string>(CategoriesConnector.meta_title_gb);
const metaDescriptionPl = ref<string>(CategoriesConnector.meta_description_pl);
const metaDescriptionGb = ref<string>(CategoriesConnector.meta_description_gb);
const metaFriendlyUrlPL = ref<string>(CategoriesConnector.friendly_url_pl);
const metaFriendlyUrlGb = ref<string>(CategoriesConnector.friendly_url_gb);
const metaKeywordsPl = ref<string>(CategoriesConnector.meta_keywords_pl);
const metaKeywordsGb = ref<string>(CategoriesConnector.meta_keywords_gb);
const isDisplayed = ref<string>(CategoriesConnector.is_displayed ? '1' : '0');
const parentCategoryId = ref<number>(CategoriesConnector.parent_category_id);

function event_save(): void {
  const is_new = CategoriesConnector.categoryId === -1;

  const formData = new FormData();
  if(!is_new) formData.append('id', String(CategoriesConnector.categoryId));
  formData.append('name_pl', namePl.value);
  formData.append('name_gb', nameGb.value);
  formData.append('description_pl', descriptionPl.value);
  formData.append('description_gb', descriptionGb.value);
  formData.append('meta_title_pl', metaTitlePl.value);
  formData.append('meta_title_gb', metaTitleGb.value);
  formData.append('meta_description_pl', metaDescriptionPl.value);
  formData.append('meta_description_gb', metaDescriptionGb.value);
  formData.append('meta_keywords_pl', metaKeywordsPl.value);
  formData.append('meta_keywords_gb', metaKeywordsGb.value);
  formData.append('friendly_url_pl', metaFriendlyUrlPL.value);
  formData.append('friendly_url_gb', metaFriendlyUrlGb.value);
  formData.append('is_displayed', isDisplayed.value ? '1' : '');
  formData.append('parent_category_id', String(parentCategoryId.value));

  if(is_new)
    ApiAdminCategoryNew.request(formData as unknown as ApiParams, () => {
      Form.error_reflesh();
      window.scrollTo(0, 0);
      
      router.push('/panel/sell/catalog/categories');
      Notify.success('Kategoria została dodana');
    }, r => {
      Form.error_reflesh(r);
    });
  else
    ApiAdminCategoryUpdate.request(formData as unknown as ApiParamsUpdate, () => {
      Form.error_reflesh();
      window.scrollTo(0, 0);
      
      router.push('/panel/sell/catalog/categories');
      Notify.success('Kategoria została zaktualizowana');
    }, r => {
      Form.error_reflesh(r);
    });
}

GeneralCategories.run_with_data(data => {
  // Deletin category: Bazowa
  categories.value = Object.values(data).filter(v => v.id !== 1);
});

return (_ctx: any,_cache: any) => {
  const _component_RouterLink = _resolveComponent("RouterLink")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(ContentHeader, {
      main_title: "Kategorie",
      sub_title: categoryId.value === -1 ? 'Nowa kategoria' : 'Edytuj kategorie'
    }, null, 8, ["sub_title"]),
    _createElementVNode("section", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("div", _hoisted_4, [
            _createElementVNode("div", _hoisted_5, [
              _createElementVNode("div", _hoisted_6, [
                _createElementVNode("h3", _hoisted_7, [
                  _cache[14] || (_cache[14] = _createElementVNode("i", { class: "fas fa-folder-open mr-2" }, null, -1)),
                  _cache[15] || (_cache[15] = _createTextVNode(" Kategoria")),
                  (categoryId.value !== -1)
                    ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                        _createTextVNode(": #" + _toDisplayString(categoryId.value) + " " + _toDisplayString(namePl.value), 1)
                      ], 64))
                    : _createCommentVNode("", true)
                ])
              ]),
              _createElementVNode("div", _hoisted_8, [
                _createElementVNode("div", _hoisted_9, [
                  _createElementVNode("div", _hoisted_10, [
                    _cache[40] || (_cache[40] = _createElementVNode("div", { class: "form-group row" }, [
                      _createElementVNode("label", { class: "col-12 col-form-label text-center text-primary" }, [
                        _createElementVNode("h3", null, "Wartości po Polsku")
                      ])
                    ], -1)),
                    _createElementVNode("div", _hoisted_11, [
                      _createElementVNode("label", _hoisted_12, [
                        _createVNode(Required),
                        _cache[16] || (_cache[16] = _createTextVNode("Nazwa (PL)"))
                      ]),
                      _createElementVNode("div", _hoisted_13, [
                        _withDirectives(_createElementVNode("input", {
                          name: "name_pl",
                          type: "text",
                          class: "form-control",
                          placeholder: "Nazwa",
                          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((namePl).value = $event))
                        }, null, 512), [
                          [_vModelText, namePl.value]
                        ]),
                        _cache[17] || (_cache[17] = _createElementVNode("span", { class: "text-muted small" }, "Nieprawidłowe znaki: <>;=#{}", -1)),
                        _cache[18] || (_cache[18] = _createElementVNode("div", { class: "error text-danger" }, null, -1))
                      ])
                    ]),
                    _createElementVNode("div", _hoisted_14, [
                      _cache[23] || (_cache[23] = _createElementVNode("label", { class: "col-4 col-form-label text-right pr-3" }, "Opis (PL)", -1)),
                      _createElementVNode("div", _hoisted_15, [
                        _withDirectives(_createElementVNode("textarea", {
                          name: "description_pl",
                          class: "form-control",
                          "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((descriptionPl).value = $event))
                        }, null, 512), [
                          [_vModelText, descriptionPl.value]
                        ]),
                        _createElementVNode("div", _hoisted_16, [
                          _cache[21] || (_cache[21] = _createElementVNode("div", { class: "text-muted small" }, "Nieprawidłowe znaki: <>;=#{}", -1)),
                          _createElementVNode("div", _hoisted_17, [
                            _cache[19] || (_cache[19] = _createTextVNode("Użyto ")),
                            _createElementVNode("b", {
                              class: _normalizeClass({ 'text-success': descriptionPl.value.length <= 21844, 'text-danger': descriptionPl.value.length > 21844 })
                            }, _toDisplayString(descriptionPl.value.length), 3),
                            _cache[20] || (_cache[20] = _createTextVNode(" z 21844 dozwolonych znaków"))
                          ])
                        ]),
                        _cache[22] || (_cache[22] = _createElementVNode("div", { class: "error text-danger" }, null, -1))
                      ])
                    ]),
                    _createElementVNode("div", _hoisted_18, [
                      _cache[27] || (_cache[27] = _createElementVNode("label", { class: "col-4 col-form-label text-right pr-3" }, "Meta-tytuł (PL)", -1)),
                      _createElementVNode("div", _hoisted_19, [
                        _withDirectives(_createElementVNode("input", {
                          name: "meta_title_pl",
                          type: "text",
                          class: "form-control",
                          placeholder: "Aby mieć inny tytuł niż nazwa kategorii, wprowadź go tutaj.",
                          "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((metaTitlePl).value = $event))
                        }, null, 512), [
                          [_vModelText, metaTitlePl.value]
                        ]),
                        _createElementVNode("span", _hoisted_20, [
                          _cache[24] || (_cache[24] = _createTextVNode("Użyto ")),
                          _createElementVNode("b", {
                            class: _normalizeClass({ 'text-success': metaTitlePl.value.length <= 70, 'text-danger': metaTitlePl.value.length > 70 })
                          }, _toDisplayString(metaTitlePl.value.length), 3),
                          _cache[25] || (_cache[25] = _createTextVNode(" z 70 (zalecanych) znaków"))
                        ]),
                        _cache[26] || (_cache[26] = _createElementVNode("div", { class: "error text-danger" }, null, -1))
                      ])
                    ]),
                    _createElementVNode("div", _hoisted_21, [
                      _cache[31] || (_cache[31] = _createElementVNode("label", { class: "col-4 col-form-label text-right pr-3" }, "Opis meta (PL)", -1)),
                      _createElementVNode("div", _hoisted_22, [
                        _withDirectives(_createElementVNode("textarea", {
                          name: "meta_description_pl",
                          class: "form-control",
                          placeholder: "Jeśli chcesz mieć inny opis niż podsumowanie produktu na stronach wyników wyszukiwania, wpisz go tutaj.",
                          "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((metaDescriptionPl).value = $event))
                        }, null, 512), [
                          [_vModelText, metaDescriptionPl.value]
                        ]),
                        _createElementVNode("span", _hoisted_23, [
                          _cache[28] || (_cache[28] = _createTextVNode("Użyto ")),
                          _createElementVNode("b", {
                            class: _normalizeClass({ 'text-success': metaDescriptionPl.value.length <= 160, 'text-danger': metaDescriptionPl.value.length > 160 })
                          }, _toDisplayString(metaDescriptionPl.value.length), 3),
                          _cache[29] || (_cache[29] = _createTextVNode(" z 160 (zalecanych) znaków"))
                        ]),
                        _cache[30] || (_cache[30] = _createElementVNode("div", { class: "error text-danger" }, null, -1))
                      ])
                    ]),
                    _createElementVNode("div", _hoisted_24, [
                      _cache[34] || (_cache[34] = _createElementVNode("label", { class: "col-4 col-form-label text-right pr-3" }, "Słowa kluczowe meta (PL)", -1)),
                      _createElementVNode("div", _hoisted_25, [
                        _withDirectives(_createElementVNode("input", {
                          name: "meta_keywords_pl",
                          type: "text",
                          class: "form-control",
                          placeholder: "Słowa kluczowe",
                          "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((metaKeywordsPl).value = $event))
                        }, null, 512), [
                          [_vModelText, metaKeywordsPl.value]
                        ]),
                        _cache[32] || (_cache[32] = _createElementVNode("span", { class: "text-muted small" }, "Wpisz słowa kluczowe po przecinku", -1)),
                        _cache[33] || (_cache[33] = _createElementVNode("div", { class: "error text-danger" }, null, -1))
                      ])
                    ]),
                    _createElementVNode("div", _hoisted_26, [
                      _createElementVNode("label", _hoisted_27, [
                        _createVNode(Required),
                        _cache[35] || (_cache[35] = _createTextVNode("Przyjazny adres URL (PL)"))
                      ]),
                      _createElementVNode("div", _hoisted_28, [
                        _withDirectives(_createElementVNode("input", {
                          name: "friendly_url_pl",
                          type: "text",
                          class: "form-control",
                          placeholder: "",
                          "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((metaFriendlyUrlPL).value = $event))
                        }, null, 512), [
                          [_vModelText, metaFriendlyUrlPL.value]
                        ]),
                        _cache[36] || (_cache[36] = _createElementVNode("span", { class: "text-muted small" }, "Tylko litery, cyfry, podkreślenia (_) i minus (-) znakami dozwolonymi.", -1)),
                        _cache[37] || (_cache[37] = _createElementVNode("div", { class: "error text-danger" }, null, -1))
                      ])
                    ]),
                    _createElementVNode("div", _hoisted_29, [
                      _cache[39] || (_cache[39] = _createElementVNode("label", { class: "col-4 col-form-label text-right pr-3" }, "Podgląd SEO (PL)", -1)),
                      _createElementVNode("div", _hoisted_30, [
                        _createVNode(SearchResultPreview, {
                          "is-category": true,
                          "seo-title": metaTitlePl.value,
                          "seo-url": idLabel.value + '-' + metaFriendlyUrlPL.value,
                          "seo-description": metaDescriptionPl.value
                        }, null, 8, ["seo-title", "seo-url", "seo-description"]),
                        _cache[38] || (_cache[38] = _createElementVNode("span", {
                          class: "text-muted small",
                          style: {"display":"block","margin-top":"-12px"}
                        }, "Oto podgląd jak Twojej strona będzie wyglądać w wynikach wyszukiwania.", -1))
                      ])
                    ])
                  ]),
                  _createElementVNode("div", _hoisted_31, [
                    _cache[65] || (_cache[65] = _createElementVNode("div", { class: "form-group row" }, [
                      _createElementVNode("label", { class: "col-12 col-form-label text-center text-primary" }, [
                        _createElementVNode("h3", null, "Wartości po Angielsku")
                      ])
                    ], -1)),
                    _createElementVNode("div", _hoisted_32, [
                      _createElementVNode("label", _hoisted_33, [
                        _createVNode(Required),
                        _cache[41] || (_cache[41] = _createTextVNode("Nazwa (GB)"))
                      ]),
                      _createElementVNode("div", _hoisted_34, [
                        _withDirectives(_createElementVNode("input", {
                          name: "name_gb",
                          type: "text",
                          class: "form-control",
                          placeholder: "Nazwa",
                          "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((nameGb).value = $event))
                        }, null, 512), [
                          [_vModelText, nameGb.value]
                        ]),
                        _cache[42] || (_cache[42] = _createElementVNode("span", { class: "text-muted small" }, "Nieprawidłowe znaki: <>;=#{}", -1)),
                        _cache[43] || (_cache[43] = _createElementVNode("div", { class: "error text-danger" }, null, -1))
                      ])
                    ]),
                    _createElementVNode("div", _hoisted_35, [
                      _cache[48] || (_cache[48] = _createElementVNode("label", { class: "col-4 col-form-label text-right pr-3" }, "Opis (GB)", -1)),
                      _createElementVNode("div", _hoisted_36, [
                        _withDirectives(_createElementVNode("textarea", {
                          name: "description_gb",
                          class: "form-control",
                          "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((descriptionGb).value = $event))
                        }, null, 512), [
                          [_vModelText, descriptionGb.value]
                        ]),
                        _createElementVNode("div", _hoisted_37, [
                          _cache[46] || (_cache[46] = _createElementVNode("div", { class: "text-muted small" }, "Nieprawidłowe znaki: <>;=#{}", -1)),
                          _createElementVNode("div", _hoisted_38, [
                            _cache[44] || (_cache[44] = _createTextVNode("Użyto ")),
                            _createElementVNode("b", {
                              class: _normalizeClass({ 'text-success': descriptionGb.value.length <= 21844, 'text-danger': descriptionGb.value.length > 21844 })
                            }, _toDisplayString(descriptionGb.value.length), 3),
                            _cache[45] || (_cache[45] = _createTextVNode(" z 21844 dozwolonych znaków"))
                          ])
                        ]),
                        _cache[47] || (_cache[47] = _createElementVNode("div", { class: "error text-danger" }, null, -1))
                      ])
                    ]),
                    _createElementVNode("div", _hoisted_39, [
                      _cache[52] || (_cache[52] = _createElementVNode("label", { class: "col-4 col-form-label text-right pr-3" }, "Meta-tytuł (GB)", -1)),
                      _createElementVNode("div", _hoisted_40, [
                        _withDirectives(_createElementVNode("input", {
                          name: "meta_title_gb",
                          type: "text",
                          class: "form-control",
                          placeholder: "Aby mieć inny tytuł niż nazwa kategorii, wprowadź go tutaj.",
                          "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => ((metaTitleGb).value = $event))
                        }, null, 512), [
                          [_vModelText, metaTitleGb.value]
                        ]),
                        _createElementVNode("span", _hoisted_41, [
                          _cache[49] || (_cache[49] = _createTextVNode("Użyto ")),
                          _createElementVNode("b", {
                            class: _normalizeClass({ 'text-success': metaTitleGb.value.length <= 70, 'text-danger': metaTitleGb.value.length > 70 })
                          }, _toDisplayString(metaTitleGb.value.length), 3),
                          _cache[50] || (_cache[50] = _createTextVNode(" z 70 (zalecanych) znaków"))
                        ]),
                        _cache[51] || (_cache[51] = _createElementVNode("div", { class: "error text-danger" }, null, -1))
                      ])
                    ]),
                    _createElementVNode("div", _hoisted_42, [
                      _cache[56] || (_cache[56] = _createElementVNode("label", { class: "col-4 col-form-label text-right pr-3" }, "Opis meta (GB)", -1)),
                      _createElementVNode("div", _hoisted_43, [
                        _withDirectives(_createElementVNode("textarea", {
                          name: "meta_description_gb",
                          class: "form-control",
                          placeholder: "Jeśli chcesz mieć inny opis niż podsumowanie produktu na stronach wyników wyszukiwania, wpisz go tutaj.",
                          "onUpdate:modelValue": _cache[9] || (_cache[9] = ($event: any) => ((metaDescriptionGb).value = $event))
                        }, null, 512), [
                          [_vModelText, metaDescriptionGb.value]
                        ]),
                        _createElementVNode("span", _hoisted_44, [
                          _cache[53] || (_cache[53] = _createTextVNode("Użyto ")),
                          _createElementVNode("b", {
                            class: _normalizeClass({ 'text-success': metaDescriptionGb.value.length <= 160, 'text-danger': metaDescriptionGb.value.length > 160 })
                          }, _toDisplayString(metaDescriptionGb.value.length), 3),
                          _cache[54] || (_cache[54] = _createTextVNode(" z 160 (zalecanych) znaków"))
                        ]),
                        _cache[55] || (_cache[55] = _createElementVNode("div", { class: "error text-danger" }, null, -1))
                      ])
                    ]),
                    _createElementVNode("div", _hoisted_45, [
                      _cache[59] || (_cache[59] = _createElementVNode("label", { class: "col-4 col-form-label text-right pr-3" }, "Słowa kluczowe meta (GB)", -1)),
                      _createElementVNode("div", _hoisted_46, [
                        _withDirectives(_createElementVNode("input", {
                          name: "meta_keywords_gb",
                          type: "text",
                          class: "form-control",
                          placeholder: "Słowa kluczowe",
                          "onUpdate:modelValue": _cache[10] || (_cache[10] = ($event: any) => ((metaKeywordsGb).value = $event))
                        }, null, 512), [
                          [_vModelText, metaKeywordsGb.value]
                        ]),
                        _cache[57] || (_cache[57] = _createElementVNode("span", { class: "text-muted small" }, "Wpisz słowa kluczowe po przecinku", -1)),
                        _cache[58] || (_cache[58] = _createElementVNode("div", { class: "error text-danger" }, null, -1))
                      ])
                    ]),
                    _createElementVNode("div", _hoisted_47, [
                      _createElementVNode("label", _hoisted_48, [
                        _createVNode(Required),
                        _cache[60] || (_cache[60] = _createTextVNode("Przyjazny adres URL (GB)"))
                      ]),
                      _createElementVNode("div", _hoisted_49, [
                        _withDirectives(_createElementVNode("input", {
                          name: "friendly_url_gb",
                          type: "text",
                          class: "form-control",
                          placeholder: "",
                          "onUpdate:modelValue": _cache[11] || (_cache[11] = ($event: any) => ((metaFriendlyUrlGb).value = $event))
                        }, null, 512), [
                          [_vModelText, metaFriendlyUrlGb.value]
                        ]),
                        _cache[61] || (_cache[61] = _createElementVNode("span", { class: "text-muted small" }, "Tylko litery, cyfry, podkreślenia (_) i minus (-) znakami dozwolonymi.", -1)),
                        _cache[62] || (_cache[62] = _createElementVNode("div", { class: "error text-danger" }, null, -1))
                      ])
                    ]),
                    _createElementVNode("div", _hoisted_50, [
                      _cache[64] || (_cache[64] = _createElementVNode("label", { class: "col-4 col-form-label text-right pr-3" }, "Podgląd SEO (GB)", -1)),
                      _createElementVNode("div", _hoisted_51, [
                        _createVNode(SearchResultPreview, {
                          "is-category": true,
                          "seo-title": metaTitleGb.value,
                          "seo-url": idLabel.value + '-' + metaFriendlyUrlGb.value,
                          "seo-description": metaDescriptionGb.value
                        }, null, 8, ["seo-title", "seo-url", "seo-description"]),
                        _cache[63] || (_cache[63] = _createElementVNode("span", {
                          class: "text-muted small",
                          style: {"display":"block","margin-top":"-12px"}
                        }, "Oto podgląd jak Twojej strona będzie wyglądać w wynikach wyszukiwania.", -1))
                      ])
                    ])
                  ])
                ]),
                _cache[71] || (_cache[71] = _createElementVNode("div", { class: "form-group row" }, [
                  _createElementVNode("label", { class: "col-12 col-form-label text-center text-primary" }, [
                    _createElementVNode("h3", null, "Wartości ogólne")
                  ])
                ], -1)),
                _createElementVNode("div", _hoisted_52, [
                  _createElementVNode("label", _hoisted_53, [
                    _createVNode(Required),
                    _cache[66] || (_cache[66] = _createTextVNode("Wyświetlane"))
                  ]),
                  _createElementVNode("div", _hoisted_54, [
                    _withDirectives(_createElementVNode("select", {
                      class: "form-control",
                      name: "is_displayed",
                      "onUpdate:modelValue": _cache[12] || (_cache[12] = ($event: any) => ((isDisplayed).value = $event))
                    }, _cache[67] || (_cache[67] = [
                      _createElementVNode("option", {
                        value: "1",
                        selected: ""
                      }, "Tak", -1),
                      _createElementVNode("option", { value: "0" }, "Nie", -1)
                    ]), 512), [
                      [_vModelSelect, isDisplayed.value]
                    ]),
                    _cache[68] || (_cache[68] = _createElementVNode("div", { class: "error text-danger" }, null, -1))
                  ])
                ]),
                _createElementVNode("div", _hoisted_55, [
                  _createElementVNode("label", _hoisted_56, [
                    _createVNode(Required),
                    _cache[69] || (_cache[69] = _createTextVNode("Kategoria nadrzędna"))
                  ]),
                  _createElementVNode("div", _hoisted_57, [
                    _withDirectives(_createElementVNode("select", {
                      name: "parent_category_id",
                      class: "form-control form",
                      "onUpdate:modelValue": _cache[13] || (_cache[13] = ($event: any) => ((parentCategoryId).value = $event))
                    }, [
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(categories.value, (item, idx) => {
                        return (_openBlock(), _createElementBlock("option", {
                          key: idx,
                          value: item.id
                        }, _toDisplayString(item.fullname), 9, _hoisted_58))
                      }), 128))
                    ], 512), [
                      [_vModelSelect, parentCategoryId.value]
                    ]),
                    _cache[70] || (_cache[70] = _createElementVNode("div", { class: "error text-danger" }, null, -1))
                  ])
                ])
              ]),
              _createElementVNode("div", _hoisted_59, [
                _createVNode(_component_RouterLink, {
                  to: "/panel/sell/catalog/categories",
                  class: "btn btn-secondary float-left"
                }, {
                  default: _withCtx(() => _cache[72] || (_cache[72] = [
                    _createTextVNode("Anuluj")
                  ])),
                  _: 1
                }),
                _createElementVNode("button", {
                  onClick: event_save,
                  type: "button",
                  class: "btn btn-primary float-right"
                }, "Zapisz")
              ])
            ])
          ])
        ])
      ])
    ])
  ], 64))
}
}

})