import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, vModelText as _vModelText, withDirectives as _withDirectives, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "form-group row" }
const _hoisted_2 = {
  key: 0,
  class: "list-group w-100 mt-2"
}
const _hoisted_3 = ["onClick"]
const _hoisted_4 = ["src"]
const _hoisted_5 = { class: "selected-products mt-3 w-100" }
const _hoisted_6 = { class: "d-flex align-items-center" }
const _hoisted_7 = ["src"]
const _hoisted_8 = ["onClick"]

import { ApiAdminProductsSearch } from '@/admin/global/Api/ProductsSearch';
import { ref, onMounted, onBeforeUnmount } from 'vue';
import { NewConnector } from './NewConnector';

interface Product {
  id: number;
  name: string;
  image: string;
}


export default /*@__PURE__*/_defineComponent({
  __name: 'NewReleatedProducts',
  setup(__props) {

const searchQuery = ref('');
const searchResults = ref<Product[]>([]);
const selectedProducts = ref<Product[]>([]);
const highlightedIndex = ref(-1);
const productSelectedWithEnter = ref(false);

function search() {
  ApiAdminProductsSearch.request({
    page: 1,
    per_page: 100,
    name: searchQuery.value,
    ignore_ids: selectedProducts.value.map(p => p.id)
  }, result => {
    searchResults.value = result.data.map(product => {
      return {
        id: product.id,
        name: product.name,
        image: '/' + product.image_id + '-home_default/' + product.link_rewrite + '.jpg'
      };
    });
  }, () => {
    console.log('error');
    searchResults.value = [];
  });
}

const searchProducts = () => {
  if (searchQuery.value.length < 1) {
    searchResults.value = [];
    return;
  }

  search();
};

const selectProduct = (product: Product) => {
  if (!selectedProducts.value.find(p => p.id === product.id)) {
    selectedProducts.value.push(product);
    NewConnector.related_products = selectedProducts.value;
  }
  searchResults.value = [];
  searchQuery.value = '';
  highlightedIndex.value = -1;
  productSelectedWithEnter.value = false;
};

const removeProduct = (productId: number) => {
  selectedProducts.value = selectedProducts.value.filter(p => p.id !== productId);
  NewConnector.related_products = selectedProducts.value;
};

const handleKeyDown = (event: KeyboardEvent) => {
  if(searchResults.value && searchResults.value.length === 0) {
    return;
  }

  if (event.key === 'ArrowDown') {
    highlightedIndex.value = (highlightedIndex.value + 1) % searchResults.value.length;
    scrollToHighlighted();
  } else if (event.key === 'ArrowUp') {
    highlightedIndex.value = (highlightedIndex.value - 1 + searchResults.value.length) % searchResults.value.length;
    scrollToHighlighted();
  } else if (event.key === 'Enter' && highlightedIndex.value >= 0) {
    selectProduct(searchResults.value[highlightedIndex.value]);
    productSelectedWithEnter.value = true;
  }
};

const scrollToHighlighted = () => {
  const list = document.querySelector('.list-group');
  const item = list?.children[highlightedIndex.value] as HTMLElement;
  if (item) {
    item.scrollIntoView({ block: 'nearest' });
  }
};

onMounted(() => {
  document.getElementById('search_products')?.addEventListener('keydown', handleKeyDown);
  selectedProducts.value = NewConnector.related_products;
});

onBeforeUnmount(() => {
  document.getElementById('search_products')?.removeEventListener('keydown', handleKeyDown);
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _cache[2] || (_cache[2] = _createElementVNode("label", { class: "col-12 card-title mb-2" }, "Powiązane produkty", -1)),
    _withDirectives(_createElementVNode("input", {
      type: "text",
      id: "search_products",
      class: "form-control",
      placeholder: "Wyszukaj produkt",
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((searchQuery).value = $event)),
      onInput: searchProducts,
      autocomplete: "off"
    }, null, 544), [
      [_vModelText, searchQuery.value]
    ]),
    (searchResults.value.length)
      ? (_openBlock(), _createElementBlock("ul", _hoisted_2, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(searchResults.value, (product, index) => {
            return (_openBlock(), _createElementBlock("li", {
              class: _normalizeClass(["list-group-item d-flex align-items-center", { 'highlighted': index === highlightedIndex.value }]),
              key: product.id,
              onClick: ($event: any) => (selectProduct(product))
            }, [
              _createElementVNode("img", {
                src: product.image,
                alt: "Product Image",
                class: "img-thumbnail mr-2",
                style: {"width":"50px","height":"50px"}
              }, null, 8, _hoisted_4),
              _createTextVNode(" " + _toDisplayString(product.name), 1)
            ], 10, _hoisted_3))
          }), 128))
        ]))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_5, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(selectedProducts.value, (product) => {
        return (_openBlock(), _createElementBlock("div", {
          class: "selected-product d-flex align-items-center justify-content-between p-2 mb-2 border",
          key: product.id
        }, [
          _createElementVNode("div", _hoisted_6, [
            _createElementVNode("img", {
              src: product.image,
              alt: "Product Image",
              class: "img-thumbnail mr-2",
              style: {"width":"50px","height":"50px"}
            }, null, 8, _hoisted_7),
            _createTextVNode(" " + _toDisplayString(product.name), 1)
          ]),
          _createElementVNode("button", {
            class: "btn btn-danger btn-sm",
            onClick: ($event: any) => (removeProduct(product.id))
          }, _cache[1] || (_cache[1] = [
            _createElementVNode("i", { class: "fas fa-times" }, null, -1)
          ]), 8, _hoisted_8)
        ]))
      }), 128))
    ])
  ]))
}
}

})