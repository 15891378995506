import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, unref as _unref, toDisplayString as _toDisplayString, withModifiers as _withModifiers, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "content content-full-height-with-header" }
const _hoisted_2 = { class: "container-fluid" }
const _hoisted_3 = { class: "row" }
const _hoisted_4 = { class: "row" }
const _hoisted_5 = { class: "col-12" }
const _hoisted_6 = { class: "card" }
const _hoisted_7 = { class: "card-header" }
const _hoisted_8 = { class: "card-title" }
const _hoisted_9 = { class: "card-tools" }
const _hoisted_10 = { class: "nav nav-pills ml-auto" }
const _hoisted_11 = { class: "nav-item" }
const _hoisted_12 = { class: "btn-group" }
const _hoisted_13 = {
  class: "card-body table-responsive p-0",
  style: {"height":"500px"}
}
const _hoisted_14 = { class: "table table-head-fixed text-nowrap" }
const _hoisted_15 = {
  key: 0,
  class: "fas fa-sort-down ml-2"
}
const _hoisted_16 = {
  key: 1,
  class: "fas fa-sort-up ml-2"
}
const _hoisted_17 = {
  key: 0,
  class: "fas fa-sort-down ml-2"
}
const _hoisted_18 = {
  key: 1,
  class: "fas fa-sort-up ml-2"
}
const _hoisted_19 = {
  key: 0,
  class: "fas fa-sort-down ml-2"
}
const _hoisted_20 = {
  key: 1,
  class: "fas fa-sort-up ml-2"
}
const _hoisted_21 = {
  key: 0,
  class: "fas fa-sort-down ml-2"
}
const _hoisted_22 = {
  key: 1,
  class: "fas fa-sort-up ml-2"
}
const _hoisted_23 = {
  key: 0,
  class: "fas fa-sort-down ml-2"
}
const _hoisted_24 = {
  key: 1,
  class: "fas fa-sort-up ml-2"
}
const _hoisted_25 = {
  key: 0,
  class: "fas fa-sort-down ml-2"
}
const _hoisted_26 = {
  key: 1,
  class: "fas fa-sort-up ml-2"
}
const _hoisted_27 = { class: "filter-row" }
const _hoisted_28 = { class: "text-center" }
const _hoisted_29 = ["onClick"]
const _hoisted_30 = ["onClick"]
const _hoisted_31 = { class: "text-center" }
const _hoisted_32 = {
  key: 0,
  class: "badge bg-success"
}
const _hoisted_33 = {
  key: 1,
  class: "badge bg-danger"
}
const _hoisted_34 = { class: "btn-group" }
const _hoisted_35 = {
  class: "dropdown-menu",
  role: "menu"
}
const _hoisted_36 = ["onClick"]
const _hoisted_37 = ["onClick"]
const _hoisted_38 = { key: 0 }
const _hoisted_39 = { class: "card-footer clearfix" }

import ContentHeader from '@/admin/components/Layouts/ContentHeader.vue';
import SmallBox from '@/admin/components/Layouts/SmallBox.vue';
import { onMounted, ref, watch } from 'vue';
import { ApiAdminCategoriesSearch, ApiResultDataSingle } from '@/admin/global/Api/CategoriesSearch';
import { ApiAdminCategoriesGeneral } from '@/admin/global/Api/CategoriesGeneral';
import { ApiAdminCategoryDelete } from '@/admin/global/Api/CategoryDelete';
import { Notify } from '@/web/global/Notify';
import { Form } from '@/web/global/Form';
import Pager from '@/admin/components/Pager.vue';
import { useRoute, useRouter } from 'vue-router';
import { ApiAdminCategorySwapActivity } from '@/admin/global/Api/CategorySwapActivity';

const item_per_page = 50;

export default /*@__PURE__*/_defineComponent({
  __name: 'Categories',
  setup(__props) {

const router = useRouter();
const route = useRoute();

let item_max = ref(0);
let categories = ref([] as Array<ApiResultDataSingle>);
let actual_page = 1;

let sort_by = ref('id');
let sort_order = ref('asc');

let disabled_categories_count = ref(0);
let empty_categories_count = ref(0);
let best_category = ref('');
let average_products_per_category = ref(0);

function get_search_params(page = -1, per_page = -1): any {
  let params: any = {};

  const params_name = ['id', 'name', 'parent_name', 'description', 'position', 'isactive'];
  params_name.forEach(name => {
    const v = (Form.value(name) + '').trim();

    if (v.length > 0) {
      params[name] = v;
    }
  });

  if (page !== -1) {
    params['page'] = page;
    params['per_page'] = per_page;
  }

  params["sort_by"] = sort_by.value;
  params["sort_order"] = sort_order.value === 'asc' ? 'asc' : 'desc';

  return params;
}

function search(page: number) {
  ApiAdminCategoriesSearch.request(get_search_params(page, item_per_page), result => {
    categories.value = result.data;
    item_max.value = result.data_count;
    actual_page = page;
  }, () => {
    console.log('error');

    categories.value = [];
    item_max.value = 0;
  });
}

function search_for_parent_name(parent_name:string){
  Form.value_set('parent_name', parent_name);

  // Update the URL with the new query parameter
  router.push({ 
    path: route.path, 
    query: { ...route.query, parent_category: parent_name } 
  });

  search(1);
}

function order_by(name: string) {
  if (sort_by.value === name) {
    sort_order.value = sort_order.value === 'asc' ? 'desc' : 'asc';
  } else {
    sort_by.value = name;
    sort_order.value = 'asc';
  }

  search(actual_page);
}

function prepare_delete_category(id: number): void {
  Notify.confirm('Czy na pewno chcesz usunąć kategorię: #' + id + '?', () => {
    delete_category(id);
  }, 'Usuń kategorię', 'Anuluj');
}

function delete_category(id: number): void {
  ApiAdminCategoryDelete.request({ id }, () => {
    search(actual_page);
    Notify.success('Kategoria: #' + id + ' została usunięta');
  }, () => {
    console.log('category delete error: ' + id);
  });
}

// function prepare_swap_activity(id: number, is_turned_on: boolean): void {
//   Notify.confirm('Czy na pewno chcesz ' + (is_turned_on ? 'wyłączyć' : 'włączyć') + ' kategorię: #' + id + '?', () => {
//     swap_activity(id, is_turned_on);
//   }, (is_turned_on ? 'Wyłącz' : 'Włącz') + ' kategorię', 'Anuluj');
// }

function swap_activity(id: number, is_turned_on: boolean): void {
  ApiAdminCategorySwapActivity.request({ id }, () => {
    search(actual_page);
    Notify.success('Kategoria: #' + id + ' ' + (is_turned_on ? 'została wyłączona' : 'została włączona'));
  }, () => {
    console.log('category switch error: ' + id);
  });
}

onMounted(() => {
  // Get the query parameter from the URL
  const parent_category = route.query.parent_category as string;
  if (parent_category) {
    Form.value_set('parent_name', parent_category);
  }
});

ApiAdminCategoriesGeneral.request(result => {
  disabled_categories_count.value = result.disabled_categories_count;
  empty_categories_count.value = result.empty_categories_count;
  best_category.value = result.best_category;
  average_products_per_category.value = result.average_products_per_category;
});

watch(() => route.query.parent_category, (newParentCategory) => {
  if (newParentCategory) {
    Form.value_set('parent_name', newParentCategory as string);
  } else {
    Form.value_set('parent_name', '');
  }
  search(1);
});

search(actual_page);

return (_ctx: any,_cache: any) => {
  const _component_RouterLink = _resolveComponent("RouterLink")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(ContentHeader, {
      main_title: "Produkty > Kategorie",
      sub_title: "Zarządzanie Kategoriami"
    }, {
      default: _withCtx(() => [
        _createVNode(_component_RouterLink, {
          to: "/panel/sell/catalog/categories/new",
          class: "btn btn-primary"
        }, {
          default: _withCtx(() => _cache[13] || (_cache[13] = [
            _createElementVNode("i", { class: "fas fa-plus-circle" }, null, -1),
            _createTextVNode(" Dodaj nową kategorię ")
          ])),
          _: 1
        })
      ]),
      _: 1
    }),
    _createElementVNode("section", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, [
          _createVNode(SmallBox, {
            count: String(_unref(disabled_categories_count)),
            title: "Wyłączone kategorie",
            icon: "ion-close",
            bg_class: "bg-danger"
          }, null, 8, ["count"]),
          _createVNode(SmallBox, {
            count: String(_unref(empty_categories_count)),
            title: "Puste kategorie",
            icon: "ion-folder",
            bg_class: "bg-warning"
          }, null, 8, ["count"]),
          _createVNode(SmallBox, {
            count: _unref(best_category),
            title: "Najlepsza kategoria (30 dni)",
            icon: "ion-trophy",
            bg_class: "bg-success"
          }, null, 8, ["count"]),
          _createVNode(SmallBox, {
            count: String(_unref(average_products_per_category)),
            title: "Średnia liczba produktów w każdej kategorii",
            icon: "ion-stats-bars",
            bg_class: "bg-info"
          }, null, 8, ["count"])
        ]),
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("div", _hoisted_5, [
            _createElementVNode("div", _hoisted_6, [
              _createElementVNode("div", _hoisted_7, [
                _createElementVNode("h3", _hoisted_8, "Kategorie (" + _toDisplayString(_unref(item_max)) + ")", 1),
                _createElementVNode("div", _hoisted_9, [
                  _createElementVNode("ul", _hoisted_10, [
                    _createElementVNode("li", _hoisted_11, [
                      _createElementVNode("div", _hoisted_12, [
                        _createElementVNode("button", {
                          class: "btn btn-default btn-sm",
                          onClick: _cache[0] || (_cache[0] = _withModifiers(($event: any) => (search(_unref(actual_page))), ["prevent"]))
                        }, _cache[14] || (_cache[14] = [
                          _createElementVNode("i", { class: "fas fa-sync-alt mr-1" }, null, -1),
                          _createTextVNode(" Odśwież liste ")
                        ]))
                      ])
                    ])
                  ])
                ])
              ]),
              _createElementVNode("div", _hoisted_13, [
                _createElementVNode("table", _hoisted_14, [
                  _createElementVNode("thead", null, [
                    _createElementVNode("tr", null, [
                      _createElementVNode("th", {
                        class: "th-sortable",
                        onClick: _cache[1] || (_cache[1] = ($event: any) => (order_by('id')))
                      }, [
                        _cache[15] || (_cache[15] = _createTextVNode(" ID ")),
                        (_unref(sort_by) == 'id' && _unref(sort_order) == 'asc')
                          ? (_openBlock(), _createElementBlock("i", _hoisted_15))
                          : _createCommentVNode("", true),
                        (_unref(sort_by) == 'id' && _unref(sort_order) == 'desc')
                          ? (_openBlock(), _createElementBlock("i", _hoisted_16))
                          : _createCommentVNode("", true)
                      ]),
                      _createElementVNode("th", {
                        class: "th-sortable",
                        onClick: _cache[2] || (_cache[2] = ($event: any) => (order_by('name')))
                      }, [
                        _cache[16] || (_cache[16] = _createTextVNode(" Nazwa (PL) ")),
                        (_unref(sort_by) == 'name' && _unref(sort_order) == 'asc')
                          ? (_openBlock(), _createElementBlock("i", _hoisted_17))
                          : _createCommentVNode("", true),
                        (_unref(sort_by) == 'name' && _unref(sort_order) == 'desc')
                          ? (_openBlock(), _createElementBlock("i", _hoisted_18))
                          : _createCommentVNode("", true)
                      ]),
                      _createElementVNode("th", {
                        class: "th-sortable",
                        onClick: _cache[3] || (_cache[3] = ($event: any) => (order_by('parent_name')))
                      }, [
                        _cache[17] || (_cache[17] = _createTextVNode(" Kategoria Rodzic ")),
                        (_unref(sort_by) == 'name' && _unref(sort_order) == 'asc')
                          ? (_openBlock(), _createElementBlock("i", _hoisted_19))
                          : _createCommentVNode("", true),
                        (_unref(sort_by) == 'name' && _unref(sort_order) == 'desc')
                          ? (_openBlock(), _createElementBlock("i", _hoisted_20))
                          : _createCommentVNode("", true)
                      ]),
                      _createElementVNode("th", {
                        class: "th-sortable",
                        onClick: _cache[4] || (_cache[4] = ($event: any) => (order_by('description')))
                      }, [
                        _cache[18] || (_cache[18] = _createTextVNode(" Opis ")),
                        (_unref(sort_by) == 'description' && _unref(sort_order) == 'asc')
                          ? (_openBlock(), _createElementBlock("i", _hoisted_21))
                          : _createCommentVNode("", true),
                        (_unref(sort_by) == 'description' && _unref(sort_order) == 'desc')
                          ? (_openBlock(), _createElementBlock("i", _hoisted_22))
                          : _createCommentVNode("", true)
                      ]),
                      _createElementVNode("th", {
                        class: "th-sortable",
                        onClick: _cache[5] || (_cache[5] = ($event: any) => (order_by('position')))
                      }, [
                        _cache[19] || (_cache[19] = _createTextVNode(" Pozycja ")),
                        (_unref(sort_by) == 'position' && _unref(sort_order) == 'asc')
                          ? (_openBlock(), _createElementBlock("i", _hoisted_23))
                          : _createCommentVNode("", true),
                        (_unref(sort_by) == 'position' && _unref(sort_order) == 'desc')
                          ? (_openBlock(), _createElementBlock("i", _hoisted_24))
                          : _createCommentVNode("", true)
                      ]),
                      _createElementVNode("th", {
                        class: "th-sortable text-center",
                        onClick: _cache[6] || (_cache[6] = ($event: any) => (order_by('isactive')))
                      }, [
                        _cache[20] || (_cache[20] = _createTextVNode(" Online ")),
                        (_unref(sort_by) == 'isactive' && _unref(sort_order) == 'asc')
                          ? (_openBlock(), _createElementBlock("i", _hoisted_25))
                          : _createCommentVNode("", true),
                        (_unref(sort_by) == 'isactive' && _unref(sort_order) == 'desc')
                          ? (_openBlock(), _createElementBlock("i", _hoisted_26))
                          : _createCommentVNode("", true)
                      ]),
                      _cache[21] || (_cache[21] = _createElementVNode("th", null, "Akcje", -1))
                    ]),
                    _createElementVNode("tr", _hoisted_27, [
                      _createElementVNode("th", null, [
                        _createElementVNode("input", {
                          class: "form-control form-control-sm",
                          type: "text",
                          placeholder: "ID",
                          onInput: _cache[7] || (_cache[7] = ($event: any) => (search(1))),
                          name: "id"
                        }, null, 32)
                      ]),
                      _createElementVNode("th", null, [
                        _createElementVNode("input", {
                          class: "form-control form-control-sm",
                          type: "text",
                          placeholder: "Nazwa",
                          onInput: _cache[8] || (_cache[8] = ($event: any) => (search(1))),
                          name: "name"
                        }, null, 32)
                      ]),
                      _createElementVNode("th", null, [
                        _createElementVNode("input", {
                          class: "form-control form-control-sm",
                          type: "text",
                          placeholder: "Kategoria Rodzic",
                          onInput: _cache[9] || (_cache[9] = ($event: any) => (search(1))),
                          name: "parent_name"
                        }, null, 32)
                      ]),
                      _createElementVNode("th", null, [
                        _createElementVNode("input", {
                          class: "form-control form-control-sm",
                          type: "text",
                          placeholder: "Opis",
                          onInput: _cache[10] || (_cache[10] = ($event: any) => (search(1))),
                          name: "description"
                        }, null, 32)
                      ]),
                      _createElementVNode("th", null, [
                        _createElementVNode("input", {
                          class: "form-control form-control-sm",
                          type: "text",
                          placeholder: "Pozycja",
                          onInput: _cache[11] || (_cache[11] = ($event: any) => (search(1))),
                          name: "position"
                        }, null, 32)
                      ]),
                      _createElementVNode("th", _hoisted_28, [
                        _createElementVNode("select", {
                          class: "form-control form-control-sm",
                          onChange: _cache[12] || (_cache[12] = ($event: any) => (search(1))),
                          name: "isactive"
                        }, _cache[22] || (_cache[22] = [
                          _createElementVNode("option", {
                            value: "",
                            selected: ""
                          }, "Niewybrane", -1),
                          _createElementVNode("option", { value: "1" }, "Tak", -1),
                          _createElementVNode("option", { value: "0" }, "Nie", -1)
                        ]), 32)
                      ]),
                      _cache[23] || (_cache[23] = _createElementVNode("th", null, null, -1))
                    ])
                  ]),
                  _createElementVNode("tbody", null, [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(categories), (category) => {
                      return (_openBlock(), _createElementBlock("tr", null, [
                        _createElementVNode("td", null, _toDisplayString(category.id), 1),
                        _createElementVNode("td", null, [
                          _createElementVNode("a", {
                            href: "#",
                            onClick: _withModifiers(($event: any) => (search_for_parent_name(category.name)), ["prevent"])
                          }, _toDisplayString(category.name), 9, _hoisted_29)
                        ]),
                        _createElementVNode("td", null, [
                          _createElementVNode("a", {
                            href: "#",
                            onClick: _withModifiers(($event: any) => (search_for_parent_name(category.parent_name)), ["prevent"])
                          }, _toDisplayString(category.parent_name), 9, _hoisted_30)
                        ]),
                        _createElementVNode("td", null, _toDisplayString(category.description), 1),
                        _createElementVNode("td", null, _toDisplayString(category.position), 1),
                        _createElementVNode("td", _hoisted_31, [
                          (category.isactive)
                            ? (_openBlock(), _createElementBlock("span", _hoisted_32, "✓"))
                            : _createCommentVNode("", true),
                          (!category.isactive)
                            ? (_openBlock(), _createElementBlock("span", _hoisted_33, "×"))
                            : _createCommentVNode("", true)
                        ]),
                        _createElementVNode("td", null, [
                          _createElementVNode("div", _hoisted_34, [
                            (category.id !== 1)
                              ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                                  _createVNode(_component_RouterLink, {
                                    to: '/panel/sell/catalog/categories/' + category.id + '/edit',
                                    class: "btn btn-default btn-sm"
                                  }, {
                                    default: _withCtx(() => _cache[24] || (_cache[24] = [
                                      _createElementVNode("i", { class: "fas fa-pen mr-1" }, null, -1),
                                      _createTextVNode(" Edytuj ")
                                    ])),
                                    _: 2
                                  }, 1032, ["to"]),
                                  _cache[27] || (_cache[27] = _createElementVNode("button", {
                                    type: "button",
                                    class: "btn btn-default btn-sm dropdown-toggle dropdown-icon",
                                    "data-toggle": "dropdown",
                                    "aria-expanded": "false"
                                  }, [
                                    _createElementVNode("span", { class: "sr-only" }, "Zobacz więcej")
                                  ], -1)),
                                  _createElementVNode("div", _hoisted_35, [
                                    _createVNode(_component_RouterLink, {
                                      to: '/panel/sell/catalog/categories/' + category.id + '/edit',
                                      class: "dropdown-item"
                                    }, {
                                      default: _withCtx(() => _cache[25] || (_cache[25] = [
                                        _createElementVNode("i", { class: "fas fa-pen mr-1" }, null, -1),
                                        _createTextVNode(" Edytuj ")
                                      ])),
                                      _: 2
                                    }, 1032, ["to"]),
                                    _createElementVNode("a", {
                                      class: "dropdown-item",
                                      href: "#",
                                      onClick: _withModifiers(($event: any) => (swap_activity(category.id, category.isactive)), ["prevent"])
                                    }, [
                                      _createElementVNode("i", {
                                        class: _normalizeClass(["far mr-1", {'fa-eye-slash': category.isactive, 'fa-eye': !category.isactive }])
                                      }, null, 2),
                                      _createTextVNode(" " + _toDisplayString(category.isactive ? 'Offline' : 'Online'), 1)
                                    ], 8, _hoisted_36),
                                    (!category.isactive)
                                      ? (_openBlock(), _createElementBlock("a", {
                                          key: 0,
                                          class: "dropdown-item",
                                          href: "#",
                                          onClick: _withModifiers(($event: any) => (prepare_delete_category(category.id)), ["prevent"])
                                        }, _cache[26] || (_cache[26] = [
                                          _createElementVNode("i", { class: "fas fa-trash mr-1" }, null, -1),
                                          _createTextVNode(" Usuń ")
                                        ]), 8, _hoisted_37))
                                      : _createCommentVNode("", true)
                                  ])
                                ], 64))
                              : _createCommentVNode("", true)
                          ])
                        ])
                      ]))
                    }), 256)),
                    (_unref(categories).length === 0)
                      ? (_openBlock(), _createElementBlock("tr", _hoisted_38, _cache[28] || (_cache[28] = [
                          _createElementVNode("td", {
                            colspan: "6",
                            class: "text-center"
                          }, "Brak wyników", -1)
                        ])))
                      : _createCommentVNode("", true)
                  ])
                ])
              ]),
              _createElementVNode("div", _hoisted_39, [
                _createVNode(Pager, {
                  now_page: 1,
                  item_max: _unref(item_max),
                  item_per_page: item_per_page,
                  event_update: search
                }, null, 8, ["item_max"])
              ])
            ])
          ])
        ])
      ])
    ])
  ], 64))
}
}

})