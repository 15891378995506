import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, withModifiers as _withModifiers, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createStaticVNode as _createStaticVNode } from "vue"

const _hoisted_1 = { class: "card card-primary card-tabs" }
const _hoisted_2 = { class: "card-body" }
const _hoisted_3 = {
  class: "tab-content",
  id: "custom-tabs-two-tabContent"
}
const _hoisted_4 = {
  class: "tab-pane fade active show",
  id: "custom-tabs-two-home",
  role: "tabpanel",
  "aria-labelledby": "custom-tabs-two-home-tab"
}
const _hoisted_5 = { class: "note-container mt-3" }
const _hoisted_6 = {
  key: 0,
  class: "note-container-textarea pt-2"
}

import { ref } from 'vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'DetailsNavBarInfo',
  setup(__props) {

const note_visible = ref(false);

function toggle_note() {
  note_visible.value = !note_visible.value;
}

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _cache[6] || (_cache[6] = _createElementVNode("div", { class: "card-header p-0 pt-1" }, [
      _createElementVNode("ul", {
        class: "nav nav-tabs pl-1",
        id: "custom-tabs-two-tab",
        role: "tablist"
      }, [
        _createElementVNode("span", { class: "text-todo ml-2" }, "TODO"),
        _createElementVNode("li", { class: "nav-item" }, [
          _createElementVNode("a", {
            class: "nav-link active",
            id: "custom-tabs-two-home-tab",
            "data-toggle": "pill",
            href: "#custom-tabs-two-home",
            role: "tab",
            "aria-controls": "custom-tabs-two-home",
            "aria-selected": "true"
          }, [
            _createElementVNode("i", { class: "fas fa-history mr-1" }),
            _createTextVNode("Wyświetlany (2) ")
          ])
        ]),
        _createElementVNode("li", { class: "nav-item" }, [
          _createElementVNode("a", {
            class: "nav-link",
            id: "custom-tabs-two-profile-tab",
            "data-toggle": "pill",
            href: "#custom-tabs-two-profile",
            role: "tab",
            "aria-controls": "custom-tabs-two-profile",
            "aria-selected": "false"
          }, [
            _createElementVNode("i", { class: "fas fa-file mr-1" }),
            _createTextVNode("Dokumenty (0) ")
          ])
        ]),
        _createElementVNode("li", { class: "nav-item" }, [
          _createElementVNode("a", {
            class: "nav-link",
            id: "custom-tabs-two-messages-tab",
            "data-toggle": "pill",
            href: "#custom-tabs-two-messages",
            role: "tab",
            "aria-controls": "custom-tabs-two-messages",
            "aria-selected": "false"
          }, [
            _createElementVNode("i", { class: "fas fa-truck mr-1" }),
            _createTextVNode("Przewoźnicy (1) ")
          ])
        ]),
        _createElementVNode("li", { class: "nav-item" }, [
          _createElementVNode("a", {
            class: "nav-link",
            id: "custom-tabs-two-settings-tab",
            "data-toggle": "pill",
            href: "#custom-tabs-two-settings",
            role: "tab",
            "aria-controls": "custom-tabs-two-settings",
            "aria-selected": "false"
          }, [
            _createElementVNode("i", { class: "fas fa-undo mr-1" }),
            _createTextVNode(" Zwroty produktów (0) ")
          ])
        ])
      ])
    ], -1)),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _cache[2] || (_cache[2] = _createStaticVNode("<table class=\"table table-sm mb-0\" data-v-41639bb4><tbody data-v-41639bb4><tr data-v-41639bb4><td data-v-41639bb4><span class=\"badge bg-danger\" data-v-41639bb4><h6 data-v-41639bb4>Anulowane</h6></span></td><td data-v-41639bb4>Michał Piątkowski </td><td data-v-41639bb4>2024-10-23 14:49:57</td></tr><tr data-v-41639bb4><td data-v-41639bb4><span class=\"badge bg-warning\" data-v-41639bb4><h6 data-v-41639bb4>Przygotowanie w toku - pobranie</h6></span></td><td data-v-41639bb4></td><td data-v-41639bb4>2024-10-23 12:14:20</td></tr></tbody></table><div class=\"d-flex justify-content-end align-items-center mt-3\" data-v-41639bb4><select class=\"form-control w-auto mr-2\" data-v-41639bb4><option data-v-41639bb4>TODO</option><option data-v-41639bb4>option 2</option><option data-v-41639bb4>option 3</option><option data-v-41639bb4>option 4</option><option data-v-41639bb4>option 5</option></select><a class=\"btn btn-primary\" href=\"#\" data-v-41639bb4>Aktualizuj status</a></div>", 2)),
          _createElementVNode("div", _hoisted_5, [
            _cache[0] || (_cache[0] = _createElementVNode("span", { class: "ml-1 font-weight-bold" }, "Notatka", -1)),
            _createElementVNode("a", {
              class: "ml-auto mr-1",
              href: "#",
              onClick: _withModifiers(toggle_note, ["prevent"])
            }, [
              _createElementVNode("i", {
                class: _normalizeClass(note_visible.value ? 'fas fa-minus' : 'fas fa-plus')
              }, null, 2)
            ])
          ]),
          (note_visible.value)
            ? (_openBlock(), _createElementBlock("div", _hoisted_6, _cache[1] || (_cache[1] = [
                _createElementVNode("textarea", {
                  class: "form-control",
                  placeholder: "Dodaj notatkę do zamówienia. Będzie widoczna tylko dla Ciebie."
                }, null, -1),
                _createElementVNode("a", {
                  class: "btn btn-primary",
                  href: "#"
                }, "Zapisz", -1)
              ])))
            : _createCommentVNode("", true)
        ]),
        _cache[3] || (_cache[3] = _createElementVNode("div", {
          class: "tab-pane fade",
          id: "custom-tabs-two-profile",
          role: "tabpanel",
          "aria-labelledby": "custom-tabs-two-profile-tab"
        }, [
          _createElementVNode("table", { class: "table table-sm mb-0" }, [
            _createElementVNode("thead", null, [
              _createElementVNode("tr", null, [
                _createElementVNode("th", null, "Data"),
                _createElementVNode("th", null, "Dokument"),
                _createElementVNode("th", null, "Numer"),
                _createElementVNode("th", null, "Kwota"),
                _createElementVNode("th", null, "Akcje")
              ])
            ]),
            _createElementVNode("tbody", null, [
              _createElementVNode("tr", null, [
                _createElementVNode("td", {
                  colspan: "5",
                  class: "text-center"
                }, "Dokument niedostepny")
              ])
            ])
          ])
        ], -1)),
        _cache[4] || (_cache[4] = _createElementVNode("div", {
          class: "tab-pane fade",
          id: "custom-tabs-two-messages",
          role: "tabpanel",
          "aria-labelledby": "custom-tabs-two-messages-tab"
        }, [
          _createElementVNode("table", { class: "table table-sm mb-0" }, [
            _createElementVNode("thead", null, [
              _createElementVNode("tr", null, [
                _createElementVNode("th", null, "Data"),
                _createElementVNode("th", null, "Przewoźnik"),
                _createElementVNode("th", null, "Waga"),
                _createElementVNode("th", null, "Koszt wysyłki"),
                _createElementVNode("th", null, "Numer śledzenia"),
                _createElementVNode("th")
              ])
            ]),
            _createElementVNode("tbody", null, [
              _createElementVNode("tr", null, [
                _createElementVNode("td", null, "2024-10-23"),
                _createElementVNode("td", null, "Apearl - Odbiór w sklepie"),
                _createElementVNode("td", null, "0.000 kg"),
                _createElementVNode("td", null, "0,00 zł"),
                _createElementVNode("td"),
                _createElementVNode("td", null, [
                  _createElementVNode("a", { href: "#" }, " Edytuj ")
                ])
              ])
            ])
          ])
        ], -1)),
        _cache[5] || (_cache[5] = _createElementVNode("div", {
          class: "tab-pane fade text-center",
          id: "custom-tabs-two-settings",
          role: "tabpanel",
          "aria-labelledby": "custom-tabs-two-settings-tab"
        }, " Brak zwrotów towarów ", -1))
      ])
    ])
  ]))
}
}

})