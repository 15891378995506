export class CategoriesConnector{
  static categoryId: number = -1;

  static name_pl = '';
  static name_gb = '';
  static description_pl = '';
  static description_gb = '';
  static meta_title_pl = '';
  static meta_title_gb = '';
  static meta_description_pl = '';
  static meta_description_gb = '';
  static meta_keywords_pl = '';
  static meta_keywords_gb = '';
  static friendly_url_pl = '';
  static friendly_url_gb = '';
  static is_displayed = true;
  static parent_category_id = 2;
}