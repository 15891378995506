import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, unref as _unref, createTextVNode as _createTextVNode, withModifiers as _withModifiers, normalizeClass as _normalizeClass, createCommentVNode as _createCommentVNode, vModelText as _vModelText, withDirectives as _withDirectives, vModelSelect as _vModelSelect, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "row" }
const _hoisted_2 = { class: "table table-head-fixed text-nowrap mb-0" }
const _hoisted_3 = { class: "text-center" }
const _hoisted_4 = ["innerHTML"]
const _hoisted_5 = { class: "text-center" }
const _hoisted_6 = ["innerHTML"]
const _hoisted_7 = {
  class: "action-buttons",
  style: {"gap":"0","margin-left":"-1px"}
}
const _hoisted_8 = ["onClick"]
const _hoisted_9 = {
  class: "dropdown-menu",
  role: "menu"
}
const _hoisted_10 = ["onClick"]
const _hoisted_11 = { key: 1 }
const _hoisted_12 = { class: "text-center" }
const _hoisted_13 = { class: "text-center" }
const _hoisted_14 = ["onUpdate:modelValue"]
const _hoisted_15 = ["onUpdate:modelValue"]
const _hoisted_16 = ["value"]
const _hoisted_17 = { class: "text-center" }
const _hoisted_18 = ["onUpdate:modelValue"]
const _hoisted_19 = ["onUpdate:modelValue"]
const _hoisted_20 = { class: "text-center" }
const _hoisted_21 = { class: "action-buttons" }
const _hoisted_22 = ["onClick"]
const _hoisted_23 = { key: 0 }
const _hoisted_24 = {
  colspan: "6",
  class: "text-center"
}

import { onMounted, ref, computed } from 'vue';
import Datepicker from 'vuejs3-datepicker';
import { NewConnector, SpecificPrice } from '../NewConnector';


export default /*@__PURE__*/_defineComponent({
  __name: 'SpecificPriceBlock',
  setup(__props) {

const specificPrices = ref<SpecificPrice[]>([]);
const editingPriceId = ref<number | null>(null);
const originalPrice = ref<SpecificPrice | null>(null);

let combinations:string[] = []; // Example combinations

const impactTypeText = {
  'amount': 'zł',
  'percentage': '%'
};

function startEditingPrice(id: number) {
  const price = specificPrices.value.find(p => p.id === id);
  if (price) {
    originalPrice.value = { ...price };
  }
  editingPriceId.value = id;
}

function cancelEditingPrice() {
  if (editingPriceId.value !== null) {
    const index = specificPrices.value.findIndex(p => p.id === editingPriceId.value);
    if (index !== -1) {
      if (originalPrice.value && specificPrices.value[index].name === '' && specificPrices.value[index].reduction === '') {
        specificPrices.value.splice(index, 1);
        resetIds();
      } else if (originalPrice.value) {
        specificPrices.value[index] = { ...originalPrice.value };
      }
    }
  }
  editingPriceId.value = null;
  originalPrice.value = null;
}

function savePrice(price: SpecificPrice) {
  // Ensure reduction is a number with at most 2 decimal places and not empty
  if (price.reduction === '') {
    price.reduction = '0';
  }
  price.reduction = parseFloat(parseFloat(price.reduction).toFixed(2)).toString();

  const index = specificPrices.value.findIndex(p => p.id === price.id);
  if (index !== -1) {
    specificPrices.value[index] = price;
  } else {
    specificPrices.value.push(price);
  }
  syncWithConnector();
  editingPriceId.value = null;
}

function addNewPrice() {
  const newPrice: SpecificPrice = {
    id: specificPrices.value.length ? Math.max(...specificPrices.value.map(p => p.id)) + 1 : 1,
    name: '',
    product_attribute_id: 0, // Set the initial id to 0
    reduction: '',
    reduction_type: 'amount',
    from_date: '',
    to_date: ''
  };
  specificPrices.value.push(newPrice);
  startEditingPrice(newPrice.id);
}

function formatDate(dateString: string): string {
  if (!dateString) return '';
  const date = new Date(dateString);
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const day = String(date.getDate()).padStart(2, '0');
  return `${year}-${month}-${day}`;
}

function resetIds() {
  specificPrices.value.forEach((price, index) => {
    price.id = index + 1;
  });
}

function deletePrice(id: number) {
  const index = specificPrices.value.findIndex(p => p.id === id);
  if (index !== -1) {
    specificPrices.value.splice(index, 1);
    resetIds();
    syncWithConnector();
  }
}

function syncWithConnector(){
  NewConnector.specific_price = specificPrices.value.map(price => {
    return {
      ...price,
      to_date: price.to_date ? formatDate(price.to_date) : null,
      from_date: price.from_date ? formatDate(price.from_date) : null
    }
  });
}

function syncFromConnector(){
  const default_combinations = ['Wszystkie kombinacje'];
  const loaded_combinations = NewConnector.combinations.map(v => {
    return v.attributes.join(', ');
  });

  combinations = [...default_combinations, ...loaded_combinations];

  NewConnector.specific_price = NewConnector.specific_price.map(price => {
    return {
      ...price,
      // product_attribute_name: combinations[price.product_attribute_id] || combinations[0],
      reduction: parseFloat(parseFloat(price.reduction || '0').toFixed(2)).toString()
    }
  });
  
  specificPrices.value = NewConnector.specific_price;
}

const formattedPrices = computed(() => {
  return specificPrices.value.map(price => {
    let period = '';
    if (price.from_date && price.to_date) {
      period = `${formatDate(price.from_date)} - ${formatDate(price.to_date)}`;
    } else if (price.from_date) {
      period = `od ${formatDate(price.from_date)}`;
    } else if (price.to_date) {
      period = `do ${formatDate(price.to_date)}`;
    } else {
      period = '<span class="text-muted">Brak</span>';
    }
    return {
      ...price,
      formattedPeriod: period,
      displayRule: !price.name ? '<span class="text-muted">Brak</span>' : price.name
    };
  });
});

onMounted(() => {
  syncFromConnector();
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", null, [
    _cache[6] || (_cache[6] = _createElementVNode("label", {
      class: "card-title mb-2",
      style: {"float":"none"}
    }, "Specyficzne ceny", -1)),
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("table", _hoisted_2, [
        _cache[5] || (_cache[5] = _createElementVNode("thead", null, [
          _createElementVNode("tr", null, [
            _createElementVNode("th", {
              style: {"width":"80px"},
              class: "text-center"
            }, "ID"),
            _createElementVNode("th", {
              style: {"width":"200px"},
              class: "text-center"
            }, "Nazwa zasady"),
            _createElementVNode("th", null, "Kombinacja"),
            _createElementVNode("th", {
              style: {"width":"130px"},
              class: "text-center"
            }, "Wartość zniżki"),
            _createElementVNode("th", {
              style: {"width":"320px"},
              class: "text-center"
            }, "Okres czasu"),
            _createElementVNode("th", {
              style: {"width":"150px"},
              class: "text-center"
            }, "Akcje")
          ])
        ], -1)),
        _createElementVNode("tbody", null, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(formattedPrices.value, (price) => {
            return (_openBlock(), _createElementBlock(_Fragment, {
              key: price.id
            }, [
              (editingPriceId.value !== price.id)
                ? (_openBlock(), _createElementBlock("tr", {
                    key: 0,
                    class: _normalizeClass({ 'attribute-group-unusable': editingPriceId.value !== null && editingPriceId.value !== price.id })
                  }, [
                    _createElementVNode("td", _hoisted_3, _toDisplayString(price.id), 1),
                    _createElementVNode("td", {
                      class: "text-center",
                      innerHTML: price.displayRule
                    }, null, 8, _hoisted_4),
                    _createElementVNode("td", null, _toDisplayString(_unref(combinations)[price.product_attribute_id] ?? ''), 1),
                    _createElementVNode("td", _hoisted_5, _toDisplayString(price.reduction) + " " + _toDisplayString(impactTypeText[price.reduction_type]), 1),
                    _createElementVNode("td", {
                      class: "text-center",
                      innerHTML: price.formattedPeriod
                    }, null, 8, _hoisted_6),
                    _createElementVNode("td", null, [
                      _createElementVNode("div", _hoisted_7, [
                        _createElementVNode("button", {
                          onClick: _withModifiers(($event: any) => (startEditingPrice(price.id)), ["prevent"]),
                          class: "btn btn-default btn-sm"
                        }, _cache[0] || (_cache[0] = [
                          _createElementVNode("i", { class: "fas fa-pen mr-1" }, null, -1),
                          _createTextVNode(" Edytuj ")
                        ]), 8, _hoisted_8),
                        _cache[2] || (_cache[2] = _createElementVNode("button", {
                          type: "button",
                          class: "btn btn-default btn-sm dropdown-toggle dropdown-icon",
                          "data-toggle": "dropdown",
                          "aria-expanded": "false"
                        }, [
                          _createElementVNode("span", { class: "sr-only" }, "Zobacz więcej")
                        ], -1)),
                        _createElementVNode("div", _hoisted_9, [
                          _createElementVNode("a", {
                            class: "dropdown-item",
                            href: "#",
                            onClick: _withModifiers(($event: any) => (deletePrice(price.id)), ["prevent"])
                          }, _cache[1] || (_cache[1] = [
                            _createElementVNode("i", { class: "fas fa-trash mr-1" }, null, -1),
                            _createTextVNode(" Usuń ")
                          ]), 8, _hoisted_10)
                        ])
                      ])
                    ])
                  ], 2))
                : (_openBlock(), _createElementBlock("tr", _hoisted_11, [
                    _createElementVNode("td", _hoisted_12, _toDisplayString(price.id), 1),
                    _createElementVNode("td", _hoisted_13, [
                      _withDirectives(_createElementVNode("input", {
                        "onUpdate:modelValue": ($event: any) => ((price.name) = $event),
                        class: "form-control form-control-sm",
                        placeholder: "Nazwa"
                      }, null, 8, _hoisted_14), [
                        [_vModelText, price.name]
                      ])
                    ]),
                    _createElementVNode("td", null, [
                      _withDirectives(_createElementVNode("select", {
                        "onUpdate:modelValue": ($event: any) => ((price.product_attribute_id) = $event),
                        class: "form-control form-control-sm"
                      }, [
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(combinations), (comb, index) => {
                          return (_openBlock(), _createElementBlock("option", {
                            key: index,
                            value: index
                          }, _toDisplayString(comb), 9, _hoisted_16))
                        }), 128))
                      ], 8, _hoisted_15), [
                        [_vModelSelect, price.product_attribute_id]
                      ])
                    ]),
                    _createElementVNode("td", _hoisted_17, [
                      _withDirectives(_createElementVNode("input", {
                        "onUpdate:modelValue": ($event: any) => ((price.reduction) = $event),
                        class: "form-control form-control-sm",
                        style: {"width":"70px","display":"inline-block"},
                        type: "number",
                        step: "0.01",
                        min: "0"
                      }, null, 8, _hoisted_18), [
                        [
                          _vModelText,
                          price.reduction,
                          void 0,
                          { number: true }
                        ]
                      ]),
                      _withDirectives(_createElementVNode("select", {
                        "onUpdate:modelValue": ($event: any) => ((price.reduction_type) = $event),
                        class: "form-control form-control-sm",
                        style: {"width":"60px","display":"inline-block"}
                      }, _cache[3] || (_cache[3] = [
                        _createElementVNode("option", { value: "amount" }, "zł", -1),
                        _createElementVNode("option", { value: "percentage" }, "%", -1)
                      ]), 8, _hoisted_19), [
                        [_vModelSelect, price.reduction_type]
                      ])
                    ]),
                    _createElementVNode("td", _hoisted_20, [
                      _createVNode(_unref(Datepicker), {
                        "clear-button": true,
                        modelValue: price.from_date,
                        "onUpdate:modelValue": ($event: any) => ((price.from_date) = $event),
                        language: "pl",
                        format: "yyyy/MM/dd",
                        placeholder: "Data od"
                      }, null, 8, ["modelValue", "onUpdate:modelValue"]),
                      _createVNode(_unref(Datepicker), {
                        "clear-button": true,
                        modelValue: price.to_date,
                        "onUpdate:modelValue": ($event: any) => ((price.to_date) = $event),
                        language: "pl",
                        format: "yyyy/MM/dd",
                        placeholder: "Data do"
                      }, null, 8, ["modelValue", "onUpdate:modelValue"])
                    ]),
                    _createElementVNode("td", _hoisted_21, [
                      _createElementVNode("button", {
                        onClick: _withModifiers(($event: any) => (savePrice(price)), ["prevent"]),
                        class: "btn btn-primary btn-sm"
                      }, " Potwierdź ", 8, _hoisted_22),
                      _createElementVNode("button", {
                        onClick: _withModifiers(cancelEditingPrice, ["prevent"]),
                        class: "btn btn-secondary btn-sm"
                      }, " Anuluj ")
                    ])
                  ]))
            ], 64))
          }), 128)),
          (editingPriceId.value === null)
            ? (_openBlock(), _createElementBlock("tr", _hoisted_23, [
                _createElementVNode("td", _hoisted_24, [
                  _createElementVNode("button", {
                    onClick: _withModifiers(addNewPrice, ["prevent"]),
                    class: "btn btn-outline-primary w-100"
                  }, _cache[4] || (_cache[4] = [
                    _createElementVNode("i", { class: "fas fa-plus-circle mr-2" }, null, -1),
                    _createTextVNode(" Dodaj nową wartość ")
                  ]))
                ])
              ]))
            : _createCommentVNode("", true)
        ])
      ])
    ])
  ]))
}
}

})