import { _rootApi } from "../../../web/global/Api/_root"

export interface ApiParams {
  id: number;
  name: string;
  name_gb: string;
  public_name: string;
  public_name_gb: string;
  type_id: number;
}

export class ApiAdminAttributeGroupUpdate extends _rootApi {
  static request(params: ApiParams, func_success?: () => void, func_error?: (r) => void): void {
    this._request({
      url: '/attribute-group/update',
      params: params as unknown as { [index: string]: string },
      func_success: () => {
        if (func_success) func_success();
      },
      func_error: (r) => {
        if (func_error) func_error(r);
      }
    });
  }
}