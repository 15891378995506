import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, vModelText as _vModelText, withDirectives as _withDirectives, vModelRadio as _vModelRadio, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "col-12" }
const _hoisted_2 = { class: "card" }
const _hoisted_3 = { class: "card-body" }
const _hoisted_4 = { class: "form-group d-flex" }
const _hoisted_5 = { class: "mr-2" }
const _hoisted_6 = { class: "input-group" }
const _hoisted_7 = { class: "mr-2" }
const _hoisted_8 = { class: "input-group" }
const _hoisted_9 = { class: "mr-2" }
const _hoisted_10 = { class: "input-group" }
const _hoisted_11 = { class: "input-group" }
const _hoisted_12 = { class: "form-check" }
const _hoisted_13 = { class: "form-check" }
const _hoisted_14 = {
  key: 0,
  class: "form-group"
}

import { ref, watch, onMounted } from 'vue';
import { NewConnector } from './NewConnector';


export default /*@__PURE__*/_defineComponent({
  __name: 'NewShipping',
  setup(__props) {

const width = ref<number>(NewConnector.width);
const height = ref<number>(NewConnector.height);
const depth = ref<number>(NewConnector.depth);
const weight = ref<number>(NewConnector.weight);
const deliveryTime = ref<string>(NewConnector.deliveryTime);
const customDeliveryTime = ref<string>(NewConnector.customDeliveryTime);

watch([width, height, depth, weight, deliveryTime, customDeliveryTime], () => {
  NewConnector.width = width.value;
  NewConnector.height = height.value;
  NewConnector.depth = depth.value;
  NewConnector.weight = weight.value;
  NewConnector.deliveryTime = deliveryTime.value;
  NewConnector.customDeliveryTime = customDeliveryTime.value;
});

watch(deliveryTime, (newValue) => {
  if (newValue === 'default') {
    customDeliveryTime.value = '';
  }
});

onMounted(() => {
  width.value = NewConnector.width;
  height.value = NewConnector.height;
  depth.value = NewConnector.depth;
  weight.value = NewConnector.weight;
  deliveryTime.value = NewConnector.deliveryTime;
  customDeliveryTime.value = NewConnector.customDeliveryTime;
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _cache[17] || (_cache[17] = _createElementVNode("label", {
          class: "card-title mb-2",
          style: {"float":"none"}
        }, "Rozmiar paczki", -1)),
        _cache[18] || (_cache[18] = _createElementVNode("div", { class: "mb-2" }, "Dostosuj koszty wysyłki poprzez uzupełnienie wymiarów produktów.", -1)),
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("div", _hoisted_5, [
            _cache[8] || (_cache[8] = _createElementVNode("label", { for: "width" }, "Szerokość", -1)),
            _createElementVNode("div", _hoisted_6, [
              _withDirectives(_createElementVNode("input", {
                id: "width",
                type: "number",
                class: "form-control",
                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((width).value = $event)),
                step: "0.01"
              }, null, 512), [
                [
                  _vModelText,
                  width.value,
                  void 0,
                  { number: true }
                ]
              ]),
              _cache[7] || (_cache[7] = _createElementVNode("div", { class: "input-group-append" }, [
                _createElementVNode("span", { class: "input-group-text" }, "cm")
              ], -1))
            ])
          ]),
          _createElementVNode("div", _hoisted_7, [
            _cache[10] || (_cache[10] = _createElementVNode("label", { for: "height" }, "Wysokość", -1)),
            _createElementVNode("div", _hoisted_8, [
              _withDirectives(_createElementVNode("input", {
                id: "height",
                type: "number",
                class: "form-control",
                "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((height).value = $event)),
                step: "0.01"
              }, null, 512), [
                [
                  _vModelText,
                  height.value,
                  void 0,
                  { number: true }
                ]
              ]),
              _cache[9] || (_cache[9] = _createElementVNode("div", { class: "input-group-append" }, [
                _createElementVNode("span", { class: "input-group-text" }, "cm")
              ], -1))
            ])
          ]),
          _createElementVNode("div", _hoisted_9, [
            _cache[12] || (_cache[12] = _createElementVNode("label", { for: "depth" }, "Głębokość", -1)),
            _createElementVNode("div", _hoisted_10, [
              _withDirectives(_createElementVNode("input", {
                id: "depth",
                type: "number",
                class: "form-control",
                "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((depth).value = $event)),
                step: "0.01"
              }, null, 512), [
                [
                  _vModelText,
                  depth.value,
                  void 0,
                  { number: true }
                ]
              ]),
              _cache[11] || (_cache[11] = _createElementVNode("div", { class: "input-group-append" }, [
                _createElementVNode("span", { class: "input-group-text" }, "cm")
              ], -1))
            ])
          ]),
          _createElementVNode("div", null, [
            _cache[14] || (_cache[14] = _createElementVNode("label", { for: "weight" }, "Waga", -1)),
            _createElementVNode("div", _hoisted_11, [
              _withDirectives(_createElementVNode("input", {
                id: "weight",
                type: "number",
                class: "form-control",
                "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((weight).value = $event)),
                step: "0.01"
              }, null, 512), [
                [
                  _vModelText,
                  weight.value,
                  void 0,
                  { number: true }
                ]
              ]),
              _cache[13] || (_cache[13] = _createElementVNode("div", { class: "input-group-append" }, [
                _createElementVNode("span", { class: "input-group-text" }, "kg")
              ], -1))
            ])
          ])
        ]),
        _cache[19] || (_cache[19] = _createElementVNode("label", {
          class: "card-title mb-2",
          style: {"float":"none"}
        }, "Czas dostawy", -1)),
        _createElementVNode("div", _hoisted_12, [
          _withDirectives(_createElementVNode("input", {
            type: "radio",
            id: "default-delivery-time",
            value: "default",
            "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((deliveryTime).value = $event)),
            class: "form-check-input"
          }, null, 512), [
            [_vModelRadio, deliveryTime.value]
          ]),
          _cache[15] || (_cache[15] = _createElementVNode("label", {
            for: "default-delivery-time",
            class: "form-check-label"
          }, "Domyślny czas dostawy", -1))
        ]),
        _createElementVNode("div", _hoisted_13, [
          _withDirectives(_createElementVNode("input", {
            type: "radio",
            id: "custom-delivery-time",
            value: "custom",
            "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((deliveryTime).value = $event)),
            class: "form-check-input"
          }, null, 512), [
            [_vModelRadio, deliveryTime.value]
          ]),
          _cache[16] || (_cache[16] = _createElementVNode("label", {
            for: "custom-delivery-time",
            class: "form-check-label"
          }, "Określony czas dostawy", -1))
        ]),
        (deliveryTime.value === 'custom')
          ? (_openBlock(), _createElementBlock("div", _hoisted_14, [
              _withDirectives(_createElementVNode("input", {
                type: "text",
                class: "form-control",
                "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((customDeliveryTime).value = $event)),
                placeholder: "Dostawa w ciągu 3-4 dni"
              }, null, 512), [
                [_vModelText, customDeliveryTime.value]
              ])
            ]))
          : _createCommentVNode("", true)
      ])
    ])
  ]))
}
}

})