export interface Combination {
  attributes: string[];
  price: number;
  quantity: number;
  initial_price: number;
  brutto_price: number;
  imageIndex: number[];
  defaultCombination: boolean;
  index: string;
}

export interface SpecificPrice {
  id: number;
  name: string|null;
  // product_attribute_name: string|null;
  product_attribute_id: number;
  reduction: string;
  reduction_type: string;
  from_date: string|null;
  to_date: string|null;
}

export enum ProductStatusType{
  new = 1,
  edit = 2
}

export class NewConnector {
  static category_refresh: () => void = () => null;
  static back_to_combinations: () => void = () => null;
  static selected_combinations_refresh: () => void = () => null;
  static selected_combinations_on_attributes_refresh: () => void = () => null;
  static changed_productName: () => void = () => null;
  static reload_action_buttons: () => void = () => null;

  // Manage product edit start
  static is_copy = false;

  // Only information
  static productId: number = -1;
  static totalQuantity: number = 0;
  static product_status = ProductStatusType.new;
  static vat_rate: number = 23;

  // Required fields
  static productNamePL: string = '';
  static productNameGB: string = '';
  static summary_pl: string = '';
  static summary_gb: string = '';
  static descriptionPL: string = '';
  static descriptionGB: string = '';
  static price_net: number = 0;
  static price_gross: number = 0;
  static index: string = '';
  static categories: Array<string> = [];

  // Optional fields
  static features: Array<any> = [];
  static related_products: Array<any> = [];
  static is_online: boolean = false;
  static availabilityPreference: string = 'no-order';
  static width: number = 0;
  static height: number = 0;
  static depth: number = 0;
  static weight: number = 0;
  static deliveryTime: string = 'default';
  static customDeliveryTime: string = '';
  static uploaded_images: Array<{ id:number, name: string, url: string, isCover?: boolean, header?: string }> = [];

  static seoTitlePL: string = '';
  static seoDescriptionPL: string = '';
  static seoUrlPL: string = '';
  static seoTitleGB: string = '';
  static seoDescriptionGB: string = '';
  static seoUrlGB: string = '';

  // Combinations
  static combinations: Array<Combination> = [];
  static selected_combinations: Array<string> = [];

  static specific_price: SpecificPrice[] = [];
}