import { defineComponent as _defineComponent } from 'vue'
import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "btn-group" }
const _hoisted_2 = ["onClick"]

import { PropType, ref, onMounted } from 'vue';
import { DatetimeButtonDateType } from './HomeDateButtonsEnum';
import { nextTick } from 'process';


export default /*@__PURE__*/_defineComponent({
  __name: 'HomeDateButtons',
  props: {
  on_change: {
    type: Function,
    default: Function
  }
},
  setup(__props) {

const props = __props;

const selected_button_id = ref(0);

const buttons: Array<{ id: number, title: string }> = [
  { id: DatetimeButtonDateType.today, title: 'Dziś' },
  { id: DatetimeButtonDateType.this_month, title: 'W tym miesiącu' },
  { id: DatetimeButtonDateType.this_year, title: 'W tym roku' },
  { id: DatetimeButtonDateType.yesterday, title: 'Wczoraj' },
  { id: DatetimeButtonDateType.last_month, title: 'Ubiegły miesiąc' },
  { id: DatetimeButtonDateType.last_year, title: 'Ubiegły rok' }
];

function on_click(selected_id: number): void {
  selected_button_id.value = selected_id;
  props.on_change(selected_id);
}

onMounted(() => {
  setTimeout(() => {
    nextTick(() => {
      selected_button_id.value = DatetimeButtonDateType.this_month;
    });
  }, 100);
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(), _createElementBlock(_Fragment, null, _renderList(buttons, (btn) => {
      return _createElementVNode("div", {
        key: btn.id,
        onClick: ($event: any) => (on_click(btn.id)),
        class: _normalizeClass(["btn font-weight-bold", { 'btn-outline-primary': selected_button_id.value !== btn.id, 'btn-primary': selected_button_id.value === btn.id }])
      }, _toDisplayString(btn.title), 11, _hoisted_2)
    }), 64))
  ]))
}
}

})