import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, withModifiers as _withModifiers, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createVNode as _createVNode, unref as _unref, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, renderList as _renderList, Fragment as _Fragment, vModelText as _vModelText, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = { class: "content content-full-height" }
const _hoisted_2 = { class: "container-fluid" }
const _hoisted_3 = { class: "row" }
const _hoisted_4 = { class: "col-12" }
const _hoisted_5 = { class: "card" }
const _hoisted_6 = { class: "card-header" }
const _hoisted_7 = { class: "card-title" }
const _hoisted_8 = { class: "card-tools" }
const _hoisted_9 = { class: "nav nav-pills ml-auto" }
const _hoisted_10 = { class: "nav-item" }
const _hoisted_11 = { class: "btn-group" }
const _hoisted_12 = { class: "card-body table-responsive p-0" }
const _hoisted_13 = { class: "table table-head-fixed text-nowrap" }
const _hoisted_14 = { key: 0 }
const _hoisted_15 = {
  key: 0,
  class: "fas fa-sort-down ml-2"
}
const _hoisted_16 = {
  key: 1,
  class: "fas fa-sort-up ml-2"
}
const _hoisted_17 = {
  key: 0,
  class: "fas fa-sort-down ml-2"
}
const _hoisted_18 = {
  key: 1,
  class: "fas fa-sort-up ml-2"
}
const _hoisted_19 = {
  key: 0,
  class: "fas fa-sort-down ml-2"
}
const _hoisted_20 = {
  key: 1,
  class: "fas fa-sort-up ml-2"
}
const _hoisted_21 = { class: "text-center" }
const _hoisted_22 = { class: "text-center td-position" }
const _hoisted_23 = {
  key: 0,
  class: "btn-group-vertical"
}
const _hoisted_24 = ["onClick"]
const _hoisted_25 = ["onClick"]
const _hoisted_26 = { class: "position-relative" }
const _hoisted_27 = {
  key: 0,
  class: "td-2row-text"
}
const _hoisted_28 = { class: "td-gb-text" }
const _hoisted_29 = { key: 1 }
const _hoisted_30 = { class: "text-right" }
const _hoisted_31 = { class: "btn-group" }
const _hoisted_32 = ["onClick"]
const _hoisted_33 = ["disabled"]
const _hoisted_34 = {
  class: "dropdown-menu",
  role: "menu"
}
const _hoisted_35 = ["onClick"]
const _hoisted_36 = ["onClick"]
const _hoisted_37 = {
  key: 1,
  style: {"background-color":"#f4f6f9"}
}
const _hoisted_38 = { colspan: "6" }
const _hoisted_39 = { class: "card mb-0" }
const _hoisted_40 = { class: "card-body table-responsive p-0" }
const _hoisted_41 = { class: "table table-head-fixed text-nowrap" }
const _hoisted_42 = { key: 0 }
const _hoisted_43 = {
  key: 0,
  class: "fas fa-sort-down ml-2"
}
const _hoisted_44 = {
  key: 1,
  class: "fas fa-sort-up ml-2"
}
const _hoisted_45 = {
  key: 0,
  class: "fas fa-sort-down ml-2"
}
const _hoisted_46 = {
  key: 1,
  class: "fas fa-sort-up ml-2"
}
const _hoisted_47 = {
  key: 0,
  class: "position-relative"
}
const _hoisted_48 = {
  class: "td-2row-text",
  style: {"top":"3px"}
}
const _hoisted_49 = { class: "td-gb-text" }
const _hoisted_50 = { key: 1 }
const _hoisted_51 = { class: "text-right" }
const _hoisted_52 = { class: "btn-group" }
const _hoisted_53 = ["onClick"]
const _hoisted_54 = {
  class: "dropdown-menu",
  role: "menu"
}
const _hoisted_55 = ["onClick"]
const _hoisted_56 = {
  key: 1,
  style: {"background-color":"#e9ecef"}
}
const _hoisted_57 = { class: "text-center" }
const _hoisted_58 = ["onUpdate:modelValue"]
const _hoisted_59 = { class: "input-group input-group-sm" }
const _hoisted_60 = ["onUpdate:modelValue"]
const _hoisted_61 = { class: "input-group input-group-sm" }
const _hoisted_62 = ["onUpdate:modelValue"]
const _hoisted_63 = { class: "text-right btns-td" }
const _hoisted_64 = ["onClick"]
const _hoisted_65 = { key: 0 }
const _hoisted_66 = {
  colspan: "9",
  class: "text-center"
}
const _hoisted_67 = { key: 1 }
const _hoisted_68 = {
  key: 2,
  style: {"background-color":"#e9ecef"}
}
const _hoisted_69 = { class: "text-center" }
const _hoisted_70 = ["onUpdate:modelValue"]
const _hoisted_71 = { class: "text-center" }
const _hoisted_72 = ["onUpdate:modelValue"]
const _hoisted_73 = { class: "input-group input-group-sm" }
const _hoisted_74 = ["onUpdate:modelValue"]
const _hoisted_75 = { class: "input-group input-group-sm" }
const _hoisted_76 = ["onUpdate:modelValue"]
const _hoisted_77 = { class: "text-right btns-td" }
const _hoisted_78 = ["onClick"]
const _hoisted_79 = { key: 0 }
const _hoisted_80 = {
  colspan: "9",
  class: "text-center"
}
const _hoisted_81 = { key: 1 }
const _hoisted_82 = { class: "card-footer clearfix" }

import ContentHeader from '@/admin/components/Layouts/ContentHeader.vue';
import Pager from '@/admin/components/Pager.vue';
import { ref } from 'vue';
import { ApiAdminAddressesExport } from '@/admin/global/Api/AddressesExport';
import { ApiParams } from "@/admin/global/Api/AddressesSearch";
import { Form } from '@/web/global/Form';
import { Notify } from '@/web/global/Notify';
import { ApiAdminFeatureGroupSearch, ApiResultDataSingle } from '@/admin/global/Api/FeatureGroupSearch';
import { ApiResultDataSingle as ApiFeaturesResultDataSingle, ApiAdminFeaturesSearch } from '@/admin/global/Api/FeaturesSearch';
import { FeatureGroupUpdateOrder } from '@/admin/global/Api/FeatureGroupUpdateOrder';
import { ApiAdminFeatureGroupUpdate } from '@/admin/global/Api/FeatureGroupUpdate';
import { FeatureUpdateOrder } from '@/admin/global/Api/FeatureUpdateOrder';
import { ApiAdminFeatureUpdate } from '@/admin/global/Api/FeatureUpdate';
import { ApiAdminFeatureDelete } from '@/admin/global/Api/FeatureDelete';
import { ApiAdminFeatureGroupDelete } from '@/admin/global/Api/FeatureGroupDelete';
import { ApiAdminFeatureGroupNew } from '@/admin/global/Api/FeatureGroupNew';
import { ApiAdminFeatureNew } from '@/admin/global/Api/FeatureNew';

const item_per_page = 999;

export default /*@__PURE__*/_defineComponent({
  __name: 'Features',
  setup(__props) {

let item_max = ref(0);
let feature_groups = ref([] as Array<ApiResultDataSingle>);
let features = ref([] as Array<ApiFeaturesResultDataSingle>);
let actual_page = 1;

let sort_by = ref('position');
let sort_order = ref('asc');

let sort_by_features = ref('id');
let sort_order_features = ref('asc');

const selected_feature_group_id = ref(-1);
const editing_feature_group_id = ref(-1);
const editing_feature_id = ref(-1);

function get_search_params(page = -1, per_page = -1):ApiParams{
  let params:ApiParams = {};

  const params_name = ['search_id', 'search_name', 'search_position'];
  params_name.forEach(name => {
    const v = (Form.value(name) + '').trim();

    if(v.length > 0){
      params[name.replace('search_', '')] = v;
    }
  });

  if(page !== -1){
    params['page'] = page;
    params['per_page'] = per_page;
  }

  params["sort_by"] = sort_by.value;
  params["sort_order"] = sort_order.value === 'asc' ? 'asc' : 'desc';

  return params;
}

function close_feature_group(){
  selected_feature_group_id.value = -1;
  editing_feature_id.value = -1;
}

function open_feature_group(group_id: number){
  search_features(group_id, () => {
    selected_feature_group_id.value = group_id;
  });
}

function search(page: number, func: () => void = () => null){
  ApiAdminFeatureGroupSearch.request(get_search_params(page, item_per_page), result => {
    feature_groups.value = result.data;
    item_max.value = result.data_count;
    actual_page = page;
    func();
  }, () => {
    console.log('error');

    feature_groups.value = [];
    item_max.value = 0;
  });
}

function search_features(feature_group_id: number, func: () => void = () => null){
  ApiAdminFeaturesSearch.request({ 
    feature_group_id, 
    sort_by: sort_by_features.value, 
    sort_order: sort_order_features.value === 'asc' ? 'asc' : 'desc' 
  }, result => {
    features.value = result.data;
    func();
  }, () => {
    console.log('error');
    features.value = [];
  });
}

function start_editing_feature_group(feature_group_id: number){
  const feature_group = feature_groups.value.find(group => group.id === feature_group_id);
  if (feature_group && feature_group.name === feature_group.name_gb) feature_group.name_gb = '';

  editing_feature_group_id.value = feature_group_id;
}

function cancel_editing_feature_group(){
  search(actual_page, () => {
    editing_feature_group_id.value = -1;
  });
}

function update_feature_group(feature_group_id: number) {
  const feature_group = feature_groups.value.find(group => group.id === feature_group_id);
  if (feature_group) {
    if (!feature_group.name_gb) feature_group.name_gb = feature_group.name;

    const params = {
      name: feature_group.name,
      name_gb: feature_group.name_gb
    };

    const is_new_element = feature_group.id === 0;
    if(is_new_element){
      ApiAdminFeatureGroupNew.request(params, () => {
        Notify.success('Grupa cech została dodana');
        editing_feature_group_id.value = -1;
        search(actual_page);
      }, r => {
        Form.error_reflesh(r, ['nazwa', 'public name']);
        Notify.error('Błąd podczas dodawania grupy cech');
      });
    }
    else{
      const update_params = { ...params, id: feature_group.id };
      ApiAdminFeatureGroupUpdate.request(update_params, () => {
        Notify.success('Grupa cech została zaktualizowana');
        editing_feature_group_id.value = -1;
        search(actual_page);
      }, r => {
        Form.error_reflesh(r, ['nazwa', 'public name']);
        Notify.error('Błąd podczas aktualizacji grupy cech');
      });
    }
  }
}

function start_editing_feature(feature_id: number){
  const feature = features.value.find(attr => attr.id === feature_id);
  if (feature && feature.name === feature.name_gb) {
    feature.name_gb = '';
  }
  editing_feature_id.value = feature_id;
}

function cancel_editing_feature(){
  search_features(selected_feature_group_id.value, () => {
    editing_feature_id.value = -1;
  });
}

function update_feature(feature_id: number) {
  const feature = features.value.find(attr => attr.id === feature_id);
  if (feature) {
    if (!feature.name_gb) {
      feature.name_gb = feature.name;
    }

    const params = {
      name: feature.name,
      name_gb: feature.name_gb
    };

    const is_new_element = feature.id === 0;
    if (is_new_element) {
      const new_params = { ...params, feature_group_id: selected_feature_group_id.value };
      ApiAdminFeatureNew.request(new_params, () => {
        Notify.success('Atrybut został dodany');
        editing_feature_id.value = -1;
        search_features(selected_feature_group_id.value);
      }, r => {
        Form.error_reflesh(r, ['nazwa']);
        Notify.error('Błąd podczas dodawania cechy');
      });
    } else {
      const update_params = { ...params, id: feature.id };
      ApiAdminFeatureUpdate.request(update_params, () => {
        Notify.success('Atrybut został zaktualizowany');
        editing_feature_id.value = -1;
        search_features(selected_feature_group_id.value);
      }, r => {
        Form.error_reflesh(r, ['nazwa']);
        Notify.error('Błąd podczas aktualizacji cechy');
      });
    }
  }
}

function start_adding_attrbiute():void{
  const new_feature = {
    id: 0,
    name: '',
    name_gb: '',
    position: 0
  };

  features.value.push(new_feature);
  start_editing_feature(new_feature.id);
}

function start_adding_attrbiute_group():void{
  const new_group = {
    id: 0,
    name: '',
    name_gb: '',
    position: 0
  };

  feature_groups.value.push(new_group);
  start_editing_feature_group(new_group.id);
}

function data_export(){
  ApiAdminAddressesExport.request(get_search_params());
}

function order_by(name: string){
  if(sort_by.value === name){
    sort_order.value = sort_order.value === 'asc' ? 'desc' : 'asc';
  }
  else{
    sort_by.value = name;
    sort_order.value = 'asc';
  }

  search(actual_page);
}

function order_by_features(name: string){
  if(sort_by_features.value === name){
    sort_order_features.value = sort_order_features.value === 'asc' ? 'desc' : 'asc';
  }
  else{
    sort_by_features.value = name;
    sort_order_features.value = 'asc';
  }

  search_features(selected_feature_group_id.value);
}

function prepare_delete_feature_group(id: number): void {
  const feature_group_name = (feature_groups.value.find(v => v.id === id)?.name) ?? '';
  Notify.confirm('Czy na pewno chcesz usunąć grupę cech: #' + id + ' "' + feature_group_name + '"?', () => {
    delete_feature_group(id, feature_group_name);
  }, 'Usuń grupę', 'Anuluj');
}

function delete_feature_group(id: number, feature_group_name:string): void {
  ApiAdminFeatureGroupDelete.request({ id }, () => {
    search(actual_page);
    Notify.success('Grupa cech: #' + id + ' "' + feature_group_name + '" została usunięta');
  }, () => {
    console.log('delete error: ' + id);
  });
}

function prepare_delete_feature(id: number): void {
  const feature_name = (features.value.find(v => v.id === id)?.name) ?? '';
  Notify.confirm('Czy na pewno chcesz usunąć ceche: #' + id + ' "' + feature_name + '"?', () => {
    delete_feature(id, feature_name);
  }, 'Usuń ceche', 'Anuluj');
}

function delete_feature(id:number, feature_name:string):void{
  ApiAdminFeatureDelete.request({id}, () => {
    search_features(selected_feature_group_id.value);
    Notify.success('Atrybut: #' + id + ' "' + feature_name + '" został usunięty');
  }, () => {
    console.log('delete error: ' + id);
  });
}

function is_feature_group_selected(group_id: number):boolean{
  return selected_feature_group_id.value == group_id;
}

function is_feature_group_editing(group_id: number):boolean{
  return editing_feature_group_id.value == group_id;
}

function is_feature_editing(group_id: number):boolean{
  return editing_feature_id.value == group_id;
}

function is_some_feature_group_selected():boolean{
  return selected_feature_group_id.value !== -1;
}

function is_some_feature_group_editing():boolean{
  return editing_feature_group_id.value !== -1;
}

function is_some_feature_editing():boolean{
  return editing_feature_id.value !== -1;
}

function is_feature_group_blocked(group_id: number):boolean{
  if(is_feature_group_selected(group_id) && !is_some_feature_group_editing()){
    return false;
  }
  else if(is_feature_group_editing(group_id)){
    return false;
  }
  
  return is_some_feature_group_selected() || is_some_feature_group_editing();
}

function is_feature_blocked(feature_id: number):boolean{
  if(is_feature_editing(feature_id)){
    return false;
  }

  return is_some_feature_editing();
}

function change_order(group_id: number, direction: 'up' | 'down') {
  FeatureGroupUpdateOrder.request({ id: group_id, order_change_type: direction }, () => {
    search(actual_page);
  });
}

function change_order_feature(feature_id: number, direction: 'up' | 'down') {
  FeatureUpdateOrder.request({ id: feature_id, order_change_type: direction }, () => {
    search_features(selected_feature_group_id.value);
  });
}

search(actual_page);

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(ContentHeader, {
      main_title: "Katalog > Cechy",
      sub_title: "Zarządzanie Cechami"
    }, {
      default: _withCtx(() => [
        (!is_feature_group_blocked(0) && !is_some_feature_group_editing())
          ? (_openBlock(), _createElementBlock("button", {
              key: 0,
              class: "btn btn-primary",
              onClick: _cache[0] || (_cache[0] = _withModifiers(($event: any) => (start_adding_attrbiute_group()), ["prevent"]))
            }, _cache[15] || (_cache[15] = [
              _createElementVNode("i", { class: "fas fa-plus-circle" }, null, -1),
              _createTextVNode(" Dodaj grupe cech ")
            ])))
          : _createCommentVNode("", true)
      ]),
      _: 1
    }),
    _createElementVNode("section", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("div", _hoisted_4, [
            _createElementVNode("div", _hoisted_5, [
              _createElementVNode("div", _hoisted_6, [
                _createElementVNode("h3", _hoisted_7, "Grupy cech (" + _toDisplayString(_unref(item_max)) + ")", 1),
                _createElementVNode("div", _hoisted_8, [
                  _createElementVNode("ul", _hoisted_9, [
                    _createElementVNode("li", _hoisted_10, [
                      _createElementVNode("div", _hoisted_11, [
                        _createElementVNode("button", {
                          class: "btn btn-default btn-sm",
                          onClick: _cache[1] || (_cache[1] = _withModifiers(($event: any) => (search(_unref(actual_page))), ["prevent"]))
                        }, _cache[16] || (_cache[16] = [
                          _createElementVNode("i", { class: "fas fa-sync-alt mr-1" }, null, -1),
                          _createTextVNode(" Odśwież liste ")
                        ]))
                      ])
                    ])
                  ])
                ])
              ]),
              _createElementVNode("div", _hoisted_12, [
                _createElementVNode("table", _hoisted_13, [
                  (_unref(feature_groups).length > 0)
                    ? (_openBlock(), _createElementBlock("thead", _hoisted_14, [
                        _createElementVNode("tr", {
                          class: _normalizeClass({'feature-group-unusable': is_feature_group_blocked(-2)})
                        }, [
                          _createElementVNode("th", {
                            class: "th-sortable text-center",
                            style: {"width":"80px"},
                            onClick: _cache[2] || (_cache[2] = ($event: any) => (order_by('id')))
                          }, [
                            _cache[17] || (_cache[17] = _createTextVNode(" ID ")),
                            (_unref(sort_by) == 'id' && _unref(sort_order) == 'asc')
                              ? (_openBlock(), _createElementBlock("i", _hoisted_15))
                              : _createCommentVNode("", true),
                            (_unref(sort_by) == 'id' && _unref(sort_order) == 'desc')
                              ? (_openBlock(), _createElementBlock("i", _hoisted_16))
                              : _createCommentVNode("", true)
                          ]),
                          _createElementVNode("th", {
                            class: "th-sortable text-center",
                            style: {"width":"80px"},
                            onClick: _cache[3] || (_cache[3] = ($event: any) => (order_by('position')))
                          }, [
                            _cache[18] || (_cache[18] = _createTextVNode(" Poz. ")),
                            (_unref(sort_by) == 'position' && _unref(sort_order) == 'asc')
                              ? (_openBlock(), _createElementBlock("i", _hoisted_17))
                              : _createCommentVNode("", true),
                            (_unref(sort_by) == 'position' && _unref(sort_order) == 'desc')
                              ? (_openBlock(), _createElementBlock("i", _hoisted_18))
                              : _createCommentVNode("", true)
                          ]),
                          _createElementVNode("th", {
                            class: "th-sortable",
                            onClick: _cache[4] || (_cache[4] = ($event: any) => (order_by('name')))
                          }, [
                            _cache[19] || (_cache[19] = _createTextVNode(" Nazwa ")),
                            (_unref(sort_by) == 'name' && _unref(sort_order) == 'asc')
                              ? (_openBlock(), _createElementBlock("i", _hoisted_19))
                              : _createCommentVNode("", true),
                            (_unref(sort_by) == 'name' && _unref(sort_order) == 'desc')
                              ? (_openBlock(), _createElementBlock("i", _hoisted_20))
                              : _createCommentVNode("", true)
                          ]),
                          _cache[20] || (_cache[20] = _createElementVNode("th", {
                            class: "text-center",
                            style: {"width":"175px"}
                          }, "Akcje", -1))
                        ], 2),
                        _createElementVNode("tr", {
                          class: _normalizeClass(["filter-row", {'feature-group-unusable': is_feature_group_blocked(-2)}])
                        }, [
                          _createElementVNode("th", null, [
                            _createElementVNode("input", {
                              class: "form-control form-control-sm",
                              type: "text",
                              placeholder: "ID",
                              onInput: _cache[5] || (_cache[5] = ($event: any) => (search(1))),
                              name: "search_id"
                            }, null, 32)
                          ]),
                          _createElementVNode("th", null, [
                            _createElementVNode("input", {
                              class: "form-control form-control-sm",
                              type: "text",
                              placeholder: "Pozycja",
                              onInput: _cache[6] || (_cache[6] = ($event: any) => (search(1))),
                              name: "search_position"
                            }, null, 32)
                          ]),
                          _createElementVNode("th", null, [
                            _createElementVNode("input", {
                              class: "form-control form-control-sm",
                              type: "text",
                              placeholder: "Nazwa",
                              onInput: _cache[7] || (_cache[7] = ($event: any) => (search(1))),
                              name: "search_name"
                            }, null, 32)
                          ])
                        ], 2)
                      ]))
                    : _createCommentVNode("", true),
                  _createElementVNode("tbody", null, [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(feature_groups), (feature_group) => {
                      return (_openBlock(), _createElementBlock(_Fragment, null, [
                        (!is_feature_group_editing(feature_group.id))
                          ? (_openBlock(), _createElementBlock("tr", {
                              key: 0,
                              class: _normalizeClass({
                      'feature-group-unusable': is_feature_group_blocked(feature_group.id)
                    })
                            }, [
                              _createElementVNode("td", _hoisted_21, _toDisplayString(feature_group.id), 1),
                              _createElementVNode("td", _hoisted_22, [
                                _createTextVNode(_toDisplayString(feature_group.position) + " ", 1),
                                (!is_some_feature_group_selected() && (_unref(sort_by) === 'position' && _unref(sort_order) === 'asc'))
                                  ? (_openBlock(), _createElementBlock("div", _hoisted_23, [
                                      (feature_group.position !== Math.min(..._unref(feature_groups).map(group => group.position)))
                                        ? (_openBlock(), _createElementBlock("button", {
                                            key: 0,
                                            class: "btn btn-sm btn-outline-secondary",
                                            onClick: _withModifiers(($event: any) => (change_order(feature_group.id, 'up')), ["prevent"])
                                          }, _cache[21] || (_cache[21] = [
                                            _createElementVNode("i", { class: "fas fa-arrow-up" }, null, -1)
                                          ]), 8, _hoisted_24))
                                        : _createCommentVNode("", true),
                                      (feature_group.position !== Math.max(..._unref(feature_groups).map(group => group.position)))
                                        ? (_openBlock(), _createElementBlock("button", {
                                            key: 1,
                                            class: "btn btn-sm btn-outline-secondary",
                                            onClick: _withModifiers(($event: any) => (change_order(feature_group.id, 'down')), ["prevent"])
                                          }, _cache[22] || (_cache[22] = [
                                            _createElementVNode("i", { class: "fas fa-arrow-down" }, null, -1)
                                          ]), 8, _hoisted_25))
                                        : _createCommentVNode("", true)
                                    ]))
                                  : _createCommentVNode("", true)
                              ]),
                              _createElementVNode("td", _hoisted_26, [
                                (feature_group.name !== feature_group.name_gb)
                                  ? (_openBlock(), _createElementBlock("div", _hoisted_27, [
                                      _createElementVNode("div", null, _toDisplayString(feature_group.name), 1),
                                      _createElementVNode("div", _hoisted_28, _toDisplayString(feature_group.name_gb), 1)
                                    ]))
                                  : _createCommentVNode("", true),
                                (feature_group.name === feature_group.name_gb)
                                  ? (_openBlock(), _createElementBlock("div", _hoisted_29, _toDisplayString(feature_group.name), 1))
                                  : _createCommentVNode("", true)
                              ]),
                              _createElementVNode("td", _hoisted_30, [
                                _createElementVNode("div", _hoisted_31, [
                                  (!is_feature_group_selected(feature_group.id))
                                    ? (_openBlock(), _createElementBlock("button", {
                                        key: 0,
                                        onClick: _withModifiers(($event: any) => (open_feature_group(feature_group.id)), ["prevent"]),
                                        class: "btn btn-default btn-sm"
                                      }, _cache[23] || (_cache[23] = [
                                        _createElementVNode("i", { class: "fas fa-chevron-down mr-1" }, null, -1),
                                        _createTextVNode(" Rozwiń ")
                                      ]), 8, _hoisted_32))
                                    : _createCommentVNode("", true),
                                  (is_feature_group_selected(feature_group.id))
                                    ? (_openBlock(), _createElementBlock("button", {
                                        key: 1,
                                        onClick: _cache[8] || (_cache[8] = _withModifiers(($event: any) => (close_feature_group()), ["prevent"])),
                                        class: "btn btn-default btn-sm"
                                      }, _cache[24] || (_cache[24] = [
                                        _createElementVNode("i", { class: "fas fa-chevron-up mr-1" }, null, -1),
                                        _createTextVNode(" Zwiń ")
                                      ])))
                                    : _createCommentVNode("", true),
                                  _createElementVNode("button", {
                                    disabled: is_feature_group_selected(feature_group.id),
                                    type: "button",
                                    class: "btn btn-default btn-sm dropdown-toggle dropdown-icon",
                                    "data-toggle": "dropdown",
                                    "aria-expanded": "false"
                                  }, _cache[25] || (_cache[25] = [
                                    _createElementVNode("span", { class: "sr-only" }, "Zobacz więcej", -1)
                                  ]), 8, _hoisted_33),
                                  _createElementVNode("div", _hoisted_34, [
                                    _createElementVNode("a", {
                                      class: "dropdown-item",
                                      href: "#",
                                      onClick: _withModifiers(($event: any) => (start_editing_feature_group(feature_group.id)), ["prevent"])
                                    }, _cache[26] || (_cache[26] = [
                                      _createElementVNode("i", { class: "fas fa-pen mr-1" }, null, -1),
                                      _createTextVNode(" Edytuj ")
                                    ]), 8, _hoisted_35),
                                    _createElementVNode("a", {
                                      class: "dropdown-item",
                                      href: "#",
                                      onClick: _withModifiers(($event: any) => (prepare_delete_feature_group(feature_group.id)), ["prevent"])
                                    }, _cache[27] || (_cache[27] = [
                                      _createElementVNode("i", { class: "fas fa-trash mr-1" }, null, -1),
                                      _createTextVNode(" Usuń ")
                                    ]), 8, _hoisted_36)
                                  ])
                                ])
                              ])
                            ], 2))
                          : _createCommentVNode("", true),
                        (is_feature_group_selected(feature_group.id))
                          ? (_openBlock(), _createElementBlock("tr", _hoisted_37, [
                              _createElementVNode("td", _hoisted_38, [
                                _createElementVNode("div", _hoisted_39, [
                                  _createElementVNode("div", _hoisted_40, [
                                    _createElementVNode("table", _hoisted_41, [
                                      (_unref(features).length > 0)
                                        ? (_openBlock(), _createElementBlock("thead", _hoisted_42, [
                                            _createElementVNode("tr", null, [
                                              _createElementVNode("th", {
                                                class: "th-sortable",
                                                style: {"width":"80px"},
                                                onClick: _cache[9] || (_cache[9] = ($event: any) => (order_by_features('id')))
                                              }, [
                                                _cache[28] || (_cache[28] = _createTextVNode(" ID ")),
                                                (_unref(sort_by_features) == 'id' && _unref(sort_order_features) == 'asc')
                                                  ? (_openBlock(), _createElementBlock("i", _hoisted_43))
                                                  : _createCommentVNode("", true),
                                                (_unref(sort_by_features) == 'id' && _unref(sort_order_features) == 'desc')
                                                  ? (_openBlock(), _createElementBlock("i", _hoisted_44))
                                                  : _createCommentVNode("", true)
                                              ]),
                                              _createElementVNode("th", {
                                                class: "th-sortable",
                                                onClick: _cache[10] || (_cache[10] = ($event: any) => (order_by_features('name')))
                                              }, [
                                                _cache[29] || (_cache[29] = _createTextVNode(" Nazwa ")),
                                                (_unref(sort_by_features) == 'name' && _unref(sort_order_features) == 'asc')
                                                  ? (_openBlock(), _createElementBlock("i", _hoisted_45))
                                                  : _createCommentVNode("", true),
                                                (_unref(sort_by_features) == 'name' && _unref(sort_order_features) == 'desc')
                                                  ? (_openBlock(), _createElementBlock("i", _hoisted_46))
                                                  : _createCommentVNode("", true)
                                              ]),
                                              _cache[30] || (_cache[30] = _createElementVNode("th", {
                                                class: "text-center",
                                                style: {"width":"175px"}
                                              }, "Akcje", -1))
                                            ])
                                          ]))
                                        : _createCommentVNode("", true),
                                      _createElementVNode("tbody", null, [
                                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(features), (feature) => {
                                          return (_openBlock(), _createElementBlock(_Fragment, {
                                            key: feature.id
                                          }, [
                                            (!is_feature_editing(feature.id))
                                              ? (_openBlock(), _createElementBlock("tr", {
                                                  key: 0,
                                                  class: _normalizeClass({
                                    'feature-group-unusable': is_feature_blocked(feature.id)
                                  })
                                                }, [
                                                  _createElementVNode("td", null, _toDisplayString(feature.id), 1),
                                                  (feature.name !== feature.name_gb)
                                                    ? (_openBlock(), _createElementBlock("td", _hoisted_47, [
                                                        _createElementVNode("div", _hoisted_48, [
                                                          _createElementVNode("div", null, _toDisplayString(feature.name), 1),
                                                          _createElementVNode("div", _hoisted_49, _toDisplayString(feature.name_gb), 1)
                                                        ])
                                                      ]))
                                                    : _createCommentVNode("", true),
                                                  (feature.name === feature.name_gb)
                                                    ? (_openBlock(), _createElementBlock("td", _hoisted_50, _toDisplayString(feature.name), 1))
                                                    : _createCommentVNode("", true),
                                                  _createElementVNode("td", _hoisted_51, [
                                                    _createElementVNode("div", _hoisted_52, [
                                                      _createElementVNode("button", {
                                                        onClick: _withModifiers(($event: any) => (start_editing_feature(feature.id)), ["prevent"]),
                                                        class: "btn btn-default btn-sm"
                                                      }, _cache[31] || (_cache[31] = [
                                                        _createElementVNode("i", { class: "fas fa-pen mr-1" }, null, -1),
                                                        _createTextVNode(" Edytuj ")
                                                      ]), 8, _hoisted_53),
                                                      _cache[33] || (_cache[33] = _createElementVNode("button", {
                                                        type: "button",
                                                        class: "btn btn-default btn-sm dropdown-toggle dropdown-icon",
                                                        "data-toggle": "dropdown",
                                                        "aria-expanded": "false"
                                                      }, [
                                                        _createElementVNode("span", { class: "sr-only" }, "Zobacz więcej")
                                                      ], -1)),
                                                      _createElementVNode("div", _hoisted_54, [
                                                        _createElementVNode("a", {
                                                          class: "dropdown-item",
                                                          href: "#",
                                                          onClick: _withModifiers(($event: any) => (prepare_delete_feature(feature.id)), ["prevent"])
                                                        }, _cache[32] || (_cache[32] = [
                                                          _createElementVNode("i", { class: "fas fa-trash mr-1" }, null, -1),
                                                          _createTextVNode(" Usuń ")
                                                        ]), 8, _hoisted_55)
                                                      ])
                                                    ])
                                                  ])
                                                ], 2))
                                              : _createCommentVNode("", true),
                                            (is_feature_editing(feature.id))
                                              ? (_openBlock(), _createElementBlock("tr", _hoisted_56, [
                                                  _createElementVNode("td", _hoisted_57, [
                                                    _withDirectives(_createElementVNode("input", {
                                                      class: "form-control form-control-sm",
                                                      type: "text",
                                                      "onUpdate:modelValue": ($event: any) => ((feature.id) = $event),
                                                      disabled: ""
                                                    }, null, 8, _hoisted_58), [
                                                      [_vModelText, feature.id]
                                                    ])
                                                  ]),
                                                  _createElementVNode("td", null, [
                                                    _createElementVNode("div", _hoisted_59, [
                                                      _cache[34] || (_cache[34] = _createElementVNode("div", { class: "input-group-prepend" }, [
                                                        _createElementVNode("span", {
                                                          class: "input-group-text",
                                                          style: {"width":"34px","font-size":"12px"}
                                                        }, "PL")
                                                      ], -1)),
                                                      _withDirectives(_createElementVNode("input", {
                                                        name: "name",
                                                        class: "form-control",
                                                        type: "text",
                                                        "onUpdate:modelValue": ($event: any) => ((feature.name) = $event),
                                                        placeholder: "Nazwa (PL)"
                                                      }, null, 8, _hoisted_60), [
                                                        [_vModelText, feature.name]
                                                      ]),
                                                      _cache[35] || (_cache[35] = _createElementVNode("div", { class: "error text-danger" }, null, -1))
                                                    ]),
                                                    _createElementVNode("div", _hoisted_61, [
                                                      _cache[36] || (_cache[36] = _createElementVNode("div", { class: "input-group-prepend" }, [
                                                        _createElementVNode("span", {
                                                          class: "input-group-text",
                                                          style: {"width":"34px","font-size":"12px"}
                                                        }, "GB")
                                                      ], -1)),
                                                      _withDirectives(_createElementVNode("input", {
                                                        class: "form-control",
                                                        type: "text",
                                                        "onUpdate:modelValue": ($event: any) => ((feature.name_gb) = $event),
                                                        placeholder: "Nazwa (GB) -> Gdy pusta to taka sama jak PL"
                                                      }, null, 8, _hoisted_62), [
                                                        [_vModelText, feature.name_gb]
                                                      ]),
                                                      _cache[37] || (_cache[37] = _createElementVNode("div", { class: "error text-danger" }, null, -1))
                                                    ])
                                                  ]),
                                                  _createElementVNode("td", _hoisted_63, [
                                                    _createElementVNode("button", {
                                                      class: "btn btn-primary btn-sm mr-2",
                                                      onClick: _withModifiers(($event: any) => (update_feature(feature.id)), ["prevent"])
                                                    }, " Zapisz ", 8, _hoisted_64),
                                                    _createElementVNode("button", {
                                                      class: "btn btn-secondary btn-sm",
                                                      onClick: _cache[11] || (_cache[11] = _withModifiers(($event: any) => (cancel_editing_feature()), ["prevent"]))
                                                    }, " Anuluj ")
                                                  ])
                                                ]))
                                              : _createCommentVNode("", true)
                                          ], 64))
                                        }), 128)),
                                        (!is_some_feature_editing())
                                          ? (_openBlock(), _createElementBlock("tr", _hoisted_65, [
                                              _createElementVNode("td", _hoisted_66, [
                                                _createElementVNode("button", {
                                                  class: "btn btn-outline-primary w-100",
                                                  onClick: _cache[12] || (_cache[12] = _withModifiers(($event: any) => (start_adding_attrbiute()), ["prevent"]))
                                                }, _cache[38] || (_cache[38] = [
                                                  _createElementVNode("i", { class: "fas fa-plus-circle mr-2" }, null, -1),
                                                  _createTextVNode(" Dodaj ceche ")
                                                ]))
                                              ])
                                            ]))
                                          : _createCommentVNode("", true),
                                        (_unref(features).length === 0)
                                          ? (_openBlock(), _createElementBlock("tr", _hoisted_67, _cache[39] || (_cache[39] = [
                                              _createElementVNode("td", {
                                                colspan: "4",
                                                class: "text-center"
                                              }, "Brak cech", -1)
                                            ])))
                                          : _createCommentVNode("", true)
                                      ])
                                    ])
                                  ])
                                ])
                              ])
                            ]))
                          : _createCommentVNode("", true),
                        (is_feature_group_editing(feature_group.id))
                          ? (_openBlock(), _createElementBlock("tr", _hoisted_68, [
                              _createElementVNode("td", _hoisted_69, [
                                _withDirectives(_createElementVNode("input", {
                                  class: "form-control form-control-sm",
                                  type: "text",
                                  "onUpdate:modelValue": ($event: any) => ((feature_group.id) = $event),
                                  disabled: ""
                                }, null, 8, _hoisted_70), [
                                  [_vModelText, feature_group.id]
                                ])
                              ]),
                              _createElementVNode("td", _hoisted_71, [
                                _withDirectives(_createElementVNode("input", {
                                  class: "form-control form-control-sm",
                                  type: "text",
                                  "onUpdate:modelValue": ($event: any) => ((feature_group.position) = $event),
                                  disabled: ""
                                }, null, 8, _hoisted_72), [
                                  [_vModelText, feature_group.position]
                                ])
                              ]),
                              _createElementVNode("td", null, [
                                _createElementVNode("div", _hoisted_73, [
                                  _cache[40] || (_cache[40] = _createElementVNode("div", { class: "input-group-prepend" }, [
                                    _createElementVNode("span", {
                                      class: "input-group-text",
                                      style: {"width":"34px","font-size":"12px"}
                                    }, "PL")
                                  ], -1)),
                                  _withDirectives(_createElementVNode("input", {
                                    name: "name",
                                    class: "form-control",
                                    type: "text",
                                    "onUpdate:modelValue": ($event: any) => ((feature_group.name) = $event),
                                    placeholder: "Nazwa (PL)"
                                  }, null, 8, _hoisted_74), [
                                    [_vModelText, feature_group.name]
                                  ]),
                                  _cache[41] || (_cache[41] = _createElementVNode("div", { class: "error text-danger" }, null, -1))
                                ]),
                                _createElementVNode("div", _hoisted_75, [
                                  _cache[42] || (_cache[42] = _createElementVNode("div", { class: "input-group-prepend" }, [
                                    _createElementVNode("span", {
                                      class: "input-group-text",
                                      style: {"width":"34px","font-size":"12px"}
                                    }, "GB")
                                  ], -1)),
                                  _withDirectives(_createElementVNode("input", {
                                    name: "name_gb",
                                    class: "form-control",
                                    type: "text",
                                    "onUpdate:modelValue": ($event: any) => ((feature_group.name_gb) = $event),
                                    placeholder: "Nazwa (GB) -> Gdy pusta to taka sama jak PL"
                                  }, null, 8, _hoisted_76), [
                                    [_vModelText, feature_group.name_gb]
                                  ]),
                                  _cache[43] || (_cache[43] = _createElementVNode("div", { class: "error text-danger" }, null, -1))
                                ])
                              ]),
                              _createElementVNode("td", _hoisted_77, [
                                _createElementVNode("button", {
                                  class: "btn btn-primary btn-sm mr-2",
                                  onClick: _withModifiers(($event: any) => (update_feature_group(feature_group.id)), ["prevent"])
                                }, " Zapisz ", 8, _hoisted_78),
                                _createElementVNode("button", {
                                  class: "btn btn-secondary btn-sm",
                                  onClick: _cache[13] || (_cache[13] = _withModifiers(($event: any) => (cancel_editing_feature_group()), ["prevent"]))
                                }, " Anuluj ")
                              ])
                            ]))
                          : _createCommentVNode("", true)
                      ], 64))
                    }), 256)),
                    (!is_feature_group_blocked(0) && !is_some_feature_group_editing())
                      ? (_openBlock(), _createElementBlock("tr", _hoisted_79, [
                          _createElementVNode("td", _hoisted_80, [
                            _createElementVNode("button", {
                              class: "btn btn-outline-primary w-100",
                              onClick: _cache[14] || (_cache[14] = _withModifiers(($event: any) => (start_adding_attrbiute_group()), ["prevent"]))
                            }, _cache[44] || (_cache[44] = [
                              _createElementVNode("i", { class: "fas fa-plus-circle mr-2" }, null, -1),
                              _createTextVNode(" Dodaj grupe cech ")
                            ]))
                          ])
                        ]))
                      : _createCommentVNode("", true),
                    (_unref(feature_groups).length === 0)
                      ? (_openBlock(), _createElementBlock("tr", _hoisted_81, _cache[45] || (_cache[45] = [
                          _createElementVNode("td", {
                            colspan: "9",
                            class: "text-center"
                          }, "Brak grup cech", -1)
                        ])))
                      : _createCommentVNode("", true)
                  ])
                ])
              ]),
              _createElementVNode("div", _hoisted_82, [
                _createVNode(Pager, {
                  now_page: 1,
                  item_max: _unref(item_max),
                  item_per_page: item_per_page,
                  show_pager: false,
                  event_update: search
                }, null, 8, ["item_max"])
              ])
            ])
          ])
        ])
      ])
    ])
  ], 64))
}
}

})