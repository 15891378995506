import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, vModelSelect as _vModelSelect, withDirectives as _withDirectives, resolveComponent as _resolveComponent, withCtx as _withCtx, createStaticVNode as _createStaticVNode } from "vue"

const _hoisted_1 = { class: "content" }
const _hoisted_2 = { class: "container-fluid" }
const _hoisted_3 = { class: "row" }
const _hoisted_4 = { class: "col-12" }
const _hoisted_5 = { class: "card" }
const _hoisted_6 = { class: "card-body" }
const _hoisted_7 = { class: "form-group row" }
const _hoisted_8 = { class: "col-4 col-form-label text-right pr-3" }
const _hoisted_9 = { class: "form-group row" }
const _hoisted_10 = { class: "col-4 col-form-label text-right pr-3" }
const _hoisted_11 = { class: "form-group row" }
const _hoisted_12 = { class: "col-4 col-form-label text-right pr-3" }
const _hoisted_13 = { class: "form-group row" }
const _hoisted_14 = { class: "col-4 col-form-label text-right pr-3" }
const _hoisted_15 = { class: "form-group row" }
const _hoisted_16 = { class: "col-4 col-form-label text-right pr-3" }
const _hoisted_17 = { class: "form-group row" }
const _hoisted_18 = { class: "col-4 col-form-label text-right pr-3" }
const _hoisted_19 = { class: "form-group row" }
const _hoisted_20 = { class: "col-4 col-form-label text-right pr-3" }
const _hoisted_21 = { class: "col-6" }
const _hoisted_22 = ["value"]
const _hoisted_23 = { class: "card-footer clearfix" }

import ContentHeader from '@/admin/components/Layouts/ContentHeader.vue';
import Required from '@/admin/components/Form/Required.vue';
import { ApiAdminAddressNew, ApiParams } from '@/admin/global/Api/AddressNew';
import { Notify } from '@/admin/global/Notify';
import { Form } from '@/web/global/Form';
import { useRouter } from 'vue-router';
import { GeneralCountries } from '@/admin/global/General/Countries';
import { ref } from 'vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'New',
  setup(__props) {

const router = useRouter();
const countries = ref({});
const selected_country = ref(14);

function event_save():void{
  let params:ApiParams = {
    company: Form.value("company"),
    firstname: Form.value("firstname"),
    lastname: Form.value("lastname"),
    address1: Form.value("address1"),
    address2: Form.value("address2"),
    nip: Form.value("nip"),
    alias: Form.value("alias"),
    postcode: Form.value("postcode"),
    city: Form.value("city"),
    country_id: Number(Form.value("country_id")),
    other: Form.value("other"),
    phone: Form.value("phone"),
    phone_mobile: Form.value("phone_mobile"),
    vat_number: Form.value("vat_number"),
    is_active: Form.value("is_active") === '1'
  };

  params = Object.fromEntries(Object.entries(params).filter(([key, value]) => value && String(value).length >= 1)) as unknown as ApiParams;

  ApiAdminAddressNew.request(params, filteredParams => {
    Form.error_reflesh();
    window.scrollTo(0,0);
    
    router.push('/panel/sell/addresses/' + filteredParams.address_id);
    Notify.success('Adres został dodany');
  }, r => {
    Form.error_reflesh(r);
  });
}

GeneralCountries.run_with_data(data => {
  countries.value = data;
});

return (_ctx: any,_cache: any) => {
  const _component_RouterLink = _resolveComponent("RouterLink")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(ContentHeader, {
      main_title: "Klienci > Adresy",
      sub_title: "Nowy adres"
    }),
    _createElementVNode("section", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("div", _hoisted_4, [
            _createElementVNode("div", _hoisted_5, [
              _cache[21] || (_cache[21] = _createElementVNode("div", { class: "card-header" }, [
                _createElementVNode("h3", { class: "card-title" }, [
                  _createElementVNode("i", { class: "fas fa-address-book mr-2" }),
                  _createTextVNode(" Adres Klienta")
                ])
              ], -1)),
              _createElementVNode("div", _hoisted_6, [
                _cache[16] || (_cache[16] = _createStaticVNode("<div class=\"form-group row\"><label class=\"col-4 col-form-label text-right pr-3\">ID</label><div class=\"col-6\"><input name=\"id\" type=\"text\" class=\"form-control\" placeholder=\"ID\" disabled><div class=\"error text-danger\"></div></div></div><div class=\"form-group row\"><label class=\"col-4 col-form-label text-right pr-3\">Numer NIP</label><div class=\"col-6\"><input name=\"nip\" type=\"text\" class=\"form-control\" placeholder=\"Numer NIP\"><div class=\"error text-danger\"></div></div></div>", 2)),
                _createElementVNode("div", _hoisted_7, [
                  _createElementVNode("label", _hoisted_8, [
                    _createVNode(Required),
                    _cache[1] || (_cache[1] = _createTextVNode("Alias adresu"))
                  ]),
                  _cache[2] || (_cache[2] = _createElementVNode("div", { class: "col-6" }, [
                    _createElementVNode("input", {
                      name: "alias",
                      type: "text",
                      class: "form-control",
                      placeholder: "Alias adresu"
                    }),
                    _createElementVNode("span", { class: "text-muted small" }, "Tylko litery, kropka (.), cyfry i spacja są dozwolone."),
                    _createElementVNode("div", { class: "error text-danger" })
                  ], -1))
                ]),
                _createElementVNode("div", _hoisted_9, [
                  _createElementVNode("label", _hoisted_10, [
                    _createVNode(Required),
                    _cache[3] || (_cache[3] = _createTextVNode("Imię"))
                  ]),
                  _cache[4] || (_cache[4] = _createElementVNode("div", { class: "col-6" }, [
                    _createElementVNode("input", {
                      name: "firstname",
                      type: "text",
                      class: "form-control",
                      placeholder: "Imię"
                    }),
                    _createElementVNode("span", { class: "text-muted small" }, "Tylko litery, kropka (.) i spacja są dozwolone."),
                    _createElementVNode("div", { class: "error text-danger" })
                  ], -1))
                ]),
                _createElementVNode("div", _hoisted_11, [
                  _createElementVNode("label", _hoisted_12, [
                    _createVNode(Required),
                    _cache[5] || (_cache[5] = _createTextVNode("Nazwisko"))
                  ]),
                  _cache[6] || (_cache[6] = _createElementVNode("div", { class: "col-6" }, [
                    _createElementVNode("input", {
                      name: "lastname",
                      type: "text",
                      class: "form-control",
                      placeholder: "Nazwisko"
                    }),
                    _createElementVNode("span", { class: "text-muted small" }, "Tylko litery, kropka (.) i spacja są dozwolone."),
                    _createElementVNode("div", { class: "error text-danger" })
                  ], -1))
                ]),
                _cache[17] || (_cache[17] = _createStaticVNode("<div class=\"form-group row\"><label class=\"col-4 col-form-label text-right pr-3\">Firma</label><div class=\"col-6\"><input name=\"company\" type=\"text\" class=\"form-control\" placeholder=\"Firma\"><span class=\"text-muted small\">Tylko litery, kropka (.) i spacja są dozwolone.</span><div class=\"error text-danger\"></div></div></div><div class=\"form-group row\"><label class=\"col-4 col-form-label text-right pr-3\">NIP UE</label><div class=\"col-6\"><input name=\"vat_number\" type=\"text\" class=\"form-control\" placeholder=\"NIP UE\"><div class=\"error text-danger\"></div></div></div>", 2)),
                _createElementVNode("div", _hoisted_13, [
                  _createElementVNode("label", _hoisted_14, [
                    _createVNode(Required),
                    _cache[7] || (_cache[7] = _createTextVNode("Adres 1"))
                  ]),
                  _cache[8] || (_cache[8] = _createElementVNode("div", { class: "col-6" }, [
                    _createElementVNode("input", {
                      name: "address1",
                      type: "text",
                      class: "form-control",
                      placeholder: "Adres 1"
                    }),
                    _createElementVNode("div", { class: "error text-danger" })
                  ], -1))
                ]),
                _cache[18] || (_cache[18] = _createStaticVNode("<div class=\"form-group row\"><label class=\"col-4 col-form-label text-right pr-3\">Adres 2</label><div class=\"col-6\"><input name=\"address2\" type=\"text\" class=\"form-control\" placeholder=\"Adres 2\"><div class=\"error text-danger\"></div></div></div>", 1)),
                _createElementVNode("div", _hoisted_15, [
                  _createElementVNode("label", _hoisted_16, [
                    _createVNode(Required),
                    _cache[9] || (_cache[9] = _createTextVNode("Kod pocztowy"))
                  ]),
                  _cache[10] || (_cache[10] = _createElementVNode("div", { class: "col-6" }, [
                    _createElementVNode("input", {
                      name: "postcode",
                      type: "text",
                      class: "form-control",
                      placeholder: "Kod pocztowy"
                    }),
                    _createElementVNode("div", { class: "error text-danger" })
                  ], -1))
                ]),
                _createElementVNode("div", _hoisted_17, [
                  _createElementVNode("label", _hoisted_18, [
                    _createVNode(Required),
                    _cache[11] || (_cache[11] = _createTextVNode("Miasto"))
                  ]),
                  _cache[12] || (_cache[12] = _createElementVNode("div", { class: "col-6" }, [
                    _createElementVNode("input", {
                      name: "city",
                      type: "text",
                      class: "form-control",
                      placeholder: "Miasto"
                    }),
                    _createElementVNode("div", { class: "error text-danger" })
                  ], -1))
                ]),
                _createElementVNode("div", _hoisted_19, [
                  _createElementVNode("label", _hoisted_20, [
                    _createVNode(Required),
                    _cache[13] || (_cache[13] = _createTextVNode("Kraj"))
                  ]),
                  _createElementVNode("div", _hoisted_21, [
                    _withDirectives(_createElementVNode("select", {
                      name: "country_id",
                      class: "form-control form",
                      placeholder: "ID kraju",
                      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((selected_country).value = $event))
                    }, [
                      _cache[14] || (_cache[14] = _createElementVNode("option", { value: "" }, "Niewybrane", -1)),
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(countries.value, (name, id) => {
                        return (_openBlock(), _createElementBlock("option", { value: id }, _toDisplayString(name), 9, _hoisted_22))
                      }), 256))
                    ], 512), [
                      [_vModelSelect, selected_country.value]
                    ]),
                    _cache[15] || (_cache[15] = _createElementVNode("div", { class: "error text-danger" }, null, -1))
                  ])
                ]),
                _cache[19] || (_cache[19] = _createStaticVNode("<div class=\"form-group row\"><label class=\"col-4 col-form-label text-right pr-3\">Telefon</label><div class=\"col-6\"><input name=\"phone\" type=\"text\" class=\"form-control\" placeholder=\"Telefon\"><div class=\"error text-danger\"></div></div></div><div class=\"form-group row\"><label class=\"col-4 col-form-label text-right pr-3\">Telefon komórkowy</label><div class=\"col-6\"><input name=\"phone_mobile\" type=\"text\" class=\"form-control\" placeholder=\"Telefon komórkowy\"><div class=\"error text-danger\"></div></div></div><div class=\"form-group row\"><label class=\"col-4 col-form-label text-right pr-3\">Inne</label><div class=\"col-6\"><textarea name=\"other\" class=\"form-control\" placeholder=\"Inne\"></textarea><span class=\"text-muted small\">Tylko litery, kropka (.), cyfry i spacja są dozwolone.</span><div class=\"error text-danger\"></div></div></div><div class=\"form-group row\"><label class=\"col-4 col-form-label text-right pr-3\">Aktywny</label><div class=\"col-6\"><select class=\"form-control\" name=\"is_active\"><option value=\"1\" selected>Tak</option><option value=\"0\">Nie</option></select><div class=\"error text-danger\"></div></div></div>", 4))
              ]),
              _createElementVNode("div", _hoisted_23, [
                _createVNode(_component_RouterLink, {
                  to: "/panel/sell/addresses",
                  class: "btn btn-secondary float-left"
                }, {
                  default: _withCtx(() => _cache[20] || (_cache[20] = [
                    _createTextVNode("Anuluj")
                  ])),
                  _: 1
                }),
                _createElementVNode("button", {
                  onClick: event_save,
                  type: "button",
                  class: "btn btn-primary float-right"
                }, "Zapisz")
              ])
            ])
          ])
        ])
      ])
    ])
  ], 64))
}
}

})