import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["id"]

import { onMounted, ref } from 'vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'Wysiwyg',
  props: {
    initial_value: {}
  },
  emits: ['update:content'],
  setup(__props: any, { emit: __emit }) {

const props = __props;

const emit = __emit;

const generate_unique_id = () => {
  return 'editor-' + Math.random().toString(36).substr(2, 9);
};

const editor_id = ref(generate_unique_id());

const updateContent = (content: string) => {
  emit('update:content', content);
};

onMounted(() => {
  const editorElement = document.getElementById(editor_id.value);
  if (editorElement && props.initial_value) {
    editorElement.innerHTML = props.initial_value;
  }

  // @ts-ignore
  const quill = new Quill('#' + editor_id.value, {
    theme: 'snow'
  });

  quill.on('text-change', () => {
    updateContent(quill.root.innerHTML);
  });
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", { id: editor_id.value }, _cache[0] || (_cache[0] = [
      _createElementVNode("br", null, null, -1)
    ]), 8, _hoisted_1)
  ]))
}
}

})