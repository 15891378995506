import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, createVNode as _createVNode, vModelText as _vModelText, withDirectives as _withDirectives, toDisplayString as _toDisplayString, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "col-6" }
const _hoisted_2 = { class: "card" }
const _hoisted_3 = { class: "card-body" }
const _hoisted_4 = { class: "form-group" }
const _hoisted_5 = { class: "form-text text-muted" }
const _hoisted_6 = { class: "form-group" }
const _hoisted_7 = { class: "form-text text-muted" }
const _hoisted_8 = { class: "form-group" }
const _hoisted_9 = { class: "input-group" }
const _hoisted_10 = { class: "col-6" }
const _hoisted_11 = { class: "card" }
const _hoisted_12 = { class: "card-body" }
const _hoisted_13 = { class: "form-group" }
const _hoisted_14 = { class: "form-text text-muted" }
const _hoisted_15 = { class: "form-group" }
const _hoisted_16 = { class: "form-text text-muted" }
const _hoisted_17 = { class: "form-group" }
const _hoisted_18 = { class: "input-group" }

import { ref, watch, onMounted, onUnmounted } from 'vue';
import { NewConnector } from './NewConnector';
import SearchResultPreview from '@/admin/components/SearchResultPreview.vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'NewSEO',
  setup(__props) {

const seoTitlePL = ref<string>('');
const seoTitleGB = ref<string>('');
const seoDescriptionPL = ref<string>('');
const seoDescriptionGB = ref<string>('');
const seoUrlPL = ref<string>('');
const seoUrlGB = ref<string>('');
const isAutomaticInserting = ref<boolean>(true);

const resetUrlPL = () => {
  seoUrlPL.value = (NewConnector.seoTitlePL || NewConnector.productNamePL).toLowerCase().replace(/\s+/g, '-');
};

const resetUrlGB = () => {
  seoUrlGB.value = (NewConnector.seoTitleGB || NewConnector.productNameGB).toLowerCase().replace(/\s+/g, '-');
};

const valuesRefresh = () => {
  seoTitlePL.value = NewConnector.seoTitlePL || NewConnector.productNamePL;
  seoDescriptionPL.value = NewConnector.seoDescriptionPL || `Apearl poleca ➤ ${NewConnector.productNamePL} ✔️ Szybka wysyłka ⭐️ Duży wybór ✔️ Idealne na prezent ✔️ Wysoka jakość i atrakcyjne ceny ✔️ Sprawdź!`;
  seoUrlPL.value = NewConnector.seoUrlPL || NewConnector.productNamePL.toLowerCase().replace(/\s+/g, '-');
  seoTitleGB.value = NewConnector.seoTitleGB || NewConnector.productNameGB;
  seoDescriptionGB.value = NewConnector.seoDescriptionGB || `Apearl recommends ➤ ${NewConnector.productNameGB} ✔️ Fast shipping ⭐️ Wide selection ✔️ Perfect for gifts ✔️ High quality and attractive prices ✔️ Check it out!`;
  seoUrlGB.value = NewConnector.seoUrlGB || NewConnector.productNameGB.toLowerCase().replace(/\s+/g, '-');
};

const resetAllValues = () => {
  NewConnector.seoTitlePL = '';
  NewConnector.seoDescriptionPL = '';
  NewConnector.seoUrlPL = '';
  NewConnector.seoTitleGB = '';
  NewConnector.seoDescriptionGB = '';
  NewConnector.seoUrlGB = '';
  isAutomaticInserting.value = true;
  valuesRefresh();
};

function SEOValuesUpdated(){
  NewConnector.seoTitlePL = seoTitlePL.value;
  NewConnector.seoDescriptionPL = seoDescriptionPL.value;
  NewConnector.seoUrlPL = seoUrlPL.value;
  NewConnector.seoTitleGB = seoTitleGB.value;
  NewConnector.seoDescriptionGB = seoDescriptionGB.value;
  NewConnector.seoUrlGB = seoUrlGB.value;

  isAutomaticInserting.value = false;
}

onMounted(() => {
  valuesRefresh();
  isAutomaticInserting.value = (NewConnector.seoTitlePL === '' && NewConnector.seoDescriptionPL === '' && NewConnector.seoUrlPL === '' && NewConnector.seoTitleGB === '' && NewConnector.seoDescriptionGB === '' && NewConnector.seoUrlGB === '');

  NewConnector.changed_productName = () => {
    if(isAutomaticInserting.value){
      valuesRefresh();
    }
  };
});

onUnmounted(() => {
  NewConnector.changed_productName = () => null;
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("div", { class: "d-flex justify-content-between align-items-center mb-2" }, [
            _cache[6] || (_cache[6] = _createElementVNode("label", {
              class: "card-title mb-0",
              style: {"float":"none"}
            }, "Optymalizacja dla wyszukiwarek (PL)", -1)),
            _createElementVNode("button", {
              class: "btn btn-outline-secondary btn-sm",
              onClick: resetAllValues
            }, "Resetuj wszystkie wartości (PL & GB)")
          ]),
          _cache[10] || (_cache[10] = _createElementVNode("p", { class: "text-muted" }, "Popraw to jak strona tego produktu będzie widoczna w wynikach wyszukiwania.", -1)),
          _createVNode(SearchResultPreview, {
            "seo-title": seoTitlePL.value,
            "seo-url": seoUrlPL.value,
            "seo-description": seoDescriptionPL.value
          }, null, 8, ["seo-title", "seo-url", "seo-description"]),
          _createElementVNode("div", _hoisted_4, [
            _cache[7] || (_cache[7] = _createElementVNode("label", { for: "seo-title" }, "Tytuł", -1)),
            _withDirectives(_createElementVNode("input", {
              id: "seo-title",
              type: "text",
              class: "form-control",
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((seoTitlePL).value = $event)),
              onInput: SEOValuesUpdated
            }, null, 544), [
              [_vModelText, seoTitlePL.value]
            ]),
            _createElementVNode("small", _hoisted_5, "Użyto " + _toDisplayString(seoTitlePL.value.length) + " z 70 (zalecanych) znaków", 1)
          ]),
          _createElementVNode("div", _hoisted_6, [
            _cache[8] || (_cache[8] = _createElementVNode("label", { for: "seo-description" }, "Opis", -1)),
            _withDirectives(_createElementVNode("textarea", {
              id: "seo-description",
              class: "form-control",
              "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((seoDescriptionPL).value = $event)),
              onInput: SEOValuesUpdated
            }, null, 544), [
              [_vModelText, seoDescriptionPL.value]
            ]),
            _createElementVNode("small", _hoisted_7, "Użyto " + _toDisplayString(seoDescriptionPL.value.length) + " z 160 (zalecanych) znaków", 1)
          ]),
          _createElementVNode("div", _hoisted_8, [
            _cache[9] || (_cache[9] = _createElementVNode("label", { for: "seo-url" }, "Przyjazny adres URL", -1)),
            _createElementVNode("div", _hoisted_9, [
              _withDirectives(_createElementVNode("input", {
                id: "seo-url",
                type: "text",
                class: "form-control",
                "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((seoUrlPL).value = $event)),
                onInput: SEOValuesUpdated
              }, null, 544), [
                [_vModelText, seoUrlPL.value]
              ]),
              _createElementVNode("div", { class: "input-group-append" }, [
                _createElementVNode("button", {
                  class: "btn btn-outline-secondary",
                  onClick: resetUrlPL
                }, "Resetowanie URL")
              ])
            ])
          ])
        ])
      ])
    ]),
    _createElementVNode("div", _hoisted_10, [
      _createElementVNode("div", _hoisted_11, [
        _createElementVNode("div", _hoisted_12, [
          _cache[14] || (_cache[14] = _createElementVNode("div", { class: "d-flex justify-content-between align-items-center mb-2" }, [
            _createElementVNode("label", {
              class: "card-title mb-0",
              style: {"float":"none"}
            }, "Optymalizacja dla wyszukiwarek (GB)")
          ], -1)),
          _cache[15] || (_cache[15] = _createElementVNode("p", { class: "text-muted" }, "Popraw to jak strona tego produktu będzie widoczna w wynikach wyszukiwania.", -1)),
          _createVNode(SearchResultPreview, {
            "seo-title": seoTitleGB.value,
            "seo-url": seoTitleGB.value,
            "seo-description": seoDescriptionGB.value
          }, null, 8, ["seo-title", "seo-url", "seo-description"]),
          _createElementVNode("div", _hoisted_13, [
            _cache[11] || (_cache[11] = _createElementVNode("label", { for: "seo-title-gb" }, "Tytuł", -1)),
            _withDirectives(_createElementVNode("input", {
              id: "seo-title-gb",
              type: "text",
              class: "form-control",
              "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((seoTitleGB).value = $event)),
              onInput: SEOValuesUpdated
            }, null, 544), [
              [_vModelText, seoTitleGB.value]
            ]),
            _createElementVNode("small", _hoisted_14, "Użyto " + _toDisplayString(seoTitleGB.value.length) + " of 70 (zalecanych) znaków", 1)
          ]),
          _createElementVNode("div", _hoisted_15, [
            _cache[12] || (_cache[12] = _createElementVNode("label", { for: "seo-description-gb" }, "Opis", -1)),
            _withDirectives(_createElementVNode("textarea", {
              id: "seo-description-gb",
              class: "form-control",
              "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((seoDescriptionGB).value = $event)),
              onInput: SEOValuesUpdated
            }, null, 544), [
              [_vModelText, seoDescriptionGB.value]
            ]),
            _createElementVNode("small", _hoisted_16, "Użyto " + _toDisplayString(seoDescriptionGB.value.length) + " z 160 (zalecanych) znaków", 1)
          ]),
          _createElementVNode("div", _hoisted_17, [
            _cache[13] || (_cache[13] = _createElementVNode("label", { for: "seo-url-gb" }, "Przyjazny adres URL", -1)),
            _createElementVNode("div", _hoisted_18, [
              _withDirectives(_createElementVNode("input", {
                id: "seo-url-gb",
                type: "text",
                class: "form-control",
                "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((seoUrlGB).value = $event)),
                onInput: SEOValuesUpdated
              }, null, 544), [
                [_vModelText, seoUrlGB.value]
              ]),
              _createElementVNode("div", { class: "input-group-append" }, [
                _createElementVNode("button", {
                  class: "btn btn-outline-secondary",
                  onClick: resetUrlGB
                }, "Resetowanie URL")
              ])
            ])
          ])
        ])
      ])
    ])
  ], 64))
}
}

})