import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withModifiers as _withModifiers, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "pager-text" }
const _hoisted_2 = {
  key: 0,
  class: "pagination m-0 float-right"
}
const _hoisted_3 = {
  key: 1,
  class: "page-item"
}
const _hoisted_4 = ["onClick"]
const _hoisted_5 = {
  class: "page-link",
  href: "#"
}
const _hoisted_6 = {
  key: 2,
  class: "page-item"
}
const _hoisted_7 = {
  class: "page-link",
  href: "#"
}

import { ref, computed, watch, PropType } from 'vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'Pager',
  props: {
  item_max: {type: Number, default: 0},
  item_per_page: {type: Number, default: 0},
  now_page: {type: Number, default: 0},
  show_pager: {type: Boolean, default: true},
  event_update: {type: Function as PropType<(v:number) => void>, default: () => null}
},
  setup(__props) {

const props = __props;

let index_current_ = ref(1);
let index_current = computed({
  get: () => index_current_.value,
  set: (v) => {
      index_current_.value = v;
      props.event_update(v);
  }
});

let center_arr = computed(() => {
  const result = [] as Array<number>;

  if(index_current.value === 3 || index_current.value === index_max.value){
      result.push(index_current.value - 2);
  }
  if(index_current.value > 1){
      result.push(index_current.value - 1);
  }
  result.push(index_current.value);
  if(index_current.value < index_max.value){
      result.push(index_current.value + 1);
  }
  if(index_current.value + 2 === index_max.value || index_current.value === 1 && 2 < index_max.value){
      result.push(index_current.value + 2);
  }

  return result.filter(v => v > 0);
});

let item_from = computed(() => ((index_current.value - 1) * props.item_per_page) + 1);

let item_to = computed(() => {
  const result = item_from.value + props.item_per_page - 1;
  return result < props.item_max ? result : props.item_max;
});

let index_max = computed(() => Math.round(props.item_max / props.item_per_page - 0.5) + 1);

watch(() => props.now_page, () => {
  index_current_.value = props.now_page;
});

const index_click = (index) => {
  if(index_current.value !== index){
      index_current.value = index;
  }
}

const left_click = () => {
  if(index_current.value > 1){
      index_current.value--;
  }
}

const right_click = () => {
  if(index_current.value < index_max.value){
      index_current.value++;
  }
}

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("span", _hoisted_1, "Pokazano " + _toDisplayString(_unref(item_from)) + "-" + _toDisplayString(_unref(item_to)) + " z " + _toDisplayString(__props.item_max) + " pozycji", 1),
    (__props.show_pager && __props.item_max > 0)
      ? (_openBlock(), _createElementBlock("ul", _hoisted_2, [
          _createElementVNode("li", {
            class: _normalizeClass(["page-item", { 'disabled': _unref(index_current) <= 1 }]),
            onClick: _cache[0] || (_cache[0] = _withModifiers(($event: any) => (left_click()), ["prevent"]))
          }, _cache[4] || (_cache[4] = [
            _createElementVNode("a", {
              class: "page-link",
              href: "#"
            }, [
              _createElementVNode("i", { class: "fas fa-angle-left" })
            ], -1)
          ]), 2),
          (_unref(index_current) >= 1 + 3)
            ? (_openBlock(), _createElementBlock("li", {
                key: 0,
                class: "page-item",
                onClick: _cache[1] || (_cache[1] = _withModifiers(($event: any) => (index_click(1)), ["prevent"]))
              }, _cache[5] || (_cache[5] = [
                _createElementVNode("a", {
                  class: "page-link",
                  href: "#"
                }, "1", -1)
              ])))
            : _createCommentVNode("", true),
          (_unref(index_current) >= 1 + 3)
            ? (_openBlock(), _createElementBlock("li", _hoisted_3, _cache[6] || (_cache[6] = [
                _createElementVNode("a", { class: "page-link dot-link" }, "...", -1)
              ])))
            : _createCommentVNode("", true),
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(center_arr), (v, k) => {
            return (_openBlock(), _createElementBlock("li", {
              key: k,
              class: _normalizeClass(["page-item", { 'active': v === _unref(index_current) }]),
              onClick: _withModifiers(($event: any) => (index_click(v)), ["prevent"])
            }, [
              _createElementVNode("a", _hoisted_5, _toDisplayString(v), 1)
            ], 10, _hoisted_4))
          }), 128)),
          (_unref(index_current) <= _unref(index_max) - 3)
            ? (_openBlock(), _createElementBlock("li", _hoisted_6, _cache[7] || (_cache[7] = [
                _createElementVNode("a", { class: "page-link dot-link" }, "...", -1)
              ])))
            : _createCommentVNode("", true),
          (_unref(index_current) <= _unref(index_max) - 3)
            ? (_openBlock(), _createElementBlock("li", {
                key: 3,
                class: "page-item",
                onClick: _cache[2] || (_cache[2] = _withModifiers(($event: any) => (index_click(_unref(index_max))), ["prevent"]))
              }, [
                _createElementVNode("a", _hoisted_7, _toDisplayString(_unref(index_max)), 1)
              ]))
            : _createCommentVNode("", true),
          _createElementVNode("li", {
            class: _normalizeClass(["page-item", { 'disabled': _unref(index_current) >= _unref(index_max) }]),
            onClick: _cache[3] || (_cache[3] = _withModifiers(($event: any) => (right_click()), ["prevent"]))
          }, _cache[8] || (_cache[8] = [
            _createElementVNode("a", {
              class: "page-link",
              href: "#"
            }, [
              _createElementVNode("i", { class: "fas fa-angle-right" })
            ], -1)
          ]), 2)
        ]))
      : _createCommentVNode("", true)
  ], 64))
}
}

})