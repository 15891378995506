import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, renderList as _renderList, Fragment as _Fragment } from "vue"
import _imports_0 from '@assets/inpost.svg'
import _imports_1 from '@assets/dpd.png'
import _imports_2 from '@assets/poczta-polska.png'


const _hoisted_1 = { class: "card card-primary card-tabs" }
const _hoisted_2 = { class: "card-body" }
const _hoisted_3 = {
  class: "tab-content",
  id: "details-nav-bar-sending-info-tabContent"
}
const _hoisted_4 = {
  class: "tab-pane fade active show",
  id: "details-nav-bar-sending-info-home",
  role: "tabpanel",
  "aria-labelledby": "details-nav-bar-sending-info-home-tab"
}
const _hoisted_5 = { class: "form-group row" }
const _hoisted_6 = { class: "col-sm-8" }
const _hoisted_7 = {
  key: 0,
  type: "email",
  class: "form-control",
  placeholder: "Email"
}
const _hoisted_8 = { key: 1 }
const _hoisted_9 = { class: "form-group row" }
const _hoisted_10 = { class: "col-sm-8" }
const _hoisted_11 = {
  key: 0,
  name: "phone",
  type: "text",
  class: "form-control",
  placeholder: "000000000"
}
const _hoisted_12 = { key: 1 }
const _hoisted_13 = { class: "form-group row" }
const _hoisted_14 = { class: "col-sm-8" }
const _hoisted_15 = {
  key: 0,
  name: "order_place",
  type: "text",
  class: "form-control",
  placeholder: "XXX000X"
}
const _hoisted_16 = { key: 1 }
const _hoisted_17 = { class: "form-group row" }
const _hoisted_18 = { class: "col-sm-8" }
const _hoisted_19 = {
  key: 0,
  name: "size",
  class: "custom-select"
}
const _hoisted_20 = { key: 1 }
const _hoisted_21 = { class: "form-group row" }
const _hoisted_22 = { class: "col-sm-8" }
const _hoisted_23 = {
  key: 0,
  name: "reference",
  type: "text",
  class: "form-control",
  placeholder: "00000"
}
const _hoisted_24 = { key: 1 }
const _hoisted_25 = { class: "form-group row" }
const _hoisted_26 = { class: "col-sm-8" }
const _hoisted_27 = {
  key: 0,
  name: "method_of_sending",
  class: "custom-select"
}
const _hoisted_28 = { key: 1 }
const _hoisted_29 = { class: "form-group row" }
const _hoisted_30 = { class: "col-sm-8" }
const _hoisted_31 = {
  key: 0,
  name: "selected_from",
  type: "text",
  class: "form-control",
  placeholder: "XXX000X"
}
const _hoisted_32 = { key: 1 }
const _hoisted_33 = { class: "form-group row" }
const _hoisted_34 = { class: "col-sm-8" }
const _hoisted_35 = {
  key: 0,
  name: "cash_on_delivery",
  class: "custom-select"
}
const _hoisted_36 = { class: "form-group row" }
const _hoisted_37 = { class: "col-sm-8" }
const _hoisted_38 = {
  key: 0,
  name: "cash_on_delivery_value",
  type: "text",
  class: "form-control",
  placeholder: "000.00"
}
const _hoisted_39 = { key: 1 }
const _hoisted_40 = { class: "form-group row" }
const _hoisted_41 = { class: "col-sm-8" }
const _hoisted_42 = {
  key: 0,
  name: "insurance",
  class: "custom-select"
}
const _hoisted_43 = { class: "form-group row" }
const _hoisted_44 = { class: "col-sm-8" }
const _hoisted_45 = {
  key: 0,
  name: "insurance_value",
  type: "text",
  class: "form-control",
  placeholder: "000.00"
}
const _hoisted_46 = { key: 1 }
const _hoisted_47 = { class: "form-group row" }
const _hoisted_48 = { class: "col-sm-8" }
const _hoisted_49 = {
  key: 0,
  name: "weekend_post",
  class: "custom-select"
}
const _hoisted_50 = {
  key: 0,
  class: "form-group row disable",
  disabled: ""
}
const _hoisted_51 = {
  key: 0,
  class: "col-sm-8"
}
const _hoisted_52 = { class: "note-container" }
const _hoisted_53 = {
  key: 1,
  class: "form-group row"
}
const _hoisted_54 = {
  key: 2,
  class: "form-group row"
}
const _hoisted_55 = { class: "form-group row" }
const _hoisted_56 = { class: "col-12 text-center" }
const _hoisted_57 = {
  key: 1,
  class: "btn btn-primary"
}

import { ref } from 'vue';

export default /*@__PURE__*/_defineComponent({
  __name: 'DetailsNavBarSendingInfo',
  setup(__props) {

const is_sended = ref(false);

const data = {
  email: 'test@test.test',
  phone: '000-000-000',
  order_place: 'XXX000X',
  size: 'A',
  reference: '00000',
  method_of_sending: 1,
  selected_from: 'XXX000X',
  cash_on_delivery: true,
  cash_on_delivery_value: 155.50,
  insurance: true,
  insurance_value: 155.55,
  weekend_post: true,
  generated_files: ['file1']
}

const MethodOfSendingType:{[index:number]:string} = {
  1: 'Nadanie w paczkomacie',
  2: 'Odbierze kurier',
  3: 'Nadanie w POP',
}

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _cache[24] || (_cache[24] = _createElementVNode("div", { class: "card-header p-0 pt-1" }, [
      _createElementVNode("ul", {
        class: "nav nav-tabs pl-1",
        id: "details-nav-bar-sending-info-tab",
        role: "tablist"
      }, [
        _createElementVNode("span", { class: "text-todo ml-2" }, "TODO"),
        _createElementVNode("li", { class: "nav-item" }, [
          _createElementVNode("a", {
            class: "nav-link active",
            id: "details-nav-bar-sending-info-home-tab",
            "data-toggle": "pill",
            href: "#details-nav-bar-sending-info-home",
            role: "tab",
            "aria-controls": "details-nav-bar-sending-info-home",
            "aria-selected": "true"
          }, [
            _createElementVNode("img", {
              class: "logo-svg",
              src: _imports_0
            }),
            _createTextVNode("Paczkomaty ")
          ])
        ]),
        _createElementVNode("li", { class: "nav-item" }, [
          _createElementVNode("a", {
            class: "nav-link",
            id: "details-nav-bar-sending-info-profile-tab",
            "data-toggle": "pill",
            href: "#details-nav-bar-sending-info-profile",
            role: "tab",
            "aria-controls": "details-nav-bar-sending-info-profile",
            "aria-selected": "false"
          }, [
            _createElementVNode("img", {
              class: "logo-svg",
              src: _imports_0
            }),
            _createTextVNode("Kurier ")
          ])
        ]),
        _createElementVNode("li", { class: "nav-item" }, [
          _createElementVNode("a", {
            class: "nav-link",
            id: "details-nav-bar-sending-info-messages-tab",
            "data-toggle": "pill",
            href: "#details-nav-bar-sending-info-messages",
            role: "tab",
            "aria-controls": "details-nav-bar-sending-info-messages",
            "aria-selected": "false"
          }, [
            _createElementVNode("img", {
              class: "logo-png",
              src: _imports_1
            }),
            _createTextVNode("DPD Polska ")
          ])
        ]),
        _createElementVNode("li", { class: "nav-item" }, [
          _createElementVNode("a", {
            class: "nav-link",
            id: "details-nav-bar-sending-info-messages-tab",
            "data-toggle": "pill",
            href: "#details-nav-bar-sending-info-messages",
            role: "tab",
            "aria-controls": "details-nav-bar-sending-info-messages",
            "aria-selected": "false"
          }, [
            _createElementVNode("img", {
              class: "logo-png logo-png-pp",
              src: _imports_2
            })
          ])
        ])
      ])
    ], -1)),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("div", _hoisted_5, [
            _cache[1] || (_cache[1] = _createElementVNode("label", { class: "col-sm-4 col-form-label" }, "Adres e-mail odbiorcy", -1)),
            _createElementVNode("div", _hoisted_6, [
              (!is_sended.value)
                ? (_openBlock(), _createElementBlock("input", _hoisted_7))
                : (_openBlock(), _createElementBlock("span", _hoisted_8, _toDisplayString(data.email), 1))
            ])
          ]),
          _createElementVNode("div", _hoisted_9, [
            _cache[2] || (_cache[2] = _createElementVNode("label", { class: "col-sm-4 col-form-label" }, "Telefon odbiorcy", -1)),
            _createElementVNode("div", _hoisted_10, [
              (!is_sended.value)
                ? (_openBlock(), _createElementBlock("input", _hoisted_11))
                : (_openBlock(), _createElementBlock("span", _hoisted_12, _toDisplayString(data.phone), 1))
            ])
          ]),
          _createElementVNode("div", _hoisted_13, [
            _cache[3] || (_cache[3] = _createElementVNode("label", { class: "col-sm-4 col-form-label" }, "Paczkomat", -1)),
            _createElementVNode("div", _hoisted_14, [
              (!is_sended.value)
                ? (_openBlock(), _createElementBlock("input", _hoisted_15))
                : (_openBlock(), _createElementBlock("span", _hoisted_16, _toDisplayString(data.order_place), 1))
            ])
          ]),
          _createElementVNode("div", _hoisted_17, [
            _cache[5] || (_cache[5] = _createElementVNode("label", { class: "col-sm-4 col-form-label" }, "Rozmiar", -1)),
            _createElementVNode("div", _hoisted_18, [
              (!is_sended.value)
                ? (_openBlock(), _createElementBlock("select", _hoisted_19, _cache[4] || (_cache[4] = [
                    _createElementVNode("option", { value: "A" }, "A", -1),
                    _createElementVNode("option", { value: "B" }, "B", -1),
                    _createElementVNode("option", { value: "C" }, "C", -1)
                  ])))
                : (_openBlock(), _createElementBlock("span", _hoisted_20, _toDisplayString(data.size), 1))
            ])
          ]),
          _createElementVNode("div", _hoisted_21, [
            _cache[6] || (_cache[6] = _createElementVNode("label", { class: "col-sm-4 col-form-label" }, "Nr referencyjny", -1)),
            _createElementVNode("div", _hoisted_22, [
              (!is_sended.value)
                ? (_openBlock(), _createElementBlock("input", _hoisted_23))
                : (_openBlock(), _createElementBlock("span", _hoisted_24, _toDisplayString(data.reference), 1))
            ])
          ]),
          _createElementVNode("div", _hoisted_25, [
            _cache[8] || (_cache[8] = _createElementVNode("label", { class: "col-sm-4 col-form-label" }, "Sposob nadania", -1)),
            _createElementVNode("div", _hoisted_26, [
              (!is_sended.value)
                ? (_openBlock(), _createElementBlock("select", _hoisted_27, _cache[7] || (_cache[7] = [
                    _createElementVNode("option", null, "Nadanie w paczkomacie", -1),
                    _createElementVNode("option", null, "Odbierze kurier", -1),
                    _createElementVNode("option", null, "Nadanie w POP", -1)
                  ])))
                : (_openBlock(), _createElementBlock("span", _hoisted_28, _toDisplayString(MethodOfSendingType[data.method_of_sending]), 1))
            ])
          ]),
          _createElementVNode("div", _hoisted_29, [
            _cache[9] || (_cache[9] = _createElementVNode("label", { class: "col-sm-4 col-form-label" }, "Paczkomat nadania", -1)),
            _createElementVNode("div", _hoisted_30, [
              (!is_sended.value)
                ? (_openBlock(), _createElementBlock("input", _hoisted_31))
                : (_openBlock(), _createElementBlock("span", _hoisted_32, _toDisplayString(data.selected_from), 1))
            ])
          ]),
          _createElementVNode("div", _hoisted_33, [
            _cache[11] || (_cache[11] = _createElementVNode("label", { class: "col-sm-4 col-form-label" }, "Pobranie", -1)),
            _createElementVNode("div", _hoisted_34, [
              (!is_sended.value)
                ? (_openBlock(), _createElementBlock("select", _hoisted_35, _cache[10] || (_cache[10] = [
                    _createElementVNode("option", { value: "1" }, "Tak", -1),
                    _createElementVNode("option", { value: "0" }, "Nie", -1)
                  ])))
                : (_openBlock(), _createElementBlock("span", {
                    key: 1,
                    class: _normalizeClass(["font-weight-bolder", {
                'text-danger': !data.cash_on_delivery,
                'text-success': data.cash_on_delivery
              }])
                  }, _toDisplayString(data.cash_on_delivery ? 'Tak' : 'Nie'), 3))
            ])
          ]),
          _createElementVNode("div", _hoisted_36, [
            _cache[12] || (_cache[12] = _createElementVNode("label", { class: "col-sm-4 col-form-label" }, "Wartość pobrania", -1)),
            _createElementVNode("div", _hoisted_37, [
              (!is_sended.value)
                ? (_openBlock(), _createElementBlock("input", _hoisted_38))
                : (_openBlock(), _createElementBlock("span", _hoisted_39, _toDisplayString(data.cash_on_delivery_value), 1))
            ])
          ]),
          _createElementVNode("div", _hoisted_40, [
            _cache[14] || (_cache[14] = _createElementVNode("label", { class: "col-sm-4 col-form-label" }, "Ubezpieczenie", -1)),
            _createElementVNode("div", _hoisted_41, [
              (!is_sended.value)
                ? (_openBlock(), _createElementBlock("select", _hoisted_42, _cache[13] || (_cache[13] = [
                    _createElementVNode("option", { value: "1" }, "Tak", -1),
                    _createElementVNode("option", { value: "0" }, "Nie", -1)
                  ])))
                : (_openBlock(), _createElementBlock("span", {
                    key: 1,
                    class: _normalizeClass(["font-weight-bolder", {
                'text-danger': !data.insurance,
                'text-success': data.insurance
              }])
                  }, _toDisplayString(data.insurance ? 'Tak' : 'Nie'), 3))
            ])
          ]),
          _createElementVNode("div", _hoisted_43, [
            _cache[15] || (_cache[15] = _createElementVNode("label", { class: "col-sm-4 col-form-label" }, "Wartość ubezpeiczenia", -1)),
            _createElementVNode("div", _hoisted_44, [
              (!is_sended.value)
                ? (_openBlock(), _createElementBlock("input", _hoisted_45))
                : (_openBlock(), _createElementBlock("span", _hoisted_46, _toDisplayString(data.insurance_value), 1))
            ])
          ]),
          _createElementVNode("div", _hoisted_47, [
            _cache[17] || (_cache[17] = _createElementVNode("label", { class: "col-sm-4 col-form-label" }, "Paczka w weekend", -1)),
            _createElementVNode("div", _hoisted_48, [
              (!is_sended.value)
                ? (_openBlock(), _createElementBlock("select", _hoisted_49, _cache[16] || (_cache[16] = [
                    _createElementVNode("option", { value: "1" }, "Tak", -1),
                    _createElementVNode("option", { value: "0" }, "Nie", -1)
                  ])))
                : (_openBlock(), _createElementBlock("span", {
                    key: 1,
                    class: _normalizeClass(["font-weight-bolder", {
                'text-danger': !data.weekend_post,
                'text-success': data.weekend_post
              }])
                  }, _toDisplayString(data.weekend_post ? 'Tak' : 'Nie'), 3))
            ])
          ]),
          (is_sended.value)
            ? (_openBlock(), _createElementBlock("div", _hoisted_50, [
                _cache[19] || (_cache[19] = _createElementVNode("label", { class: "col-sm-4 col-form-label" }, "Numery listów", -1)),
                (is_sended.value)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_51, [
                      _createElementVNode("div", _hoisted_52, [
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(data.generated_files, (v, k) => {
                          return (_openBlock(), _createElementBlock("span", null, [
                            _cache[18] || (_cache[18] = _createElementVNode("i", { class: "fas fa-print mr-1" }, null, -1)),
                            _createTextVNode(_toDisplayString(v), 1)
                          ]))
                        }), 256))
                      ])
                    ]))
                  : _createCommentVNode("", true)
              ]))
            : _createCommentVNode("", true),
          (is_sended.value)
            ? (_openBlock(), _createElementBlock("div", _hoisted_53, _cache[20] || (_cache[20] = [
                _createElementVNode("label", { class: "col-sm-4 col-form-label" }, "Format etykiety", -1),
                _createElementVNode("div", { class: "col-sm-8" }, [
                  _createElementVNode("select", { class: "custom-select" }, [
                    _createElementVNode("option", null, "PDF"),
                    _createElementVNode("option", null, "ZPL"),
                    _createElementVNode("option", null, "EPL")
                  ])
                ], -1)
              ])))
            : _createCommentVNode("", true),
          (is_sended.value)
            ? (_openBlock(), _createElementBlock("div", _hoisted_54, _cache[21] || (_cache[21] = [
                _createElementVNode("label", { class: "col-sm-4 col-form-label" }, "Typ etykiety", -1),
                _createElementVNode("div", { class: "col-sm-8" }, [
                  _createElementVNode("select", { class: "custom-select" }, [
                    _createElementVNode("option", null, "A4"),
                    _createElementVNode("option", null, "A6P")
                  ])
                ], -1)
              ])))
            : _createCommentVNode("", true),
          _createElementVNode("div", _hoisted_55, [
            _createElementVNode("div", _hoisted_56, [
              (!is_sended.value)
                ? (_openBlock(), _createElementBlock("button", {
                    key: 0,
                    onClick: _cache[0] || (_cache[0] = ($event: any) => (is_sended.value = true)),
                    class: "btn btn-primary"
                  }, "Wyślij paczkę"))
                : (_openBlock(), _createElementBlock("button", _hoisted_57, "Drukuj list"))
            ])
          ])
        ]),
        _cache[22] || (_cache[22] = _createElementVNode("div", {
          class: "tab-pane fade",
          id: "details-nav-bar-sending-info-profile",
          role: "tabpanel",
          "aria-labelledby": "details-nav-bar-sending-info-profile-tab"
        }, [
          _createElementVNode("table", { class: "table table-sm mb-0" }, [
            _createElementVNode("thead", null, [
              _createElementVNode("tr", null, [
                _createElementVNode("th", null, "Data"),
                _createElementVNode("th", null, "Dokument"),
                _createElementVNode("th", null, "Numer"),
                _createElementVNode("th", null, "Kwota"),
                _createElementVNode("th", null, "Akcje")
              ])
            ]),
            _createElementVNode("tbody", null, [
              _createElementVNode("tr", null, [
                _createElementVNode("td", {
                  colspan: "5",
                  class: "text-center"
                }, "Dokument niedostepny")
              ])
            ])
          ])
        ], -1)),
        _cache[23] || (_cache[23] = _createElementVNode("div", {
          class: "tab-pane fade",
          id: "details-nav-bar-sending-info-messages",
          role: "tabpanel",
          "aria-labelledby": "details-nav-bar-sending-info-messages-tab"
        }, [
          _createElementVNode("table", { class: "table table-sm mb-0" }, [
            _createElementVNode("thead", null, [
              _createElementVNode("tr", null, [
                _createElementVNode("th", null, "Data"),
                _createElementVNode("th", null, "Przewoźnik"),
                _createElementVNode("th", null, "Waga"),
                _createElementVNode("th", null, "Koszt wysyłki"),
                _createElementVNode("th", null, "Numer śledzenia"),
                _createElementVNode("th")
              ])
            ]),
            _createElementVNode("tbody", null, [
              _createElementVNode("tr", null, [
                _createElementVNode("td", null, "2024-10-23"),
                _createElementVNode("td", null, "Apearl - Odbiór w sklepie"),
                _createElementVNode("td", null, "0.000 kg"),
                _createElementVNode("td", null, "0,00 zł"),
                _createElementVNode("td"),
                _createElementVNode("td", null, [
                  _createElementVNode("a", { href: "#" }, " Edytuj ")
                ])
              ])
            ])
          ])
        ], -1))
      ])
    ])
  ]))
}
}

})