import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, unref as _unref, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "row" }
const _hoisted_2 = { class: "col-6" }
const _hoisted_3 = { class: "col-6" }

import Wysiwyg from '@/admin/components/Wysiwyg.vue';
import { NewConnector } from './NewConnector';
import Required from '@/admin/components/Form/Required.vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'NewSummary',
  setup(__props) {

const initial_value_pl = NewConnector.summary_pl;
const initial_value_gb = NewConnector.summary_gb;

const updateSummaryPL = (content: string) => {
  NewConnector.summary_pl = content;
};

const updateSummaryGB = (content: string) => {
  NewConnector.summary_gb = content;
};

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("label", null, [
        _cache[0] || (_cache[0] = _createTextVNode("Podsumowanie (PL) ")),
        _createVNode(Required)
      ]),
      _createVNode(Wysiwyg, {
        initial_value: _unref(initial_value_pl),
        "onUpdate:content": updateSummaryPL
      }, null, 8, ["initial_value"])
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createElementVNode("label", null, [
        _cache[1] || (_cache[1] = _createTextVNode("Podsumowanie (GB) ")),
        _createVNode(Required)
      ]),
      _createVNode(Wysiwyg, {
        initial_value: _unref(initial_value_gb),
        "onUpdate:content": updateSummaryGB
      }, null, 8, ["initial_value"])
    ])
  ]))
}
}

})