import { _rootApi } from "../../../web/global/Api/_root"

export interface ApiParams {
  id: number;
}

export class ApiAdminFeatureGroupDelete extends _rootApi {
    static request(params: ApiParams, func_success?: () => void, func_error?: () => void): void {
        this._request({
            url: '/feature-group/delete',
            params: params as unknown as { [index: string]: string },
            func_success: () => {
                if (func_success) func_success();
            },
            func_error: () => {
                if (func_error) func_error();
            }
        });
    }
}
