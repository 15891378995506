import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, unref as _unref, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createCommentVNode as _createCommentVNode, createStaticVNode as _createStaticVNode } from "vue"

const _hoisted_1 = { class: "card" }
const _hoisted_2 = { class: "card-body" }
const _hoisted_3 = { class: "btn-group btn-group-toggle" }
const _hoisted_4 = {
  key: 0,
  class: "card mt-3"
}
const _hoisted_5 = { class: "card-body" }
const _hoisted_6 = {
  key: 0,
  class: "table table-sm",
  style: {"font-size":"0.9rem"}
}
const _hoisted_7 = {
  key: 1,
  class: "font-weight-bold text-center w-100"
}
const _hoisted_8 = {
  key: 1,
  class: "card mt-3"
}
const _hoisted_9 = { class: "card-header" }
const _hoisted_10 = { class: "card-title" }
const _hoisted_11 = { class: "text-warning font-weight-bold" }
const _hoisted_12 = { class: "card-body" }
const _hoisted_13 = {
  key: 0,
  class: "table table-sm",
  style: {"font-size":"0.8rem"}
}
const _hoisted_14 = { class: "text-center" }
const _hoisted_15 = ["src"]
const _hoisted_16 = { class: "text-center" }
const _hoisted_17 = ["href"]
const _hoisted_18 = { class: "text-center" }
const _hoisted_19 = { class: "text-center" }
const _hoisted_20 = { class: "text-center" }
const _hoisted_21 = { class: "text-center" }
const _hoisted_22 = {
  key: 1,
  class: "font-weight-bold text-center w-100"
}
const _hoisted_23 = {
  key: 2,
  class: "card mt-3"
}
const _hoisted_24 = { class: "card-header" }
const _hoisted_25 = { class: "card-title" }
const _hoisted_26 = { class: "text-warning font-weight-bold" }

import { ApiAdminHome, ApiResultBestSeller } from '@/admin/global/Api/Home';
import { ApiResultDataSingle } from '@/admin/global/Api/OrdersSearch';
import { onMounted, PropType, ref, watch } from 'vue';
import moment from 'moment';

enum PartType {
  last_orders = 0,
  most_bought = 1,
  most_searched = 2
}


export default /*@__PURE__*/_defineComponent({
  __name: 'productsSellsCard',
  props: {
  date_start: { type: String, default: '' },
  date_end: { type: String, default: '' },
  last_orders: { type: Array as PropType<Array<ApiResultDataSingle>>, default: () => [] },
  best_sellers: { type: Array as PropType<Array<ApiResultBestSeller>>, default: () => [] }
},
  setup(__props) {

const props = __props;

const selected_part_id = ref(0);

return (_ctx: any,_cache: any) => {
  const _component_RouterLink = _resolveComponent("RouterLink")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _cache[14] || (_cache[14] = _createElementVNode("div", { class: "card-header" }, [
      _createElementVNode("h3", { class: "card-title" }, [
        _createElementVNode("i", { class: "fas fa-chart-bar" }),
        _createTextVNode(" Produkty i sprzedaż ")
      ])
    ], -1)),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("label", {
          class: _normalizeClass(["btn btn-sm", { 'btn-primary': selected_part_id.value === PartType.last_orders, 'btn-outline-primary': selected_part_id.value !== PartType.last_orders }]),
          onClick: _cache[0] || (_cache[0] = ($event: any) => (selected_part_id.value = 0))
        }, _cache[3] || (_cache[3] = [
          _createElementVNode("i", { class: "fas fa-cash-register mr-2" }, null, -1),
          _createTextVNode("Ostatnie Zamówienia")
        ]), 2),
        _createElementVNode("label", {
          class: _normalizeClass(["btn btn-sm", { 'btn-primary': selected_part_id.value === PartType.most_bought, 'btn-outline-primary': selected_part_id.value !== PartType.most_bought }]),
          onClick: _cache[1] || (_cache[1] = ($event: any) => (selected_part_id.value = 1))
        }, _cache[4] || (_cache[4] = [
          _createElementVNode("i", { class: "fas fa-trophy mr-2" }, null, -1),
          _createTextVNode("Najcześciej Kupowane")
        ]), 2),
        _createElementVNode("label", {
          class: _normalizeClass(["btn btn-sm text-todo", { 'btn-primary': selected_part_id.value === PartType.most_searched, 'btn-outline-primary': selected_part_id.value !== PartType.most_searched }]),
          onClick: _cache[2] || (_cache[2] = ($event: any) => (selected_part_id.value = 2))
        }, _cache[5] || (_cache[5] = [
          _createElementVNode("i", { class: "fas fa-search mr-2" }, null, -1),
          _createTextVNode("Najcześciej Szukane")
        ]), 2)
      ]),
      (selected_part_id.value === PartType.last_orders)
        ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
            _cache[8] || (_cache[8] = _createElementVNode("div", { class: "card-header" }, [
              _createElementVNode("h4", { class: "card-title" }, "10 ostatnich zamówień")
            ], -1)),
            _createElementVNode("div", _hoisted_5, [
              (__props.last_orders.length > 0)
                ? (_openBlock(), _createElementBlock("table", _hoisted_6, [
                    _cache[7] || (_cache[7] = _createElementVNode("thead", null, [
                      _createElementVNode("tr", null, [
                        _createElementVNode("th", { scope: "col" }, "Nazwa klienta"),
                        _createElementVNode("th", { scope: "col" }, "Produkty"),
                        _createElementVNode("th", { scope: "col" }, "Razem Netto"),
                        _createElementVNode("th", { scope: "col" }, "Data"),
                        _createElementVNode("th", { scope: "col" }, "Status"),
                        _createElementVNode("th", { scope: "col" }, "Akcje")
                      ])
                    ], -1)),
                    _createElementVNode("tbody", null, [
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(__props.last_orders, (order) => {
                        return (_openBlock(), _createElementBlock("tr", {
                          key: order.id
                        }, [
                          _createElementVNode("td", {
                            class: _normalizeClass({ 'text-danger': !order.customer })
                          }, _toDisplayString(order.customer || 'Usunięty klient'), 3),
                          _createElementVNode("td", null, _toDisplayString(order.products_count), 1),
                          _createElementVNode("td", null, _toDisplayString(Number(order.total).toFixed(2)) + " zł", 1),
                          _createElementVNode("td", null, _toDisplayString(_unref(moment)(order.date).format('YYYY-MM-DD')), 1),
                          _createElementVNode("td", null, _toDisplayString(order.payment), 1),
                          _createElementVNode("td", null, [
                            _createVNode(_component_RouterLink, {
                              to: '/panel/sell/orders/' + order.id,
                              class: "btn btn-outline-primary btn-sm"
                            }, {
                              default: _withCtx(() => _cache[6] || (_cache[6] = [
                                _createElementVNode("i", { class: "fas fa-search" }, null, -1)
                              ])),
                              _: 2
                            }, 1032, ["to"])
                          ])
                        ]))
                      }), 128))
                    ])
                  ]))
                : (_openBlock(), _createElementBlock("div", _hoisted_7, "Brak danych"))
            ])
          ]))
        : _createCommentVNode("", true),
      (selected_part_id.value === PartType.most_bought)
        ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
            _createElementVNode("div", _hoisted_9, [
              _createElementVNode("h4", _hoisted_10, [
                _cache[9] || (_cache[9] = _createTextVNode("Najczęściej kupowane 10 produktów w okresie: ")),
                _createElementVNode("span", _hoisted_11, _toDisplayString(props.date_start) + " - " + _toDisplayString(props.date_end), 1)
              ])
            ]),
            _createElementVNode("div", _hoisted_12, [
              (__props.best_sellers.length > 0)
                ? (_openBlock(), _createElementBlock("table", _hoisted_13, [
                    _cache[11] || (_cache[11] = _createElementVNode("thead", null, [
                      _createElementVNode("tr", null, [
                        _createElementVNode("th", {
                          scope: "col",
                          class: "text-center"
                        }, "Obraz"),
                        _createElementVNode("th", {
                          scope: "col",
                          class: "text-center"
                        }, "Produkt"),
                        _createElementVNode("th", {
                          scope: "col",
                          class: "text-center"
                        }, "Kategoria"),
                        _createElementVNode("th", {
                          scope: "col",
                          class: "text-center"
                        }, "Razem sprzedano"),
                        _createElementVNode("th", {
                          scope: "col",
                          class: "text-center"
                        }, "Sprzedaż"),
                        _createElementVNode("th", {
                          scope: "col",
                          class: "text-center"
                        }, "Zysk netto")
                      ])
                    ], -1)),
                    _createElementVNode("tbody", null, [
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(__props.best_sellers, (product) => {
                        return (_openBlock(), _createElementBlock("tr", {
                          key: product.id
                        }, [
                          _createElementVNode("td", _hoisted_14, [
                            (product.imgs && product.imgs.length > 0)
                              ? (_openBlock(), _createElementBlock("img", {
                                  key: 0,
                                  style: {"height":"75px"},
                                  src: '/' + product.imgs[0].id + '-home_default/' + product.link_rewrite + '.jpg'
                                }, null, 8, _hoisted_15))
                              : _createCommentVNode("", true)
                          ]),
                          _createElementVNode("td", _hoisted_16, [
                            _createElementVNode("a", {
                              href: '/product/' + product.id
                            }, _toDisplayString(product.name), 9, _hoisted_17),
                            _cache[10] || (_cache[10] = _createElementVNode("br", null, null, -1)),
                            _createTextVNode(_toDisplayString(Number(product.price).toFixed(2)) + " zł ", 1)
                          ]),
                          _createElementVNode("td", _hoisted_18, _toDisplayString(product.category), 1),
                          _createElementVNode("td", _hoisted_19, _toDisplayString(product.quantity), 1),
                          _createElementVNode("td", _hoisted_20, _toDisplayString(Number(product.total).toFixed(2)) + " zł", 1),
                          _createElementVNode("td", _hoisted_21, _toDisplayString(Number(product.total).toFixed(2)) + " zł", 1)
                        ]))
                      }), 128))
                    ])
                  ]))
                : (_openBlock(), _createElementBlock("div", _hoisted_22, "Brak danych"))
            ])
          ]))
        : _createCommentVNode("", true),
      (selected_part_id.value === PartType.most_searched)
        ? (_openBlock(), _createElementBlock("div", _hoisted_23, [
            _createElementVNode("div", _hoisted_24, [
              _createElementVNode("h4", _hoisted_25, [
                _cache[12] || (_cache[12] = _createTextVNode("10 najczęściej szukanych wyrażeń w okresie: ")),
                _createElementVNode("span", _hoisted_26, _toDisplayString(props.date_start) + " - " + _toDisplayString(props.date_end), 1)
              ])
            ]),
            _cache[13] || (_cache[13] = _createStaticVNode("<div class=\"card-body\"><table class=\"table table-sm\" style=\"font-size:0.9rem;\"><thead><tr><th scope=\"col\">Wyszukiwane slowo</th><th scope=\"col\">Ilosc wyszukan</th><th scope=\"col\">Ilosc znalezionych produktow</th></tr></thead><tbody><tr><td>Kolczyki</td><td>139</td><td>373</td></tr></tbody></table></div>", 1))
          ]))
        : _createCommentVNode("", true)
    ])
  ]))
}
}

})