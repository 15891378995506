import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "search-result-preview" }
const _hoisted_2 = { class: "title" }
const _hoisted_3 = {
  key: 0,
  class: "url"
}
const _hoisted_4 = {
  key: 1,
  class: "url"
}
const _hoisted_5 = { class: "description" }


export default /*@__PURE__*/_defineComponent({
  __name: 'SearchResultPreview',
  props: {
  seoTitle: { type: String, default: '' },
  seoUrl: { type: String, default: '' },
  seoDescription: { type: String, default: '' },
  isCategory: { type: Boolean, default: false },
},
  setup(__props) {



return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, _toDisplayString(__props.seoTitle), 1),
    (!__props.isCategory)
      ? (_openBlock(), _createElementBlock("div", _hoisted_3, "https://apearl.eu/[category]/" + _toDisplayString(__props.seoUrl) + ".html", 1))
      : _createCommentVNode("", true),
    (__props.isCategory)
      ? (_openBlock(), _createElementBlock("div", _hoisted_4, "https://apearl.eu/" + _toDisplayString(__props.seoUrl), 1))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_5, _toDisplayString(__props.seoDescription), 1)
  ]))
}
}

})