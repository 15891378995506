import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "metrics-container" }
const _hoisted_2 = { class: "value" }
const _hoisted_3 = { class: "value" }
const _hoisted_4 = { class: "value" }
const _hoisted_5 = { class: "value" }
const _hoisted_6 = { class: "value" }
const _hoisted_7 = { class: "value" }

import { ApiResultMetricsData } from '@/admin/global/Api/Home';
import { onMounted, PropType, ref } from 'vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'HomeMetrics',
  props: {
  on_change: { type: Function, default: Function },
  metrics_data: { type: Object as PropType<ApiResultMetricsData>, default: {sales: 0, orders: 0, cart_value: 0, visits: 0, conversion_rate: 0, net_profit: 0 } }
},
  setup(__props) {

const props = __props;

const selectedBlock = ref<number | null>(null);

function formatCurrency(value: number): string {
  return value.toLocaleString('pl-PL', { style: 'currency', currency: 'PLN' }).replace('PLN', 'zł');
}

function formatNumber(value: number): string {
  return value.toLocaleString('pl-PL');
}

function selectBlock(index: number) {
  if(selectedBlock.value === index) {
    return;
  }

  selectedBlock.value = selectedBlock.value === index ? null : index;
  props.on_change(index);
}

onMounted(() => {
  selectBlock(0);
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", {
      class: _normalizeClass(["block", { selected: selectedBlock.value === 0, 'selected-sales': selectedBlock.value === 0 }]),
      onClick: _cache[0] || (_cache[0] = ($event: any) => (selectBlock(0)))
    }, [
      _cache[7] || (_cache[7] = _createElementVNode("p", { class: "title" }, "Sprzedaż", -1)),
      _createElementVNode("p", _hoisted_2, [
        _createTextVNode(_toDisplayString(formatCurrency(Number(__props.metrics_data.sales))), 1),
        _cache[6] || (_cache[6] = _createElementVNode("span", { class: "suffix" }, "Netto", -1))
      ])
    ], 2),
    _createElementVNode("div", {
      class: _normalizeClass(["block", { selected: selectedBlock.value === 1, 'selected-orders': selectedBlock.value === 1 }]),
      onClick: _cache[1] || (_cache[1] = ($event: any) => (selectBlock(1)))
    }, [
      _cache[8] || (_cache[8] = _createElementVNode("p", { class: "title" }, "Zamówienia", -1)),
      _createElementVNode("p", _hoisted_3, _toDisplayString(__props.metrics_data.orders), 1)
    ], 2),
    _createElementVNode("div", {
      class: _normalizeClass(["block", { selected: selectedBlock.value === 2, 'selected-cart-value': selectedBlock.value === 2 }]),
      onClick: _cache[2] || (_cache[2] = ($event: any) => (selectBlock(2)))
    }, [
      _cache[10] || (_cache[10] = _createElementVNode("p", { class: "title text-todo" }, "Wartość koszyka", -1)),
      _createElementVNode("p", _hoisted_4, [
        _createTextVNode(_toDisplayString(formatCurrency(Number(__props.metrics_data.cart_value))), 1),
        _cache[9] || (_cache[9] = _createElementVNode("span", { class: "suffix" }, "Netto", -1))
      ])
    ], 2),
    _createElementVNode("div", {
      class: _normalizeClass(["block", { selected: selectedBlock.value === 3, 'selected-visits': selectedBlock.value === 3 }]),
      onClick: _cache[3] || (_cache[3] = ($event: any) => (selectBlock(3)))
    }, [
      _cache[11] || (_cache[11] = _createElementVNode("p", { class: "title text-todo" }, "Wizyty", -1)),
      _createElementVNode("p", _hoisted_5, _toDisplayString(formatNumber(Number(__props.metrics_data.visits))), 1)
    ], 2),
    _createElementVNode("div", {
      class: _normalizeClass(["block", { selected: selectedBlock.value === 4, 'selected-conversion-rate': selectedBlock.value === 4 }]),
      onClick: _cache[4] || (_cache[4] = ($event: any) => (selectBlock(4)))
    }, [
      _cache[12] || (_cache[12] = _createElementVNode("p", { class: "title text-todo" }, "Stopa konwersji", -1)),
      _createElementVNode("p", _hoisted_6, _toDisplayString(Number(__props.metrics_data.conversion_rate).toFixed(2)) + "%", 1)
    ], 2),
    _createElementVNode("div", {
      class: _normalizeClass(["block", { selected: selectedBlock.value === 5, 'selected-net-profit': selectedBlock.value === 5 }]),
      onClick: _cache[5] || (_cache[5] = ($event: any) => (selectBlock(5)))
    }, [
      _cache[14] || (_cache[14] = _createElementVNode("p", { class: "title text-todo" }, "Zysk netto", -1)),
      _createElementVNode("p", _hoisted_7, [
        _createTextVNode(_toDisplayString(formatCurrency(Number(__props.metrics_data.net_profit))), 1),
        _cache[13] || (_cache[13] = _createElementVNode("span", { class: "suffix" }, "Netto", -1))
      ])
    ], 2)
  ]))
}
}

})