import { defineComponent as _defineComponent } from 'vue'
import { openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

import { onMounted, ref } from 'vue';
import NewMainScreen from './New/NewMainScreen.vue';
import { Notify } from '@/admin/global/Notify';
import { CategoriesConnector } from './New/CategoriesConnector';
import { ApiAdminCategoryDetails } from '@/admin/global/Api/CategoryDetails';
import { useRoute } from 'vue-router';


export default /*@__PURE__*/_defineComponent({
  __name: 'Edit',
  setup(__props) {

const route = useRoute();
const category_id = Number(route .params.category_id);
const isLoaded = ref<boolean>(false);

onMounted(() => {
  ApiAdminCategoryDetails.request({ id: category_id }, (category) => {
    CategoriesConnector.categoryId = Number(category_id);
    CategoriesConnector.name_pl = category.name_pl;
    CategoriesConnector.name_gb = category.name_gb;
    CategoriesConnector.description_pl = String(category.description_pl);
    CategoriesConnector.description_gb = String(category.description_gb);
    CategoriesConnector.meta_title_pl = String(category.meta_title_pl);
    CategoriesConnector.meta_title_gb = String(category.meta_title_gb);
    CategoriesConnector.meta_description_pl = String(category.meta_description_pl);
    CategoriesConnector.meta_description_gb = String(category.meta_description_gb);
    CategoriesConnector.meta_keywords_pl = String(category.meta_keywords_pl);
    CategoriesConnector.meta_keywords_gb = String(category.meta_keywords_gb);
    CategoriesConnector.friendly_url_pl = String(category.friendly_url_pl);
    CategoriesConnector.friendly_url_gb = String(category.friendly_url_gb);
    CategoriesConnector.is_displayed = Boolean(category.is_displayed);
    CategoriesConnector.parent_category_id = Number(category.parent_category_id);
    isLoaded.value = true;
  }, () => {
    Notify.error('Nie można pobrać danych kategorii');
  });
});

return (_ctx: any,_cache: any) => {
  return (isLoaded.value)
    ? (_openBlock(), _createBlock(NewMainScreen, { key: 0 }))
    : _createCommentVNode("", true)
}
}

})