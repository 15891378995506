import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, vModelCheckbox as _vModelCheckbox, withDirectives as _withDirectives, createTextVNode as _createTextVNode, vModelText as _vModelText, withModifiers as _withModifiers, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "col-12" }
const _hoisted_2 = { class: "card" }
const _hoisted_3 = { class: "card-body" }
const _hoisted_4 = { class: "form-group" }
const _hoisted_5 = { class: "form-row" }
const _hoisted_6 = { class: "form-group col-md-3" }
const _hoisted_7 = { class: "form-group col-md-3" }
const _hoisted_8 = { class: "form-group col-md-3" }
const _hoisted_9 = { class: "form-row" }
const _hoisted_10 = { class: "form-group col-md-6" }
const _hoisted_11 = { class: "form-group col-md-6" }
const _hoisted_12 = { class: "form-group" }
const _hoisted_13 = { class: "form-row" }
const _hoisted_14 = { class: "form-group col-md-3" }
const _hoisted_15 = { class: "form-group col-md-3" }
const _hoisted_16 = { class: "form-group col-md-3" }
const _hoisted_17 = { class: "form-group col-md-3" }
const _hoisted_18 = { class: "form-row" }
const _hoisted_19 = { class: "form-group col-md-4" }
const _hoisted_20 = { class: "form-group col-md-4" }
const _hoisted_21 = { class: "form-group col-md-4" }
const _hoisted_22 = { class: "form-group" }

import { ref } from 'vue';
import { NewConnector } from './NewConnector';


export default /*@__PURE__*/_defineComponent({
  __name: 'NewCombinationsDetails',
  setup(__props) {

const isDefaultCombination = ref(false);
const quantity = ref(0);
const availabilityDate = ref('');
const reference = ref('');
const warehouseLocation = ref('');
const lowStockLevel = ref<number | null>(null);
const notifyOnLowStock = ref(false);
const cost = ref(0);
const impactPriceNet = ref(0);
const impactPriceGross = ref(0);
const impactWeight = ref(0);
const isbn = ref('');
const ean13 = ref('');
const upc = ref('');

const goBack = () => {
  NewConnector.back_to_combinations();
};

const handleImageUpload = (event: Event) => {
  const target = event.target as HTMLInputElement;
  const files = target.files;
  if (files) {
    console.log(files);
    // Implementacja obsługi przesłanych zdjęć
  }
};

const saveCombination = () => {
  console.log('Zapisz kombinację:', {
    isDefaultCombination: isDefaultCombination.value,
    quantity: quantity.value,
    availabilityDate: availabilityDate.value,
    reference: reference.value,
    warehouseLocation: warehouseLocation.value,
    lowStockLevel: lowStockLevel.value,
    notifyOnLowStock: notifyOnLowStock.value,
    cost: cost.value,
    impactPriceNet: impactPriceNet.value,
    impactPriceGross: impactPriceGross.value,
    impactWeight: impactWeight.value,
    isbn: isbn.value,
    ean13: ean13.value,
    upc: upc.value,
  });
};

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", { class: "card-header" }, [
        _createElementVNode("button", {
          class: "btn btn-default mr-2 back-arrow",
          onClick: goBack
        }, _cache[14] || (_cache[14] = [
          _createElementVNode("i", { class: "fa fa-arrow-left" }, null, -1)
        ])),
        _cache[15] || (_cache[15] = _createElementVNode("h3", { class: "card-title font-weight-bold" }, "Szczegóły kombinacji", -1))
      ]),
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("form", {
          onSubmit: _withModifiers(saveCombination, ["prevent"])
        }, [
          _createElementVNode("div", _hoisted_4, [
            _createElementVNode("label", null, [
              _withDirectives(_createElementVNode("input", {
                type: "checkbox",
                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((isDefaultCombination).value = $event))
              }, null, 512), [
                [_vModelCheckbox, isDefaultCombination.value]
              ]),
              _cache[16] || (_cache[16] = _createTextVNode(" Ustaw jako kombinację domyślną "))
            ])
          ]),
          _createElementVNode("div", _hoisted_5, [
            _createElementVNode("div", _hoisted_6, [
              _cache[17] || (_cache[17] = _createElementVNode("label", { for: "quantity" }, "Ilość", -1)),
              _withDirectives(_createElementVNode("input", {
                type: "number",
                id: "quantity",
                class: "form-control",
                "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((quantity).value = $event))
              }, null, 512), [
                [_vModelText, quantity.value]
              ])
            ]),
            _createElementVNode("div", _hoisted_7, [
              _cache[18] || (_cache[18] = _createElementVNode("label", { for: "availabilityDate" }, "Data dostępności", -1)),
              _withDirectives(_createElementVNode("input", {
                type: "date",
                id: "availabilityDate",
                class: "form-control",
                "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((availabilityDate).value = $event))
              }, null, 512), [
                [_vModelText, availabilityDate.value]
              ])
            ]),
            _createElementVNode("div", _hoisted_8, [
              _cache[19] || (_cache[19] = _createElementVNode("label", { for: "reference" }, "Indeks", -1)),
              _withDirectives(_createElementVNode("input", {
                type: "text",
                id: "reference",
                class: "form-control",
                "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((reference).value = $event))
              }, null, 512), [
                [_vModelText, reference.value]
              ])
            ])
          ]),
          _cache[31] || (_cache[31] = _createElementVNode("h5", null, "Magazyn", -1)),
          _createElementVNode("div", _hoisted_9, [
            _createElementVNode("div", _hoisted_10, [
              _cache[20] || (_cache[20] = _createElementVNode("label", { for: "warehouseLocation" }, "Miejsce magazynowania", -1)),
              _withDirectives(_createElementVNode("input", {
                type: "text",
                id: "warehouseLocation",
                class: "form-control",
                "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((warehouseLocation).value = $event))
              }, null, 512), [
                [_vModelText, warehouseLocation.value]
              ])
            ]),
            _createElementVNode("div", _hoisted_11, [
              _cache[21] || (_cache[21] = _createElementVNode("label", { for: "lowStockLevel" }, "Niski poziom produktów w magazynie", -1)),
              _withDirectives(_createElementVNode("input", {
                type: "number",
                id: "lowStockLevel",
                class: "form-control",
                "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((lowStockLevel).value = $event)),
                placeholder: "Pozostaw puste, aby wyłączyć"
              }, null, 512), [
                [_vModelText, lowStockLevel.value]
              ])
            ])
          ]),
          _createElementVNode("div", _hoisted_12, [
            _createElementVNode("label", null, [
              _withDirectives(_createElementVNode("input", {
                type: "checkbox",
                "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((notifyOnLowStock).value = $event))
              }, null, 512), [
                [_vModelCheckbox, notifyOnLowStock.value]
              ]),
              _cache[22] || (_cache[22] = _createTextVNode(" Wyślij do mnie wiadomość e-mail, gdy ilość jest niższa lub równa temu poziomowi "))
            ])
          ]),
          _cache[32] || (_cache[32] = _createElementVNode("h5", null, "Cena i wpływy", -1)),
          _createElementVNode("div", _hoisted_13, [
            _createElementVNode("div", _hoisted_14, [
              _cache[23] || (_cache[23] = _createElementVNode("label", { for: "cost" }, "Koszt własny", -1)),
              _withDirectives(_createElementVNode("input", {
                type: "number",
                id: "cost",
                class: "form-control",
                "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((cost).value = $event))
              }, null, 512), [
                [_vModelText, cost.value]
              ])
            ]),
            _createElementVNode("div", _hoisted_15, [
              _cache[24] || (_cache[24] = _createElementVNode("label", { for: "impactPriceNet" }, "Wpływ na cenę (netto)", -1)),
              _withDirectives(_createElementVNode("input", {
                type: "number",
                id: "impactPriceNet",
                class: "form-control",
                "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => ((impactPriceNet).value = $event))
              }, null, 512), [
                [_vModelText, impactPriceNet.value]
              ])
            ]),
            _createElementVNode("div", _hoisted_16, [
              _cache[25] || (_cache[25] = _createElementVNode("label", { for: "impactPriceGross" }, "Wpływ na cenę (brutto)", -1)),
              _withDirectives(_createElementVNode("input", {
                type: "number",
                id: "impactPriceGross",
                class: "form-control",
                "onUpdate:modelValue": _cache[9] || (_cache[9] = ($event: any) => ((impactPriceGross).value = $event))
              }, null, 512), [
                [_vModelText, impactPriceGross.value]
              ])
            ]),
            _createElementVNode("div", _hoisted_17, [
              _cache[26] || (_cache[26] = _createElementVNode("label", { for: "impactWeight" }, "Wpływ na wagę", -1)),
              _withDirectives(_createElementVNode("input", {
                type: "number",
                id: "impactWeight",
                class: "form-control",
                "onUpdate:modelValue": _cache[10] || (_cache[10] = ($event: any) => ((impactWeight).value = $event))
              }, null, 512), [
                [_vModelText, impactWeight.value]
              ])
            ])
          ]),
          _cache[33] || (_cache[33] = _createElementVNode("h5", null, "Specyficzne kody", -1)),
          _createElementVNode("div", _hoisted_18, [
            _createElementVNode("div", _hoisted_19, [
              _cache[27] || (_cache[27] = _createElementVNode("label", { for: "isbn" }, "Numer ISBN", -1)),
              _withDirectives(_createElementVNode("input", {
                type: "text",
                id: "isbn",
                class: "form-control",
                "onUpdate:modelValue": _cache[11] || (_cache[11] = ($event: any) => ((isbn).value = $event))
              }, null, 512), [
                [_vModelText, isbn.value]
              ])
            ]),
            _createElementVNode("div", _hoisted_20, [
              _cache[28] || (_cache[28] = _createElementVNode("label", { for: "ean13" }, "Kod kreskowy EAN-13 lub JAN", -1)),
              _withDirectives(_createElementVNode("input", {
                type: "text",
                id: "ean13",
                class: "form-control",
                "onUpdate:modelValue": _cache[12] || (_cache[12] = ($event: any) => ((ean13).value = $event))
              }, null, 512), [
                [_vModelText, ean13.value]
              ])
            ]),
            _createElementVNode("div", _hoisted_21, [
              _cache[29] || (_cache[29] = _createElementVNode("label", { for: "upc" }, "Kod kreskowy UPC", -1)),
              _withDirectives(_createElementVNode("input", {
                type: "text",
                id: "upc",
                class: "form-control",
                "onUpdate:modelValue": _cache[13] || (_cache[13] = ($event: any) => ((upc).value = $event))
              }, null, 512), [
                [_vModelText, upc.value]
              ])
            ])
          ]),
          _cache[34] || (_cache[34] = _createElementVNode("h5", null, "Zdjęcia", -1)),
          _createElementVNode("div", _hoisted_22, [
            _cache[30] || (_cache[30] = _createElementVNode("label", { for: "images" }, "Wybierz zdjęcia do tej kombinacji", -1)),
            _createElementVNode("input", {
              type: "file",
              id: "images",
              class: "form-control",
              multiple: "",
              onChange: handleImageUpload
            }, null, 32)
          ]),
          _cache[35] || (_cache[35] = _createElementVNode("button", {
            type: "submit",
            class: "btn btn-primary"
          }, "Zapisz", -1))
        ], 32)
      ])
    ])
  ]))
}
}

})