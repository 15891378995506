import { defineComponent as _defineComponent } from 'vue'
import { openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "text-danger" }


export default /*@__PURE__*/_defineComponent({
  __name: 'Required',
  setup(__props) {

// No script needed for this simple component

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("span", _hoisted_1, "*"))
}
}

})