import { _rootApi } from "../../../web/global/Api/_root"

export interface ApiParams {
  attribute_group_id: number,
  id?: number;
  name?: string;
  position?: number;
  sort_by?: ('id' | 'name' | 'color' | 'position') | string,
  sort_order?: 'asc' | 'desc',
  page?: number,
  per_page?: number;
}

export interface ApiResult {
  data: Array<ApiResultDataSingle>,
  data_count: number
}

export interface ApiResultDataSingle {
  id: number,
  name: string,
  name_gb: string,
  color: string,
  position: number
}

export class ApiAdminAttributesSearch extends _rootApi {
  static request(params: ApiParams, func_success?: (v: ApiResult) => void, func_error?: () => void): void {
    this._request({
      url: '/attributes/search',
      params: params as unknown as { [index: string]: string },
      func_success: (r: ApiResult) => {
        if (func_success) func_success(r);
      },
      func_error: () => {
        if (func_error) func_error();
      }
    });
  }
}