import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, withModifiers as _withModifiers, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createVNode as _createVNode, unref as _unref, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, renderList as _renderList, Fragment as _Fragment, normalizeStyle as _normalizeStyle, vModelText as _vModelText, withDirectives as _withDirectives, vModelSelect as _vModelSelect } from "vue"

const _hoisted_1 = { class: "content content-full-height" }
const _hoisted_2 = { class: "container-fluid" }
const _hoisted_3 = { class: "row" }
const _hoisted_4 = { class: "col-12" }
const _hoisted_5 = { class: "card" }
const _hoisted_6 = { class: "card-header" }
const _hoisted_7 = { class: "card-title" }
const _hoisted_8 = { class: "card-tools" }
const _hoisted_9 = { class: "nav nav-pills ml-auto" }
const _hoisted_10 = { class: "nav-item" }
const _hoisted_11 = { class: "btn-group" }
const _hoisted_12 = { class: "card-body table-responsive p-0" }
const _hoisted_13 = { class: "table table-head-fixed text-nowrap" }
const _hoisted_14 = { key: 0 }
const _hoisted_15 = {
  key: 0,
  class: "fas fa-sort-down ml-2"
}
const _hoisted_16 = {
  key: 1,
  class: "fas fa-sort-up ml-2"
}
const _hoisted_17 = {
  key: 0,
  class: "fas fa-sort-down ml-2"
}
const _hoisted_18 = {
  key: 1,
  class: "fas fa-sort-up ml-2"
}
const _hoisted_19 = {
  key: 0,
  class: "fas fa-sort-down ml-2"
}
const _hoisted_20 = {
  key: 1,
  class: "fas fa-sort-up ml-2"
}
const _hoisted_21 = {
  key: 0,
  class: "fas fa-sort-down ml-2"
}
const _hoisted_22 = {
  key: 1,
  class: "fas fa-sort-up ml-2"
}
const _hoisted_23 = {
  key: 0,
  class: "fas fa-sort-down ml-2"
}
const _hoisted_24 = {
  key: 1,
  class: "fas fa-sort-up ml-2"
}
const _hoisted_25 = { style: {"width":"125px"} }
const _hoisted_26 = ["value"]
const _hoisted_27 = { class: "text-center" }
const _hoisted_28 = { class: "text-center td-position" }
const _hoisted_29 = {
  key: 0,
  class: "btn-group-vertical"
}
const _hoisted_30 = ["onClick"]
const _hoisted_31 = ["onClick"]
const _hoisted_32 = { class: "position-relative" }
const _hoisted_33 = {
  key: 0,
  class: "td-2row-text"
}
const _hoisted_34 = { class: "td-gb-text" }
const _hoisted_35 = { key: 1 }
const _hoisted_36 = { class: "position-relative" }
const _hoisted_37 = {
  key: 0,
  class: "td-2row-text"
}
const _hoisted_38 = { class: "td-gb-text" }
const _hoisted_39 = { key: 1 }
const _hoisted_40 = { class: "text-center" }
const _hoisted_41 = { class: "text-right" }
const _hoisted_42 = { class: "btn-group" }
const _hoisted_43 = ["onClick"]
const _hoisted_44 = ["disabled"]
const _hoisted_45 = {
  class: "dropdown-menu",
  role: "menu"
}
const _hoisted_46 = ["onClick"]
const _hoisted_47 = ["onClick"]
const _hoisted_48 = {
  key: 1,
  style: {"background-color":"#f4f6f9"}
}
const _hoisted_49 = { colspan: "6" }
const _hoisted_50 = { class: "card mb-0" }
const _hoisted_51 = { class: "card-body table-responsive p-0" }
const _hoisted_52 = { class: "table table-head-fixed text-nowrap" }
const _hoisted_53 = { key: 0 }
const _hoisted_54 = {
  key: 0,
  class: "fas fa-sort-down ml-2"
}
const _hoisted_55 = {
  key: 1,
  class: "fas fa-sort-up ml-2"
}
const _hoisted_56 = {
  key: 0,
  class: "fas fa-sort-down ml-2"
}
const _hoisted_57 = {
  key: 1,
  class: "fas fa-sort-up ml-2"
}
const _hoisted_58 = {
  key: 0,
  class: "fas fa-sort-down ml-2"
}
const _hoisted_59 = {
  key: 1,
  class: "fas fa-sort-up ml-2"
}
const _hoisted_60 = {
  key: 0,
  class: "fas fa-sort-down ml-2"
}
const _hoisted_61 = {
  key: 1,
  class: "fas fa-sort-up ml-2"
}
const _hoisted_62 = { class: "td-position" }
const _hoisted_63 = {
  key: 0,
  class: "btn-group-vertical"
}
const _hoisted_64 = ["onClick"]
const _hoisted_65 = ["onClick"]
const _hoisted_66 = {
  key: 0,
  class: "position-relative"
}
const _hoisted_67 = {
  class: "td-2row-text",
  style: {"top":"3px"}
}
const _hoisted_68 = { class: "td-gb-text" }
const _hoisted_69 = { key: 1 }
const _hoisted_70 = {
  key: 2,
  class: "d-flex align-items-center"
}
const _hoisted_71 = { class: "ml-1" }
const _hoisted_72 = { class: "text-right" }
const _hoisted_73 = { class: "btn-group" }
const _hoisted_74 = ["onClick"]
const _hoisted_75 = {
  class: "dropdown-menu",
  role: "menu"
}
const _hoisted_76 = ["onClick"]
const _hoisted_77 = {
  key: 1,
  style: {"background-color":"#e9ecef"}
}
const _hoisted_78 = { class: "text-center" }
const _hoisted_79 = ["onUpdate:modelValue"]
const _hoisted_80 = { class: "text-center" }
const _hoisted_81 = ["onUpdate:modelValue"]
const _hoisted_82 = { class: "input-group input-group-sm" }
const _hoisted_83 = ["onUpdate:modelValue"]
const _hoisted_84 = { class: "input-group input-group-sm" }
const _hoisted_85 = ["onUpdate:modelValue"]
const _hoisted_86 = { key: 0 }
const _hoisted_87 = ["onUpdate:modelValue"]
const _hoisted_88 = { class: "text-right btns-td" }
const _hoisted_89 = ["onClick"]
const _hoisted_90 = { key: 0 }
const _hoisted_91 = {
  colspan: "9",
  class: "text-center"
}
const _hoisted_92 = { key: 1 }
const _hoisted_93 = {
  key: 2,
  style: {"background-color":"#e9ecef"}
}
const _hoisted_94 = { class: "text-center" }
const _hoisted_95 = ["onUpdate:modelValue"]
const _hoisted_96 = { class: "text-center" }
const _hoisted_97 = ["onUpdate:modelValue"]
const _hoisted_98 = { class: "input-group input-group-sm" }
const _hoisted_99 = ["onUpdate:modelValue"]
const _hoisted_100 = { class: "input-group input-group-sm" }
const _hoisted_101 = ["onUpdate:modelValue"]
const _hoisted_102 = { class: "input-group input-group-sm" }
const _hoisted_103 = ["onUpdate:modelValue"]
const _hoisted_104 = { class: "input-group input-group-sm" }
const _hoisted_105 = ["onUpdate:modelValue"]
const _hoisted_106 = ["onUpdate:modelValue"]
const _hoisted_107 = ["value"]
const _hoisted_108 = { class: "text-right btns-td" }
const _hoisted_109 = ["onClick"]
const _hoisted_110 = { key: 0 }
const _hoisted_111 = {
  colspan: "9",
  class: "text-center"
}
const _hoisted_112 = { key: 1 }
const _hoisted_113 = { class: "card-footer clearfix" }

import ContentHeader from '@/admin/components/Layouts/ContentHeader.vue';
import Pager from '@/admin/components/Pager.vue';
import { ref } from 'vue';
import { ApiAdminAddressesExport } from '@/admin/global/Api/AddressesExport';
import { ApiParams } from "@/admin/global/Api/AddressesSearch";
import { Form } from '@/web/global/Form';
import { Notify } from '@/web/global/Notify';
import { ApiAdminAttributesGroupSearch, ApiResultDataSingle, AttributeGroupType, AttributeGroupTypeAsObject } from '@/admin/global/Api/AttributesGroupSearch';
import { ApiResultDataSingle as ApiAttributesResultDataSingle, ApiAdminAttributesSearch } from '@/admin/global/Api/AttributesSearch';
import { AttributeGroupUpdateOrder } from '@/admin/global/Api/AttributeGroupUpdateOrder';
import { ApiAdminAttributeGroupUpdate } from '@/admin/global/Api/AttributeGroupUpdate';
import { AttributeUpdateOrder } from '@/admin/global/Api/AttributeUpdateOrder';
import { ApiAdminAttributeUpdate } from '@/admin/global/Api/AttributeUpdate';
import { ApiAdminAttributeDelete } from '@/admin/global/Api/AttributeDelete';
import { ApiAdminAttributeGroupDelete } from '@/admin/global/Api/AttributeGroupDelete';
import { ApiAdminAttributeGroupNew } from '@/admin/global/Api/AttributeGroupNew';
import { ApiAdminAttributeNew } from '@/admin/global/Api/AttributeNew';

const item_per_page = 999;

export default /*@__PURE__*/_defineComponent({
  __name: 'Atrtributes',
  setup(__props) {

let item_max = ref(0);
let attribute_groups = ref([] as Array<ApiResultDataSingle>);
let attributes = ref([] as Array<ApiAttributesResultDataSingle>);
let actual_page = 1;

let sort_by = ref('position');
let sort_order = ref('asc');

let sort_by_attributes = ref('position');
let sort_order_attributes = ref('asc');

const attribute_group_types = ref(AttributeGroupTypeAsObject);
const selected_attribute_group_id = ref(-1);
const editing_attribute_group_id = ref(-1);
const editing_attribute_id = ref(-1);

function get_search_params(page = -1, per_page = -1):ApiParams{
  let params:ApiParams = {};

  const params_name = ['search_id', 'search_name', 'search_public_name', 'search_type_id', 'search_position'];
  params_name.forEach(name => {
    const v = (Form.value(name) + '').trim();

    if(v.length > 0){
      params[name.replace('search_', '')] = v;
    }
  });

  if(page !== -1){
    params['page'] = page;
    params['per_page'] = per_page;
  }

  params["sort_by"] = sort_by.value;
  params["sort_order"] = sort_order.value === 'asc' ? 'asc' : 'desc';

  return params;
}

function close_attribute_group(){
  selected_attribute_group_id.value = -1;
  editing_attribute_id.value = -1;
}

function open_attribute_group(group_id: number){
  search_attributes(group_id, () => {
    selected_attribute_group_id.value = group_id;
  });
}

function search(page: number, func: () => void = () => null){
  ApiAdminAttributesGroupSearch.request(get_search_params(page, item_per_page), result => {
    attribute_groups.value = result.data;
    item_max.value = result.data_count;
    actual_page = page;
    func();
  }, () => {
    console.log('error');

    attribute_groups.value = [];
    item_max.value = 0;
  });
}

function search_attributes(attribute_group_id: number, func: () => void = () => null){
  ApiAdminAttributesSearch.request({ 
    attribute_group_id, 
    sort_by: sort_by_attributes.value, 
    sort_order: sort_order_attributes.value === 'asc' ? 'asc' : 'desc' 
  }, result => {
    attributes.value = result.data;
    func();
  }, () => {
    console.log('error');
    attributes.value = [];
  });
}

function start_editing_attribute_group(attribute_group_id: number){
  const attribute_group = attribute_groups.value.find(group => group.id === attribute_group_id);
  if (attribute_group && attribute_group.name === attribute_group.name_gb) attribute_group.name_gb = '';
  if (attribute_group && attribute_group.public_name === attribute_group.public_name_gb) attribute_group.public_name_gb = '';

  editing_attribute_group_id.value = attribute_group_id;
}

function cancel_editing_attribute_group(){
  search(actual_page, () => {
    editing_attribute_group_id.value = -1;
  });
}

function update_attribute_group(attribute_group_id: number) {
  const attribute_group = attribute_groups.value.find(group => group.id === attribute_group_id);
  if (attribute_group) {
    if (!attribute_group.name_gb) attribute_group.name_gb = attribute_group.name;
    if (!attribute_group.public_name_gb) attribute_group.public_name_gb = attribute_group.public_name;

    const params = {
      name: attribute_group.name,
      name_gb: attribute_group.name_gb,
      public_name: attribute_group.public_name,
      public_name_gb: attribute_group.public_name_gb,
      type_id: attribute_group.type_id
    };

    const is_new_element = attribute_group.id === 0;
    if(is_new_element){
      ApiAdminAttributeGroupNew.request(params, () => {
        Notify.success('Grupa atrybutów została dodana');
        editing_attribute_group_id.value = -1;
        search(actual_page);
      }, r => {
        Form.error_reflesh(r, ['nazwa', 'public name']);
        Notify.error('Błąd podczas dodawania grupy atrybutów');
      });
    }
    else{
      const update_params = { ...params, id: attribute_group.id };
      ApiAdminAttributeGroupUpdate.request(update_params, () => {
        Notify.success('Grupa atrybutów została zaktualizowana');
        editing_attribute_group_id.value = -1;
        search(actual_page);
      }, r => {
        Form.error_reflesh(r, ['nazwa', 'public name']);
        Notify.error('Błąd podczas aktualizacji grupy atrybutów');
      });
    }
  }
}

function start_editing_attribute(attribute_id: number){
  const attribute = attributes.value.find(attr => attr.id === attribute_id);
  if (attribute && attribute.name === attribute.name_gb) {
    attribute.name_gb = '';
  }
  editing_attribute_id.value = attribute_id;
}

function cancel_editing_attribute(){
  search_attributes(selected_attribute_group_id.value, () => {
    editing_attribute_id.value = -1;
  });
}

function update_attribute(attribute_id: number) {
  const attribute = attributes.value.find(attr => attr.id === attribute_id);
  if (attribute) {
    if (!attribute.name_gb) {
      attribute.name_gb = attribute.name;
    }

    const params = {
      name: attribute.name,
      name_gb: attribute.name_gb,
      color: attribute.color
    };

    const is_new_element = attribute.id === 0;
    if (is_new_element) {
      const new_params = { ...params, attribute_group_id: selected_attribute_group_id.value };
      ApiAdminAttributeNew.request(new_params, () => {
        Notify.success('Atrybut został dodany');
        editing_attribute_id.value = -1;
        search_attributes(selected_attribute_group_id.value);
      }, r => {
        Form.error_reflesh(r, ['nazwa']);
        Notify.error('Błąd podczas dodawania atrybutu');
      });
    } else {
      const update_params = { ...params, id: attribute.id };
      ApiAdminAttributeUpdate.request(update_params, () => {
        Notify.success('Atrybut został zaktualizowany');
        editing_attribute_id.value = -1;
        search_attributes(selected_attribute_group_id.value);
      }, r => {
        Form.error_reflesh(r, ['nazwa']);
        Notify.error('Błąd podczas aktualizacji atrybutu');
      });
    }
  }
}

function start_adding_attrbiute():void{
  const new_attribute = {
    id: 0,
    name: '',
    name_gb: '',
    color: '',
    position: 0
  };

  attributes.value.push(new_attribute);
  start_editing_attribute(new_attribute.id);
}

function start_adding_attrbiute_group():void{
  const new_group = {
    id: 0,
    name: '',
    name_gb: '',
    public_name: '',
    public_name_gb: '',
    type_id: AttributeGroupType['Lista wartości'],
    position: 0
  };

  attribute_groups.value.push(new_group);
  start_editing_attribute_group(new_group.id);
}

function data_export(){
  ApiAdminAddressesExport.request(get_search_params());
}

function order_by(name: string){
  if(sort_by.value === name){
    sort_order.value = sort_order.value === 'asc' ? 'desc' : 'asc';
  }
  else{
    sort_by.value = name;
    sort_order.value = 'asc';
  }

  search(actual_page);
}

function order_by_attributes(name: string){
  if(sort_by_attributes.value === name){
    sort_order_attributes.value = sort_order_attributes.value === 'asc' ? 'desc' : 'asc';
  }
  else{
    sort_by_attributes.value = name;
    sort_order_attributes.value = 'asc';
  }

  search_attributes(selected_attribute_group_id.value);
}

function prepare_delete_attribute_group(id: number): void {
  const attribute_group_name = (attribute_groups.value.find(v => v.id === id)?.name) ?? '';
  Notify.confirm('Czy na pewno chcesz usunąć grupę atrybutów: #' + id + ' "' + attribute_group_name + '"?', () => {
    delete_attribute_group(id, attribute_group_name);
  }, 'Usuń grupę', 'Anuluj');
}

function delete_attribute_group(id: number, attribute_group_name:string): void {
  ApiAdminAttributeGroupDelete.request({ id }, () => {
    search(actual_page);
    Notify.success('Grupa atrybutów: #' + id + ' "' + attribute_group_name + '" została usunięta');
  }, () => {
    console.log('delete error: ' + id);
  });
}

function prepare_delete_attribute(id: number): void {
  const attribute_name = (attributes.value.find(v => v.id === id)?.name) ?? '';
  Notify.confirm('Czy na pewno chcesz usunąć atrybut: #' + id + ' "' + attribute_name + '"?', () => {
    delete_attribute(id, attribute_name);
  }, 'Usuń atrybut', 'Anuluj');
}

function delete_attribute(id:number, attribute_name:string):void{
  ApiAdminAttributeDelete.request({id}, () => {
    search_attributes(selected_attribute_group_id.value);
    Notify.success('Atrybut: #' + id + ' "' + attribute_name + '" został usunięty');
  }, () => {
    console.log('delete error: ' + id);
  });
}

function is_color_enabled():boolean{
  return Object.values(attribute_groups.value).find(group => group.id === selected_attribute_group_id.value)
    ?.type_id === AttributeGroupType.Kolory;
}

function is_attribute_group_selected(group_id: number):boolean{
  return selected_attribute_group_id.value == group_id;
}

function is_attribute_group_editing(group_id: number):boolean{
  return editing_attribute_group_id.value == group_id;
}

function is_attribute_editing(group_id: number):boolean{
  return editing_attribute_id.value == group_id;
}

function is_some_attribute_group_selected():boolean{
  return selected_attribute_group_id.value !== -1;
}

function is_some_attribute_group_editing():boolean{
  return editing_attribute_group_id.value !== -1;
}

function is_some_attribute_editing():boolean{
  return editing_attribute_id.value !== -1;
}

function is_attribute_group_blocked(group_id: number):boolean{
  if(is_attribute_group_selected(group_id) && !is_some_attribute_group_editing()){
    return false;
  }
  else if(is_attribute_group_editing(group_id)){
    return false;
  }
  
  return is_some_attribute_group_selected() || is_some_attribute_group_editing();
}

function is_attribute_blocked(attribute_id: number):boolean{
  if(is_attribute_editing(attribute_id)){
    return false;
  }

  return is_some_attribute_editing();
}

function change_order(group_id: number, direction: 'up' | 'down') {
  AttributeGroupUpdateOrder.request({ id: group_id, order_change_type: direction }, () => {
    search(actual_page);
  });
}

function change_order_attribute(attribute_id: number, direction: 'up' | 'down') {
  AttributeUpdateOrder.request({ id: attribute_id, order_change_type: direction }, () => {
    search_attributes(selected_attribute_group_id.value);
  });
}

search(actual_page);

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(ContentHeader, {
      main_title: "Katalog > Atrybuty",
      sub_title: "Zarządzanie Atrybutami"
    }, {
      default: _withCtx(() => [
        (!is_attribute_group_blocked(0) && !is_some_attribute_group_editing())
          ? (_openBlock(), _createElementBlock("button", {
              key: 0,
              class: "btn btn-primary",
              onClick: _cache[0] || (_cache[0] = _withModifiers(($event: any) => (start_adding_attrbiute_group()), ["prevent"]))
            }, _cache[21] || (_cache[21] = [
              _createElementVNode("i", { class: "fas fa-plus-circle" }, null, -1),
              _createTextVNode(" Dodaj grupe atrybutów ")
            ])))
          : _createCommentVNode("", true)
      ]),
      _: 1
    }),
    _createElementVNode("section", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("div", _hoisted_4, [
            _createElementVNode("div", _hoisted_5, [
              _createElementVNode("div", _hoisted_6, [
                _createElementVNode("h3", _hoisted_7, "Grupy atrybutów (" + _toDisplayString(_unref(item_max)) + ")", 1),
                _createElementVNode("div", _hoisted_8, [
                  _createElementVNode("ul", _hoisted_9, [
                    _createElementVNode("li", _hoisted_10, [
                      _createElementVNode("div", _hoisted_11, [
                        _createElementVNode("button", {
                          class: "btn btn-default btn-sm",
                          onClick: _cache[1] || (_cache[1] = _withModifiers(($event: any) => (search(_unref(actual_page))), ["prevent"]))
                        }, _cache[22] || (_cache[22] = [
                          _createElementVNode("i", { class: "fas fa-sync-alt mr-1" }, null, -1),
                          _createTextVNode(" Odśwież liste ")
                        ]))
                      ])
                    ])
                  ])
                ])
              ]),
              _createElementVNode("div", _hoisted_12, [
                _createElementVNode("table", _hoisted_13, [
                  (_unref(attribute_groups).length > 0)
                    ? (_openBlock(), _createElementBlock("thead", _hoisted_14, [
                        _createElementVNode("tr", {
                          class: _normalizeClass({'attribute-group-unusable': is_attribute_group_blocked(-2)})
                        }, [
                          _createElementVNode("th", {
                            class: "th-sortable text-center",
                            style: {"width":"80px"},
                            onClick: _cache[2] || (_cache[2] = ($event: any) => (order_by('id')))
                          }, [
                            _cache[23] || (_cache[23] = _createTextVNode(" ID ")),
                            (_unref(sort_by) == 'id' && _unref(sort_order) == 'asc')
                              ? (_openBlock(), _createElementBlock("i", _hoisted_15))
                              : _createCommentVNode("", true),
                            (_unref(sort_by) == 'id' && _unref(sort_order) == 'desc')
                              ? (_openBlock(), _createElementBlock("i", _hoisted_16))
                              : _createCommentVNode("", true)
                          ]),
                          _createElementVNode("th", {
                            class: "th-sortable text-center",
                            style: {"width":"80px"},
                            onClick: _cache[3] || (_cache[3] = ($event: any) => (order_by('position')))
                          }, [
                            _cache[24] || (_cache[24] = _createTextVNode(" Poz. ")),
                            (_unref(sort_by) == 'position' && _unref(sort_order) == 'asc')
                              ? (_openBlock(), _createElementBlock("i", _hoisted_17))
                              : _createCommentVNode("", true),
                            (_unref(sort_by) == 'position' && _unref(sort_order) == 'desc')
                              ? (_openBlock(), _createElementBlock("i", _hoisted_18))
                              : _createCommentVNode("", true)
                          ]),
                          _createElementVNode("th", {
                            class: "th-sortable",
                            onClick: _cache[4] || (_cache[4] = ($event: any) => (order_by('name')))
                          }, [
                            _cache[25] || (_cache[25] = _createTextVNode(" Nazwa ")),
                            (_unref(sort_by) == 'name' && _unref(sort_order) == 'asc')
                              ? (_openBlock(), _createElementBlock("i", _hoisted_19))
                              : _createCommentVNode("", true),
                            (_unref(sort_by) == 'name' && _unref(sort_order) == 'desc')
                              ? (_openBlock(), _createElementBlock("i", _hoisted_20))
                              : _createCommentVNode("", true)
                          ]),
                          _createElementVNode("th", {
                            class: "th-sortable",
                            onClick: _cache[5] || (_cache[5] = ($event: any) => (order_by('public_name')))
                          }, [
                            _cache[26] || (_cache[26] = _createTextVNode(" Nazwa Publiczna ")),
                            (_unref(sort_by) == 'public_name' && _unref(sort_order) == 'asc')
                              ? (_openBlock(), _createElementBlock("i", _hoisted_21))
                              : _createCommentVNode("", true),
                            (_unref(sort_by) == 'public_name' && _unref(sort_order) == 'desc')
                              ? (_openBlock(), _createElementBlock("i", _hoisted_22))
                              : _createCommentVNode("", true)
                          ]),
                          _createElementVNode("th", {
                            class: "th-sortable text-center",
                            onClick: _cache[6] || (_cache[6] = ($event: any) => (order_by('type')))
                          }, [
                            _cache[27] || (_cache[27] = _createTextVNode(" Typ ")),
                            (_unref(sort_by) == 'type' && _unref(sort_order) == 'asc')
                              ? (_openBlock(), _createElementBlock("i", _hoisted_23))
                              : _createCommentVNode("", true),
                            (_unref(sort_by) == 'type' && _unref(sort_order) == 'desc')
                              ? (_openBlock(), _createElementBlock("i", _hoisted_24))
                              : _createCommentVNode("", true)
                          ]),
                          _cache[28] || (_cache[28] = _createElementVNode("th", {
                            class: "text-center",
                            style: {"width":"175px"}
                          }, "Akcje", -1))
                        ], 2),
                        _createElementVNode("tr", {
                          class: _normalizeClass(["filter-row", {'attribute-group-unusable': is_attribute_group_blocked(-2)}])
                        }, [
                          _createElementVNode("th", null, [
                            _createElementVNode("input", {
                              class: "form-control form-control-sm",
                              type: "text",
                              placeholder: "ID",
                              onInput: _cache[7] || (_cache[7] = ($event: any) => (search(1))),
                              name: "search_id"
                            }, null, 32)
                          ]),
                          _createElementVNode("th", null, [
                            _createElementVNode("input", {
                              class: "form-control form-control-sm",
                              type: "text",
                              placeholder: "Pozycja",
                              onInput: _cache[8] || (_cache[8] = ($event: any) => (search(1))),
                              name: "search_position"
                            }, null, 32)
                          ]),
                          _createElementVNode("th", null, [
                            _createElementVNode("input", {
                              class: "form-control form-control-sm",
                              type: "text",
                              placeholder: "Nazwa",
                              onInput: _cache[9] || (_cache[9] = ($event: any) => (search(1))),
                              name: "search_name"
                            }, null, 32)
                          ]),
                          _createElementVNode("th", null, [
                            _createElementVNode("input", {
                              class: "form-control form-control-sm",
                              type: "text",
                              placeholder: "Nazwa Publiczna",
                              onInput: _cache[10] || (_cache[10] = ($event: any) => (search(1))),
                              name: "search_public_name"
                            }, null, 32)
                          ]),
                          _createElementVNode("th", _hoisted_25, [
                            _createElementVNode("select", {
                              class: "form-control form-control-sm",
                              onChange: _cache[11] || (_cache[11] = ($event: any) => (search(1))),
                              name: "search_type_id"
                            }, [
                              _cache[29] || (_cache[29] = _createElementVNode("option", {
                                value: "",
                                selected: ""
                              }, "Niewybrane", -1)),
                              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(attribute_group_types.value, (name, id) => {
                                return (_openBlock(), _createElementBlock("option", { value: id }, _toDisplayString(name), 9, _hoisted_26))
                              }), 256))
                            ], 32)
                          ])
                        ], 2)
                      ]))
                    : _createCommentVNode("", true),
                  _createElementVNode("tbody", null, [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(attribute_groups), (attribute_group) => {
                      return (_openBlock(), _createElementBlock(_Fragment, null, [
                        (!is_attribute_group_editing(attribute_group.id))
                          ? (_openBlock(), _createElementBlock("tr", {
                              key: 0,
                              class: _normalizeClass({
                      'attribute-group-unusable': is_attribute_group_blocked(attribute_group.id)
                    })
                            }, [
                              _createElementVNode("td", _hoisted_27, _toDisplayString(attribute_group.id), 1),
                              _createElementVNode("td", _hoisted_28, [
                                _createTextVNode(_toDisplayString(attribute_group.position) + " ", 1),
                                (!is_some_attribute_group_selected() && (_unref(sort_by) === 'position' && _unref(sort_order) === 'asc'))
                                  ? (_openBlock(), _createElementBlock("div", _hoisted_29, [
                                      (attribute_group.position !== Math.min(..._unref(attribute_groups).map(group => group.position)))
                                        ? (_openBlock(), _createElementBlock("button", {
                                            key: 0,
                                            class: "btn btn-sm btn-outline-secondary",
                                            onClick: _withModifiers(($event: any) => (change_order(attribute_group.id, 'up')), ["prevent"])
                                          }, _cache[30] || (_cache[30] = [
                                            _createElementVNode("i", { class: "fas fa-arrow-up" }, null, -1)
                                          ]), 8, _hoisted_30))
                                        : _createCommentVNode("", true),
                                      (attribute_group.position !== Math.max(..._unref(attribute_groups).map(group => group.position)))
                                        ? (_openBlock(), _createElementBlock("button", {
                                            key: 1,
                                            class: "btn btn-sm btn-outline-secondary",
                                            onClick: _withModifiers(($event: any) => (change_order(attribute_group.id, 'down')), ["prevent"])
                                          }, _cache[31] || (_cache[31] = [
                                            _createElementVNode("i", { class: "fas fa-arrow-down" }, null, -1)
                                          ]), 8, _hoisted_31))
                                        : _createCommentVNode("", true)
                                    ]))
                                  : _createCommentVNode("", true)
                              ]),
                              _createElementVNode("td", _hoisted_32, [
                                (attribute_group.name !== attribute_group.name_gb)
                                  ? (_openBlock(), _createElementBlock("div", _hoisted_33, [
                                      _createElementVNode("div", null, _toDisplayString(attribute_group.name), 1),
                                      _createElementVNode("div", _hoisted_34, _toDisplayString(attribute_group.name_gb), 1)
                                    ]))
                                  : _createCommentVNode("", true),
                                (attribute_group.name === attribute_group.name_gb)
                                  ? (_openBlock(), _createElementBlock("div", _hoisted_35, _toDisplayString(attribute_group.name), 1))
                                  : _createCommentVNode("", true)
                              ]),
                              _createElementVNode("td", _hoisted_36, [
                                (attribute_group.public_name !== attribute_group.public_name_gb)
                                  ? (_openBlock(), _createElementBlock("div", _hoisted_37, [
                                      _createElementVNode("div", null, _toDisplayString(attribute_group.public_name), 1),
                                      _createElementVNode("div", _hoisted_38, _toDisplayString(attribute_group.public_name_gb), 1)
                                    ]))
                                  : _createCommentVNode("", true),
                                (attribute_group.public_name === attribute_group.public_name_gb)
                                  ? (_openBlock(), _createElementBlock("div", _hoisted_39, _toDisplayString(attribute_group.public_name), 1))
                                  : _createCommentVNode("", true)
                              ]),
                              _createElementVNode("td", _hoisted_40, _toDisplayString(attribute_group_types.value[attribute_group.type_id] ?? ''), 1),
                              _createElementVNode("td", _hoisted_41, [
                                _createElementVNode("div", _hoisted_42, [
                                  (!is_attribute_group_selected(attribute_group.id))
                                    ? (_openBlock(), _createElementBlock("button", {
                                        key: 0,
                                        onClick: _withModifiers(($event: any) => (open_attribute_group(attribute_group.id)), ["prevent"]),
                                        class: "btn btn-default btn-sm"
                                      }, _cache[32] || (_cache[32] = [
                                        _createElementVNode("i", { class: "fas fa-chevron-down mr-1" }, null, -1),
                                        _createTextVNode(" Rozwiń ")
                                      ]), 8, _hoisted_43))
                                    : _createCommentVNode("", true),
                                  (is_attribute_group_selected(attribute_group.id))
                                    ? (_openBlock(), _createElementBlock("button", {
                                        key: 1,
                                        onClick: _cache[12] || (_cache[12] = _withModifiers(($event: any) => (close_attribute_group()), ["prevent"])),
                                        class: "btn btn-default btn-sm"
                                      }, _cache[33] || (_cache[33] = [
                                        _createElementVNode("i", { class: "fas fa-chevron-up mr-1" }, null, -1),
                                        _createTextVNode(" Zwiń ")
                                      ])))
                                    : _createCommentVNode("", true),
                                  _createElementVNode("button", {
                                    disabled: is_attribute_group_selected(attribute_group.id),
                                    type: "button",
                                    class: "btn btn-default btn-sm dropdown-toggle dropdown-icon",
                                    "data-toggle": "dropdown",
                                    "aria-expanded": "false"
                                  }, _cache[34] || (_cache[34] = [
                                    _createElementVNode("span", { class: "sr-only" }, "Zobacz więcej", -1)
                                  ]), 8, _hoisted_44),
                                  _createElementVNode("div", _hoisted_45, [
                                    _createElementVNode("a", {
                                      class: "dropdown-item",
                                      href: "#",
                                      onClick: _withModifiers(($event: any) => (start_editing_attribute_group(attribute_group.id)), ["prevent"])
                                    }, _cache[35] || (_cache[35] = [
                                      _createElementVNode("i", { class: "fas fa-pen mr-1" }, null, -1),
                                      _createTextVNode(" Edytuj ")
                                    ]), 8, _hoisted_46),
                                    _createElementVNode("a", {
                                      class: "dropdown-item",
                                      href: "#",
                                      onClick: _withModifiers(($event: any) => (prepare_delete_attribute_group(attribute_group.id)), ["prevent"])
                                    }, _cache[36] || (_cache[36] = [
                                      _createElementVNode("i", { class: "fas fa-trash mr-1" }, null, -1),
                                      _createTextVNode(" Usuń ")
                                    ]), 8, _hoisted_47)
                                  ])
                                ])
                              ])
                            ], 2))
                          : _createCommentVNode("", true),
                        (is_attribute_group_selected(attribute_group.id))
                          ? (_openBlock(), _createElementBlock("tr", _hoisted_48, [
                              _createElementVNode("td", _hoisted_49, [
                                _createElementVNode("div", _hoisted_50, [
                                  _createElementVNode("div", _hoisted_51, [
                                    _createElementVNode("table", _hoisted_52, [
                                      (_unref(attributes).length > 0)
                                        ? (_openBlock(), _createElementBlock("thead", _hoisted_53, [
                                            _createElementVNode("tr", null, [
                                              _createElementVNode("th", {
                                                class: "th-sortable",
                                                style: {"width":"80px"},
                                                onClick: _cache[13] || (_cache[13] = ($event: any) => (order_by_attributes('id')))
                                              }, [
                                                _cache[37] || (_cache[37] = _createTextVNode(" ID ")),
                                                (_unref(sort_by_attributes) == 'id' && _unref(sort_order_attributes) == 'asc')
                                                  ? (_openBlock(), _createElementBlock("i", _hoisted_54))
                                                  : _createCommentVNode("", true),
                                                (_unref(sort_by_attributes) == 'id' && _unref(sort_order_attributes) == 'desc')
                                                  ? (_openBlock(), _createElementBlock("i", _hoisted_55))
                                                  : _createCommentVNode("", true)
                                              ]),
                                              _createElementVNode("th", {
                                                class: "th-sortable",
                                                style: {"width":"80px"},
                                                onClick: _cache[14] || (_cache[14] = ($event: any) => (order_by_attributes('position')))
                                              }, [
                                                _cache[38] || (_cache[38] = _createTextVNode(" Poz. ")),
                                                (_unref(sort_by_attributes) == 'position' && _unref(sort_order_attributes) == 'asc')
                                                  ? (_openBlock(), _createElementBlock("i", _hoisted_56))
                                                  : _createCommentVNode("", true),
                                                (_unref(sort_by_attributes) == 'position' && _unref(sort_order_attributes) == 'desc')
                                                  ? (_openBlock(), _createElementBlock("i", _hoisted_57))
                                                  : _createCommentVNode("", true)
                                              ]),
                                              _createElementVNode("th", {
                                                class: "th-sortable",
                                                onClick: _cache[15] || (_cache[15] = ($event: any) => (order_by_attributes('name')))
                                              }, [
                                                _cache[39] || (_cache[39] = _createTextVNode(" Nazwa ")),
                                                (_unref(sort_by_attributes) == 'name' && _unref(sort_order_attributes) == 'asc')
                                                  ? (_openBlock(), _createElementBlock("i", _hoisted_58))
                                                  : _createCommentVNode("", true),
                                                (_unref(sort_by_attributes) == 'name' && _unref(sort_order_attributes) == 'desc')
                                                  ? (_openBlock(), _createElementBlock("i", _hoisted_59))
                                                  : _createCommentVNode("", true)
                                              ]),
                                              (is_color_enabled())
                                                ? (_openBlock(), _createElementBlock("th", {
                                                    key: 0,
                                                    class: "th-sortable",
                                                    onClick: _cache[16] || (_cache[16] = ($event: any) => (order_by_attributes('color')))
                                                  }, [
                                                    _cache[40] || (_cache[40] = _createTextVNode(" Kolor ")),
                                                    (_unref(sort_by_attributes) == 'color' && _unref(sort_order_attributes) == 'asc')
                                                      ? (_openBlock(), _createElementBlock("i", _hoisted_60))
                                                      : _createCommentVNode("", true),
                                                    (_unref(sort_by_attributes) == 'color' && _unref(sort_order_attributes) == 'desc')
                                                      ? (_openBlock(), _createElementBlock("i", _hoisted_61))
                                                      : _createCommentVNode("", true)
                                                  ]))
                                                : _createCommentVNode("", true),
                                              _cache[41] || (_cache[41] = _createElementVNode("th", {
                                                class: "text-center",
                                                style: {"width":"175px"}
                                              }, "Akcje", -1))
                                            ])
                                          ]))
                                        : _createCommentVNode("", true),
                                      _createElementVNode("tbody", null, [
                                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(attributes), (attribute) => {
                                          return (_openBlock(), _createElementBlock(_Fragment, {
                                            key: attribute.id
                                          }, [
                                            (!is_attribute_editing(attribute.id))
                                              ? (_openBlock(), _createElementBlock("tr", {
                                                  key: 0,
                                                  class: _normalizeClass({
                                    'attribute-group-unusable': is_attribute_blocked(attribute.id)
                                  })
                                                }, [
                                                  _createElementVNode("td", null, _toDisplayString(attribute.id), 1),
                                                  _createElementVNode("td", _hoisted_62, [
                                                    _createTextVNode(_toDisplayString(attribute.position) + " ", 1),
                                                    ((_unref(sort_by_attributes) === 'position' && _unref(sort_order_attributes) === 'asc'))
                                                      ? (_openBlock(), _createElementBlock("div", _hoisted_63, [
                                                          (attribute.position !== Math.min(..._unref(attributes).map(attr => attr.position)))
                                                            ? (_openBlock(), _createElementBlock("button", {
                                                                key: 0,
                                                                class: "btn btn-sm btn-outline-secondary",
                                                                onClick: _withModifiers(($event: any) => (change_order_attribute(attribute.id, 'up')), ["prevent"])
                                                              }, _cache[42] || (_cache[42] = [
                                                                _createElementVNode("i", { class: "fas fa-arrow-up" }, null, -1)
                                                              ]), 8, _hoisted_64))
                                                            : _createCommentVNode("", true),
                                                          (attribute.position !== Math.max(..._unref(attributes).map(attr => attr.position)))
                                                            ? (_openBlock(), _createElementBlock("button", {
                                                                key: 1,
                                                                class: "btn btn-sm btn-outline-secondary",
                                                                onClick: _withModifiers(($event: any) => (change_order_attribute(attribute.id, 'down')), ["prevent"])
                                                              }, _cache[43] || (_cache[43] = [
                                                                _createElementVNode("i", { class: "fas fa-arrow-down" }, null, -1)
                                                              ]), 8, _hoisted_65))
                                                            : _createCommentVNode("", true)
                                                        ]))
                                                      : _createCommentVNode("", true)
                                                  ]),
                                                  (attribute.name !== attribute.name_gb)
                                                    ? (_openBlock(), _createElementBlock("td", _hoisted_66, [
                                                        _createElementVNode("div", _hoisted_67, [
                                                          _createElementVNode("div", null, _toDisplayString(attribute.name), 1),
                                                          _createElementVNode("div", _hoisted_68, _toDisplayString(attribute.name_gb), 1)
                                                        ])
                                                      ]))
                                                    : _createCommentVNode("", true),
                                                  (attribute.name === attribute.name_gb)
                                                    ? (_openBlock(), _createElementBlock("td", _hoisted_69, _toDisplayString(attribute.name), 1))
                                                    : _createCommentVNode("", true),
                                                  (is_color_enabled())
                                                    ? (_openBlock(), _createElementBlock("td", _hoisted_70, [
                                                        _createElementVNode("div", {
                                                          style: _normalizeStyle({ backgroundColor: attribute.color, width: '20px', height: '20px', border: '1px solid black' })
                                                        }, null, 4),
                                                        _createElementVNode("div", _hoisted_71, _toDisplayString(attribute.color), 1)
                                                      ]))
                                                    : _createCommentVNode("", true),
                                                  _createElementVNode("td", _hoisted_72, [
                                                    _createElementVNode("div", _hoisted_73, [
                                                      _createElementVNode("button", {
                                                        onClick: _withModifiers(($event: any) => (start_editing_attribute(attribute.id)), ["prevent"]),
                                                        class: "btn btn-default btn-sm"
                                                      }, _cache[44] || (_cache[44] = [
                                                        _createElementVNode("i", { class: "fas fa-pen mr-1" }, null, -1),
                                                        _createTextVNode(" Edytuj ")
                                                      ]), 8, _hoisted_74),
                                                      _cache[46] || (_cache[46] = _createElementVNode("button", {
                                                        type: "button",
                                                        class: "btn btn-default btn-sm dropdown-toggle dropdown-icon",
                                                        "data-toggle": "dropdown",
                                                        "aria-expanded": "false"
                                                      }, [
                                                        _createElementVNode("span", { class: "sr-only" }, "Zobacz więcej")
                                                      ], -1)),
                                                      _createElementVNode("div", _hoisted_75, [
                                                        _createElementVNode("a", {
                                                          class: "dropdown-item",
                                                          href: "#",
                                                          onClick: _withModifiers(($event: any) => (prepare_delete_attribute(attribute.id)), ["prevent"])
                                                        }, _cache[45] || (_cache[45] = [
                                                          _createElementVNode("i", { class: "fas fa-trash mr-1" }, null, -1),
                                                          _createTextVNode(" Usuń ")
                                                        ]), 8, _hoisted_76)
                                                      ])
                                                    ])
                                                  ])
                                                ], 2))
                                              : _createCommentVNode("", true),
                                            (is_attribute_editing(attribute.id))
                                              ? (_openBlock(), _createElementBlock("tr", _hoisted_77, [
                                                  _createElementVNode("td", _hoisted_78, [
                                                    _withDirectives(_createElementVNode("input", {
                                                      class: "form-control form-control-sm",
                                                      type: "text",
                                                      "onUpdate:modelValue": ($event: any) => ((attribute.id) = $event),
                                                      disabled: ""
                                                    }, null, 8, _hoisted_79), [
                                                      [_vModelText, attribute.id]
                                                    ])
                                                  ]),
                                                  _createElementVNode("td", _hoisted_80, [
                                                    _withDirectives(_createElementVNode("input", {
                                                      class: "form-control form-control-sm",
                                                      type: "text",
                                                      "onUpdate:modelValue": ($event: any) => ((attribute.position) = $event),
                                                      disabled: ""
                                                    }, null, 8, _hoisted_81), [
                                                      [_vModelText, attribute.position]
                                                    ])
                                                  ]),
                                                  _createElementVNode("td", null, [
                                                    _createElementVNode("div", _hoisted_82, [
                                                      _cache[47] || (_cache[47] = _createElementVNode("div", { class: "input-group-prepend" }, [
                                                        _createElementVNode("span", {
                                                          class: "input-group-text",
                                                          style: {"width":"34px","font-size":"12px"}
                                                        }, "PL")
                                                      ], -1)),
                                                      _withDirectives(_createElementVNode("input", {
                                                        name: "name",
                                                        class: "form-control",
                                                        type: "text",
                                                        "onUpdate:modelValue": ($event: any) => ((attribute.name) = $event),
                                                        placeholder: "Nazwa (PL)"
                                                      }, null, 8, _hoisted_83), [
                                                        [_vModelText, attribute.name]
                                                      ]),
                                                      _cache[48] || (_cache[48] = _createElementVNode("div", { class: "error text-danger" }, null, -1))
                                                    ]),
                                                    _createElementVNode("div", _hoisted_84, [
                                                      _cache[49] || (_cache[49] = _createElementVNode("div", { class: "input-group-prepend" }, [
                                                        _createElementVNode("span", {
                                                          class: "input-group-text",
                                                          style: {"width":"34px","font-size":"12px"}
                                                        }, "GB")
                                                      ], -1)),
                                                      _withDirectives(_createElementVNode("input", {
                                                        class: "form-control",
                                                        type: "text",
                                                        "onUpdate:modelValue": ($event: any) => ((attribute.name_gb) = $event),
                                                        placeholder: "Nazwa (GB) -> Gdy pusta to taka sama jak PL"
                                                      }, null, 8, _hoisted_85), [
                                                        [_vModelText, attribute.name_gb]
                                                      ]),
                                                      _cache[50] || (_cache[50] = _createElementVNode("div", { class: "error text-danger" }, null, -1))
                                                    ])
                                                  ]),
                                                  (is_color_enabled())
                                                    ? (_openBlock(), _createElementBlock("td", _hoisted_86, [
                                                        _withDirectives(_createElementVNode("input", {
                                                          class: "form-control form-control-sm",
                                                          type: "text",
                                                          "onUpdate:modelValue": ($event: any) => ((attribute.color) = $event),
                                                          placeholder: "Kolor"
                                                        }, null, 8, _hoisted_87), [
                                                          [_vModelText, attribute.color]
                                                        ]),
                                                        _cache[51] || (_cache[51] = _createElementVNode("div", { class: "error text-danger" }, null, -1))
                                                      ]))
                                                    : _createCommentVNode("", true),
                                                  _createElementVNode("td", _hoisted_88, [
                                                    _createElementVNode("button", {
                                                      class: "btn btn-primary btn-sm mr-2",
                                                      onClick: _withModifiers(($event: any) => (update_attribute(attribute.id)), ["prevent"])
                                                    }, " Zapisz ", 8, _hoisted_89),
                                                    _createElementVNode("button", {
                                                      class: "btn btn-secondary btn-sm",
                                                      onClick: _cache[17] || (_cache[17] = _withModifiers(($event: any) => (cancel_editing_attribute()), ["prevent"]))
                                                    }, " Anuluj ")
                                                  ])
                                                ]))
                                              : _createCommentVNode("", true)
                                          ], 64))
                                        }), 128)),
                                        (!is_some_attribute_editing())
                                          ? (_openBlock(), _createElementBlock("tr", _hoisted_90, [
                                              _createElementVNode("td", _hoisted_91, [
                                                _createElementVNode("button", {
                                                  class: "btn btn-outline-primary w-100",
                                                  onClick: _cache[18] || (_cache[18] = _withModifiers(($event: any) => (start_adding_attrbiute()), ["prevent"]))
                                                }, _cache[52] || (_cache[52] = [
                                                  _createElementVNode("i", { class: "fas fa-plus-circle mr-2" }, null, -1),
                                                  _createTextVNode(" Dodaj atrybut ")
                                                ]))
                                              ])
                                            ]))
                                          : _createCommentVNode("", true),
                                        (_unref(attributes).length === 0)
                                          ? (_openBlock(), _createElementBlock("tr", _hoisted_92, _cache[53] || (_cache[53] = [
                                              _createElementVNode("td", {
                                                colspan: "4",
                                                class: "text-center"
                                              }, "Brak atrybutów", -1)
                                            ])))
                                          : _createCommentVNode("", true)
                                      ])
                                    ])
                                  ])
                                ])
                              ])
                            ]))
                          : _createCommentVNode("", true),
                        (is_attribute_group_editing(attribute_group.id))
                          ? (_openBlock(), _createElementBlock("tr", _hoisted_93, [
                              _createElementVNode("td", _hoisted_94, [
                                _withDirectives(_createElementVNode("input", {
                                  class: "form-control form-control-sm",
                                  type: "text",
                                  "onUpdate:modelValue": ($event: any) => ((attribute_group.id) = $event),
                                  disabled: ""
                                }, null, 8, _hoisted_95), [
                                  [_vModelText, attribute_group.id]
                                ])
                              ]),
                              _createElementVNode("td", _hoisted_96, [
                                _withDirectives(_createElementVNode("input", {
                                  class: "form-control form-control-sm",
                                  type: "text",
                                  "onUpdate:modelValue": ($event: any) => ((attribute_group.position) = $event),
                                  disabled: ""
                                }, null, 8, _hoisted_97), [
                                  [_vModelText, attribute_group.position]
                                ])
                              ]),
                              _createElementVNode("td", null, [
                                _createElementVNode("div", _hoisted_98, [
                                  _cache[54] || (_cache[54] = _createElementVNode("div", { class: "input-group-prepend" }, [
                                    _createElementVNode("span", {
                                      class: "input-group-text",
                                      style: {"width":"34px","font-size":"12px"}
                                    }, "PL")
                                  ], -1)),
                                  _withDirectives(_createElementVNode("input", {
                                    name: "name",
                                    class: "form-control",
                                    type: "text",
                                    "onUpdate:modelValue": ($event: any) => ((attribute_group.name) = $event),
                                    placeholder: "Nazwa (PL)"
                                  }, null, 8, _hoisted_99), [
                                    [_vModelText, attribute_group.name]
                                  ]),
                                  _cache[55] || (_cache[55] = _createElementVNode("div", { class: "error text-danger" }, null, -1))
                                ]),
                                _createElementVNode("div", _hoisted_100, [
                                  _cache[56] || (_cache[56] = _createElementVNode("div", { class: "input-group-prepend" }, [
                                    _createElementVNode("span", {
                                      class: "input-group-text",
                                      style: {"width":"34px","font-size":"12px"}
                                    }, "GB")
                                  ], -1)),
                                  _withDirectives(_createElementVNode("input", {
                                    name: "name_gb",
                                    class: "form-control",
                                    type: "text",
                                    "onUpdate:modelValue": ($event: any) => ((attribute_group.name_gb) = $event),
                                    placeholder: "Nazwa (GB) -> Gdy pusta to taka sama jak PL"
                                  }, null, 8, _hoisted_101), [
                                    [_vModelText, attribute_group.name_gb]
                                  ]),
                                  _cache[57] || (_cache[57] = _createElementVNode("div", { class: "error text-danger" }, null, -1))
                                ])
                              ]),
                              _createElementVNode("td", null, [
                                _createElementVNode("div", _hoisted_102, [
                                  _cache[58] || (_cache[58] = _createElementVNode("div", { class: "input-group-prepend" }, [
                                    _createElementVNode("span", {
                                      class: "input-group-text",
                                      style: {"width":"34px","font-size":"12px"}
                                    }, "PL")
                                  ], -1)),
                                  _withDirectives(_createElementVNode("input", {
                                    name: "public_name",
                                    class: "form-control",
                                    type: "text",
                                    "onUpdate:modelValue": ($event: any) => ((attribute_group.public_name) = $event),
                                    placeholder: "Nazwa Publiczna (PL)"
                                  }, null, 8, _hoisted_103), [
                                    [_vModelText, attribute_group.public_name]
                                  ]),
                                  _cache[59] || (_cache[59] = _createElementVNode("div", { class: "error text-danger" }, null, -1))
                                ]),
                                _createElementVNode("div", _hoisted_104, [
                                  _cache[60] || (_cache[60] = _createElementVNode("div", { class: "input-group-prepend" }, [
                                    _createElementVNode("span", {
                                      class: "input-group-text",
                                      style: {"width":"34px","font-size":"12px"}
                                    }, "GB")
                                  ], -1)),
                                  _withDirectives(_createElementVNode("input", {
                                    name: "public_name_gb",
                                    class: "form-control",
                                    type: "text",
                                    "onUpdate:modelValue": ($event: any) => ((attribute_group.public_name_gb) = $event),
                                    placeholder: "Nazwa Publiczna (GB) -> Gdy pusta to taka sama jak PL"
                                  }, null, 8, _hoisted_105), [
                                    [_vModelText, attribute_group.public_name_gb]
                                  ]),
                                  _cache[61] || (_cache[61] = _createElementVNode("div", { class: "error text-danger" }, null, -1))
                                ])
                              ]),
                              _createElementVNode("td", null, [
                                _withDirectives(_createElementVNode("select", {
                                  class: "form-control form-control-sm",
                                  "onUpdate:modelValue": ($event: any) => ((attribute_group.type_id) = $event)
                                }, [
                                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(attribute_group_types.value, (name, id) => {
                                    return (_openBlock(), _createElementBlock("option", { value: id }, _toDisplayString(name), 9, _hoisted_107))
                                  }), 256))
                                ], 8, _hoisted_106), [
                                  [_vModelSelect, attribute_group.type_id]
                                ])
                              ]),
                              _createElementVNode("td", _hoisted_108, [
                                _createElementVNode("button", {
                                  class: "btn btn-primary btn-sm mr-2",
                                  onClick: _withModifiers(($event: any) => (update_attribute_group(attribute_group.id)), ["prevent"])
                                }, " Zapisz ", 8, _hoisted_109),
                                _createElementVNode("button", {
                                  class: "btn btn-secondary btn-sm",
                                  onClick: _cache[19] || (_cache[19] = _withModifiers(($event: any) => (cancel_editing_attribute_group()), ["prevent"]))
                                }, " Anuluj ")
                              ])
                            ]))
                          : _createCommentVNode("", true)
                      ], 64))
                    }), 256)),
                    (!is_attribute_group_blocked(0) && !is_some_attribute_group_editing())
                      ? (_openBlock(), _createElementBlock("tr", _hoisted_110, [
                          _createElementVNode("td", _hoisted_111, [
                            _createElementVNode("button", {
                              class: "btn btn-outline-primary w-100",
                              onClick: _cache[20] || (_cache[20] = _withModifiers(($event: any) => (start_adding_attrbiute_group()), ["prevent"]))
                            }, _cache[62] || (_cache[62] = [
                              _createElementVNode("i", { class: "fas fa-plus-circle mr-2" }, null, -1),
                              _createTextVNode(" Dodaj grupe atrybutów ")
                            ]))
                          ])
                        ]))
                      : _createCommentVNode("", true),
                    (_unref(attribute_groups).length === 0)
                      ? (_openBlock(), _createElementBlock("tr", _hoisted_112, _cache[63] || (_cache[63] = [
                          _createElementVNode("td", {
                            colspan: "9",
                            class: "text-center"
                          }, "Brak grup atrybutów", -1)
                        ])))
                      : _createCommentVNode("", true)
                  ])
                ])
              ]),
              _createElementVNode("div", _hoisted_113, [
                _createVNode(Pager, {
                  now_page: 1,
                  item_max: _unref(item_max),
                  item_per_page: item_per_page,
                  show_pager: false,
                  event_update: search
                }, null, 8, ["item_max"])
              ])
            ])
          ])
        ])
      ])
    ])
  ], 64))
}
}

})