import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, unref as _unref, toDisplayString as _toDisplayString, withModifiers as _withModifiers, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "content content-full-height-with-header" }
const _hoisted_2 = { class: "container-fluid" }
const _hoisted_3 = { class: "row" }
const _hoisted_4 = { class: "row" }
const _hoisted_5 = { class: "col-12" }
const _hoisted_6 = { class: "card" }
const _hoisted_7 = { class: "card-header" }
const _hoisted_8 = { class: "card-title" }
const _hoisted_9 = { class: "card-tools" }
const _hoisted_10 = { class: "nav nav-pills ml-auto" }
const _hoisted_11 = { class: "nav-item" }
const _hoisted_12 = { class: "btn-group" }
const _hoisted_13 = {
  class: "dropdown-menu",
  role: "menu"
}
const _hoisted_14 = {
  class: "card-body table-responsive p-0",
  style: {"height":"500px"}
}
const _hoisted_15 = { class: "table table-head-fixed text-nowrap" }
const _hoisted_16 = {
  key: 0,
  class: "fas fa-sort-down ml-2"
}
const _hoisted_17 = {
  key: 1,
  class: "fas fa-sort-up ml-2"
}
const _hoisted_18 = {
  key: 0,
  class: "fas fa-sort-down ml-2"
}
const _hoisted_19 = {
  key: 1,
  class: "fas fa-sort-up ml-2"
}
const _hoisted_20 = {
  key: 0,
  class: "fas fa-sort-down ml-2"
}
const _hoisted_21 = {
  key: 1,
  class: "fas fa-sort-up ml-2"
}
const _hoisted_22 = {
  key: 0,
  class: "fas fa-sort-down ml-2"
}
const _hoisted_23 = {
  key: 1,
  class: "fas fa-sort-up ml-2"
}
const _hoisted_24 = {
  key: 0,
  class: "fas fa-sort-down ml-2"
}
const _hoisted_25 = {
  key: 1,
  class: "fas fa-sort-up ml-2"
}
const _hoisted_26 = {
  key: 0,
  class: "fas fa-sort-down ml-2"
}
const _hoisted_27 = {
  key: 1,
  class: "fas fa-sort-up ml-2"
}
const _hoisted_28 = {
  key: 0,
  class: "fas fa-sort-down ml-2"
}
const _hoisted_29 = {
  key: 1,
  class: "fas fa-sort-up ml-2"
}
const _hoisted_30 = { class: "filter-row" }
const _hoisted_31 = { class: "text-center" }
const _hoisted_32 = { class: "text-center" }
const _hoisted_33 = { class: "date-th" }
const _hoisted_34 = { class: "td-email" }
const _hoisted_35 = { class: "text-center" }
const _hoisted_36 = {
  key: 0,
  class: "badge bg-success"
}
const _hoisted_37 = {
  key: 1,
  class: "badge bg-danger"
}
const _hoisted_38 = { class: "text-center" }
const _hoisted_39 = {
  key: 0,
  class: "badge bg-success"
}
const _hoisted_40 = {
  key: 1,
  class: "badge bg-danger"
}
const _hoisted_41 = { class: "btn-group" }
const _hoisted_42 = {
  class: "dropdown-menu",
  role: "menu"
}
const _hoisted_43 = ["onClick"]
const _hoisted_44 = ["onClick"]
const _hoisted_45 = ["onClick"]
const _hoisted_46 = { key: 0 }
const _hoisted_47 = { class: "card-footer clearfix" }

import ContentHeader from '@/admin/components/Layouts/ContentHeader.vue';
import SmallBox from '@/admin/components/Layouts/SmallBox.vue';
import { ApiAdminCustomersSearch, ApiResultDataSingle } from '@/admin/global/Api/CustomersSearch';
import Pager from '@/admin/components/Pager.vue';
import { ref } from 'vue';
import { ApiAdminCustomersGeneral } from '@/admin/global/Api/CustomersGeneral';
import { ApiAdminCustomersExport } from '@/admin/global/Api/CustomersExport';
import { ApiParams } from "@/admin/global/Api/CustomersSearch";
import { Form } from '@/web/global/Form';
import { DateTime } from '@/admin/global/DateTime';
import { ApiAdminCustomerDisable } from '@/admin/global/Api/CustomerDisable';
import { Notify } from '@/web/global/Notify';
import { ApiAdminCustomerEnable } from '@/admin/global/Api/CustomerEnable';
import { ApiAdminCustomerDelete } from '@/admin/global/Api/CustomerDelete';

const item_per_page = 50;

export default /*@__PURE__*/_defineComponent({
  __name: 'Customers',
  setup(__props) {

let item_max = ref(0);
let customers = ref([] as Array<ApiResultDataSingle>);
let actual_page = 1;

let sort_by = ref('id');
let sort_order = ref('asc');

let customers_count = ref(0);
let average_age = ref(0);
let orders_count = ref(0);
let newsletter_count = ref(0);

function get_search_params(page = -1, per_page = -1):ApiParams{
  let params:ApiParams = {};

  const params_name = ['id', 'firstname', 'lastname', 'email', 'isactive', 'newsletter', 'dt_from', 'dt_to'];
  params_name.forEach(name => {
    const v = (Form.value(name) + '').trim();

    if(v.length > 0){
      params[name] = v;
    }
  });

  if(page !== -1){
    params['page'] = page;
    params['per_page'] = per_page;
  }

  params["sort_by"] = sort_by.value;
  params["sort_order"] = sort_order.value === 'asc' ? 'asc' : 'desc';

  return params;
}

function search(page: number) {
  ApiAdminCustomersSearch.request(get_search_params(page, item_per_page), result => {
    customers.value = result.data.map(v => {
      v.dt = DateTime.format_datetime(v.dt);
      return v;
    });
    item_max.value = result.data_count;
    actual_page = page;
  }, () => {
    console.log('error');

    customers.value = [];
    item_max.value = 0;
  });
}

function data_export(){
  ApiAdminCustomersExport.request(get_search_params());
}

function order_by(name: string){
  if(sort_by.value === name){
    sort_order.value = sort_order.value === 'asc' ? 'desc' : 'asc';
  }
  else{
    sort_by.value = name;
    sort_order.value = 'asc';
  }

  search(actual_page);
}

function prepare_delete_customer(id: number): void {
  Notify.confirm('Czy na pewno chcesz usunąć użytkownika: #' + id + '?', () => {
    delete_customer(id);
  }, 'Usuń użytkownika', 'Anuluj');
}

function delete_customer(id:number):void{
  ApiAdminCustomerDelete.request({id}, () => {
    search(actual_page);
    Notify.success('Użytkownik: #' + id + ' został usunięty');
  }, () => {
    console.log('user delete error: ' + id);
  });
}

function hide_customer(id:number):void{
  ApiAdminCustomerDisable.request({id}, () => {
    search(actual_page);
    Notify.success('Użytkownik: #' + id + ' został wyłączony');
  }, () => {
    console.log('user delete error: ' + id);
  });
}

function show_customer(id:number):void{
  ApiAdminCustomerEnable.request({id}, () => {
    search(actual_page);
    Notify.success('Użytkownik: #' + id + ' został włączony');
  }, () => {
    console.log('user active error: ' + id);
  });
}

ApiAdminCustomersGeneral.request(result => {
  customers_count.value = result.customers_count;
  average_age.value = result.average_age;
  orders_count.value = result.orders_count;
  newsletter_count.value = result.newsletter_count;
});

search(actual_page);

return (_ctx: any,_cache: any) => {
  const _component_RouterLink = _resolveComponent("RouterLink")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(ContentHeader, {
      main_title: "Klienci > Klienci",
      sub_title: "Zarządzanie Klientami"
    }, {
      default: _withCtx(() => [
        _createVNode(_component_RouterLink, {
          to: "/panel/sell/customers/new",
          class: "btn btn-primary"
        }, {
          default: _withCtx(() => _cache[18] || (_cache[18] = [
            _createElementVNode("i", { class: "fas fa-plus-circle" }, null, -1),
            _createTextVNode(" Dodaj nowego klienta ")
          ])),
          _: 1
        })
      ]),
      _: 1
    }),
    _createElementVNode("section", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, [
          _createVNode(SmallBox, {
            count: String(_unref(customers_count)),
            title: "Aktywni klienci",
            icon: "ion-person",
            bg_class: "bg-info"
          }, null, 8, ["count"]),
          _createVNode(SmallBox, {
            count: _unref(average_age) + ' lat',
            title: "Średni wiek",
            icon: "ion-calendar",
            bg_class: "bg-danger"
          }, null, 8, ["count"]),
          _createVNode(SmallBox, {
            count: String(_unref(orders_count)),
            title: "Zamówienia na klienta",
            icon: "ion-android-cart",
            bg_class: "bg-primary"
          }, null, 8, ["count"]),
          _createVNode(SmallBox, {
            count: String(_unref(newsletter_count)),
            title: "Rejestracje biuletynu",
            icon: "ion-email",
            bg_class: "bg-success"
          }, null, 8, ["count"])
        ]),
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("div", _hoisted_5, [
            _createElementVNode("div", _hoisted_6, [
              _createElementVNode("div", _hoisted_7, [
                _createElementVNode("h3", _hoisted_8, "Klienci (" + _toDisplayString(_unref(item_max)) + ")", 1),
                _createElementVNode("div", _hoisted_9, [
                  _createElementVNode("ul", _hoisted_10, [
                    _createElementVNode("li", _hoisted_11, [
                      _createElementVNode("div", _hoisted_12, [
                        _createElementVNode("button", {
                          class: "btn btn-default btn-sm",
                          onClick: _cache[0] || (_cache[0] = _withModifiers(($event: any) => (data_export()), ["prevent"]))
                        }, _cache[19] || (_cache[19] = [
                          _createElementVNode("i", { class: "fas fa-download mr-1" }, null, -1),
                          _createTextVNode(" Eksportuj ")
                        ])),
                        _cache[22] || (_cache[22] = _createElementVNode("button", {
                          type: "button",
                          class: "btn btn-default btn-sm dropdown-toggle dropdown-icon",
                          "data-toggle": "dropdown",
                          "aria-expanded": "false"
                        }, [
                          _createElementVNode("span", { class: "sr-only" }, "Zobacz więcej")
                        ], -1)),
                        _createElementVNode("div", _hoisted_13, [
                          _createElementVNode("button", {
                            class: "dropdown-item",
                            onClick: _cache[1] || (_cache[1] = _withModifiers(($event: any) => (data_export()), ["prevent"]))
                          }, _cache[20] || (_cache[20] = [
                            _createElementVNode("i", { class: "fas fa-download mr-1" }, null, -1),
                            _createTextVNode(" Eksportuj ")
                          ])),
                          _createElementVNode("button", {
                            class: "dropdown-item",
                            onClick: _cache[2] || (_cache[2] = _withModifiers(($event: any) => (search(_unref(actual_page))), ["prevent"]))
                          }, _cache[21] || (_cache[21] = [
                            _createElementVNode("i", { class: "fas fa-sync-alt mr-1" }, null, -1),
                            _createTextVNode(" Odśwież liste ")
                          ]))
                        ])
                      ])
                    ])
                  ])
                ])
              ]),
              _createElementVNode("div", _hoisted_14, [
                _createElementVNode("table", _hoisted_15, [
                  _createElementVNode("thead", null, [
                    _createElementVNode("tr", null, [
                      _createElementVNode("th", {
                        class: "th-sortable",
                        onClick: _cache[3] || (_cache[3] = ($event: any) => (order_by('id')))
                      }, [
                        _cache[23] || (_cache[23] = _createTextVNode(" ID ")),
                        (_unref(sort_by) == 'id' && _unref(sort_order) == 'asc')
                          ? (_openBlock(), _createElementBlock("i", _hoisted_16))
                          : _createCommentVNode("", true),
                        (_unref(sort_by) == 'id' && _unref(sort_order) == 'desc')
                          ? (_openBlock(), _createElementBlock("i", _hoisted_17))
                          : _createCommentVNode("", true)
                      ]),
                      _createElementVNode("th", {
                        class: "th-sortable",
                        onClick: _cache[4] || (_cache[4] = ($event: any) => (order_by('firstname')))
                      }, [
                        _cache[24] || (_cache[24] = _createTextVNode(" Imię ")),
                        (_unref(sort_by) == 'firstname' && _unref(sort_order) == 'asc')
                          ? (_openBlock(), _createElementBlock("i", _hoisted_18))
                          : _createCommentVNode("", true),
                        (_unref(sort_by) == 'firstname' && _unref(sort_order) == 'desc')
                          ? (_openBlock(), _createElementBlock("i", _hoisted_19))
                          : _createCommentVNode("", true)
                      ]),
                      _createElementVNode("th", {
                        class: "th-sortable",
                        onClick: _cache[5] || (_cache[5] = ($event: any) => (order_by('lastname')))
                      }, [
                        _cache[25] || (_cache[25] = _createTextVNode(" Nazwisko ")),
                        (_unref(sort_by) == 'lastname' && _unref(sort_order) == 'asc')
                          ? (_openBlock(), _createElementBlock("i", _hoisted_20))
                          : _createCommentVNode("", true),
                        (_unref(sort_by) == 'lastname' && _unref(sort_order) == 'desc')
                          ? (_openBlock(), _createElementBlock("i", _hoisted_21))
                          : _createCommentVNode("", true)
                      ]),
                      _createElementVNode("th", {
                        class: "th-sortable",
                        onClick: _cache[6] || (_cache[6] = ($event: any) => (order_by('email')))
                      }, [
                        _cache[26] || (_cache[26] = _createTextVNode(" E-mail ")),
                        (_unref(sort_by) == 'email' && _unref(sort_order) == 'asc')
                          ? (_openBlock(), _createElementBlock("i", _hoisted_22))
                          : _createCommentVNode("", true),
                        (_unref(sort_by) == 'email' && _unref(sort_order) == 'desc')
                          ? (_openBlock(), _createElementBlock("i", _hoisted_23))
                          : _createCommentVNode("", true)
                      ]),
                      _cache[30] || (_cache[30] = _createElementVNode("th", null, "Sprzedaż", -1)),
                      _createElementVNode("th", {
                        class: "th-sortable text-center",
                        onClick: _cache[7] || (_cache[7] = ($event: any) => (order_by('isactive')))
                      }, [
                        _cache[27] || (_cache[27] = _createTextVNode(" Włączony ")),
                        (_unref(sort_by) == 'isactive' && _unref(sort_order) == 'asc')
                          ? (_openBlock(), _createElementBlock("i", _hoisted_24))
                          : _createCommentVNode("", true),
                        (_unref(sort_by) == 'isactive' && _unref(sort_order) == 'desc')
                          ? (_openBlock(), _createElementBlock("i", _hoisted_25))
                          : _createCommentVNode("", true)
                      ]),
                      _createElementVNode("th", {
                        class: "th-sortable text-center",
                        onClick: _cache[8] || (_cache[8] = ($event: any) => (order_by('newsletter')))
                      }, [
                        _cache[28] || (_cache[28] = _createTextVNode(" Newsletter ")),
                        (_unref(sort_by) == 'newsletter' && _unref(sort_order) == 'asc')
                          ? (_openBlock(), _createElementBlock("i", _hoisted_26))
                          : _createCommentVNode("", true),
                        (_unref(sort_by) == 'newsletter' && _unref(sort_order) == 'desc')
                          ? (_openBlock(), _createElementBlock("i", _hoisted_27))
                          : _createCommentVNode("", true)
                      ]),
                      _createElementVNode("th", {
                        class: "th-sortable",
                        onClick: _cache[9] || (_cache[9] = ($event: any) => (order_by('dt')))
                      }, [
                        _cache[29] || (_cache[29] = _createTextVNode(" Rejestracja ")),
                        (_unref(sort_by) == 'dt' && _unref(sort_order) == 'asc')
                          ? (_openBlock(), _createElementBlock("i", _hoisted_28))
                          : _createCommentVNode("", true),
                        (_unref(sort_by) == 'dt' && _unref(sort_order) == 'desc')
                          ? (_openBlock(), _createElementBlock("i", _hoisted_29))
                          : _createCommentVNode("", true)
                      ]),
                      _cache[31] || (_cache[31] = _createElementVNode("th", null, "Akcje", -1))
                    ]),
                    _createElementVNode("tr", _hoisted_30, [
                      _createElementVNode("th", null, [
                        _createElementVNode("input", {
                          class: "form-control form-control-sm",
                          type: "text",
                          placeholder: "ID",
                          onInput: _cache[10] || (_cache[10] = ($event: any) => (search(1))),
                          name: "id"
                        }, null, 32)
                      ]),
                      _createElementVNode("th", null, [
                        _createElementVNode("input", {
                          class: "form-control form-control-sm",
                          type: "text",
                          placeholder: "Imię",
                          onInput: _cache[11] || (_cache[11] = ($event: any) => (search(1))),
                          name: "firstname"
                        }, null, 32)
                      ]),
                      _createElementVNode("th", null, [
                        _createElementVNode("input", {
                          class: "form-control form-control-sm",
                          type: "text",
                          placeholder: "Nazwisko",
                          onInput: _cache[12] || (_cache[12] = ($event: any) => (search(1))),
                          name: "lastname"
                        }, null, 32)
                      ]),
                      _createElementVNode("th", null, [
                        _createElementVNode("input", {
                          class: "form-control form-control-sm",
                          type: "text",
                          placeholder: "E-mail",
                          onInput: _cache[13] || (_cache[13] = ($event: any) => (search(1))),
                          name: "email"
                        }, null, 32)
                      ]),
                      _cache[34] || (_cache[34] = _createElementVNode("th", null, null, -1)),
                      _createElementVNode("th", _hoisted_31, [
                        _createElementVNode("select", {
                          class: "form-control form-control-sm",
                          onChange: _cache[14] || (_cache[14] = ($event: any) => (search(1))),
                          name: "isactive"
                        }, _cache[32] || (_cache[32] = [
                          _createElementVNode("option", {
                            value: "",
                            selected: ""
                          }, "Niewybrane", -1),
                          _createElementVNode("option", { value: "1" }, "Tak", -1),
                          _createElementVNode("option", { value: "0" }, "Nie", -1)
                        ]), 32)
                      ]),
                      _createElementVNode("th", _hoisted_32, [
                        _createElementVNode("select", {
                          class: "form-control form-control-sm",
                          onChange: _cache[15] || (_cache[15] = ($event: any) => (search(1))),
                          name: "newsletter"
                        }, _cache[33] || (_cache[33] = [
                          _createElementVNode("option", {
                            value: "",
                            selected: ""
                          }, "Niewybrane", -1),
                          _createElementVNode("option", { value: "1" }, "Tak", -1),
                          _createElementVNode("option", { value: "0" }, "Nie", -1)
                        ]), 32)
                      ]),
                      _createElementVNode("th", _hoisted_33, [
                        _createElementVNode("input", {
                          class: "form-control form-control-sm",
                          type: "date",
                          placeholder: "Data od",
                          onInput: _cache[16] || (_cache[16] = ($event: any) => (search(1))),
                          name: "dt_from"
                        }, null, 32),
                        _createElementVNode("input", {
                          class: "form-control form-control-sm",
                          type: "date",
                          placeholder: "Data do",
                          onInput: _cache[17] || (_cache[17] = ($event: any) => (search(1))),
                          name: "dt_to"
                        }, null, 32)
                      ]),
                      _cache[35] || (_cache[35] = _createElementVNode("th", null, null, -1))
                    ])
                  ]),
                  _createElementVNode("tbody", null, [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(customers), (customer) => {
                      return (_openBlock(), _createElementBlock("tr", null, [
                        _createElementVNode("td", null, _toDisplayString(customer.id), 1),
                        _createElementVNode("td", null, _toDisplayString(customer.firstname), 1),
                        _createElementVNode("td", null, _toDisplayString(customer.lastname), 1),
                        _createElementVNode("td", _hoisted_34, [
                          _createElementVNode("span", null, _toDisplayString(customer.email), 1)
                        ]),
                        _createElementVNode("td", null, [
                          _createElementVNode("span", {
                            class: _normalizeClass({'badge bg-success': Number(customer.total_spent) > 0})
                          }, _toDisplayString(Number(customer.total_spent).toLocaleString('pl-PL', { style: 'currency', currency: 'PLN' })), 3)
                        ]),
                        _createElementVNode("td", _hoisted_35, [
                          (customer.isactive)
                            ? (_openBlock(), _createElementBlock("span", _hoisted_36, "✓"))
                            : _createCommentVNode("", true),
                          (!customer.isactive)
                            ? (_openBlock(), _createElementBlock("span", _hoisted_37, "×"))
                            : _createCommentVNode("", true)
                        ]),
                        _createElementVNode("td", _hoisted_38, [
                          (customer.newsletter)
                            ? (_openBlock(), _createElementBlock("span", _hoisted_39, "✓"))
                            : _createCommentVNode("", true),
                          (!customer.newsletter)
                            ? (_openBlock(), _createElementBlock("span", _hoisted_40, "×"))
                            : _createCommentVNode("", true)
                        ]),
                        _createElementVNode("td", null, _toDisplayString(customer.dt), 1),
                        _createElementVNode("td", null, [
                          _createElementVNode("div", _hoisted_41, [
                            _createVNode(_component_RouterLink, {
                              to: '/panel/sell/customers/' + customer.id,
                              class: "btn btn-default btn-sm"
                            }, {
                              default: _withCtx(() => _cache[36] || (_cache[36] = [
                                _createElementVNode("i", { class: "fas fa-eye mr-1" }, null, -1),
                                _createTextVNode(" Zobacz ")
                              ])),
                              _: 2
                            }, 1032, ["to"]),
                            _cache[42] || (_cache[42] = _createElementVNode("button", {
                              type: "button",
                              class: "btn btn-default btn-sm dropdown-toggle dropdown-icon",
                              "data-toggle": "dropdown",
                              "aria-expanded": "false"
                            }, [
                              _createElementVNode("span", { class: "sr-only" }, "Zobacz więcej")
                            ], -1)),
                            _createElementVNode("div", _hoisted_42, [
                              _createVNode(_component_RouterLink, {
                                to: '/panel/sell/customers/' + customer.id,
                                class: "dropdown-item"
                              }, {
                                default: _withCtx(() => _cache[37] || (_cache[37] = [
                                  _createElementVNode("i", { class: "fas fa-eye mr-1" }, null, -1),
                                  _createTextVNode(" Zobacz ")
                                ])),
                                _: 2
                              }, 1032, ["to"]),
                              _createVNode(_component_RouterLink, {
                                to: '/panel/sell/customers/' + customer.id + '/edit',
                                class: "dropdown-item"
                              }, {
                                default: _withCtx(() => _cache[38] || (_cache[38] = [
                                  _createElementVNode("i", { class: "fas fa-pen mr-1" }, null, -1),
                                  _createTextVNode(" Edytuj ")
                                ])),
                                _: 2
                              }, 1032, ["to"]),
                              (customer.isactive)
                                ? (_openBlock(), _createElementBlock("a", {
                                    key: 0,
                                    class: "dropdown-item",
                                    href: "#",
                                    onClick: _withModifiers(($event: any) => (hide_customer(customer.id)), ["prevent"])
                                  }, _cache[39] || (_cache[39] = [
                                    _createElementVNode("i", { class: "far fa-eye-slash mr-1" }, null, -1),
                                    _createTextVNode(" Wyłącz ")
                                  ]), 8, _hoisted_43))
                                : _createCommentVNode("", true),
                              (!customer.isactive)
                                ? (_openBlock(), _createElementBlock("a", {
                                    key: 1,
                                    class: "dropdown-item",
                                    href: "#",
                                    onClick: _withModifiers(($event: any) => (show_customer(customer.id)), ["prevent"])
                                  }, _cache[40] || (_cache[40] = [
                                    _createElementVNode("i", { class: "far fa-eye mr-1" }, null, -1),
                                    _createTextVNode(" Włącz ")
                                  ]), 8, _hoisted_44))
                                : _createCommentVNode("", true),
                              (!customer.isactive)
                                ? (_openBlock(), _createElementBlock("a", {
                                    key: 2,
                                    class: "dropdown-item",
                                    href: "#",
                                    onClick: _withModifiers(($event: any) => (prepare_delete_customer(customer.id)), ["prevent"])
                                  }, _cache[41] || (_cache[41] = [
                                    _createElementVNode("i", { class: "fas fa-trash mr-1" }, null, -1),
                                    _createTextVNode(" Usuń ")
                                  ]), 8, _hoisted_45))
                                : _createCommentVNode("", true)
                            ])
                          ])
                        ])
                      ]))
                    }), 256)),
                    (_unref(customers).length === 0)
                      ? (_openBlock(), _createElementBlock("tr", _hoisted_46, _cache[43] || (_cache[43] = [
                          _createElementVNode("td", {
                            colspan: "9",
                            class: "text-center"
                          }, "Brak wyników", -1)
                        ])))
                      : _createCommentVNode("", true)
                  ])
                ])
              ]),
              _createElementVNode("div", _hoisted_47, [
                _createVNode(Pager, {
                  now_page: 1,
                  item_max: _unref(item_max),
                  item_per_page: item_per_page,
                  event_update: search
                }, null, 8, ["item_max"])
              ])
            ])
          ])
        ])
      ])
    ])
  ], 64))
}
}

})