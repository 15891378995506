import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, vModelText as _vModelText, withDirectives as _withDirectives, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "col-12" }
const _hoisted_2 = { class: "card" }
const _hoisted_3 = { class: "card-body" }
const _hoisted_4 = { class: "row" }
const _hoisted_5 = { class: "col-6" }
const _hoisted_6 = { class: "input-group" }
const _hoisted_7 = { class: "col-6" }
const _hoisted_8 = { class: "input-group" }

import { ref, watch } from 'vue';
import { NewConnector } from './NewConnector';
import SpecificPriceBlock from './NewPricing/SpecificPriceBlock.vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'NewPricing',
  setup(__props) {

const price_gross = ref<number>(NewConnector.price_gross);
const price_net = ref<number>(NewConnector.price_net);
const vat_rate = ref<number>(23); // Example VAT rate of 23%

const update_netto_price = () => {
  price_net.value = parseFloat((price_gross.value / (1 + vat_rate.value / 100)).toFixed(2));
  NewConnector.price_net = price_net.value;
  NewConnector.price_gross = price_gross.value;
};

const update_brutto_price = () => {
  price_gross.value = parseFloat((price_net.value * (1 + vat_rate.value / 100)).toFixed(2));
  NewConnector.price_net = price_net.value;
  NewConnector.price_gross = price_gross.value;
};

watch([price_gross, price_net], () => {
  NewConnector.price_gross = price_gross.value;
  NewConnector.price_net = price_net.value;
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _cache[6] || (_cache[6] = _createElementVNode("label", {
          class: "card-title mb-2",
          style: {"float":"none"}
        }, "Cena Detaliczna", -1)),
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("div", _hoisted_5, [
            _cache[3] || (_cache[3] = _createElementVNode("label", { for: "price_net" }, "Netto", -1)),
            _createElementVNode("div", _hoisted_6, [
              _withDirectives(_createElementVNode("input", {
                id: "price_net",
                class: "form-control",
                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((price_net).value = $event)),
                onInput: update_brutto_price,
                type: "number",
                step: "0.01"
              }, null, 544), [
                [
                  _vModelText,
                  price_net.value,
                  void 0,
                  { number: true }
                ]
              ]),
              _cache[2] || (_cache[2] = _createElementVNode("div", { class: "input-group-append" }, [
                _createElementVNode("span", { class: "input-group-text" }, "zł")
              ], -1))
            ])
          ]),
          _createElementVNode("div", _hoisted_7, [
            _cache[5] || (_cache[5] = _createElementVNode("label", { for: "price_gross" }, "Brutto", -1)),
            _createElementVNode("div", _hoisted_8, [
              _withDirectives(_createElementVNode("input", {
                id: "price_gross",
                class: "form-control",
                "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((price_gross).value = $event)),
                onInput: update_netto_price,
                type: "number",
                step: "0.01"
              }, null, 544), [
                [
                  _vModelText,
                  price_gross.value,
                  void 0,
                  { number: true }
                ]
              ]),
              _cache[4] || (_cache[4] = _createElementVNode("div", { class: "input-group-append" }, [
                _createElementVNode("span", { class: "input-group-text" }, "zł")
              ], -1))
            ])
          ])
        ]),
        _cache[7] || (_cache[7] = _createElementVNode("div", { class: "row mt-2" }, [
          _createElementVNode("div", { class: "col-12" }, [
            _createElementVNode("div", { class: "input-group" }, [
              _createElementVNode("span", { class: "font-weight-bold text-primary" }, "Podatek 23%")
            ])
          ])
        ], -1)),
        _createVNode(SpecificPriceBlock, { class: "mt-4" })
      ])
    ])
  ]))
}
}

})