import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderSlot as _renderSlot, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "content-header pt-2" }
const _hoisted_2 = { class: "container-fluid" }
const _hoisted_3 = { class: "row mb-1" }
const _hoisted_4 = { class: "col-12 breadcrumb-item active" }
const _hoisted_5 = { class: "row mb-2" }
const _hoisted_6 = { class: "col-8" }
const _hoisted_7 = { class: "m-0" }
const _hoisted_8 = { class: "col-4 flex justify-content-end text-right" }


import { ref } from 'vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'ContentHeader',
  props: {
  main_title: { type: String },
  sub_title: { type: String }
},
  setup(__props) {

const props = __props;

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, _toDisplayString(props.main_title), 1)
      ]),
      _createElementVNode("div", _hoisted_5, [
        _createElementVNode("div", _hoisted_6, [
          _createElementVNode("h1", _hoisted_7, _toDisplayString(props.sub_title), 1)
        ]),
        _createElementVNode("div", _hoisted_8, [
          _createElementVNode("div", null, [
            _renderSlot(_ctx.$slots, "default")
          ])
        ])
      ])
    ])
  ]))
}
}

})