import { defineComponent as _defineComponent } from 'vue'
import { openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

import { onMounted, ref } from 'vue';
import NewMainScreen from './New/NewMainScreen.vue';
import { CustomersConnector } from './New/CustomersConnector';
import { ApiAdminCustomerDetails } from '@/admin/global/Api/CustomerDetails';
import { useRoute } from 'vue-router';
import { Notify } from '@/admin/global/Notify';


export default /*@__PURE__*/_defineComponent({
  __name: 'Edit',
  setup(__props) {

const route = useRoute();
const customer_id = Number(route.params.customer_id);
const isLoaded = ref<boolean>(false);

onMounted(() => {
  ApiAdminCustomerDetails.request({id: customer_id}, (customer) => {
    CustomersConnector.clientName = '#' + customer_id + ' ' + customer.firstname + ' ' + customer.lastname;
    CustomersConnector.customerId = Number(customer_id);
    CustomersConnector.firstname = customer.firstname;
    CustomersConnector.lastname = customer.lastname;
    CustomersConnector.email = customer.email;
    CustomersConnector.birthday = customer.birthday;
    CustomersConnector.isactive = customer.isactive;
    CustomersConnector.newsletter = customer.newsletter;
    isLoaded.value = true;
  }, () => {
    Notify.error('Nie można pobrać danych klienta');
  });
});

return (_ctx: any,_cache: any) => {
  return (isLoaded.value)
    ? (_openBlock(), _createBlock(NewMainScreen, { key: 0 }))
    : _createCommentVNode("", true)
}
}

})