import { defineComponent as _defineComponent } from 'vue'
import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, withModifiers as _withModifiers, normalizeStyle as _normalizeStyle, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = ["onClick"]
const _hoisted_2 = {
  key: 0,
  class: "subcategories"
}
const _hoisted_3 = ["onClick"]
const _hoisted_4 = ["id", "value", "checked", "onChange"]
const _hoisted_5 = { class: "ml-2 m-0" }

import { ApiAdminGeneralAttributes, ApiResultCorrect } from '@/admin/global/Api/GeneralAttributes';
import { defineComponent, onMounted, onUnmounted, ref, watch } from 'vue';
import { NewConnector } from './NewConnector';


export default /*@__PURE__*/_defineComponent({
  __name: 'NewCombinationsAttributeList',
  setup(__props) {

const attributes = ref<ApiResultCorrect>({});
const openCategory = ref<number | null>(null);
const selectedAttributes = ref<{ [key: number]: { id: number, category_id: number }[] }>({});

function toggleCategory(categoryId: number) {
  openCategory.value = openCategory.value === categoryId ? null : categoryId;
}

function click_subcategory(e: Event, key: string) {
  document.getElementById(key)?.click();
  NewConnector.selected_combinations = getSelectedAttributes();
  NewConnector.selected_combinations_refresh();
}

function toggleSelection(categoryId: number, subcategoryId: number) {
  if (!selectedAttributes.value[categoryId]) {
    selectedAttributes.value[categoryId] = [];
  }
  const index = selectedAttributes.value[categoryId].findIndex(attr => attr.id === subcategoryId);
  if (index === -1) {
    selectedAttributes.value[categoryId].push({ id: subcategoryId, category_id: categoryId });
  } else {
    selectedAttributes.value[categoryId].splice(index, 1);
  }
}

function getSelectedAttributes() {
  const result: string[] = [];
  for (const categoryId in selectedAttributes.value) {
    selectedAttributes.value[categoryId].forEach(attr => {
      result.push(`${attr.category_id}-${attr.id}`);
    });
  }
  return result;
}

function setSelectedAttributes(selected: string[]) {
  // Clear all selected attributes
  for (const categoryId in selectedAttributes.value) {
    selectedAttributes.value[categoryId] = [];
  }

  // Set new selected attributes
  selected.forEach(item => {
    const [categoryId, subcategoryId] = item.split('-').map(Number);
    if (attributes.value[categoryId]) {
      if (!selectedAttributes.value[categoryId]) {
        selectedAttributes.value[categoryId] = [];
      }
      selectedAttributes.value[categoryId].push({ id: subcategoryId, category_id: categoryId });
    }
  });
}

function stopPropagation(event: Event) {
  event.stopPropagation();
}

onMounted(() => {
  ApiAdminGeneralAttributes.request({lang_id: 1}, (result) => {
    attributes.value = result;
  }, () => {
    console.log('error');
  });

  NewConnector.selected_combinations_on_attributes_refresh = () => {
    setSelectedAttributes(NewConnector.selected_combinations);
  };
});

onUnmounted(() => {
  NewConnector.selected_combinations = [];
  NewConnector.selected_combinations_refresh = () => null;
  NewConnector.selected_combinations_on_attributes_refresh = () => null;
});

watch(selectedAttributes, (newVal) => {
  console.log('Selected Attributes:', getSelectedAttributes());
}, { deep: true });

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", null, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(attributes.value, (category, id) => {
      return (_openBlock(), _createElementBlock(_Fragment, { key: id }, [
        _createElementVNode("div", {
          class: _normalizeClass(["item-parent btn", { open: openCategory.value === id, 'btn-primary': openCategory.value === id, 'btn-default': openCategory.value !== id }]),
          onClick: ($event: any) => (toggleCategory(id))
        }, [
          _createTextVNode(_toDisplayString(category.label) + " ", 1),
          _createElementVNode("i", {
            class: _normalizeClass([openCategory.value === id ? 'fa fa-chevron-up' : 'fa fa-chevron-down', "arrow-icon"])
          }, null, 2)
        ], 10, _hoisted_1),
        (openCategory.value === id)
          ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(category.subcategories, (subcategory, subId) => {
                return (_openBlock(), _createElementBlock("div", {
                  key: subId,
                  class: "subcategory",
                  onClick: ($event: any) => (click_subcategory($event, String(id) + '-' + String(subcategory.id)))
                }, [
                  _createElementVNode("input", {
                    type: "checkbox",
                    id: String(id) + '-' + String(subcategory.id),
                    value: subcategory.id,
                    checked: getSelectedAttributes().includes(id + '-' + subcategory.id),
                    onChange: ($event: any) => (toggleSelection(id, subcategory.id)),
                    onClick: _withModifiers(stopPropagation, ["stop"])
                  }, null, 40, _hoisted_4),
                  (subcategory.color && String(subcategory.color).length > 0)
                    ? (_openBlock(), _createElementBlock("span", {
                        key: 0,
                        class: "color-square",
                        style: _normalizeStyle({'background-color': subcategory.color})
                      }, null, 4))
                    : _createCommentVNode("", true),
                  _createElementVNode("label", _hoisted_5, _toDisplayString(subcategory.name), 1)
                ], 8, _hoisted_3))
              }), 128))
            ]))
          : _createCommentVNode("", true)
      ], 64))
    }), 128))
  ]))
}
}

})