import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "tooltip-box position-absolute bg-dark text-white rounded p-2"
}

import { ref } from 'vue';

// Props

export default /*@__PURE__*/_defineComponent({
  __name: 'HelpTooltip',
  props: {
    message: {}
  },
  setup(__props: any) {



// Tooltip visibility state
const isTooltipVisible = ref(false);

// Methods to toggle tooltip visibility
const toggleTooltip = () => {
  isTooltipVisible.value = !isTooltipVisible.value;
};

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", {
    class: "info-tooltip",
    onClick: toggleTooltip
  }, [
    _cache[0] || (_cache[0] = _createElementVNode("i", { class: "fas fa-info-circle text-info" }, null, -1)),
    (isTooltipVisible.value)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, _toDisplayString(_ctx.message), 1))
      : _createCommentVNode("", true)
  ]))
}
}

})