import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  class: "modal fade",
  id: "previewModal",
  tabindex: "-1",
  "aria-labelledby": "previewModalLabel",
  "aria-hidden": "true"
}
const _hoisted_2 = { class: "modal-dialog modal-lg" }
const _hoisted_3 = { class: "modal-content" }
const _hoisted_4 = { class: "modal-header" }
const _hoisted_5 = {
  class: "modal-title",
  id: "previewModalLabel"
}
const _hoisted_6 = { class: "modal-body text-center" }
const _hoisted_7 = ["src"]
const _hoisted_8 = { key: 1 }

import { PropType } from 'vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'ImagePreviewModal',
  props: {
  file: {
    type: Object as PropType<{ name: string, url: string } | null>,
    required: false,
    default: null
  }
},
  setup(__props) {

const props = __props;

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("h5", _hoisted_5, _toDisplayString(__props.file?.name || 'No file selected'), 1),
          _cache[0] || (_cache[0] = _createElementVNode("button", {
            type: "button",
            class: "btn-close",
            "data-bs-dismiss": "modal",
            "aria-label": "Close"
          }, null, -1))
        ]),
        _createElementVNode("div", _hoisted_6, [
          (__props.file)
            ? (_openBlock(), _createElementBlock("img", {
                key: 0,
                src: __props.file?.url || '',
                class: "img-fluid"
              }, null, 8, _hoisted_7))
            : (_openBlock(), _createElementBlock("p", _hoisted_8, "No image to preview"))
        ])
      ])
    ])
  ]))
}
}

})