import { defineComponent as _defineComponent } from 'vue'
import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, unref as _unref, createVNode as _createVNode, createCommentVNode as _createCommentVNode, vModelText as _vModelText, withDirectives as _withDirectives, createTextVNode as _createTextVNode } from "vue"

const _hoisted_1 = { class: "form-group col-12" }
const _hoisted_2 = { class: "col-md-5" }
const _hoisted_3 = {
  key: 0,
  class: "col-md-5"
}
const _hoisted_4 = {
  key: 1,
  class: "col-md-5"
}
const _hoisted_5 = ["onUpdate:modelValue"]
const _hoisted_6 = { class: "col-md-2 d-flex align-items-end" }
const _hoisted_7 = ["onClick"]

import { GeneralFeatures } from "@/admin/global/General/Features";
import { nextTick, onMounted, ref } from "vue";
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";
import { NewConnector } from "./NewConnector";

// Struktura cech
interface Feature {
  id: number;
  name: string;
  predefinedValue: string | null;
  customValue: string | null;
}

// Lista dostępnych cech (dla przykładu)

export default /*@__PURE__*/_defineComponent({
  __name: 'NewFeatures',
  setup(__props) {

const availableFeatures = ref([
  "Indywidualna wartość"
]);

// Lista dostępnych wartości dla cech (dla przykładu)
const predefinedValues = ref({});

// Lista aktualnych cech
const features = ref<Feature[]>([]);

// Dodawanie nowej cechy
const addFeature = () => {
  features.value.push({
    id: Date.now(),
    name: "Indywidualna wartość",
    predefinedValue: null,
    customValue: "",
  });
  NewConnector.features = features.value;
};

// Usuwanie cechy
const removeFeature = (id: number) => {
  features.value = features.value.filter((feature) => feature.id !== id);
  NewConnector.features = features.value;
};

// Resetowanie indywidualnej wartości przy zmianie cechy
const CustomValueChanged = (feature: Feature) => {
  feature.predefinedValue = null;
};

onMounted(() => {
  GeneralFeatures.run_with_data((data) => {
    Object.values(data).forEach(v => {
      if(!availableFeatures.value.includes(v.name)){
        availableFeatures.value.push(v.name);
      }

      if(!Object.keys(predefinedValues.value).includes(v.name)){
        predefinedValues.value[v.name] = [];
      }
      predefinedValues.value[v.name].push(v.value_name);
    });
  });

  features.value = NewConnector.features;
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(features.value, (feature) => {
      return (_openBlock(), _createElementBlock("div", {
        key: feature.id,
        class: "mb-3 row"
      }, [
        _createElementVNode("div", _hoisted_2, [
          _cache[0] || (_cache[0] = _createElementVNode("label", null, "Cecha", -1)),
          _createVNode(_unref(vSelect), {
            modelValue: feature.name,
            "onUpdate:modelValue": [($event: any) => ((feature.name) = $event), ($event: any) => (CustomValueChanged(feature))],
            options: availableFeatures.value,
            reduce: option => option,
            placeholder: "Wybierz cechę"
          }, null, 8, ["modelValue", "onUpdate:modelValue", "options", "reduce"])
        ]),
        (feature.name !== 'Indywidualna wartość')
          ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
              _cache[1] || (_cache[1] = _createElementVNode("label", null, "Wartość", -1)),
              _createVNode(_unref(vSelect), {
                modelValue: feature.predefinedValue,
                "onUpdate:modelValue": ($event: any) => ((feature.predefinedValue) = $event),
                options: predefinedValues.value[feature.name] || [],
                reduce: option => option,
                placeholder: "Wybierz lub wpisz wartość",
                taggable: ""
              }, null, 8, ["modelValue", "onUpdate:modelValue", "options", "reduce"])
            ]))
          : (_openBlock(), _createElementBlock("div", _hoisted_4, [
              _cache[2] || (_cache[2] = _createElementVNode("label", null, "Wartość", -1)),
              _withDirectives(_createElementVNode("input", {
                "onUpdate:modelValue": ($event: any) => ((feature.customValue) = $event),
                type: "text",
                class: "form-control",
                placeholder: "Wpisz wartość"
              }, null, 8, _hoisted_5), [
                [_vModelText, feature.customValue]
              ])
            ])),
        _createElementVNode("div", _hoisted_6, [
          _createElementVNode("button", {
            onClick: ($event: any) => (removeFeature(feature.id)),
            type: "button",
            class: "btn btn-danger"
          }, _cache[3] || (_cache[3] = [
            _createElementVNode("i", { class: "fas fa-trash" }, null, -1)
          ]), 8, _hoisted_7)
        ])
      ]))
    }), 128)),
    _createElementVNode("button", {
      onClick: addFeature,
      type: "button",
      class: "btn btn-primary"
    }, _cache[4] || (_cache[4] = [
      _createElementVNode("i", { class: "fas fa-plus" }, null, -1),
      _createTextVNode(" Dodaj cechę ")
    ]))
  ]))
}
}

})