import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, unref as _unref, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "card" }
const _hoisted_2 = { class: "card-header" }
const _hoisted_3 = { class: "card-title font-weight-bold" }
const _hoisted_4 = { class: "card-body" }

import { ref, onMounted, nextTick, watch, onUnmounted } from 'vue';
import HelpTooltip from '@/admin/components/HelpTooltip.vue';
import Treeselect from 'vue-treeselect-next';
import { ApiAdminCategoriesTree, ApiResult } from '../../../../../global/Api/CategoriesTree';
import { NewConnector } from './NewConnector';
import Required from '@/admin/components/Required.vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'NewCategories',
  setup(__props) {

const tress_options = ref([] as Array<ApiResult>);
const tress_value = ref([] as Array<string>);
const treeselectRef = ref(null as Treeselect | null);
const is_showed = ref(true);

function refresh_categories(with_selected = false):void{
  ApiAdminCategoriesTree.request(v => {
    tress_options.value = v;

    if(with_selected){
      nextTick(() => {
        treeselectRef.value.select({
          ...tress_options.value[0],
          ...{
            isDisabled: false
          }
        });
      });
    }
    else{
      is_showed.value = false;
      nextTick(() => {
        is_showed.value = true;
      });
    }
  });
}

onMounted(() => {
  tress_value.value = NewConnector.categories;
  refresh_categories(NewConnector.categories.length <= 1 ? true : false);
  NewConnector.category_refresh = () => {
    refresh_categories();
  };
});

watch(tress_value, (newValue) => {
  NewConnector.categories = newValue;
});

onUnmounted(() => {
  NewConnector.category_refresh = () => null;
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("h3", _hoisted_3, [
        _cache[1] || (_cache[1] = _createTextVNode("Kategorie ")),
        _createVNode(Required),
        _cache[2] || (_cache[2] = _createTextVNode()),
        _createVNode(HelpTooltip, { message: "Gdzie produkt powinien by dostępny w witrynie? Główną kategorią jest, gdzie produkt pojawia się domyślnie: jest to kategoria, która jest widoczna w adresie URL strony produktu." })
      ])
    ]),
    _createElementVNode("div", _hoisted_4, [
      (is_showed.value)
        ? (_openBlock(), _createBlock(_unref(Treeselect), {
            key: 0,
            modelValue: tress_value.value,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((tress_value).value = $event)),
            multiple: true,
            "default-expand-level": 1,
            options: tress_options.value,
            "allow-selecting-children-only": true,
            flat: true,
            ref_key: "treeselectRef",
            ref: treeselectRef
          }, null, 8, ["modelValue", "options"]))
        : _createCommentVNode("", true)
    ])
  ]))
}
}

})