import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, withModifiers as _withModifiers, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, toDisplayString as _toDisplayString } from "vue"

const _hoisted_1 = { class: "card" }
const _hoisted_2 = { class: "card-header" }
const _hoisted_3 = { class: "card-title font-weight-bold" }
const _hoisted_4 = { class: "card-body" }
const _hoisted_5 = { key: 1 }
const _hoisted_6 = { class: "form-group" }
const _hoisted_7 = {
  class: "form-control",
  name: "category_parent",
  id: "category_parent"
}
const _hoisted_8 = ["value"]
const _hoisted_9 = { class: "form-group d-flex justify-content-center" }

import HelpTooltip from '@/admin/components/HelpTooltip.vue';
import { ApiAdminCategoryNew, ApiParams } from '@/admin/global/Api/CategoryNew';
import { ApiResultCorrect } from '@/admin/global/Api/GeneralCategories';
import { GeneralCategories } from '@/admin/global/General/Categories';
import { Notify } from '@/admin/global/Notify';
import { Form } from '@/web/global/Form';
import { ref, onMounted, onBeforeUnmount } from 'vue';
import { NewConnector } from './NewConnector';


export default /*@__PURE__*/_defineComponent({
  __name: 'NewNewCategory',
  setup(__props) {

const is_creating_now = ref(false);
const categories = ref({} as ApiResultCorrect);

function create_event():void{
  let params:any = {
    name_pl: Form.value("name_pl"),
    name_gb: Form.value("category_name"),
    friendly_url_pl: String(Form.value("category_name")).toLowerCase(),
    friendly_url_gb: String(Form.value("category_name")).toLowerCase(),
    parent_category_id: Form.value("category_parent"),
    is_displayed: '0' 
  };

  params = Object.fromEntries(Object.entries(params).filter(([key, value]) => value && String(value).length >= 1)) as unknown as ApiParams;

  ApiAdminCategoryNew.request(params, () => {
    Form.error_reflesh();
    NewConnector.category_refresh();
    Notify.success('Kategoria dodana pomyślnie!');
    is_creating_now.value = false;
  }, r => {
    Form.error_reflesh(r);
  });
}

function cancel_event():void{
  is_creating_now.value = false;
}

onMounted(() => {
  GeneralCategories.run_with_data((data:ApiResultCorrect) => {
    data = Object.values(data).filter(v => v.id !== 1);
    data = Object.values(data).map(v => {
      v.fullname = String(v.fullname).replace("Bazowa > ", "");
      return v;
    });

    categories.value = data;
  });
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("h3", _hoisted_3, [
        _cache[1] || (_cache[1] = _createTextVNode("Utwórz nową kategorię ")),
        _createVNode(HelpTooltip, { message: "Jeśli chcesz szybko utworzyć nową kategorię, możesz to zrobić tutaj. Pamiętaj, żeby po skończeniu odwiedzić stronę Kategorii i uzupełnić niezbędne dane (opis itp.)." })
      ])
    ]),
    _createElementVNode("div", _hoisted_4, [
      (!is_creating_now.value)
        ? (_openBlock(), _createElementBlock("button", {
            key: 0,
            class: "btn btn-success",
            onClick: _cache[0] || (_cache[0] = _withModifiers(($event: any) => (is_creating_now.value = true), ["prevent"]))
          }, _cache[2] || (_cache[2] = [
            _createElementVNode("i", { class: "fas fa-plus-circle" }, null, -1),
            _createTextVNode(" Tworzenie kategorii ")
          ])))
        : (_openBlock(), _createElementBlock("div", _hoisted_5, [
            _cache[4] || (_cache[4] = _createElementVNode("div", { class: "form-group" }, [
              _createElementVNode("label", { for: "category_name" }, "Nazwa kategorii (PL)"),
              _createElementVNode("input", {
                type: "text",
                class: "form-control",
                name: "name_pl",
                placeholder: "Wpisz nazwę kategorii",
                autocomplete: "off"
              }),
              _createElementVNode("div", { class: "error text-danger" })
            ], -1)),
            _createElementVNode("div", _hoisted_6, [
              _cache[3] || (_cache[3] = _createElementVNode("label", { for: "category_parent" }, "Kategoria nadrzędna", -1)),
              _createElementVNode("select", _hoisted_7, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(categories.value, (item, idx) => {
                  return (_openBlock(), _createElementBlock("option", {
                    key: idx,
                    value: item.id
                  }, _toDisplayString(item.fullname), 9, _hoisted_8))
                }), 128))
              ])
            ]),
            _createElementVNode("div", _hoisted_9, [
              _createElementVNode("button", {
                onClick: _withModifiers(create_event, ["prevent"]),
                class: "btn btn-primary mr-2"
              }, " Utwórz "),
              _createElementVNode("button", {
                onClick: _withModifiers(cancel_event, ["prevent"]),
                class: "btn btn-outline-secondary"
              }, " Anuluj ")
            ])
          ]))
    ])
  ]))
}
}

})