import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, unref as _unref, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "row" }
const _hoisted_2 = { class: "col-6" }
const _hoisted_3 = { class: "col-6" }

import Wysiwyg from '@/admin/components/Wysiwyg.vue';
import { NewConnector } from './NewConnector';
import Required from '@/admin/components/Form/Required.vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'NewDescription',
  setup(__props) {

const initialValuePL = NewConnector.descriptionPL;
const initialValueGB = NewConnector.descriptionGB;

const updateDescriptionPL = (content: string) => {
  NewConnector.descriptionPL = content;
};

const updateDescriptionGB = (content: string) => {
  NewConnector.descriptionGB = content;
};

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("label", null, [
        _cache[0] || (_cache[0] = _createTextVNode("Opis (PL) ")),
        _createVNode(Required)
      ]),
      _createVNode(Wysiwyg, {
        initial_value: _unref(initialValuePL),
        "onUpdate:content": updateDescriptionPL
      }, null, 8, ["initial_value"])
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createElementVNode("label", null, [
        _cache[1] || (_cache[1] = _createTextVNode("Opis (GB) ")),
        _createVNode(Required)
      ]),
      _createVNode(Wysiwyg, {
        initial_value: _unref(initialValueGB),
        "onUpdate:content": updateDescriptionGB
      }, null, 8, ["initial_value"])
    ])
  ]))
}
}

})