import { defineComponent as _defineComponent } from 'vue'
import { openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

import { onMounted, ref } from 'vue';
import NewMainScreen from './New/NewMainScreen.vue';
import { CustomersConnector } from './New/CustomersConnector';


export default /*@__PURE__*/_defineComponent({
  __name: 'New',
  setup(__props) {

const isLoaded = ref<boolean>(false);

onMounted(() => {
  CustomersConnector.customerId = -1;
  CustomersConnector.clientName = '';
  CustomersConnector.firstname = '';
  CustomersConnector.lastname = '';
  CustomersConnector.email = '';
  CustomersConnector.birthday = '';
  CustomersConnector.isactive = true;
  CustomersConnector.newsletter = false;
  isLoaded.value = true;
});

return (_ctx: any,_cache: any) => {
  return (isLoaded.value)
    ? (_openBlock(), _createBlock(NewMainScreen, { key: 0 }))
    : _createCommentVNode("", true)
}
}

})