import { defineComponent as _defineComponent } from 'vue'
import { openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

import { onMounted, ref } from 'vue';
import NewMainScreen from './New/NewMainScreen.vue';
import { NewConnector, ProductStatusType } from './New/NewConnector';


export default /*@__PURE__*/_defineComponent({
  __name: 'New',
  setup(__props) {

const isLoaded = ref<boolean>(false);

onMounted(() => {
  NewConnector.productId = -1;

  if(NewConnector.is_copy === false){
    NewConnector.productNamePL = '';
    NewConnector.productNameGB = '';
    NewConnector.summary_pl = '';
    NewConnector.summary_gb = '';
    NewConnector.descriptionPL = '';
    NewConnector.descriptionGB = '';
    NewConnector.price_net = 0;
    NewConnector.price_gross = 0;
    NewConnector.index = '';
    NewConnector.categories = [];
    NewConnector.features = [];
    NewConnector.related_products = [];
    NewConnector.is_online = false;
    NewConnector.availabilityPreference = 'no-order';
    NewConnector.width = 0;
    NewConnector.height = 0;
    NewConnector.depth = 0;
    NewConnector.weight = 0;
    NewConnector.deliveryTime = '';
    NewConnector.customDeliveryTime = '';
    NewConnector.uploaded_images = [];
    NewConnector.combinations = [];
    NewConnector.selected_combinations = [];
    NewConnector.seoTitlePL = '';
    NewConnector.seoDescriptionPL = '';
    NewConnector.seoUrlPL = '';
    NewConnector.seoTitleGB = '';
    NewConnector.seoDescriptionGB = '';
    NewConnector.seoUrlGB = '';
    NewConnector.specific_price = [];
  }
  
  NewConnector.is_copy = false;
  NewConnector.product_status = ProductStatusType.new;
  isLoaded.value = true;
});

return (_ctx: any,_cache: any) => {
  return (isLoaded.value)
    ? (_openBlock(), _createBlock(NewMainScreen, { key: 0 }))
    : _createCommentVNode("", true)
}
}

})