import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "input-group mb-3" }
const _hoisted_2 = ["name", "type", "placeholder", "autocomplete"]
const _hoisted_3 = {
  key: 0,
  class: "input-group-append"
}
const _hoisted_4 = { class: "input-group-text" }

import { PropType, ref } from 'vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'Input',
  props: {
  name: { type: String, default: '' },
  placeholder: { type: String, default: '' },
  type: { type: String, default: 'text' },
  autocomplete: { type: String, default: '' },
  icon: { type: String, default: '' },
  event_update: { type: Function as PropType<(v:string|number) => void>, default: () => null }
},
  setup(__props) {

const props = __props;

const name = ref(props.name);
const placeholder = ref(props.placeholder);
const type = ref(props.type);
const autocomplete = ref(props.autocomplete);
const icon = ref(props.icon);
const event_update = ref(props.event_update);
let before = '';

function change(e:Event):void{
    const target = e.target as HTMLInputElement;
    const v = target.value;
    if(before !== v){
        event_update.value(v);
        before = v;
    }
}

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("input", {
      class: "form-control",
      name: name.value,
      type: type.value,
      placeholder: placeholder.value,
      autocomplete: autocomplete.value,
      onChange: change,
      onKeyup: change
    }, null, 40, _hoisted_2),
    (icon.value.length > 0)
      ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
          _createElementVNode("div", _hoisted_4, [
            _createElementVNode("span", {
              class: _normalizeClass(icon.value)
            }, null, 2)
          ])
        ]))
      : _createCommentVNode("", true),
    _cache[0] || (_cache[0] = _createElementVNode("span", { class: "error invalid-feedback" }, null, -1))
  ]))
}
}

})