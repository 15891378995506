import { defineComponent as _defineComponent } from 'vue'
import { openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

import { onMounted, ref } from 'vue';
import { useRoute } from 'vue-router'; // Import useRoute
import NewMainScreen from './New/NewMainScreen.vue';
import { ApiAdminProductDetails, ApiResult } from '@/admin/global/Api/ProductDetails';
import { NewConnector, ProductStatusType } from './New/NewConnector';


export default /*@__PURE__*/_defineComponent({
  __name: 'Edit',
  setup(__props) {

const isLoaded = ref<boolean>(false);
const route = useRoute(); // Initialize route

onMounted(() => {
  const product_id = Number(route.params.product_id);
  if(product_id !== NewConnector.productId)
    ApiAdminProductDetails.request({ id: product_id }, (result: ApiResult) => {
      NewConnector.productId = result.id;
      NewConnector.productNamePL = result.productNamePL;
      NewConnector.productNameGB = result.productNameGB;
      NewConnector.summary_pl = result.summary_pl;
      NewConnector.summary_gb = result.summary_gb;
      NewConnector.descriptionPL = result.descriptionPL;
      NewConnector.descriptionGB = result.descriptionGB;
      NewConnector.price_net = result.price_net;
      NewConnector.price_gross = parseFloat((result.price_net * (1 + NewConnector.vat_rate / 100)).toFixed(6));
      NewConnector.index = result.index;
      
      NewConnector.categories = result.categories;
      if(!NewConnector.categories.map(Number).includes(2))
        NewConnector.categories = [ ...['2'], ...NewConnector.categories ];

      NewConnector.features = result.features;
      NewConnector.related_products = result.related_products;
      NewConnector.is_online = result.is_online;
      NewConnector.availabilityPreference = result.availabilityPreference;
      NewConnector.width = result.width;
      NewConnector.height = result.height;
      NewConnector.depth = result.depth;
      NewConnector.weight = result.weight;
      NewConnector.deliveryTime = result.deliveryTime;
      NewConnector.customDeliveryTime = result.customDeliveryTime;
      NewConnector.uploaded_images = result.uploaded_images;
      NewConnector.seoTitlePL = result.seoTitlePL;
      NewConnector.seoDescriptionPL = result.seoDescriptionPL;
      NewConnector.seoUrlPL = result.seoUrlPL;
      NewConnector.seoTitleGB = result.seoTitleGB;
      NewConnector.seoDescriptionGB = result.seoDescriptionGB;
      NewConnector.seoUrlGB = result.seoUrlGB;
      
      NewConnector.combinations = result.combinations.map(v => {
        v.brutto_price = parseFloat((v.price * (1 + NewConnector.vat_rate / 100)).toFixed(6));
        return v;
      });

      NewConnector.selected_combinations = result.combinations.map(c => c.index);
      NewConnector.specific_price = result.specific_price;
      
      NewConnector.product_status = ProductStatusType.edit;
      isLoaded.value = true;
    });
  else{
    NewConnector.product_status = ProductStatusType.edit;
    isLoaded.value = true;
  }
});

return (_ctx: any,_cache: any) => {
  return (isLoaded.value)
    ? (_openBlock(), _createBlock(NewMainScreen, { key: 0 }))
    : _createCommentVNode("", true)
}
}

})