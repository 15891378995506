import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, vModelText as _vModelText, withDirectives as _withDirectives, unref as _unref, vModelSelect as _vModelSelect, resolveComponent as _resolveComponent, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "content" }
const _hoisted_2 = { class: "container-fluid" }
const _hoisted_3 = { class: "row" }
const _hoisted_4 = { class: "col-12" }
const _hoisted_5 = { class: "card" }
const _hoisted_6 = { class: "card-header" }
const _hoisted_7 = { class: "card-title" }
const _hoisted_8 = { class: "card-body" }
const _hoisted_9 = { class: "form-group row" }
const _hoisted_10 = { class: "col-4 col-form-label text-right pr-3" }
const _hoisted_11 = { class: "col-6" }
const _hoisted_12 = { class: "form-group row" }
const _hoisted_13 = { class: "col-4 col-form-label text-right pr-3" }
const _hoisted_14 = { class: "col-6" }
const _hoisted_15 = { class: "form-group row" }
const _hoisted_16 = { class: "col-4 col-form-label text-right pr-3" }
const _hoisted_17 = { class: "col-6" }
const _hoisted_18 = { class: "form-group row" }
const _hoisted_19 = { class: "col-4 col-form-label text-right pr-3" }
const _hoisted_20 = { class: "col-6" }
const _hoisted_21 = {
  key: 0,
  class: "text-muted small"
}
const _hoisted_22 = { class: "form-group row" }
const _hoisted_23 = { class: "form-group row" }
const _hoisted_24 = { class: "col-4 col-form-label text-right pr-3" }
const _hoisted_25 = { class: "col-6" }
const _hoisted_26 = { class: "form-group row" }
const _hoisted_27 = { class: "col-4 col-form-label text-right pr-3" }
const _hoisted_28 = { class: "col-6" }
const _hoisted_29 = { class: "card-footer clearfix" }

import ContentHeader from '@/admin/components/Layouts/ContentHeader.vue';
import BirthdateSelect from '@/admin/components/Form/BirthdateSelect.vue';
import Required from '@/admin/components/Form/Required.vue';
import { ApiAdminCustomerNew, ApiParams } from '@/admin/global/Api/CustomerNew';
import { Form } from '@/web/global/Form';
import { useRouter } from 'vue-router';
import { Notify } from '@/admin/global/Notify';
import { ref } from 'vue';
import { CustomersConnector } from './CustomersConnector';
import { ApiAdminCustomerUpdate, ApiParams as ApiParamsUpdate } from '@/admin/global/Api/CustomerUpdate';
import Datepicker from 'vuejs3-datepicker';


export default /*@__PURE__*/_defineComponent({
  __name: 'NewMainScreen',
  setup(__props) {

const router = useRouter();
const clientName = ref<string>(CustomersConnector.clientName);
const customerId = ref<number>(CustomersConnector.customerId);
const firstname = ref<string>(CustomersConnector.firstname);
const lastname = ref<string>(CustomersConnector.lastname);
const email = ref<string>(CustomersConnector.email);
const password = ref<string>('');
const birthday = ref<string>(CustomersConnector.birthday ?? '');
const isactive = ref<string>(CustomersConnector.isactive ? '1' : '0');
const newsletter = ref<string>(CustomersConnector.newsletter ? '1' : '0');

function event_save(): void {
  const is_new = CustomersConnector.customerId === -1;

  const formData = new FormData();
  if(!is_new) formData.append('id', String(CustomersConnector.customerId));
  formData.append('firstname', firstname.value);
  formData.append('lastname', lastname.value);
  formData.append('email', email.value);
  if(is_new || (!is_new && password.value.length > 0)) formData.append('password', password.value);
  if(birthday.value.length > 0) formData.append('birthday', birthday.value);
  formData.append('isactive', isactive.value);
  formData.append('newsletter', newsletter.value);

  if(is_new)
    ApiAdminCustomerNew.request(formData as unknown as ApiParams, (result) => {
      Form.error_reflesh();
      window.scrollTo(0, 0);

      router.push('/panel/sell/customers/' + result.user_id);
      Notify.success('Klient został ustworzony');
    }, r => {
      Form.error_reflesh(r);
    });
  else
    ApiAdminCustomerUpdate.request(formData as unknown as ApiParamsUpdate, (result) => {
      Form.error_reflesh();
      window.scrollTo(0,0);
      
      router.push('/panel/sell/customers/' + customerId.value);
      Notify.success('Klinet został zaktuazlizowany');
    }, r => {
      Form.error_reflesh(r);
    });
}

return (_ctx: any,_cache: any) => {
  const _component_RouterLink = _resolveComponent("RouterLink")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(ContentHeader, {
      main_title: "Klienci",
      sub_title: customerId.value === -1 ? 'Nowy Klient' : 'Edytowanie klienta'
    }, null, 8, ["sub_title"]),
    _createElementVNode("section", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("div", _hoisted_4, [
            _createElementVNode("div", _hoisted_5, [
              _createElementVNode("div", _hoisted_6, [
                _createElementVNode("h3", _hoisted_7, [
                  _cache[7] || (_cache[7] = _createElementVNode("i", { class: "fas fa-user mr-2" }, null, -1)),
                  _cache[8] || (_cache[8] = _createTextVNode(" Klient")),
                  (customerId.value !== -1)
                    ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                        _createTextVNode(": " + _toDisplayString(clientName.value), 1)
                      ], 64))
                    : _createCommentVNode("", true)
                ])
              ]),
              _createElementVNode("div", _hoisted_8, [
                _cache[28] || (_cache[28] = _createElementVNode("div", { class: "form-group row" }, [
                  _createElementVNode("label", { class: "col-4 col-form-label text-right pr-3" }, "Nazwa kontaktu")
                ], -1)),
                _createElementVNode("div", _hoisted_9, [
                  _createElementVNode("label", _hoisted_10, [
                    _createVNode(Required),
                    _cache[9] || (_cache[9] = _createTextVNode("Imię"))
                  ]),
                  _createElementVNode("div", _hoisted_11, [
                    _withDirectives(_createElementVNode("input", {
                      name: "firstname",
                      type: "text",
                      class: "form-control",
                      placeholder: "Imie",
                      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((firstname).value = $event))
                    }, null, 512), [
                      [_vModelText, firstname.value]
                    ]),
                    _cache[10] || (_cache[10] = _createElementVNode("span", { class: "text-muted small" }, "Tylko litery, kropka (.) i spacja są dozwolone.", -1)),
                    _cache[11] || (_cache[11] = _createElementVNode("div", { class: "error text-danger" }, null, -1))
                  ])
                ]),
                _createElementVNode("div", _hoisted_12, [
                  _createElementVNode("label", _hoisted_13, [
                    _createVNode(Required),
                    _cache[12] || (_cache[12] = _createTextVNode("Nazwisko"))
                  ]),
                  _createElementVNode("div", _hoisted_14, [
                    _withDirectives(_createElementVNode("input", {
                      name: "lastname",
                      type: "text",
                      class: "form-control",
                      placeholder: "Nazwisko",
                      "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((lastname).value = $event))
                    }, null, 512), [
                      [_vModelText, lastname.value]
                    ]),
                    _cache[13] || (_cache[13] = _createElementVNode("span", { class: "text-muted small" }, "Tylko litery, kropka (.) i spacja są dozwolone.", -1)),
                    _cache[14] || (_cache[14] = _createElementVNode("div", { class: "error text-danger" }, null, -1))
                  ])
                ]),
                _createElementVNode("div", _hoisted_15, [
                  _createElementVNode("label", _hoisted_16, [
                    _createVNode(Required),
                    _cache[15] || (_cache[15] = _createTextVNode("Email"))
                  ]),
                  _createElementVNode("div", _hoisted_17, [
                    _withDirectives(_createElementVNode("input", {
                      name: "email",
                      type: "email",
                      class: "form-control",
                      placeholder: "Email",
                      "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((email).value = $event))
                    }, null, 512), [
                      [_vModelText, email.value]
                    ]),
                    _cache[16] || (_cache[16] = _createElementVNode("div", { class: "error text-danger" }, null, -1))
                  ])
                ]),
                _createElementVNode("div", _hoisted_18, [
                  _createElementVNode("label", _hoisted_19, [
                    _createVNode(Required),
                    _cache[17] || (_cache[17] = _createTextVNode("Hasło"))
                  ]),
                  _createElementVNode("div", _hoisted_20, [
                    _withDirectives(_createElementVNode("input", {
                      name: "password",
                      type: "password",
                      class: "form-control",
                      placeholder: "Hasło",
                      "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((password).value = $event))
                    }, null, 512), [
                      [_vModelText, password.value]
                    ]),
                    (customerId.value !== -1)
                      ? (_openBlock(), _createElementBlock("span", _hoisted_21, "Hasło zostanie zaktualizowane tylko w przypadku wypełnienia pola."))
                      : _createCommentVNode("", true),
                    _cache[18] || (_cache[18] = _createElementVNode("div", { class: "error text-danger" }, null, -1))
                  ])
                ]),
                _createElementVNode("div", _hoisted_22, [
                  _cache[19] || (_cache[19] = _createElementVNode("label", { class: "col-4 col-form-label text-right pr-3" }, "Urodziny", -1)),
                  _createVNode(_unref(Datepicker), {
                    modelValue: birthday.value,
                    "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((birthday).value = $event)),
                    language: "pl",
                    format: "yyyy/MM/dd"
                  }, null, 8, ["modelValue"])
                ]),
                _createElementVNode("div", _hoisted_23, [
                  _createElementVNode("label", _hoisted_24, [
                    _createVNode(Required),
                    _cache[20] || (_cache[20] = _createTextVNode("Włączony"))
                  ]),
                  _createElementVNode("div", _hoisted_25, [
                    _withDirectives(_createElementVNode("select", {
                      class: "form-control",
                      name: "isactive",
                      "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((isactive).value = $event))
                    }, _cache[21] || (_cache[21] = [
                      _createElementVNode("option", { value: "1" }, "Tak", -1),
                      _createElementVNode("option", { value: "0" }, "Nie", -1)
                    ]), 512), [
                      [_vModelSelect, isactive.value]
                    ]),
                    _cache[22] || (_cache[22] = _createElementVNode("span", { class: "text-muted small" }, "Włączyć lub wyłączyć logowanie klienta.", -1)),
                    _cache[23] || (_cache[23] = _createElementVNode("div", { class: "error text-danger" }, null, -1))
                  ])
                ]),
                _createElementVNode("div", _hoisted_26, [
                  _createElementVNode("label", _hoisted_27, [
                    _createVNode(Required),
                    _cache[24] || (_cache[24] = _createTextVNode("Newsletter"))
                  ]),
                  _createElementVNode("div", _hoisted_28, [
                    _withDirectives(_createElementVNode("select", {
                      class: "form-control",
                      name: "newsletter",
                      "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((newsletter).value = $event))
                    }, _cache[25] || (_cache[25] = [
                      _createElementVNode("option", { value: "1" }, "Tak", -1),
                      _createElementVNode("option", { value: "0" }, "Nie", -1)
                    ]), 512), [
                      [_vModelSelect, newsletter.value]
                    ]),
                    _cache[26] || (_cache[26] = _createElementVNode("span", { class: "text-muted small" }, "Włączyć lub wyłączyć newsletter klienta.", -1)),
                    _cache[27] || (_cache[27] = _createElementVNode("div", { class: "error text-danger" }, null, -1))
                  ])
                ])
              ]),
              _createElementVNode("div", _hoisted_29, [
                _createVNode(_component_RouterLink, {
                  to: "/panel/sell/customers",
                  class: "btn btn-secondary float-left"
                }, {
                  default: _withCtx(() => _cache[29] || (_cache[29] = [
                    _createTextVNode("Anuluj")
                  ])),
                  _: 1
                }),
                _createElementVNode("button", {
                  onClick: event_save,
                  type: "button",
                  class: "btn btn-primary float-right"
                }, "Zapisz")
              ])
            ])
          ])
        ])
      ])
    ])
  ], 64))
}
}

})