import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, unref as _unref, toDisplayString as _toDisplayString, withModifiers as _withModifiers, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "content content-full-height" }
const _hoisted_2 = { class: "container-fluid" }
const _hoisted_3 = { class: "row" }
const _hoisted_4 = { class: "col-12" }
const _hoisted_5 = { class: "card" }
const _hoisted_6 = { class: "card-header" }
const _hoisted_7 = { class: "card-title" }
const _hoisted_8 = { class: "card-tools" }
const _hoisted_9 = { class: "nav nav-pills ml-auto" }
const _hoisted_10 = { class: "nav-item" }
const _hoisted_11 = { class: "btn-group" }
const _hoisted_12 = {
  class: "dropdown-menu",
  role: "menu"
}
const _hoisted_13 = {
  class: "card-body table-responsive p-0",
  style: {"height":"500px"}
}
const _hoisted_14 = { class: "table table-head-fixed text-nowrap" }
const _hoisted_15 = {
  key: 0,
  class: "fas fa-sort-down ml-2"
}
const _hoisted_16 = {
  key: 1,
  class: "fas fa-sort-up ml-2"
}
const _hoisted_17 = {
  key: 0,
  class: "fas fa-sort-down ml-2"
}
const _hoisted_18 = {
  key: 1,
  class: "fas fa-sort-up ml-2"
}
const _hoisted_19 = {
  key: 0,
  class: "fas fa-sort-down ml-2"
}
const _hoisted_20 = {
  key: 1,
  class: "fas fa-sort-up ml-2"
}
const _hoisted_21 = {
  key: 0,
  class: "fas fa-sort-down ml-2"
}
const _hoisted_22 = {
  key: 1,
  class: "fas fa-sort-up ml-2"
}
const _hoisted_23 = {
  key: 0,
  class: "fas fa-sort-down ml-2"
}
const _hoisted_24 = {
  key: 1,
  class: "fas fa-sort-up ml-2"
}
const _hoisted_25 = {
  key: 0,
  class: "fas fa-sort-down ml-2"
}
const _hoisted_26 = {
  key: 1,
  class: "fas fa-sort-up ml-2"
}
const _hoisted_27 = {
  key: 0,
  class: "fas fa-sort-down ml-2"
}
const _hoisted_28 = {
  key: 1,
  class: "fas fa-sort-up ml-2"
}
const _hoisted_29 = { class: "filter-row" }
const _hoisted_30 = ["value"]
const _hoisted_31 = { class: "btn-group" }
const _hoisted_32 = {
  class: "dropdown-menu",
  role: "menu"
}
const _hoisted_33 = ["onClick"]
const _hoisted_34 = { key: 0 }
const _hoisted_35 = { class: "card-footer clearfix" }

import ContentHeader from '@/admin/components/Layouts/ContentHeader.vue';
import { ApiAdminAddressesSearch, ApiResultDataSingle } from '@/admin/global/Api/AddressesSearch';
import Pager from '@/admin/components/Pager.vue';
import { ref } from 'vue';
import { ApiAdminAddressesExport } from '@/admin/global/Api/AddressesExport';
import { ApiParams } from "@/admin/global/Api/AddressesSearch";
import { Form } from '@/web/global/Form';
import { GeneralCountries } from '@/admin/global/General/Countries';
import { ApiAdminAddressDelete } from '@/admin/global/Api/AddressDelete';
import { Notify } from '@/web/global/Notify';

const item_per_page = 50;

export default /*@__PURE__*/_defineComponent({
  __name: 'Addresses',
  setup(__props) {

let item_max = ref(0);
let addresses = ref([] as Array<ApiResultDataSingle>);
let actual_page = 1;

let sort_by = ref('id');
let sort_order = ref('asc');

const countries = ref({});

function get_search_params(page = -1, per_page = -1):ApiParams{
  let params:ApiParams = {};

  const params_name = ['id', 'firstname', 'lastname', 'address', 'postcode', 'city', 'country_id'];
  params_name.forEach(name => {
    const v = (Form.value(name) + '').trim();

    if(v.length > 0){
      params[name] = v;
    }
  });

  if(page !== -1){
    params['page'] = page;
    params['per_page'] = per_page;
  }

  params["sort_by"] = sort_by.value;
  params["sort_order"] = sort_order.value === 'asc' ? 'asc' : 'desc';

  return params;
}

function search(page: number) {
  ApiAdminAddressesSearch.request(get_search_params(page, item_per_page), result => {
    addresses.value = result.data;
    item_max.value = result.data_count;
    actual_page = page;
  }, () => {
    console.log('error');

    addresses.value = [];
    item_max.value = 0;
  });
}

function get_country_by_id(id: number):string{
  return countries.value[id] || '';
}

function data_export(){
  ApiAdminAddressesExport.request(get_search_params());
}

function order_by(name: string){
  if(sort_by.value === name){
    sort_order.value = sort_order.value === 'asc' ? 'desc' : 'asc';
  }
  else{
    sort_by.value = name;
    sort_order.value = 'asc';
  }

  search(actual_page);
}

function prepare_delete_address(id: number): void {
  Notify.confirm('Czy na pewno chcesz usunąć adres: #' + id + '?', () => {
    delete_address(id);
  }, 'Usuń adres', 'Anuluj');
}

function delete_address(id:number):void{
  ApiAdminAddressDelete.request({id}, () => {
    search(actual_page);
    Notify.success('Adres: #' + id + ' został usunięty');
  }, () => {
    console.log('delete error: ' + id);
  });
}

GeneralCountries.run_with_data(data => {
  countries.value = data;
});

search(actual_page);

return (_ctx: any,_cache: any) => {
  const _component_RouterLink = _resolveComponent("RouterLink")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(ContentHeader, {
      main_title: "Klienci > Adresy",
      sub_title: "Zarządzanie Adresami"
    }, {
      default: _withCtx(() => [
        _createVNode(_component_RouterLink, {
          to: "/panel/sell/addresses/new",
          class: "btn btn-primary"
        }, {
          default: _withCtx(() => _cache[17] || (_cache[17] = [
            _createElementVNode("i", { class: "fas fa-plus-circle" }, null, -1),
            _createTextVNode(" Dodaj nowy adres ")
          ])),
          _: 1
        })
      ]),
      _: 1
    }),
    _createElementVNode("section", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("div", _hoisted_4, [
            _createElementVNode("div", _hoisted_5, [
              _createElementVNode("div", _hoisted_6, [
                _createElementVNode("h3", _hoisted_7, "Zarządzanie Adresami (" + _toDisplayString(_unref(item_max)) + ")", 1),
                _createElementVNode("div", _hoisted_8, [
                  _createElementVNode("ul", _hoisted_9, [
                    _createElementVNode("li", _hoisted_10, [
                      _createElementVNode("div", _hoisted_11, [
                        _createElementVNode("button", {
                          class: "btn btn-default btn-sm",
                          onClick: _cache[0] || (_cache[0] = _withModifiers(($event: any) => (data_export()), ["prevent"]))
                        }, _cache[18] || (_cache[18] = [
                          _createElementVNode("i", { class: "fas fa-download mr-1" }, null, -1),
                          _createTextVNode(" Eksportuj ")
                        ])),
                        _cache[21] || (_cache[21] = _createElementVNode("button", {
                          type: "button",
                          class: "btn btn-default btn-sm dropdown-toggle dropdown-icon",
                          "data-toggle": "dropdown",
                          "aria-expanded": "false"
                        }, [
                          _createElementVNode("span", { class: "sr-only" }, "Zobacz więcej")
                        ], -1)),
                        _createElementVNode("div", _hoisted_12, [
                          _createElementVNode("button", {
                            class: "dropdown-item",
                            onClick: _cache[1] || (_cache[1] = _withModifiers(($event: any) => (data_export()), ["prevent"]))
                          }, _cache[19] || (_cache[19] = [
                            _createElementVNode("i", { class: "fas fa-download mr-1" }, null, -1),
                            _createTextVNode(" Eksportuj ")
                          ])),
                          _createElementVNode("button", {
                            class: "dropdown-item",
                            onClick: _cache[2] || (_cache[2] = _withModifiers(($event: any) => (search(_unref(actual_page))), ["prevent"]))
                          }, _cache[20] || (_cache[20] = [
                            _createElementVNode("i", { class: "fas fa-sync-alt mr-1" }, null, -1),
                            _createTextVNode(" Odśwież liste ")
                          ]))
                        ])
                      ])
                    ])
                  ])
                ])
              ]),
              _createElementVNode("div", _hoisted_13, [
                _createElementVNode("table", _hoisted_14, [
                  _createElementVNode("thead", null, [
                    _createElementVNode("tr", null, [
                      _createElementVNode("th", {
                        class: "th-sortable",
                        onClick: _cache[3] || (_cache[3] = ($event: any) => (order_by('id')))
                      }, [
                        _cache[22] || (_cache[22] = _createTextVNode(" ID ")),
                        (_unref(sort_by) == 'id' && _unref(sort_order) == 'asc')
                          ? (_openBlock(), _createElementBlock("i", _hoisted_15))
                          : _createCommentVNode("", true),
                        (_unref(sort_by) == 'id' && _unref(sort_order) == 'desc')
                          ? (_openBlock(), _createElementBlock("i", _hoisted_16))
                          : _createCommentVNode("", true)
                      ]),
                      _createElementVNode("th", {
                        class: "th-sortable",
                        onClick: _cache[4] || (_cache[4] = ($event: any) => (order_by('firstname')))
                      }, [
                        _cache[23] || (_cache[23] = _createTextVNode(" Imię ")),
                        (_unref(sort_by) == 'firstname' && _unref(sort_order) == 'asc')
                          ? (_openBlock(), _createElementBlock("i", _hoisted_17))
                          : _createCommentVNode("", true),
                        (_unref(sort_by) == 'firstname' && _unref(sort_order) == 'desc')
                          ? (_openBlock(), _createElementBlock("i", _hoisted_18))
                          : _createCommentVNode("", true)
                      ]),
                      _createElementVNode("th", {
                        class: "th-sortable",
                        onClick: _cache[5] || (_cache[5] = ($event: any) => (order_by('lastname')))
                      }, [
                        _cache[24] || (_cache[24] = _createTextVNode(" Nazwisko ")),
                        (_unref(sort_by) == 'lastname' && _unref(sort_order) == 'asc')
                          ? (_openBlock(), _createElementBlock("i", _hoisted_19))
                          : _createCommentVNode("", true),
                        (_unref(sort_by) == 'lastname' && _unref(sort_order) == 'desc')
                          ? (_openBlock(), _createElementBlock("i", _hoisted_20))
                          : _createCommentVNode("", true)
                      ]),
                      _createElementVNode("th", {
                        class: "th-sortable",
                        onClick: _cache[6] || (_cache[6] = ($event: any) => (order_by('address')))
                      }, [
                        _cache[25] || (_cache[25] = _createTextVNode(" Adres ")),
                        (_unref(sort_by) == 'address' && _unref(sort_order) == 'asc')
                          ? (_openBlock(), _createElementBlock("i", _hoisted_21))
                          : _createCommentVNode("", true),
                        (_unref(sort_by) == 'address' && _unref(sort_order) == 'desc')
                          ? (_openBlock(), _createElementBlock("i", _hoisted_22))
                          : _createCommentVNode("", true)
                      ]),
                      _createElementVNode("th", {
                        class: "th-sortable",
                        onClick: _cache[7] || (_cache[7] = ($event: any) => (order_by('postcode')))
                      }, [
                        _cache[26] || (_cache[26] = _createTextVNode(" Kod pocztowy ")),
                        (_unref(sort_by) == 'postcode' && _unref(sort_order) == 'asc')
                          ? (_openBlock(), _createElementBlock("i", _hoisted_23))
                          : _createCommentVNode("", true),
                        (_unref(sort_by) == 'postcode' && _unref(sort_order) == 'desc')
                          ? (_openBlock(), _createElementBlock("i", _hoisted_24))
                          : _createCommentVNode("", true)
                      ]),
                      _createElementVNode("th", {
                        class: "th-sortable",
                        onClick: _cache[8] || (_cache[8] = ($event: any) => (order_by('city')))
                      }, [
                        _cache[27] || (_cache[27] = _createTextVNode(" Miasto ")),
                        (_unref(sort_by) == 'city' && _unref(sort_order) == 'asc')
                          ? (_openBlock(), _createElementBlock("i", _hoisted_25))
                          : _createCommentVNode("", true),
                        (_unref(sort_by) == 'city' && _unref(sort_order) == 'desc')
                          ? (_openBlock(), _createElementBlock("i", _hoisted_26))
                          : _createCommentVNode("", true)
                      ]),
                      _createElementVNode("th", {
                        class: "th-sortable",
                        onClick: _cache[9] || (_cache[9] = ($event: any) => (order_by('country_id')))
                      }, [
                        _cache[28] || (_cache[28] = _createTextVNode(" Kraj ")),
                        (_unref(sort_by) == 'country_id' && _unref(sort_order) == 'asc')
                          ? (_openBlock(), _createElementBlock("i", _hoisted_27))
                          : _createCommentVNode("", true),
                        (_unref(sort_by) == 'country_id' && _unref(sort_order) == 'desc')
                          ? (_openBlock(), _createElementBlock("i", _hoisted_28))
                          : _createCommentVNode("", true)
                      ]),
                      _cache[29] || (_cache[29] = _createElementVNode("th", null, "Akcje", -1))
                    ]),
                    _createElementVNode("tr", _hoisted_29, [
                      _createElementVNode("th", null, [
                        _createElementVNode("input", {
                          class: "form-control form-control-sm",
                          type: "text",
                          placeholder: "ID",
                          onInput: _cache[10] || (_cache[10] = ($event: any) => (search(1))),
                          name: "id"
                        }, null, 32)
                      ]),
                      _createElementVNode("th", null, [
                        _createElementVNode("input", {
                          class: "form-control form-control-sm",
                          type: "text",
                          placeholder: "Imię",
                          onInput: _cache[11] || (_cache[11] = ($event: any) => (search(1))),
                          name: "firstname"
                        }, null, 32)
                      ]),
                      _createElementVNode("th", null, [
                        _createElementVNode("input", {
                          class: "form-control form-control-sm",
                          type: "text",
                          placeholder: "Nazwisko",
                          onInput: _cache[12] || (_cache[12] = ($event: any) => (search(1))),
                          name: "lastname"
                        }, null, 32)
                      ]),
                      _createElementVNode("th", null, [
                        _createElementVNode("input", {
                          class: "form-control form-control-sm",
                          type: "text",
                          placeholder: "Adres",
                          onInput: _cache[13] || (_cache[13] = ($event: any) => (search(1))),
                          name: "address"
                        }, null, 32)
                      ]),
                      _createElementVNode("th", null, [
                        _createElementVNode("input", {
                          class: "form-control form-control-sm",
                          type: "text",
                          placeholder: "Kod pocztowy",
                          onInput: _cache[14] || (_cache[14] = ($event: any) => (search(1))),
                          name: "postcode"
                        }, null, 32)
                      ]),
                      _createElementVNode("th", null, [
                        _createElementVNode("input", {
                          class: "form-control form-control-sm",
                          type: "text",
                          placeholder: "Miasto",
                          onInput: _cache[15] || (_cache[15] = ($event: any) => (search(1))),
                          name: "city"
                        }, null, 32)
                      ]),
                      _createElementVNode("th", null, [
                        _createElementVNode("select", {
                          class: "form-control form-control-sm",
                          style: {"width":"80px"},
                          onChange: _cache[16] || (_cache[16] = ($event: any) => (search(1))),
                          name: "country_id"
                        }, [
                          _cache[30] || (_cache[30] = _createElementVNode("option", {
                            value: "",
                            selected: ""
                          }, "Niewybrane", -1)),
                          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(countries.value, (name, id) => {
                            return (_openBlock(), _createElementBlock("option", { value: id }, _toDisplayString(name), 9, _hoisted_30))
                          }), 256))
                        ], 32)
                      ])
                    ])
                  ]),
                  _createElementVNode("tbody", null, [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(addresses), (address) => {
                      return (_openBlock(), _createElementBlock("tr", null, [
                        _createElementVNode("td", null, _toDisplayString(address.id), 1),
                        _createElementVNode("td", null, _toDisplayString(address.firstname), 1),
                        _createElementVNode("td", null, _toDisplayString(address.lastname), 1),
                        _createElementVNode("td", null, _toDisplayString(address.address), 1),
                        _createElementVNode("td", null, _toDisplayString(address.postcode), 1),
                        _createElementVNode("td", null, _toDisplayString(address.city), 1),
                        _createElementVNode("td", null, _toDisplayString(get_country_by_id(address.country_id)), 1),
                        _createElementVNode("td", null, [
                          _createElementVNode("div", _hoisted_31, [
                            _createVNode(_component_RouterLink, {
                              to: '/panel/sell/addresses/' + address.id,
                              class: "btn btn-default btn-sm"
                            }, {
                              default: _withCtx(() => _cache[31] || (_cache[31] = [
                                _createElementVNode("i", { class: "fas fa-eye mr-1" }, null, -1),
                                _createTextVNode(" Zobacz ")
                              ])),
                              _: 2
                            }, 1032, ["to"]),
                            _cache[35] || (_cache[35] = _createElementVNode("button", {
                              type: "button",
                              class: "btn btn-default btn-sm dropdown-toggle dropdown-icon",
                              "data-toggle": "dropdown",
                              "aria-expanded": "false"
                            }, [
                              _createElementVNode("span", { class: "sr-only" }, "Zobacz więcej")
                            ], -1)),
                            _createElementVNode("div", _hoisted_32, [
                              _createVNode(_component_RouterLink, {
                                to: '/panel/sell/addresses/' + address.id,
                                class: "dropdown-item"
                              }, {
                                default: _withCtx(() => _cache[32] || (_cache[32] = [
                                  _createElementVNode("i", { class: "fas fa-eye mr-1" }, null, -1),
                                  _createTextVNode(" Zobacz ")
                                ])),
                                _: 2
                              }, 1032, ["to"]),
                              _createVNode(_component_RouterLink, {
                                to: '/panel/sell/addresses/' + address.id + '/edit',
                                class: "dropdown-item"
                              }, {
                                default: _withCtx(() => _cache[33] || (_cache[33] = [
                                  _createElementVNode("i", { class: "fas fa-pen mr-1" }, null, -1),
                                  _createTextVNode(" Edytuj ")
                                ])),
                                _: 2
                              }, 1032, ["to"]),
                              (!address.is_deleted)
                                ? (_openBlock(), _createElementBlock("a", {
                                    key: 0,
                                    class: "dropdown-item",
                                    href: "#",
                                    onClick: _withModifiers(($event: any) => (prepare_delete_address(address.id)), ["prevent"])
                                  }, _cache[34] || (_cache[34] = [
                                    _createElementVNode("i", { class: "fas fa-trash mr-1" }, null, -1),
                                    _createTextVNode(" Usuń ")
                                  ]), 8, _hoisted_33))
                                : _createCommentVNode("", true)
                            ])
                          ])
                        ])
                      ]))
                    }), 256)),
                    (_unref(addresses).length === 0)
                      ? (_openBlock(), _createElementBlock("tr", _hoisted_34, _cache[36] || (_cache[36] = [
                          _createElementVNode("td", {
                            colspan: "9",
                            class: "text-center"
                          }, "Brak wyników", -1)
                        ])))
                      : _createCommentVNode("", true)
                  ])
                ])
              ]),
              _createElementVNode("div", _hoisted_35, [
                _createVNode(Pager, {
                  now_page: 1,
                  item_max: _unref(item_max),
                  item_per_page: item_per_page,
                  event_update: search
                }, null, 8, ["item_max"])
              ])
            ])
          ])
        ])
      ])
    ])
  ], 64))
}
}

})