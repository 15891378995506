import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withModifiers as _withModifiers } from "vue"

const _hoisted_1 = { class: "login-box" }
const _hoisted_2 = { class: "card" }
const _hoisted_3 = { class: "card-body login-card-body" }
const _hoisted_4 = { class: "row" }
const _hoisted_5 = { class: "col-8" }
const _hoisted_6 = {
  key: 0,
  class: "icheck-primary"
}
const _hoisted_7 = {
  key: 0,
  class: "mb-1"
}

import { Form } from '@/web/global/Form';
import Input from '../components/Form/Input.vue';
import { ApiAdminLogin } from '../global/Api/Login';
import { ApiAdminAccount } from '../global/Api/Account';
import { useRouter } from 'vue-router';


export default /*@__PURE__*/_defineComponent({
  __name: 'Login',
  setup(__props) {

function event_login():void{
  // Login
  ApiAdminLogin.request({
      email: Form.value("email"),
      password: Form.value("password")
  }, () => {
    Form.error_reflesh();

    // Account
    ApiAdminAccount.request(() => {
      document.location.href = '/panel';
    }, () => {
      Form.error_reflesh({
          errors: { 'email': ['Wystąpił problem podczas logowania.'] }
      });
    });
  }, r => {
    Form.error_reflesh(r);
  });
}

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _cache[5] || (_cache[5] = _createElementVNode("div", { class: "login-logo" }, [
      _createElementVNode("a", null, [
        _createElementVNode("b", null, "Apearl"),
        _createTextVNode(" - Admin")
      ])
    ], -1)),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _cache[4] || (_cache[4] = _createElementVNode("p", { class: "login-box-msg" }, "Logowanie do panelu Administratora.", -1)),
        _createElementVNode("form", {
          onSubmit: _cache[0] || (_cache[0] = _withModifiers(($event: any) => (event_login()), ["prevent"]))
        }, [
          _createVNode(Input, {
            name: "email",
            type: "email",
            placeholder: "Email",
            icon: "fas fa-envelope"
          }),
          _createVNode(Input, {
            name: "password",
            type: "password",
            placeholder: "Hasło",
            icon: "fas fa-lock"
          }),
          _createElementVNode("div", _hoisted_4, [
            _createElementVNode("div", _hoisted_5, [
              false
                ? (_openBlock(), _createElementBlock("div", _hoisted_6, _cache[1] || (_cache[1] = [
                    _createElementVNode("input", {
                      type: "checkbox",
                      id: "remember"
                    }, null, -1),
                    _createElementVNode("label", { for: "remember" }, " Zapamiętaj mnie ", -1)
                  ])))
                : _createCommentVNode("", true)
            ]),
            _cache[2] || (_cache[2] = _createElementVNode("div", { class: "col-4" }, [
              _createElementVNode("button", {
                type: "submit",
                class: "btn btn-primary btn-block"
              }, "Zaloguj")
            ], -1))
          ])
        ], 32),
        false
          ? (_openBlock(), _createElementBlock("p", _hoisted_7, _cache[3] || (_cache[3] = [
              _createElementVNode("a", { href: "forgot-password.html" }, "Zapomniałem hasła", -1)
            ])))
          : _createCommentVNode("", true)
      ])
    ])
  ]))
}
}

})